import {
    Drawer,
    FormControl,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Theme,
    makeStyles,
} from '@material-ui/core'
import UiText from './ui/UiText'
import { useEffect, useRef, useState } from 'react'
import { mergeStyle, toRem16 } from '../../styles/commonStyles'
import UiButton from './ui/UiButton'
import { useThemeContext } from './whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../styles/models/Colors.interface'
import { useVtoContext } from '../taxes/vto/providers/VtoProvider'
import CloseIcon from '@material-ui/icons/Close'
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded'

import Icon from './Icon'
import useDeviceSize from '../../hooks/useDeviceSize'
import { getVtoDetails, updateVtoData } from '../../services/vto/vtoCore'
import { FAQ } from '../taxes/vto/models/VtoDetails.model'
import { useCurrentStore } from './hooks/useCurrentStore'
const improveOptions = [
    'I had a different question',
    'The content didn’t answer my question',
    'Something Else',
    'I understand the answer, but I don’t know what to do next',
]

const useStyles = makeStyles<Theme, {isAdmin: boolean, colorTheme: ThemeColors}>((theme) => {
    return {
        extendedDrawer: {
            flexShrink: 0,

            [theme.breakpoints.up('xl')]: {
                '&.extendedDrawerCustom': {
                    '& .MuiBackdrop-root': {
                        display: 'none',
                    },
                    '& .MuiPaper-root': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        extendedDrawerPaper: {
            width: '100%',
            padding: 0,
            boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.16)',
            top: ({isAdmin})  => isAdmin ? '3rem' : 0,
            height: ({isAdmin})  => isAdmin ? `calc(100% - 3rem)`  : '100%',

            [theme.breakpoints.up('sm')]: {
                maxWidth: `calc(${toRem16(320)} + 5px)`,
                borderLeft: ({colorTheme}) => `1px solid ${colorTheme.grey200}`,
            },

            [theme.breakpoints.up('xl')]: {
                top: ({isAdmin})  => isAdmin ? '7rem' : '4rem',
                height: ({isAdmin})  => isAdmin ? `calc(100% - 7rem)`  : `calc(100% - 4rem)`,
                borderTop: ({colorTheme}) => `1px solid ${colorTheme.grey200}`,
            },

            '&.MuiDrawer-paperAnchorBottom': {
                top: 0,
            },
        },
        drawerHeader: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1rem',
            borderBottom: ({colorTheme}) => `1px solid ${colorTheme.grey200}`,
            '& > .cta-container': {
                display: 'flex',
                alignItems: 'center',
                '& > p': {
                    marginLeft: '1rem',
                    [theme.breakpoints.up('xl')]: {
                        marginLeft: 0
                    },
                },
            },
        },
        closeIconButton: {
            borderRadius: '0.5rem',
            backgroundColor: ({colorTheme}) => colorTheme.grey100,
            padding: '0.5rem',
        },
        drawerContent: {
            flex: 1,
            paddingTop: '1rem',
            overflowY: 'auto',
            padding: '1.5rem 1rem',
        },
        faqCard: {
            border: ({colorTheme}) => `1px solid ${colorTheme.grey200}`,
            borderRadius: '0.5rem',
            padding: '1rem',
            marginBottom: '1rem',
        },
        readMoreBtn: {
            paddingLeft: 0,
            ['& p']: {
                textDecoration: 'underline',
            }
        },
        btnContainer: {
            display: 'flex',
            gap: '1.5rem',
            margin: '1rem 0',

            ['& .active']: {
                border: ({colorTheme}) => `2px solid ${colorTheme.tertiary}`,
                background: ({colorTheme}) => colorTheme.cyan100,
            },
        },
        improveContainer: {
            marginBottom: '1.5rem',
        },
        radio: {
            '& .MuiRadio-colorSecondary.Mui-checked': {
                color: ({colorTheme}) => colorTheme.tertiary,
            },
        },
        faqHTMLContent: {
            '& *': {
                fontSize: '0.875rem !important',
            },
            '& li': {
                marginBottom: '0.5rem',
            },
            '& a': {
                color: ({colorTheme}) => colorTheme.blue200,
            }
        },
        removeIcon: {
            position: 'absolute',
            top: '-4px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: ({colorTheme}) => colorTheme.grey800,
        },
        fixedCloseBtn: {
            position: 'fixed',
            bottom: 0,
            width: '100%',
            boxShadow: '-2px -2px 12px 0px #0000001A',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: toRem16(74),
            backgroundColor: ({colorTheme}) => colorTheme.primaryWhite,
        },
        placeholder: {
            height: toRem16(74),
        },
        mb8: {
            marginBottom: '0.5rem',
        },
        mb16: {
            marginBottom: '1rem'
        },
        mt24: {
            marginTop: '1.5rem'
        },
        mb10: {
            marginBottom: '10px'
        }
    }
})

export default function FaqDrawer() {
    const [showImproveOptions, setShowImproveOptions] = useState(false)
    const [responseSaved, setResponseSaved] = useState(false)
    const [vtoData, setVtoData] = useState<any>({})
    const yesBtnRef = useRef<HTMLButtonElement>(null)
    const noBtnRef = useRef<HTMLButtonElement>(null)
    const {
        currentStepFaqs,
        setCurrentStepFaqs,
        openFaqs: open,
        setOpenFaqs: setOpen,
        currentVtoDetails,
        selectedYear,
    } = useVtoContext()
    const { isXsDevice, isXLDesktop } = useDeviceSize()
    const { currentUser } = useCurrentStore()
    const isAdmin = currentUser?.accountant_mode;

    const { colorTheme } = useThemeContext()
    const styles = useStyles({isAdmin, colorTheme})
    const vtoId = currentVtoDetails?.id
    const selectedFaq = currentStepFaqs.find((faq) => faq.selected)

    useEffect(() => {
        if (!vtoId) return
        getVtoDetails(selectedYear, vtoId).then((res) => {
            setVtoData(res)
        })
    }, [vtoId])

    useEffect(() => {
        if (!selectedFaq?.id || !isXLDesktop) return
        setResponseSaved(false)
        setShowImproveOptions(false)
        removeActiveClassFromButtons()
    }, [selectedFaq?.id])

    const removeActiveClassFromButtons = () => {
        if (yesBtnRef.current && noBtnRef.current) {
            yesBtnRef.current.classList.remove('active')
            noBtnRef.current.classList.remove('active')
        }
    }

    const handleButtonClick = (
        type: 'yes' | 'no',
        btnRef: React.RefObject<HTMLButtonElement>
    ) => {
        setResponseSaved(false);

        if (type === 'yes') {
            setShowImproveOptions(false)
            handleResponse()
        }
        if (type === 'no') {
            setShowImproveOptions(true)
        }

        removeActiveClassFromButtons()

        btnRef.current?.classList.add('active')
    }

    const handleResponse = (improve?: string) => {
        const response = {
            faqId: selectedFaq?.id,
            wasHelpful: !improve,
            improve,
        }
        let faq_answers = vtoData.faq_answers || []
        const faqPrevResponse = faq_answers.find(
            (faq: any) => faq.faqId === selectedFaq?.id
        )
        if (faqPrevResponse) {
            faq_answers = faq_answers.map((faq: any) =>
                faq.faqId === selectedFaq?.id ? response : faq
            )
        } else {
            faq_answers = [...faq_answers, response]
        }
        updateVtoData(selectedYear, vtoId, {
            faq_answers,
        }).then((res) => {
            setVtoData(res)
            setResponseSaved(true);
        })
    }

    const handleClose = () => {
        setOpen(false)
        setResponseSaved(false)
        setShowImproveOptions(false)
        removeActiveClassFromButtons()
    }

    const handleShowAllQuestions = () => {
        setCurrentStepFaqs((prev: FAQ[]) =>
            prev.map((item) => ({
                ...item,
                selected: false,
            }))
        )
        setResponseSaved(false)
        setShowImproveOptions(false)
    }

    return (
        <Drawer
            anchor={isXsDevice ? 'bottom' : 'right'}
            variant={isXLDesktop ? "permanent" : "temporary"}
            open={isXLDesktop ? true : open}
            className={`${styles.extendedDrawer} extendedDrawerCustom`}
            classes={{ paper: styles.extendedDrawerPaper }}
            BackdropProps={{ style: { opacity: 0 } }}
            onClose={handleClose}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <div className={styles.drawerHeader}>
                {isXsDevice && (
                    <RemoveRoundedIcon
                        onClick={handleClose}
                        fontSize="large"
                        classes={{
                            root: styles.removeIcon,
                        }}
                    />
                )}
                <div className="cta-container">
                    {!isXLDesktop && !isXsDevice && (
                        <IconButton
                            classes={{ root: styles.closeIconButton }}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                    <UiText variant="hatchback_125" weight="semi_bold_600">
                        Help
                    </UiText>
                </div>
                {/* <UiButton
                    btnType="secondary"
                    customLabel={
                        <UiText variant="motorcycle_90" weight="semi_bold_600">
                            Get Live Help
                        </UiText>
                    }
                /> */}
            </div>
            <div className={styles.drawerContent}>
                {!selectedFaq ? (
                    <>
                        {currentStepFaqs.map((faq, index: number) => {
                            return (
                                <div className={styles.faqCard}>
                                    <UiText
                                        className={styles.mb16}
                                        weight="semi_bold_600"
                                    >
                                        {faq.question}
                                    </UiText>
                                    <UiText variant="motorcycle_90">
                                        {`${faq.answer.substring(0, 70)}...`}
                                    </UiText>
                                    <UiButton
                                        handleClick={() =>
                                            setCurrentStepFaqs((prev: FAQ[]) =>
                                                prev.map((item, i: number) =>
                                                    i === index
                                                        ? {
                                                              ...item,
                                                              selected: true,
                                                          }
                                                        : {
                                                              ...item,
                                                              selected: false,
                                                          }
                                                )
                                            )
                                        }
                                        customClass={styles.readMoreBtn}
                                        btnType="hyperlink"
                                        customLabel={
                                            <UiText variant="motorcycle_90">
                                                Read More
                                            </UiText>
                                        }
                                    />
                                </div>
                            )
                        })}
                        {isXsDevice && (
                            <div className={styles.placeholder}></div>
                        )}
                    </>
                ) : (
                    <>
                        <UiButton
                            btnType="hyperlink"
                            handleClick={handleShowAllQuestions}
                            customLabel={
                                <UiText
                                    variant="moped_75"
                                    weight="semi_bold_600"
                                >
                                    All Questions
                                </UiText>
                            }
                            icon={<Icon size="20px" icon="leftArrow" />}
                        ></UiButton>
                        <UiText
                            className={mergeStyle(
                                styles.mb16,
                                styles.mt24
                            )}
                            weight="semi_bold_600"
                            variant="hatchback_125"
                        >
                            {selectedFaq.question}
                        </UiText>
                        {selectedFaq.html ? (
                            <div
                                className={styles.faqHTMLContent}
                                dangerouslySetInnerHTML={{
                                    __html: selectedFaq.html,
                                }}
                            ></div>
                        ) : (
                            <UiText variant="motorcycle_90">
                                {selectedFaq.answer}
                            </UiText>
                        )}
                        <UiText
                            className={styles.mt24}
                            weight="semi_bold_600"
                        >
                            Was this helpful?
                        </UiText>
                        <div className={styles.btnContainer}>
                            <UiButton
                                btnRef={yesBtnRef}
                                fullWidth
                                handleClick={() =>
                                    handleButtonClick('yes', yesBtnRef)
                                }
                                btnType="secondary"
                                customLabel={
                                    <UiText weight="semi_bold_600">Yes</UiText>
                                }
                            />
                            <UiButton
                                btnRef={noBtnRef}
                                fullWidth
                                handleClick={() =>
                                    handleButtonClick('no', noBtnRef)
                                }
                                btnType="secondary"
                                customLabel={
                                    <UiText weight="semi_bold_600">No</UiText>
                                }
                            />
                        </div>

                        {showImproveOptions && (
                            <div className={styles.improveContainer}>
                                <FormControl>
                                    <UiText
                                        className={styles.mb8}
                                        weight="semi_bold_600"
                                    >
                                        How can we improve?
                                    </UiText>
                                    <RadioGroup
                                        onChange={(e) =>
                                            handleResponse(e.target.value)
                                        }
                                    >
                                        {improveOptions.map((item) => (
                                            <FormControlLabel
                                                className={styles.radio}
                                                value={item}
                                                control={<Radio />}
                                                label={item}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        )}

                        {responseSaved && (
                            <div className={styles.mb16}>
                                <UiText
                                    className={styles.mb10}
                                    weight="semi_bold_600"
                                >
                                    Thanks for your feedback!
                                </UiText>
                                {/* <UiText className={styles.mb10}>
                                    We’d love to help. Please click the button
                                    below to reach one of our accountants
                                </UiText> */}
                                {/* <UiButton
                                    fullWidth
                                    btnType="tertiary"
                                    handleClick={() => {}}
                                    customLabel={
                                        <UiText
                                            variant="motorcycle_90"
                                            weight="semi_bold_600"
                                        >
                                            Get Live Help
                                        </UiText>
                                    }
                                /> */}
                            </div>
                        )}
                        {isXsDevice && (
                            <div className={styles.placeholder}></div>
                        )}
                    </>
                )}
            </div>
            {isXsDevice && (
                <div className={styles.fixedCloseBtn}>
                    <UiButton
                        customLabel={
                            <UiText weight="semi_bold_600">Close</UiText>
                        }
                        btnType="hyperlink"
                        handleClick={handleClose}
                    />
                </div>
            )}
        </Drawer>
    )
}
