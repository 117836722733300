import SectionQuestionsRenderer from '../../sections/SectionQuestionsRenderer'

const BalanceSheet = ({section}: any) => {
  //TODO:  Component will be implemented later as per the requirement
    return (
      <SectionQuestionsRenderer
          section={section}
      />
  )
}

export default BalanceSheet
