import React, { useEffect, useState } from 'react'
import UiModal from '../../../common/ui/UiModal'
import { Box, IconButton, makeStyles, Theme } from '@material-ui/core'
import Form1040UploadFlow from '../tax-prep-statuses/Form1040UploadFlow'
import UiText from '../../../common/ui/UiText'
import CloseIcon from '@material-ui/icons/Close'
import Icon from '../../../common/Icon'
import ThankYouPage from '../tax-prep-statuses/ThankYouPage'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import UiFormControlSelection from '../../../common/ui/UiFormControlSelection'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { commonStyles, toRem16 } from '../../../../styles/commonStyles'
import UiAlert from '../../../common/ui/UiAlert'
import UiButton from '../../../common/ui/UiButton'
import {
    getFolders,
    post1040UserConsent,
} from '../../../../services/apiService'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { Folder } from '../../../../models'
import { dismissBlock } from '../../AccountSetupItems/AccountSetupUtility'
import { UPLOAD_1040_INFO_CARD, UPLOAD_1040_POPUP } from '../../TodoSection/Todo.const'
import { getPrevYearOptions } from '../utils'

const styles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    modalContentContainer: {
        width: '40rem',
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        borderRadius: '0.5rem',
        paddingBottom: '4rem',
        height: toRem16(688),
        boxSizing: 'border-box',
        [theme.breakpoints.down('xs')]: {
            height: '100svh',
            borderRadius: 0,
            overflow: 'auto',
        },
    },
    modalContentContainerMainPage: {
        width: '40rem',
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        borderRadius: '0.5rem',
        paddingBottom: '4rem',
        [theme.breakpoints.down('xs')]: {
            height: '100svh',
            borderRadius: 0,
            overflow: 'auto',
        },
    },
    modalHeader: {
        height: '4rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.5rem 1.375rem',
        boxSizing: 'border-box',
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        borderRadius: '0.5rem',
        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
    },
    modalHeaderMain: {
        borderBottom: `none !important`,
    },
    closeIconButton: {
        padding: 0,
    },
    modalContent: {
        padding: '0.5rem 3.5rem',
        [theme.breakpoints.down('xs')]: {
            padding: '0.5rem 2rem',
        },
    },
    eSignFormContainer: {
        color: (colorTheme) => colorTheme.black100,
    },
    uploadFormContainer: {
        marginTop: '3rem',
        color: (colorTheme) => colorTheme.black100,
    },
    btn: {
        '& .MuiTypography-button': {
            fontWeight: 600,
        },
    },
    paragraphText: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    spanText: {
        display: 'inline-block',
        color: (colorTheme) => colorTheme.grey1000,
        marginLeft: '1rem',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0',
        },
    },
    eSignContentContainer: {
        padding: '1.5rem 3.5rem',
        backgroundColor: (colorTheme) => colorTheme.primaryWhite,
        borderRadius: '0.5rem',
        [theme.breakpoints.down('xs')]: {
            height: '100svh',
            borderRadius: 0,
            overflow: 'auto',
            padding: '1rem 1rem',
        },
        '& .MuiRadio-root': {
            alignSelf: 'flex-start',
        },
    },
    eSignContainer: {
        display: 'flex',
    },
    leftIcon: {
        marginRight: '0.5rem',
    },
    selectContainer: {
        marginTop: '1rem',
        maxWidth: toRem16(300),
    },
    uiAlert: {
        alignSelf: 'flex-start',
    },
    labelClass: {
        marginBottom: toRem16(9),
        '& .MuiRadio-colorSecondary': {
            padding: `0 ${toRem16(9)}`,
        },
    },
    confirmBtn: {
        '&.Mui-disabled': {
            background: (colorTheme) => colorTheme.primary,
            opacity: '0.25',
        },
        '& .MuiTypography-button': {
            fontWeight: 600,
        },
    },
}))

type CompleteForm1040LandingViewType = {
    handleClose: () => void
    setView: Function
    setSelectedYear: (year: string) => void
}

type ConfirmNo1040FormViewType = {
    handleClose: (showReviewModal?: boolean) => void
    setView: Function
    personalAccountId: string
}

const CompleteForm1040LandingView = ({
    handleClose,
    setView,
    setSelectedYear,
}: CompleteForm1040LandingViewType) => {
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme)
    const commonClasses = commonStyles()

    const handleLandingViewClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        handleClose()
    }

    const handleForm = (values: any) => {
        setSelectedYear(values.year)
        setView('document_flow')
    }

    

    const validationSchema = Yup.object({
        year: Yup.string().required('Required'),
    })

    const initialValues = { year: getPrevYearOptions()[2] }

    return (
        <div className={classes.modalContentContainerMainPage}>
            <div
                className={`${classes.modalHeader} ${classes.modalHeaderMain}`}
            >
                <UiText variant="car_100" weight="medium_500"></UiText>
                <IconButton
                    aria-label="close"
                    classes={{ root: classes.closeIconButton }}
                    onClick={handleLandingViewClose}
                    data-cy="cross-modal-close-btn"
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={classes.modalContent}>
                <Formik
                    onSubmit={handleForm}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {({ submitForm }) => (
                        <div className={classes.eSignFormContainer}>
                            <UiText
                                variant="truck_175"
                                weight="medium_500"
                                textColor="inherit"
                            >
                                Upload Your Previous Year’s Personal Tax Return
                                (1040)
                            </UiText>
                            <UiText className={classes.paragraphText}>
                                Uploading your previous year’s tax return
                                enables our team of accountants to streamline
                                your tax preparation and more effectively
                                develop your tax plan.
                            </UiText>
                            <UiAlert
                                severity="info"
                                icon="InfoIcon"
                                iconColor={colorTheme.blue200}
                                description={
                                    'Clients who upload their return save an average of 13% more on their taxes and spend 38 minutes less per year on tax preparation.'
                                }
                                customStyles={{ icon: classes.uiAlert }}
                            />
                            <div className={commonClasses.mtb16}>
                                <UiText>
                                    Select the tax year of the return you are
                                    uploading.
                                </UiText>

                                <div className={classes.selectContainer}>
                                    <UiFormControlSelection
                                        type="select"
                                        optionsData={getPrevYearOptions()}
                                        label="Tax year"
                                        fieldName="year"
                                        showFloatingLabel
                                    />
                                </div>
                            </div>
                            <UiButton
                                customClass={classes.btn}
                                handleClick={submitForm}
                                btnType="tertiary"
                                label="Select a File"
                            />
                        </div>
                    )}
                </Formik>
                <div className={classes.uploadFormContainer}>
                    <UiText
                        className={classes.paragraphText}
                        variant="hatchback_125"
                        weight="semi_bold_600"
                        textColor="inherit"
                    >
                        Don’t have a tax return from the previous year?
                    </UiText>

                    <UiButton
                        handleClick={() => setView('confirm_no_document')}
                        customClass={classes.btn}
                        label="Continue"
                        btnType="secondary"
                    />
                </div>
            </div>
        </div>
    )
}

const ConfirmNo1040FormView = ({
    handleClose,
    setView,
    personalAccountId,
}: ConfirmNo1040FormViewType) => {
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme)
    const commonClasses = commonStyles()

    const validationSchema = Yup.object({
        uploadLater: Yup.string().required('Required'),
    })

    const initialValues = { uploadLater: '' }

    const handleForm = (value: { uploadLater: string }) => {
        const val = value.uploadLater === 'yes' ? true : false
        post1040UserConsent(personalAccountId, val).then(() => {
            dismissBlock(UPLOAD_1040_INFO_CARD)
            if (val === true) {
                handleClose()
            } else {
                setView('thank_you_page')
            }
        })
    }

    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className={classes.modalContentContainer}
        >
            <div className={classes.modalHeader}>
                <div className={classes.eSignContainer}>
                    <Box
                        onClick={(e) => {
                            e.stopPropagation()
                            setView('landing')
                        }}
                        className={classes.leftIcon}
                    >
                        {' '}
                        <Icon icon="leftArrow" svgColor={colorTheme.grey400} />
                    </Box>

                    <UiText variant="hatchback_125" weight="medium_500">
                        Please Confirm
                    </UiText>
                </div>

                <IconButton
                    aria-label="close"
                    classes={{ root: classes.closeIconButton }}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleClose()
                    }}
                    data-cy="cross-modal-close-btn"
                >
                    <CloseIcon />
                </IconButton>
            </div>

            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={handleForm}
                validateOnMount={true}
            >
                {({ submitForm, values, isSubmitting, isValid }) => (
                    <div className={classes.eSignContentContainer}>
                        <div>
                            <div className={commonClasses.mb16}>
                                <UiText
                                    weight="medium_500"
                                    variant="hatchback_125"
                                >
                                    Select an option
                                </UiText>
                            </div>
                            <UiFormControlSelection
                                labelClass={classes.labelClass}
                                type="radio"
                                fieldName="uploadLater"
                                radioOptionsData={[
                                    {
                                        label: 'I was not required to file a personal tax return in previous years. I understand that not uploading a copy of my previous tax return may require my accountant to gather additional data from me, which may result in delays when preparing my taxes.',
                                        value: 'no',
                                    },
                                    {
                                        label: 'I will upload my previous return at another time.',
                                        value: 'yes',
                                    },
                                ]}
                            />
                        </div>
                        <div className={commonClasses.mt16}>
                            <UiButton
                                customClass={classes.confirmBtn}
                                handleClick={submitForm}
                                btnType="tertiary"
                                label="Confirm"
                                disabled={isSubmitting || !isValid}
                            />
                        </div>
                    </div>
                )}
            </Formik>
        </div>
    )
}

const Form1040UploadModal = ({ open, handleClose, setRefetch1040Info }: any) => {
    const [view, setView] = useState('landing')
    const [selectedYear, setSelectedYear] = useState('')
    const [folders, setFolders] = useState<Folder[]>([])
    const currentStore = useCurrentStore()
    const accountId = currentStore.currentAccountId
    const personalAccountId = currentStore.personalAccount?.id

    useEffect(() => {
        getFolders(accountId).then((res) => setFolders(res))
    }, [])

    const handleModalClose = () => {
        setView('landing')
        dismissBlock(UPLOAD_1040_POPUP)
        handleClose()
    }

    const renderView = () => {
        switch (view) {
            case 'landing':
                return (
                    <CompleteForm1040LandingView
                        handleClose={handleModalClose}
                        setView={setView}
                        setSelectedYear={setSelectedYear}
                    />
                )
            case 'document_flow':
                return (
                    <Form1040UploadFlow
                        handleClose={handleModalClose}
                        setView={setView}
                        selectedYear={selectedYear}
                        folders={folders}
                        accountId={accountId}
                        setRefetch1040Info={setRefetch1040Info}
                    />
                )
            case 'confirm_no_document':
                return (
                    <ConfirmNo1040FormView
                        handleClose={handleModalClose}
                        setView={setView}
                        personalAccountId={personalAccountId}
                    />
                )
            case 'thank_you_page':
                return (
                    <ThankYouPage
                        title="Thank you for confirming you don’t have a previous year’s personal tax return (1040)"
                        description="We’ll be in touch."
                        handleClose={handleModalClose}
                        textCenter
                    />
                )
            default:
                return (
                    <CompleteForm1040LandingView
                        handleClose={handleModalClose}
                        setView={setView}
                        setSelectedYear={setSelectedYear}
                    />
                )
        }
    }
    return (
        <>
            <UiModal open={open} handleClose={handleModalClose}>
                {renderView()}
            </UiModal>
        </>
    )
}

export default Form1040UploadModal
