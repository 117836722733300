import SectionQuestionsRenderer from '../../sections/SectionQuestionsRenderer'

const BusinessInfo = ({ section }: any) => {
    return (
        <SectionQuestionsRenderer
            section={section}
        />
    )
}

export default BusinessInfo
