import { makeStyles, Theme } from '@material-ui/core'
import Icon from '../../../../common/Icon'
import UiButton from '../../../../common/ui/UiButton'
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext'
import IntroVideo from '../../core/components/IntroVideo'
import UiText from '../../../../common/ui/UiText'
import { useVtoContext } from '../../providers/VtoProvider'
import { commonVtoStyles } from '../../core/styles/commonVtoStyles'
import { useState } from 'react'
import Loader from '../../../../common/Loader'

const useStyles = makeStyles((theme: Theme) => {
    return {
        headerText: {
            margin: '1rem 0 1rem 0',
        },
        container: {
            display: 'flex',
            gap: '2rem',
        },
        content: {
            paddingLeft: '0.5rem',
            overflowY: 'auto',
        },
    }
})

const BusinessIntroduction = () => {
    const styles = useStyles()
    const { colorTheme } = useThemeContext()
    const classes = commonVtoStyles(colorTheme)
    const {
        setCurrentStep,
        currentStep,
        setParentStep,
        menuItems,
        currentVtoDetails,
        updateVTO,
        loading,
    } = useVtoContext();
    

    const nextSection = () => {
        if (menuItems) {
            const nextStep =
                menuItems[
                    menuItems?.findIndex(
                        (step: any) => step?.name === currentStep
                    ) + 1
                ]
            setCurrentStep(nextStep.name)
            setParentStep(nextStep)
        }
    }

    const updateReviewedSections = () => {
        if (!currentVtoDetails.sections_reviewed?.includes("introduction")) {
            updateVTO(
                currentVtoDetails.id,
                {
                    sections_reviewed: [
                        ...currentVtoDetails.sections_reviewed,
                        currentStep,
                    ],
                },
                () => {
                    nextSection();
                },
            );
        } else {
            nextSection();
        }
    };
    

    if(loading) {
        return <div className={classes.centeredLoader}><Loader /></div>
    }

    return (
        <div>
            <UiText
                variant="hatchback_125"
                weight="medium_500"
                className={styles.headerText}
            >
                Introduction
            </UiText>
            <IntroVideo />
            <div className={styles.headerText}>
                <UiButton
                    type="submit"
                    btnType="tertiary"
                    label="Next"
                    endIcon={
                        <Icon
                            icon="rightArrow"
                            svgColor={colorTheme.primaryWhite}
                        />
                    }
                    handleClick={updateReviewedSections}
                />
            </div>
        </div>
    )
}

export default BusinessIntroduction
