import { ArrowForward } from '@material-ui/icons'
import { commonStyles } from '../../../../styles/commonStyles'
import { useStyles } from './IntroStep'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { Hidden, Theme, useMediaQuery } from '@material-ui/core'
import UiButton from '../../../common/ui/UiButton'
import UiText from '../../../common/ui/UiText'
import Icon from '../../../common/Icon'
import OverviewStep from './OverviewStep'

const DetailedStep = ({ setOpenVideoModal, goToNextStep }: any) => {
    const common = commonStyles()
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )
    return (
        <div className={styles.detailedContainer}>
            <OverviewStep hideBtn={true} />
            <div className={`${common.mt32} ${common.mb40}`}>
                <UiText
                    className={styles.title}
                    variant={smDevice ? 'car_100' : 'suv_150'}
                    weight={smDevice ? 'semi_bold_600' : 'bold_700'}
                >
                    Here’s what to expect:
                </UiText>
                <ol className={styles.list}>
                    <li>
                        <UiText weight="semi_bold_600">
                            Your accountants have collected information throughout the year and compiled it here, but you'll also need to upload a past tax return
                        </UiText>
                    </li>
                    <li>
                        <UiText weight="semi_bold_600">
                            You’ll review your information and confirm your
                            identity
                        </UiText>
                    </li>
                    <li>
                        <UiText weight="semi_bold_600">
                            Sit back and relax – your Expert Tax Preparer will
                            take care of the rest
                        </UiText>
                    </li>
                </ol>
            </div>
            <div className={common.mb40}>
                {/* <UiText className={common.mb10}>
                    Any questions? You can watch the intro video or chat with us
                    anytime.
                </UiText>
                <UiButton
                    fullWidth
                    customClass={styles.videoButton}
                    btnType="hyperlink"
                    label="Introduction Video"
                    icon={
                        <Icon icon="playIcon" svgColor={colorTheme.blue200} />
                    }
                    handleClick={() => setOpenVideoModal(true)}
                /> */}
            </div>
            <Hidden mdUp>
                <div className={styles.spacer}></div>
            </Hidden>
            <div className={styles.fixedButtonContainer}>
                <UiButton
                    fullWidth
                    btnType="tertiary"
                    endIcon={<ArrowForward />}
                    handleClick={goToNextStep}
                    label="Next"
                />
            </div>
        </div>
    )
}

export default DetailedStep
