import { Theme, makeStyles } from '@material-ui/core/styles'
import { toRem16 } from '../../styles/commonStyles'
import UiText from './ui/UiText'
import { useThemeContext } from './whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../styles/models/Colors.interface'

type LinearProgressBarProps = {
    percentage: number | string
}

const useStyles = makeStyles<Theme, ThemeColors>(() => ({
    root: {
        flexGrow: 1,
        height: `${toRem16(24)}`,
        width: '100%',
        position: 'relative',
    },
    progressBar: {
        width: '100%',
        height: '100%',
        backgroundColor: (colorTheme) => colorTheme.grey2500,
        borderRadius: '1.5rem',
        border: (colorTheme) => `${toRem16(2)} solid ${colorTheme.grey200}`,
        boxSizing: 'border-box',
    },
    progress: {
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
        minWidth: '4rem',
        maxWidth: '100%',
        display: 'flex',
        borderRadius: '1.5rem',
        backgroundColor: (colorTheme) => colorTheme.tertiary,
        color: (colorTheme) => colorTheme.primaryWhite,
    },
    percentage: {
        margin: '0 1rem 0 auto',
        lineHeight: toRem16(24),
    },
}))

export default function LinearProgressBar({ percentage }: LinearProgressBarProps) {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
   
    return (
        <div className={classes.root}>
            <div className={classes.progressBar}></div>
            <div
                className={classes.progress}
                style={{
                    width: `${percentage}%`,
                }}
            >
                <UiText
                    className={classes.percentage}
                    variant="motorcycle_90"
                    weight="bold_700"
                >
                    {percentage}%
                </UiText>
            </div>
        </div>
    )
}
