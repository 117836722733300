import Loader from '../../common/Loader'
import WidgetProvider from '../../dashboard/tax-prep/provider/WidgetProvider'
import { useVtoContext } from '../vto/providers/VtoProvider'
import BusinessVto from './BusinessVto'
import InitVto from './InitVto'

const BusinessTaxInfo = () => {
    const { loading, currentBusinessVto, selectedYear } = useVtoContext()

    if (loading) {
        return <Loader />
    }
    return (
        <WidgetProvider vtoSelectYear={selectedYear}>
            {currentBusinessVto?.title ? (
                <BusinessVto />
            ) : (
                <InitVto />
            )}
        </WidgetProvider>
    )
}

export default BusinessTaxInfo
