
import { useVtoContext } from '../vto/providers/VtoProvider'
import BusinessTaxIntro from './BusinessTaxIntro'
import PersonalTaxIntro from '../personal-tax-information/PersonalTaxIntro'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadPostAuthData } from '../../../store/actions/appData'

const InitVTO = () => {
    const {
        isPersonalVTO,
    } = useVtoContext()
    const dispatch = useDispatch()
    

    useEffect(() => {
        dispatch(loadPostAuthData())
    }, [])


    return (
        <>
            {isPersonalVTO ? (
                <PersonalTaxIntro/>
            ) : (
                <BusinessTaxIntro />
            )}
        </>
    )
}

export default InitVTO
