import { useVtoInfo } from '../hooks/useVtoInfo'
import { VtoConfig, VtoInfo } from '../../../../models/vto/VtoInfo'
import {
    BusinessSection,
    FAQ,
    PersonalSection,
    VTOConfigData,
    VTOMetaData,
    YearWiseVTOConfig,
} from '../models/VtoDetails.model'
import React from 'react'

interface VtoProviderValue {
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    selectedYear: number
    SetSelectedYear: React.Dispatch<React.SetStateAction<number>>
    dropdownIndex: any
    SetDropdownIndex: React.Dispatch<React.SetStateAction<any>>
    currentBusinessVto: VtoInfo | undefined
    currentPersonalVto: VtoInfo | undefined
    setCurrentBusinessVto: React.Dispatch<
        React.SetStateAction<VtoInfo | undefined>
    >
    vtoConfig: VtoConfig
    createBusinessVto: () => void
    createPersonalVto: () => void
    updateVTO: (vtoId: string, updatedData: any, successHandler?: any, isQuestionSubmit?:boolean, parentLoader?: boolean) => void
    onMenuItemClick: (key: string) => void
    isPersonalVTO: boolean
    isBusinessVTO: boolean
    currentYearMetaData: VTOMetaData | undefined
    currentStep: string
    setCurrentStep: React.Dispatch<React.SetStateAction<string>>
    currentVtoDetails: any //TODO
    setCurrentVtoDetails: any //TODO
    currentVtoConfig: VTOConfigData | undefined
    setCurrentVtoConfig: any
    importBalanceSheetDetails: () => void
    setFullSectionView: React.Dispatch<React.SetStateAction<boolean>>
    fullSectionView: boolean
    vtoSubmitStep: number
    setVtoSubmitStep: React.Dispatch<React.SetStateAction<number>>
    setShowYearDropdown: React.Dispatch<React.SetStateAction<boolean>>
    showYearDropdown: boolean
    currentYearVTOProducts: any[]
    setCurrentYearVTOProducts: React.Dispatch<React.SetStateAction<any>>
    hasVTOPermission: () => boolean
    currentVtoYearConfig: YearWiseVTOConfig,
    submitValues: Function,
    questionLoading: boolean
    parentStep: any,
    setParentStep: React.Dispatch<React.SetStateAction<any>>
    menuItems: PersonalSection[] | BusinessSection[] | undefined
    setMenuItems: React.Dispatch<React.SetStateAction<PersonalSection[] | BusinessSection[] | undefined>>
    goToPreviousStep: () => void
    configProductDetails: any
    highlightIncompleteSections: boolean
    setHighlightIncompleteSections: React.Dispatch<React.SetStateAction<boolean>>
    handlePrev: () => void
    goToNextStep: (steps?:any) => void
    dataOriginMap: any
    questionsContainerRef: any
    vtoAllowedSteps: PersonalSection[] | BusinessSection[] | undefined
    currentVto: any,
    refetchCurrentYearMetaData: any,
    currentStepFaqs: FAQ[],
    setCurrentStepFaqs: any,
    openFaqs: any,
    setOpenFaqs: any,
    productPriceMap: any,
    firstIncompleteStep: any
    setFirstIncompleteStep:any,
    showUnsavedChangesModal: boolean,
    setShowUnsavedChangesModal: (value: boolean) => void;
}

export const VtoContext = React.createContext<VtoProviderValue | null>(null)

export const useVtoContext = () => {
    const state = React.useContext(VtoContext)
    if (!state) {
        throw new Error('useVtoContext must be used within Vto Provider')
    }
    return state
}

const VtoProvider = ({ children }: any) => {
    const {
        loading,
        setLoading,
        selectedYear,
        SetSelectedYear,
        dropdownIndex,
        SetDropdownIndex,
        currentBusinessVto,
        currentPersonalVto,
        setCurrentBusinessVto,
        vtoConfig,
        createPersonalVto,
        createBusinessVto,
        updateVTO,
        onMenuItemClick,
        isPersonalVTO,
        isBusinessVTO,
        currentYearMetaData,
        currentVtoDetails,
        currentStep,
        setCurrentStep,
        setCurrentVtoDetails,
        currentVtoConfig,
        setCurrentVtoConfig,
        importBalanceSheetDetails,
        fullSectionView,
        setFullSectionView,
        vtoSubmitStep,
        setVtoSubmitStep,
        setShowYearDropdown,
        showYearDropdown,
        currentYearVTOProducts,
        setCurrentYearVTOProducts,
        hasVTOPermission,
        currentVtoYearConfig,
        submitValues,
        questionLoading,
        parentStep,
        setParentStep,
        configProductDetails,
        highlightIncompleteSections,
        setHighlightIncompleteSections,
        menuItems,
        setMenuItems,
        goToPreviousStep,
        handlePrev,
        goToNextStep,
        dataOriginMap,
        questionsContainerRef,
        vtoAllowedSteps,
        currentVto,
        refetchCurrentYearMetaData,
        currentStepFaqs,
        setCurrentStepFaqs,
        openFaqs,
        setOpenFaqs,
        productPriceMap,
        firstIncompleteStep,
        setFirstIncompleteStep,
        showUnsavedChangesModal,
        setShowUnsavedChangesModal,
    } = useVtoInfo()

    const commonVtoInfo: VtoProviderValue = {
        loading,
        setLoading,
        selectedYear,
        SetSelectedYear,
        dropdownIndex,
        SetDropdownIndex,
        currentBusinessVto,
        currentPersonalVto,
        setCurrentBusinessVto,
        vtoConfig,
        createPersonalVto,
        createBusinessVto,
        updateVTO,
        onMenuItemClick,
        isPersonalVTO,
        isBusinessVTO,
        currentYearMetaData,
        currentVtoDetails,
        setCurrentVtoDetails,
        currentVtoConfig,
        currentStep,
        setCurrentStep,
        setCurrentVtoConfig,
        importBalanceSheetDetails,
        setFullSectionView,
        fullSectionView,
        vtoSubmitStep,
        setVtoSubmitStep,
        showYearDropdown,
        setShowYearDropdown,
        currentYearVTOProducts,
        setCurrentYearVTOProducts,
        hasVTOPermission,
        currentVtoYearConfig,
        submitValues,
        questionLoading,
        parentStep,
        setParentStep,
        configProductDetails,
        highlightIncompleteSections,
        setHighlightIncompleteSections,
        menuItems,
        setMenuItems,
        goToPreviousStep,
        handlePrev,
        goToNextStep,
        dataOriginMap,
        questionsContainerRef,
        vtoAllowedSteps,
        currentVto,
        refetchCurrentYearMetaData,
        currentStepFaqs,
        setCurrentStepFaqs,
        openFaqs,
        setOpenFaqs,
        productPriceMap,
        firstIncompleteStep,
        setFirstIncompleteStep,
        showUnsavedChangesModal,
        setShowUnsavedChangesModal,
    }

    return (
        <VtoContext.Provider value={commonVtoInfo}>
            {children}
        </VtoContext.Provider>
    )
}

export default VtoProvider
