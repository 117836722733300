import { IconButton, Theme, makeStyles } from '@material-ui/core'
import { FieldArray } from 'formik'
import UiText from '../common/ui/UiText'
import { toRem16 } from '../../styles/commonStyles'
import UiFormControlSelection from '../common/ui/UiFormControlSelection'
import { InlineFlex } from './Wrapper'
import { ReactComponent as Delete } from '../../assets/icons-svg/deleteTrash.svg'
import { COLORS } from '../../variables/colors'
import LinkButton from '../common/LinkButton'
import { Add } from '@material-ui/icons'
import { useCurrentStore } from '../common/hooks/useCurrentStore'
import { useEffect, useState } from 'react'
import { makeTaxYearDropdown } from './utills/contractor.utils'
import { AddressAutocompletion } from '../adp-payroll/AddressAutocompletion'

const renderTaxIdentificationField = (type: string) => {
    switch (type) {
        case 'ssn':
            return (
                <UiFormControlSelection
                    type="mask"
                    cypressId="ssn"
                    fieldName="tax_identification_number"
                    label={'SSN'}
                    showFloatingLabel
                    XXX-XX-XXXX
                    mask={[
                        /[X0-9]/,
                        /[X0-9]/,
                        /[X0-9]/,
                        '-',
                        /[X0-9]/,
                        /[X0-9]/,
                        '-',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                    ]}
                    minLength={9}
                />
            )
        case 'ein':
            return (
                <UiFormControlSelection
                    type="mask"
                    cypressId="ein"
                    fieldName="tax_identification_number"
                    label={'EIN'}
                    showFloatingLabel
                    XXX-XX-XXXX
                    mask={[
                        /[X0-9]/,
                        /[X0-9]/,
                        '-',
                        /[X0-9]/,
                        /[X0-9]/,
                        /[X0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                    ]}
                    minLength={9}
                />
            )
        default:
            return (
                <UiFormControlSelection
                    type="mask"
                    cypressId="tin"
                    fieldName="tax_identification_number"
                    label={'TIN'}
                    showFloatingLabel
                    mask={[
                        /[X0-9]/,
                        /[X0-9]/,
                        /[X0-9]/,
                        /[X0-9]/,
                        /[X0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                    ]}
                    minLength={9}
                />
            )
    }
}

interface ContractorFormProps {
    formikRef?: any
    values: any
    setTouched?: (fieldObject: any) => void
    alreadyTouched?: any
    showInlineError?: boolean
    hideTaxYear?: boolean
    formObserverValue?: any
    isALlFieldsRequired?: boolean
    isEditContarctor?: boolean
    isAllFieldDisabled?: boolean
    setFieldValue: any
}

export const ContractorForm = (props: ContractorFormProps) => {
    const {
        values,
        setTouched,
        alreadyTouched,
        showInlineError,
        hideTaxYear,
        formObserverValue,
        isALlFieldsRequired,
        isEditContarctor,
        isAllFieldDisabled,
        setFieldValue,
    } = props
    const { currentConfig } = useCurrentStore()
    const [taxYearOption, setTaxYearOption] = useState<Array<any>>(
        makeTaxYearDropdown()
    )
    
    const dynamicLabel = isALlFieldsRequired ? '(Required)' : '(Optional)'
    const cantAddMoreTaxYear = values?.contractor_w_nine?.length === taxYearOption.length

    const classes = makeStyles((theme: Theme) => {
        return {
            formContainer: {
                marginTop: toRem16(32),
                width: '100%',
            },
            inputField: {
                display: 'flex',
                flexDirection: 'column',
                gap: toRem16(24),
                maxWidth: toRem16(365),
                '& .MuiFormControl-root': {
                    flexDirection: showInlineError ? 'row' : 'column',
                    '& .MuiFormHelperText-root': showInlineError && {
                        width: '50%',
                        position: 'absolute',
                        left: toRem16(365),
                        top: toRem16(5),
                    },
                    '& .MuiSelect-root': showInlineError && {
                        minWidth: toRem16(320),
                    },
                },
                marginBottom: '3rem',
            },
            radioInput: {
                display: 'inline',
            },
            addTaxYear: {
                maxWidth: toRem16(170),
            },
        }
    })()

    useEffect(() => {
        if (setTouched && alreadyTouched) {
            setTimeout(() => setTouched(alreadyTouched), 0)
        }
    }, [setTouched, alreadyTouched])

    const hideSelectedYear = (selectedYears: any[], e?: any) => {
        if (selectedYears.length === 1) {
            disableSelectedTaxYear([
                {
                    year: e?.target?.value ?? '',
                    disabled: true,
                },
            ])
        } else {
            disableSelectedTaxYear([
                { year: e?.target?.value ?? '', disabled: true },
                ...selectedYears.map((obj: any) => {
                    return { year: obj.year, disabled: true }
                }),
            ])
        }
    }

    const disableSelectedTaxYear = (selectedYears: any[]) => {
        const years: string[] = selectedYears.map((obj: any) => obj.year)
        const disabledDropdown = makeTaxYearDropdown().map((data: any) => {
            if (years.includes(data.year)) {
                return { ...data, disabled: true }
            }
            return { ...data, disabled: false }
        })
        setTaxYearOption(disabledDropdown)
    }

    // if(values.type === 'business' && !values.tax_identification_type){
    //     setFieldValue?.('tax_identification_type', 'ein')
    // }

    const handleYearDelete = (status: any) => {
        if (status === undefined) {
            return false
        }
        return status === 'incomplete' ? false : true
    }

    return (
        <div className={classes.formContainer}>
            <div className={classes.inputField}>
                <UiFormControlSelection
                    type="text"
                    fieldName="first_name"
                    label="First Name (Required)"
                    showFloatingLabel
                    disabled={isAllFieldDisabled}
                />
                <UiFormControlSelection
                    type="text"
                    fieldName="last_name"
                    label="Last Name (Required)"
                    showFloatingLabel
                    disabled={isAllFieldDisabled}
                />
                <UiFormControlSelection
                    type="text"
                    fieldName="email"
                    label="Email (Required)"
                    showFloatingLabel
                    disabled={isAllFieldDisabled}
                />
                <UiFormControlSelection
                    type="mask"
                    label={'Phone (Required)'}
                    showFloatingLabel={true}
                    fieldName="phone"
                    mask={[
                        '(',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        ')',
                        ' ',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        '-',
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                    ]}
                    minLength={10}
                    disabled={isAllFieldDisabled}
                />
                <div>
                    <span>
                        <UiText
                            className={classes.radioInput}
                            weight="medium_500"
                        >
                            Contractor Type{' '}
                        </UiText>
                        <span>{dynamicLabel}</span>
                    </span>

                    <UiFormControlSelection
                        type="radio"
                        fieldName="type"
                        label={`Contractor Type ${dynamicLabel}`}
                        radioOptionsData={[
                            {
                                label: 'Business',
                                value: 'business',
                            },
                            {
                                label: 'Individual',
                                value: 'individual',
                            },
                        ]}
                        disabled={isAllFieldDisabled}
                    />
                </div>
                <UiFormControlSelection
                    type="text"
                    fieldName="business_name"
                    label={`Business Name ${
                        formObserverValue?.type === 'business' ? '(Required)' : '(Optional)'
                    }`}
                    showFloatingLabel
                    disabled={isAllFieldDisabled}
                />
                {!hideTaxYear && (
                    <div>
                        <UiText variant="hatchback_125" weight="medium_500">
                            Tax Years
                        </UiText>
                        <UiText variant="moped_75">
                            The years you paid this contractor. If you pay a
                            contractor more than $600 in any given year, you are
                            legally required to file a 1099-NEC with the IRS.
                        </UiText>
                    </div>
                )}
                {!hideTaxYear && (
                    <FieldArray name="contractor_w_nine">
                        {({ push, remove, form }) => {
                            return (
                                <>
                                    {values?.contractor_w_nine?.map(
                                        (tax: any, index: number) => (
                                            <InlineFlex
                                                key={`${tax.year}_${index}`}
                                                onClick={(data: any) => {
                                                    hideSelectedYear(
                                                        values?.contractor_w_nine,
                                                        data
                                                    )
                                                }}
                                            >
                                                <UiFormControlSelection
                                                    type="select"
                                                    optionsData={taxYearOption}
                                                    showFloatingLabel
                                                    optionKey="year"
                                                    optionValue="year"
                                                    label="Tax Year (Required)"
                                                    fieldName={`contractor_w_nine[${index}].year`}
                                                    helperText={
                                                        tax.status ===
                                                            'filed' &&
                                                        !showInlineError
                                                            ? 'This 1099-NEC has already been filed with the IRS. You cannot delete it.'
                                                            : ''
                                                    }
                                                    disabled={ isAllFieldDisabled || tax.status === 'filed' }
                                                />
                                                {index !== 0 && (
                                                    <div>
                                                        <IconButton
                                                            onClick={() =>
                                                                remove(index)
                                                            }
                                                            disabled={handleYearDelete(
                                                                tax.status
                                                            )}
                                                        >
                                                            <Delete
                                                                style={{
                                                                    fill: handleYearDelete(
                                                                        tax.status
                                                                    )
                                                                        ? COLORS.grey200
                                                                        : COLORS.red200,
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                )}
                                            </InlineFlex>
                                        )
                                    )}
                                    <LinkButton
                                        startIcon={<Add />}
                                        onClick={() => {
                                            disableSelectedTaxYear(
                                                values?.contractor_w_nine
                                            )
                                            push({
                                                year: ' ',
                                            })
                                        }}
                                        className={classes.addTaxYear}
                                        disabled={isAllFieldDisabled || cantAddMoreTaxYear}

                                    >
                                        <UiText
                                            weight="semi_bold_600"
                                            variant="moped_75"
                                        >
                                            Add Another Tax Year
                                        </UiText>
                                    </LinkButton>
                                </>
                            )
                        }}
                    </FieldArray>
                )}

                <UiText variant="hatchback_125" weight="semi_bold_600">
                    Tax Identification Number
                </UiText>
                <div>
                    <span>
                        <UiText
                            className={classes.radioInput}
                            weight="medium_500"
                        >
                            Type{' '}
                        </UiText>
                        <span>{dynamicLabel}</span>
                    </span>
                    <UiFormControlSelection
                        type="radio"
                        fieldName="tax_identification_type"
                        radioOptionsData={[
                            {
                                label: 'EIN',
                                value: 'ein',
                            },
                            {
                                label: 'SSN',
                                value: 'ssn',
                            },
                        ]}
                        disabled={isAllFieldDisabled}
                    />
                </div>
                <div>
                    {formObserverValue?.tax_identification_type === 'ssn' &&
                        renderTaxIdentificationField('ssn')}
                    {formObserverValue?.tax_identification_type === 'ein' &&
                        renderTaxIdentificationField('ein')}
                    {(formObserverValue?.tax_identification_type === 'tin' ||
                        !formObserverValue?.tax_identification_type) &&
                        renderTaxIdentificationField('tin')}
                </div>
                <UiText variant="hatchback_125" weight="semi_bold_600">
                    Mailing Address
                </UiText>

                <AddressAutocompletion setFieldValue={setFieldValue} multiStreetFields={false}/>

                <UiFormControlSelection
                    type="text"
                    fieldName="street"
                    label={`Street Address ${dynamicLabel}`}
                    showFloatingLabel
                    disabled={isAllFieldDisabled}
                />
                <UiFormControlSelection
                    type="text"
                    fieldName="city"
                    label={`City ${dynamicLabel}`}
                    showFloatingLabel
                    disabled={isAllFieldDisabled}
                />
                <UiFormControlSelection
                    type="autocomplete"
                    optionsData={currentConfig.apiConfig.generic.states}
                    showFloatingLabel
                    optionKey="name"
                    optionValue="abbr"
                    label={`State ${dynamicLabel}`}
                    fieldName="state"
                    disabled={isAllFieldDisabled}
                />
                <UiFormControlSelection
                    type="mask"
                    label={`Zip ${dynamicLabel}`}
                    showFloatingLabel={true}
                    fieldName="zip"
                    mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                    minLength={5}
                    disabled={isAllFieldDisabled}
                />
            </div>
        </div>
    )
}
