import { Theme, makeStyles } from '@material-ui/core'
import { ThemeColors } from '../../../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../../../common/whiteLabel/ColorThemeContext'
import {
    OWNER_MAPPING_AP,
    TAX_ACCOUNTANT_MAPPING_AP,
    renderFor,
} from '../../../../../dashboard/tax-prep/constants/tax-prep.const'
import { useWidgetContext } from '../../../../../dashboard/tax-prep/provider/WidgetProvider'
import AssignedAccountant from '../../../../../dashboard/tax-prep/AssignedAccountant'
import { toRem16 } from '../../../../../../styles/commonStyles'

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    accountantContainer: {
        padding: '0.625rem 1.5rem',
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        borderRadius: '0.5rem',
        [theme.breakpoints.down('xs')]: {
            padding: '0.625rem 1rem',
        },
    },
    noDataAccountantContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: toRem16(12),
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        [theme.breakpoints.down('xs')]: {
            padding: '0.7rem 1.5rem 0.7rem 1rem',
        },
    },
    accountantContainerInfo: {
        marginLeft: '1rem',
    },
    clockIcon: {
        width: '2.5rem',
        height: '2.5rem',
        background: (colorTheme) => colorTheme.grey100,
        borderRadius: '50rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    completedStausWidget: {
        borderTop: 'none',
        padding: '1rem 1.5rem',
    },
    listRootCompleted: {
        borderRadius: toRem16(4),
        zIndex: 2,
        background: (colorTheme) => colorTheme.white100,
        color: (colorTheme) => colorTheme.black100,
        margin: '0.7rem 1.5rem',
        padding: '0.5rem 1rem',
        [theme.breakpoints.down('xs')]: {
            margin: '0.8rem',
            padding: '0.6rem',
        },
    },
}))

const AccountantDetails = ({
    status,
    type,
    widgetStatus,
    taxApStatus,
}: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyle(colorTheme)
    const { taxPrepData } = useWidgetContext()
    const accountantType = OWNER_MAPPING_AP.includes(status)
        ? taxPrepData?.owner
        : TAX_ACCOUNTANT_MAPPING_AP.includes(status)
        ? taxPrepData?.taxAccountant
        : null
    const accountant =
        type === renderFor.BUSINESS
            ? accountantType?.business
            : accountantType?.personal

    return (
        <>
            <div onClick={(e) => e.stopPropagation()}>
                {accountant && (
                    <div
                        className={`${classes.accountantContainer} ${
                            (taxApStatus === 'Completed' ||
                                taxApStatus === 'E-File' ||
                                status === 'discarded_schedule_c') &&
                            classes.completedStausWidget
                        } `}
                    >
                        <AssignedAccountant
                            appoinmentTopic={[`${type} Tax Preparation`]}
                            name={`${accountant?.first_name} ${accountant?.last_name}`}
                            role={accountant?.roles}
                            profileImage={accountant?.photoLink}
                            accountantId={accountant?.id}
                            status={widgetStatus}
                            statusIcons={status}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default AccountantDetails
