import {
    Checkbox,
    FormControlLabel,
    Theme,
    makeStyles,
} from '@material-ui/core'
import UiText from '../../../../common/ui/UiText'
import { ListAllFilesToUpload } from './AllInOneUpload'
import { useFormikContext } from 'formik'

const useStyles = makeStyles((theme: Theme) => {
    return {
        topMargin: {
            marginTop: '1rem',
        },
        labeledTop: {
            marginTop: '0.8rem',
        },
    }
})

const FaxAndMailInfo = () => {
    const styles = useStyles()
    return (
        <div className={styles.topMargin}>
            <UiText variant="hatchback_125" weight="semi_bold_600">
                Fax Information
            </UiText>
            <div className={styles.topMargin}>
                <UiText textColor="textSecondary">
                    Fax your documents to 646-971-8425
                </UiText>
            </div>
            <div className={styles.labeledTop}>
                <UiText variant="hatchback_125" weight="semi_bold_600">
                    {' '}
                    Mailing Information
                </UiText>
                <UiText className={styles.topMargin} textColor="textSecondary">
                    260 Madison Avenue, Suite 1001
                </UiText>
                <UiText textColor="textSecondary">New York, NY 10016</UiText>
            </div>
        </div>
    )
}
const MailOrFaxUpload = ({ requiredFilesData }: any) => {
    const styles = useStyles()
    const { values, setFieldValue }: any = useFormikContext()

   const hasUploadPromise = values?.uploads?.has_upload_promise
    return (
        <div>
            <ListAllFilesToUpload requiredFilesData={requiredFilesData} />
            <FaxAndMailInfo />
            <div className={styles.topMargin}>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                if (values?.uploads) {
                                    values.uploads.has_upload_promise = e.target.checked
                                }
                                setFieldValue(`uploads.has_upload_promise`, e.target.checked)
                            }}
                            name="select-all"
                            size="small"
                            checked={hasUploadPromise}
                        />
                    }
                    label={
                        <UiText textColor="textSecondary">
                            I understand that you can not start working on my
                            tax return until I submit the tax forms you have
                            requested.
                        </UiText>
                    }
                />
            </div>
        </div>
    )
}

export default MailOrFaxUpload
