import CryptoJS from 'crypto-js'

export default function maskUtil (input: string | number, mask: string) {
    if (!input) {
        return 'n/a';
    }
    const sections = mask.split('-');
    let tmp = 0;
    let result = '';
    let value = input + '';
    sections.forEach(section => {
        result += (result ? '-' : '') + value.substr(tmp, section.length);
        tmp += section.length;
    })
    return result || 'n/a';
}

export const inputMaskRegex = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
]

export const zipCodeRegex = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
export const phoneNumberRegex = [
    '(',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ')',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
]
export const contactZipCodeRegex = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]

export const ssNRegex = [
    /[0-9,X]/,
    /[0-9,X]/,
    /[0-9,X]/,
    '-',
    /[0-9,X]/,
    /[0-9,X]/,
    '-',
    /[0-9,X]/,
    /[0-9,X]/,
    /[0-9,X]/,
    /[0-9,X]/,
]


export const decryptData = (encryptedData: any) => {
    const CRYPTO_KEY = (window as any).ApiConfig.crypto_key

    const obj_json = encryptedData;

    const encrypted = obj_json.ciphertext;
    const salt = CryptoJS.enc.Hex.parse(obj_json.salt);
    const iv = CryptoJS.enc.Hex.parse(obj_json.iv);   

    const key = CryptoJS.PBKDF2(CRYPTO_KEY, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});
    const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});

    return decrypted.toString(CryptoJS.enc.Utf8);
}