import { hasPermission } from '../../../../services/aclService';
import store from '../../../../store';
import { ownershipValidation } from '../core/components/VTOMain/utils/validator';
import { GreaterThanZeroFields, VTO_STEPS } from '../models/vto.const';
import { 
    VIEW_TYPES, 
    INPUT_TYPE_QUESTIONS, 
    PERMISSION_TYPE_QUESTION, 
    DISSOLUTION_TYPE_QUESTION, 
    CHOICE_TYPE_QUESTION, 
    BUSINESS_BALANCE_REVENUE_MODEL, 
    BUSINESS_UPSELL_SALES_TAX_MODEL, 
    STATE_FIELD_TYPE_QUESTION,
    EXPENSE_TYPE_QUESTION,
    FILE_SET_QUESTION,
    CHECKBOX_SET_QUESTION,
    LIST_TYPE_QUESTION,
    LIST_SELECTABLE_TYPE_QUESTION,
    SET_TYPE_QUESTION,
    OWNERSHIP_PERCENTAGE
} from './questions.const';
import { VtoHelpers } from './VtoHelpers';
import { getValueByModelName, hasConditionMatched } from './VtoUtils';


export const isOwnershipInShares = (vtoDetails: any) => {
    return vtoDetails.business.business_type === 'S' || vtoDetails.business.business_type === 'C';
};

export const hasDefaultValue = (itemDefaultVal: any) => {
    return typeof itemDefaultVal !== 'undefined' && itemDefaultVal !== null && itemDefaultVal !== ''
}

export const isFilledAnswer = (val: any, options?: {gt0?:boolean, min?:number, max?: number}) => {
    const basicChecks = typeof val !== 'undefined' && val !== null && val !== ''
    
    if (options && options.gt0) {
        if(options.max) {
            return basicChecks && val <= options.max
        }
        return basicChecks && (val !== 0)
    }

    if(options && options.min){
        return basicChecks && val > options.min
    }

    if(options && options.max){
        return basicChecks && val <= options.max
    }

    return basicChecks
}

// FOR LIST TYPE OF QUESTION
export const isFilledList = (list: any, check: any) => {
    if (!list || typeof list.length === 'undefined' || list.length === 0) {
        return false;
    }
    for (let i in list) {
        if (!check(list[i])) {
            return false;
        }
    }
    return true;
};

export const setValueByModelName = (modelName: any, data: any, value: any) => {
    let model = data;
    let names: any[] = modelName.split('.');
    for (let i in names) {
        if (parseInt(i) === (names.length - 1)){
            model[names[i]] = value;
        }
        if (model) {
            model = model[names[i]];
        } else {
            break;
        }
    }
};


const isFilledExpenses = (expenses: any, check: any): {filled:boolean, unFilledCount:number} => {    
    if (!expenses || typeof expenses?.list === 'undefined') {
        return {filled: false, unFilledCount: 0}
    }
    let filledCount = 0;
    let unFilledCount = 0;

    for (let i in expenses.list) {
        let e = expenses.list[i];
        if (e.checked) {
            if (check(e)) {
                filledCount++;
            } else {
                unFilledCount++
            }
        }
    }

    if (expenses.has_others === true) {
        for (let oi in expenses.others) {
            let oe = expenses.others[oi];
            if (oe.checked) {
                if (check(oe)) {
                    filledCount++;
                } else {
                    unFilledCount++
                }
            }
        }
    }

    if(expenses.has_none) return {filled: true, unFilledCount:0}
    if(unFilledCount) return {filled: false, unFilledCount}
    if(!filledCount) return {filled: false, unFilledCount: 1}
    return {filled: true, unFilledCount:0}
};

// QUESTION WITH CHECKBOX SET FILLED
export const isQuestionCheckboxSetFilled = (question: any, vtoDetails: any) => {
    let set = getValueByModelName(question.model, vtoDetails);
    for (let i in question.questions) {
        if (set[question.questions[i].model]) {
            return true;
        }
    }
    if (question?.options?.has_others) {
        if (set[question.options.has_others.model]) {
            const value = getValueByModelName(question.options.has_others_description.model, set)
            return question.options.has_others_description ? isFilledAnswer(value) :  true
        }
    }
    if (question?.options?.has_none) {
        if (set[question.options.has_none.model]) {
            return true;
        }
    }
    return false;
};

// FILE SET QUESTION FIXES --- 
export const isQuestionFileSetFilled = (question: any, vtoDetails: any) => {
    if (getValueByModelName(question.model, vtoDetails)) { //none
        return true;
    }
    for (let i in question.files) {
        if (getValueByModelName(question.files[i].model, vtoDetails)) {
            return true;
        }
    }
    return false;
};


const isQuestionStateFieldsFilled = (question: any, vtoDetails: any) : {filled:boolean, unFilledCount:number} => {
    let stateFields = VtoHelpers.getStateFields(vtoDetails);
    let isFilledStateFields = (answers: any, stateFields: any) => {
        let unFilledCount = 0
        for (let i = 0; i < stateFields.length; i++) {
            if (!isFilledAnswer(answers?.[0]?.[stateFields[i]?.name])) {
                unFilledCount++;
            }
        }
        return { filled: unFilledCount === 0, unFilledCount};
    };
    let answers = getValueByModelName(question.model, vtoDetails);
    const noStateFields = !stateFields || stateFields.length === 0;
    const noAnswers = !answers || answers.length === 0;
    if(noStateFields) return { filled:true, unFilledCount:0 };
    if(stateFields.length && noAnswers) return {filled: false, unFilledCount: stateFields.length}
    return isFilledStateFields(answers, stateFields);
};

const isQuestionExpensesFilled = (question: any, vtoDetails: any) => {
    //@todo: add addition_questions validation
    return isFilledExpenses(getValueByModelName(question.model, vtoDetails), (item: any) => {
        return isFilledAnswer(item[question.options.customList ? 'name' : question.options.optionsKey])
            && isFilledAnswer(item.value, {gt0: true});
    });
};

export const isDissolutionQuestionVisible = (vtoDetails: any) => {
    const currentStore = store.getState()
    const extraInfo = currentStore.appData.personal_account.extra_info;
    const businessFormState = currentStore.appData.current_account.business.form_state
    const leadSource = extraInfo.lead_source
    const leadTypeNew = extraInfo.lead_type_new
    const isDissolution = (leadSource === 'Inc. Authority' || (
    leadTypeNew && leadTypeNew.includes('Dissolution Opt In')) || 
        (leadSource === 'ZenBusiness' && businessFormState === 'CA'))
    let isFinalReturn = getValueByModelName('business.is_final_return', vtoDetails)            
    return isFinalReturn && !isDissolution
}

export const hasSpecificPermission = (condition: any) => {
    const currentStore = store.getState()
    const personalAccount = currentStore.appData.personal_account
    let permission = hasPermission(condition.value[0],
        personalAccount
    );
    return !permission
}

export const isNoOptionsDone = (stepName: any, vtoDetails: any, vtoConfig: any) => {
    let is_othersSelected = false;
    const steps = vtoConfig.Steps['personal']
    let step = steps.find((step: any) => step.name === ('personal_'+stepName));
    for(let i in step.sections){
        let sectionName = step.sections[i];
        if (sectionName === 'no_'+stepName){ continue; }//skip no_income & no_deductions sections
        if (getValueByModelName(vtoConfig.Sections[sectionName].model, vtoDetails)){
            is_othersSelected = true;
            break;
        }
    }
    if (is_othersSelected){
        setValueByModelName(vtoConfig.Sections['no_'+stepName].model, vtoDetails, false);
    }
    return !is_othersSelected && getValueByModelName(vtoConfig.Sections['no_'+stepName].model, vtoDetails);
};


export const isUpsellQuestionFilled = (question: any, vtoDetails: any) => {
    const questionCondition = question?.options?.upsell?.show_condition[0]
    const isConditionMatched = hasConditionMatched(question?.options?.upsell?.show_condition, vtoDetails)
    const questionModalVal = getValueByModelName(questionCondition.model, vtoDetails)
    const questionAnswer = getValueByModelName(question?.model, vtoDetails);

    if(questionCondition.type === PERMISSION_TYPE_QUESTION) {
        if(!hasSpecificPermission(questionCondition)) {
            return true
        } else {
            return isFilledAnswer(questionAnswer)
        }
    } 
    else {
        if(questionCondition.type === DISSOLUTION_TYPE_QUESTION) {
            const isQuestionVisible = isDissolutionQuestionVisible(vtoDetails);
            const dissolutionQuestionValue = getValueByModelName(question.model, vtoDetails)
            return !isQuestionVisible ? true : isFilledAnswer(dissolutionQuestionValue)
        }
        if(question.model === BUSINESS_UPSELL_SALES_TAX_MODEL) {
            if(!isConditionMatched) {
                return true
            }
            return isFilledAnswer(questionAnswer)
        }
        if((question.model === BUSINESS_BALANCE_REVENUE_MODEL)){
            return !questionCondition.value.includes(questionModalVal)
        }
        if(questionCondition.min) {
            let modelVal = getValueByModelName(BUSINESS_BALANCE_REVENUE_MODEL, vtoDetails)
            const isVisible = questionCondition.min <= modelVal
            return isVisible ? isFilledAnswer(questionAnswer) : true
        }  

        if(!isConditionMatched) {
            return true
        }
        return isFilledAnswer(questionAnswer)
    }    
}   

export const isQuestionFilled = (question: any, vtoDetails: any, parent?:any, isCalledFromRemainingQuestionCount?:boolean) => {

    if (question.show_condition && !hasConditionMatched(question.show_condition, vtoDetails, parent)) {
        return true;
    }
    if (question.disable_condition && hasConditionMatched(question.disable_condition, vtoDetails)) {
        return true;
    }
   
    if(question?.options?.upsell?.show_condition && question.type === CHOICE_TYPE_QUESTION) {
        return isUpsellQuestionFilled(question, vtoDetails)
    }

    if (question.options && (question.options.optional || question.options.hidden)) {
        return true;
    }
    if (VIEW_TYPES.indexOf(question.type) !== -1 ) {
        return true;
    }

    // check for ownership % sum 100% validation
    if(question.model === OWNERSHIP_PERCENTAGE) {        
        return !ownershipValidation.isInvalid(parent)
    }

    if (INPUT_TYPE_QUESTIONS.indexOf(question.type) !== -1) {
        const modelVal = getValueByModelName(question.model, vtoDetails);
        const options: any = {};
        if(GreaterThanZeroFields.includes(question.model)) options.gt0 = true;
        if(question.options?.lessValidation) {
            const lessValidationModelVal =  getValueByModelName(question.options.lessValidation.model, vtoDetails);
            return modelVal <= lessValidationModelVal && isFilledAnswer(modelVal, options);
        }
        if(question?.options?.min){
            options.min = question.options.min
        }
        if(question?.options?.max){
            options.max = question.options.max
        }
        return isFilledAnswer(modelVal, options);
    }
    
    if (question.type === SET_TYPE_QUESTION) {
        return isItemQuestionsFilled(question, vtoDetails);
    }
    if (question.type === LIST_TYPE_QUESTION || question.type === LIST_SELECTABLE_TYPE_QUESTION) {

        // check for ownership validation
        let is_share_percent_shown = false
        let is_number_of_shares_shown = false

        if(question?.options?.customValidation === 'Ownership') {
            const share_percent = question.questions.find((field:any) => field.model === "share_percent");
            const number_of_shares = question.questions.find((field:any) => field.model === "number_of_shares");

            is_share_percent_shown = hasConditionMatched(share_percent?.show_condition, vtoDetails)
            is_number_of_shares_shown = hasConditionMatched(number_of_shares?.show_condition, vtoDetails)
        }
 
         if (
             (question.options &&
                 question.options.customValidation === 'Ownership' &&
                 is_share_percent_shown &&
                 ownershipValidation.isInvalid(vtoDetails)) ||
             (is_number_of_shares_shown &&
                 !ownershipValidation.isValidShares(vtoDetails))
         ) {
             return false
         }
       
        return isFilledList(getValueByModelName(question.model, vtoDetails), (item: any) => {
            return isItemQuestionsFilled(question, item, vtoDetails);
        });
    }
    if (question.type === CHECKBOX_SET_QUESTION) {
        return isQuestionCheckboxSetFilled(question, vtoDetails);
    }
    if (question.type === FILE_SET_QUESTION) {
       return isQuestionFileSetFilled(question, vtoDetails);
    }
    if (question.type === EXPENSE_TYPE_QUESTION) {
        const result = isQuestionExpensesFilled(question, vtoDetails);
        return isCalledFromRemainingQuestionCount ? result : result.filled;
    }
    if (question.type === STATE_FIELD_TYPE_QUESTION) {
        const result = isQuestionStateFieldsFilled(question, vtoDetails);
        return isCalledFromRemainingQuestionCount ? result : result.filled;
    }
    console.log('Unknown type ', question);
};


export const isItemQuestionsFilled = (item: any, vtoDetails: any, parent?:any) => {
    for (let i in item.questions) {
        if (!isQuestionFilled(item.questions[i], vtoDetails, parent)) {
            return false;
        }
    }
    return true;
};

export const isSectionCompleted = (section: any, vtoDetails: any) => {
    if (section.show_condition && !hasConditionMatched(section.show_condition, vtoDetails)) {
        return true;
    }
    return isItemQuestionsFilled(section, vtoDetails);

};

export const remainingQuestionCount = (section: any, vtoDetails: any) => {
    if ([VTO_STEPS.FORM_SCAN_STEP, VTO_STEPS.INTRODUCTION_STEP].includes(section)) {
        return 0;
    }
    if (section.show_condition && !hasConditionMatched(section.show_condition, vtoDetails)) {
        return 0;
    }
    let count = 0;

    const countUnansweredQuestions = (questions: any[] = [], vtoDetails: any, parent?:any): number => {
        
        let unansweredCount = 0;
        for (let question of questions) {
            if (question.show_condition && !hasConditionMatched(question.show_condition, vtoDetails, parent)) {
                continue
            }
            if (question.disable_condition && hasConditionMatched(question.disable_condition, vtoDetails)) {
                continue
            }

            if ([SET_TYPE_QUESTION].includes(question.type)) {
                if (question.options && (question.options.optional || question.options.hidden)) {
                    continue
                }

                unansweredCount += countUnansweredQuestions(question.questions, vtoDetails);
            } else if ([LIST_TYPE_QUESTION,LIST_SELECTABLE_TYPE_QUESTION].includes(question.type)){
                if (question.options && (question.options.optional || question.options.hidden)) {
                    continue
                }

                const list = getValueByModelName(question.model, vtoDetails);
                if (!list || typeof list.length === 'undefined' || list.length === 0) {
                   unansweredCount += question.questions.length;
                }
                for (const item of list) {
                    unansweredCount += countUnansweredQuestions(question.questions, item, vtoDetails)
                }

            } else {
                const filled = isQuestionFilled(question, vtoDetails, parent, true);
                if (typeof filled === 'boolean' && !filled) {
                     unansweredCount++;
                }
                if(typeof filled === 'object' && !filled.filled){
                    unansweredCount+= filled.unFilledCount
                }
            }
        }
        return unansweredCount;
    };

    count = countUnansweredQuestions(section.questions, vtoDetails);
    return count;
};