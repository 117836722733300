import { ApplicationStore } from '../../../../../../models'
import store from '../../../../../../store'
import { TAX_TYPE_8879_FLOW } from '../../../../../dashboard/tax-prep/constants/tax-prep.const'

const storeData = () => {
    const { config } = store.getState() as ApplicationStore
    return { config }
}

export const isDocumentUploadStatus = (taxApStatus: string): boolean => {
    const { config } = storeData()
    const vtoUpload = config?.apiConfig?.widget_upload
    const documentUploadedStatuses = vtoUpload?.documents_upload_status
    return documentUploadedStatuses.includes(taxApStatus)
}

// AWAITING CLIENT APPROVAL - status
export const isACAStatus = (taxApStatus: string): boolean => {
    const { config } = storeData()
    const vtoUpload = config?.apiConfig?.widget_upload
    const eFileUploadStatuses = vtoUpload?.e_file_upload_status

    return eFileUploadStatuses.includes(taxApStatus)
}

export const showDocumentUpload = (taxApStatus: string) => {
    const { config } = storeData()
    const vtoUpload = config?.apiConfig?.widget_upload
    const eFileUploadStatuses = vtoUpload?.e_file_upload_status
    const documentUploadedStatuses = vtoUpload?.documents_upload_status
    return (
        documentUploadedStatuses.includes(taxApStatus) ||
        eFileUploadStatuses.includes(taxApStatus)
    )
}

export const isForm8879Received = (vtoTaxAp: any): boolean =>
    vtoTaxAp.is_form_8879_received
export const show8879ESignFlow = (
    vtoTaxAp: any,
    isBusinessVto: boolean
): boolean => {
    if (
        isBusinessVto &&
        TAX_TYPE_8879_FLOW.includes(vtoTaxAp?.tax_ap_task_type) &&
        vtoTaxAp?.federal_tax
    ) {
        return true
    }

    return false
}


// Function to clean up the URL
export const normalizeURL = (url: string) => {
    const urlParts = url.split("/");
    const seen = new Set();
    const normalizedParts = urlParts.filter(part => {
        // Filter out duplicate parts
        if (seen.has(part)) return false;
        seen.add(part);
        return true;
    });
    return normalizedParts.join("/");
}