import { Divider, Theme, makeStyles } from '@material-ui/core'
import UiText from '../../../../common/ui/UiText'
import { ThemeColors } from '../../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext'
import { Fragment } from 'react'
import { FileDetails, FileInfo } from '../../models/vto.document-model'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        container: {
            marginTop: '1rem',
        },
        fileListing: {
            marginBottom: '0.6rem',
        },
        fileCount: {
            color: (colorTheme) => colorTheme.blue600,
        },
    }
})

export const ListAllFilesToUpload = ({ requiredFilesData }: { requiredFilesData: FileInfo}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)

    const groupFiles: FileDetails[] = Object.values(requiredFilesData).flatMap((item: any) => item.files);

    return (
        <Fragment>
            <Divider className={styles.fileListing}></Divider>
            <UiText variant="hatchback_125" weight="semi_bold_600">
                Documents
            </UiText>
            <div className={styles.container}>
                {groupFiles.map((file: any, index: number) => {
                        const isForm = file.form
                        return (
                            <div key={`${file.fileName}`}>
                                <UiText className={styles.fileListing}>
                                    {index+ 1}. {file.title} &nbsp;
                                    <span className={styles.fileCount}>
                                        {file.fileCount}
                                    </span>
                                    {isForm && (
                                        <span className={styles.fileCount}>
                                            {' '}
                                            {file.fileCount > 1
                                                ? 'forms'
                                                : 'form'}
                                        </span>
                                    )}
                                    {!isForm && (
                                        <span className={styles.fileCount}>
                                            {' '}
                                            {file.fileCount > 1
                                                ? 'files'
                                                : 'file'}
                                        </span>
                                    )}
                                </UiText>
                            </div>
                        )
                    })}
            </div>
        </Fragment>
    )
}

const AllInOneUpload = ({ requiredFilesData }: any) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)

    return (
        <div className={styles.container}>
            <ListAllFilesToUpload requiredFilesData={requiredFilesData} />
        </div>
    )
}

export default AllInOneUpload
