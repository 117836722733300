import { useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import UiText from '../../common/ui/UiText'
import { InformationBlock } from '../../contractor/InformationBlock'
import { destinations, needToCollect } from './BusinessTaxIntro'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import Loader from '../../common/Loader'
import { useDispatch } from 'react-redux'
import { getCustomerPortalLoginUrl } from '../../../services/vto/vtoSubmit'
import { showAlert } from '../../../store/actions/feedback'
import UiButton from '../../common/ui/UiButton'
import { ArrowForward } from '@material-ui/icons'
import UiDialog from '../../common/ui/UiDialog'
import { toRem16 } from '../../../styles/commonStyles'
import { formSubmitErrorHandler } from '../../../services/formService'
import { loadPostAuthData } from '../../../store/actions/appData'
import { bypassSecondaryPaymentMethod } from '../../../services/vto/vtoCore'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import { getAlreadyVisitedData, setAlreadyVisitedData } from '../vto/common/VtoUtils'

export const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        info: {
            margin: '1rem auto',
        },
        fixedButtonContainer: {
            width: '100%',
            '& button span': {
                fontWeight: 600,
            },
            '& .tertiary-btn:disabled': {
                background: (colorTheme) => colorTheme.primary,
                opacity: '0.25',
            },
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                padding: '1rem',
                backgroundColor: (colorTheme) => colorTheme.primaryWhite,
                borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
            },
        },
        bypassContainer: {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: toRem16(24),
            marginBottom: toRem16(32),
        },
        bypassAction: {
            width: toRem16(300),
        },
    }
})

export const CollectSecondaryPaymentMethod = ({
    collectCard,
    fetchingURL,
    handleCheckCardCount,
    bypassSecondaryPayment,
    isAdmin,
    handleNext,
}: any) => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const styles = useStyles(colorTheme)

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [chargeOverUrl, setChargeOverUrl] = useState<string>('')
    const [bypassModal, setBypassModal] = useState(false)
    const [isFormSubmitting, setIsFormSubmitting] = useState(false)
    const dispatch = useDispatch()
    const { personalAccount } = useCurrentStore()

    const handleSecondaryPaymentForm = () => {
        getCustomerPortalLoginUrl({
            destination: destinations.addNewCard,
        })
            .then((data: any) => {
                setChargeOverUrl(
                    `${data.redirect_url}${
                        needToCollect.TWO === collectCard
                            ? '&creditcard_set_primary=0'
                            : ''
                    }`
                )
                setIsLoading(false)
            })
            .catch((error: any) => {
                setIsLoading(false)
                dispatch(
                    showAlert({
                        alertType: 'error',
                        alertText: error?.statusText || 'Something went wrong',
                    })
                )
            })
    }

    const handlebypassClick = () => {
        setIsFormSubmitting(true)
        formSubmitErrorHandler(
            bypassSecondaryPaymentMethod({
                bypass_secondary_payment: true,
            }).then((response: any) => {
                setIsFormSubmitting(false)
                dispatch(loadPostAuthData())
                setBypassModal(false)
                handleNext()
            }),
            () => {
                setIsFormSubmitting(false)
            }
        )
    }

    const handleNectClick = () => {
        if (bypassSecondaryPayment || getAlreadyVisitedData(personalAccount.id)) {
            handleNext()
        } else {
            handleSecondaryPaymentForm()
            handleCheckCardCount()
            setAlreadyVisitedData(personalAccount.id)
        }
    }

    useEffect(() => {
        handleSecondaryPaymentForm()
        setBypassModal(isAdmin && !bypassSecondaryPayment)
    }, [])

    const Iframe = () => {
        return {
            __html: `<iframe src="${chargeOverUrl}" style="width: 100%;" height="100%" frameBorder="0"></iframe>`,
        }
    }

    if (fetchingURL || isLoading) {
        return <Loader />
    }

    return (
        <>
            <UiDialog
                open={bypassModal}
                size="sm"
                hideTitleSection
                handleClose={() => setBypassModal(false)}
            >
                <div className={styles.bypassContainer}>
                    <UiText variant="suv_150" weight="semi_bold_600">
                        Bypass Secondary Form of Payment
                    </UiText>
                    <UiText>
                        The client will be able to complete the VTO without
                        entering a secondary form of payment.
                    </UiText>
                    <div className={styles.bypassAction}>
                        <UiButton
                            fullWidth
                            btnType="tertiary"
                            handleClick={handlebypassClick}
                            disabled={isFormSubmitting}
                            label="Bypass"
                        />
                    </div>
                    <UiText variant="motorcycle_90">
                        This action is only available to admins.
                    </UiText>
                </div>
            </UiDialog>
            <UiText variant="truck_175" weight="bold_700">
                Confirm your identity with a form of payment
            </UiText>
            <div className={styles.info}>
                <InformationBlock
                    maxWith={440}
                    message={`${
                        needToCollect.ONE === collectCard
                            ? 'We need a form of payment'
                            : 'We need an additional form of payment'
                    }  to confirm your
                    identity before you can submit your tax information.`}
                />
            </div>
            <div
                style={{ width: '100%', height: '100%', overflow: 'hidden' }}
                dangerouslySetInnerHTML={Iframe()}
            />
            <div className={styles.fixedButtonContainer}>
                <UiButton
                    fullWidth
                    btnType="tertiary"
                    disabled={fetchingURL || isLoading}
                    endIcon={<ArrowForward />}
                    handleClick={handleNectClick}
                    label="Next"
                />
            </div>
        </>
    )
}
