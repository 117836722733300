import { toRem16 } from '../../../../../../../styles/commonStyles'
import { makeStyles, Theme } from '@material-ui/core'
import { ThemeColors } from '../../../../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import UiText from '../../../../../../common/ui/UiText'
import UiButton from '../../../../../../common/ui/UiButton'
import { useCurrentStore } from '../../../../../../common/hooks/useCurrentStore'
import { useVtoContext } from '../../../../providers/VtoProvider'
import { makeAnnualFrequency } from '../../../../common/VtoUtils'
import { useEffect, useState } from 'react'

const useStyles = makeStyles<Theme, ThemeColors>((theme) => {
    return {
        container: {
            position: 'relative',
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            padding: toRem16(16),
            borderRadius: toRem16(16),
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: toRem16(24),
        },
    }
})

export default function ExpeditedCard({
    setSubmitWithExpedita,
    handleSubmit,
}: any) {
    const theme = useThemeContext()
    const styles = useStyles(theme.colorTheme)
    const { currentYearVTOProducts, isBusinessVTO }: any = useVtoContext()
    const { product } = useCurrentStore()
    const [expediteProduct, setExpediteProduct] = useState<any>()

    useEffect(() => {
        if (product.products.length) {
            const vtoType = isBusinessVTO ? 'business' : 'personalInfo'
            const vtoProductName = `${vtoType}.upsell.expedite_tax_prep`

            const expediteProduct = product.products.find(
                (item: any) =>
                    item.product_id === currentYearVTOProducts[vtoProductName]
            )
            setExpediteProduct(expediteProduct)
        }
    }, [product])

    return (
        <div className={styles.container}>
            <UiText variant="hatchback_125" weight="semi_bold_600">
                Get Your Refund Faster & Guarantee On-Time Filing*
            </UiText>
            <UiText variant="suv_150" weight="bold_700">
                +$
                {expediteProduct
                    ? makeAnnualFrequency(expediteProduct.price_book)
                    : 0}
            </UiText>
            <div>
                <UiButton
                    btnType="tertiary"
                    label="Submit with Expedited Processing"
                    handleClick={() => {
                        setSubmitWithExpedita(expediteProduct.product_id)
                        handleSubmit()
                    }}
                />
            </div>
            <UiText variant="moped_75">
                *In order to file your taxes it is crucial you have reviewed the
                information here and respond to inquiries from your Accountants
                immediately.
            </UiText>
        </div>
    )
}
