import store from '../../store'
import { showAlert } from '../../store/actions/feedback'

export const showError = (errorText?: string) => {
    store.dispatch(
        showAlert({
            alertType: 'error',
            alertText: errorText || 'Internal error occurred',
        })
    )
}

export function ApiRequestHandler(
    apiRequest: Promise<any>,
    errorHandler?: () => void
) {
    apiRequest.catch((err) => {
        console.log("API Error", err)
        errorHandler?.()
        showError(err?.statusText)
    })
}
