import { Formik, Form, FormikValues } from 'formik';
import * as Yup from 'yup';
import {
    Theme,
    Grid,
    GridSize,
    makeStyles,
    useMediaQuery,
    useTheme,
    IconButton
} from '@material-ui/core';
// icons
import UiFormControlSelection from '../../../common/ui/UiFormControlSelection';
import Loader from '../../../common/Loader';
import {
    defaultValues,
    PurposeFormProps,
    propertyMappingGeneral,
    PurposePropFieldsInterface
} from './PurposeFormUtils';
import { handleCreatePurpose, handleUpdatePurpose } from '../actions';
import UiText from '../../../common/ui/UiText';
import UiButton from '../../../common/ui/UiButton'
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        marginTop: '0.75rem',
    },
    footerAction: {    
        display: 'flex',
       
        margin: '2rem 0rem 1rem 0rem'
    },
    modalButtons: {
        margin: '0rem 1rem 0rem 0rem !important',
    },
    formContainer: {
        margin: theme.spacing(10) + ' 0rem ' + theme.spacing(4) + ' 0rem'
    },
    header2: {
        margin: '1.5rem 0rem 1.5rem 0rem'
    },
    flex: {
        display: 'flex',
    },
}));

function PurposeForm({
    handleClose,
    loadPurposes,
    formikRef,
    purposeCallback,
    purpose
}: PurposeFormProps) {
    const classNames = useStyles();
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    let initialValues = defaultValues;
    const checkboxType = 'checkbox';
    if(purpose){
        initialValues = purpose
    }


    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required')
    });

    const onSubmit = (data: FormikValues) => {
        if(purpose){
            handleUpdatePurpose(purpose.id, data, (response) => {
                purposeCallback?.(response!);
                loadPurposes?.();
            });
        }else{
            handleCreatePurpose(data, (response) => {
                purposeCallback?.(response!);
                loadPurposes?.();
            });
        }
    };

    const renderFormFields = (property: PurposePropFieldsInterface) => {
        return (
            <>
                { property.type === checkboxType 
                ? 
                    (
                        <UiFormControlSelection
                            type={property?.type}
                            inlineLable={property?.label}
                            fieldName={property.key}
                            required={property?.required}
                            disabled={purpose?.is_default_mt_purpose}
                        />
                    )
                : 
                    (
                        <UiFormControlSelection
                            showFloatingLabel
                            placeholder={property?.placeholder}
                            type={property?.type}
                            fieldName={property.key}
                            required={property?.required}
                            errorMessage={property?.errorMessage}
                            label={property?.label}
                        />
                    )
                }
            </>
        );
    };

    const GridItem = (property: PurposePropFieldsInterface, data: any = null) => (
        <Grid item xs={property?.xs as GridSize} md={property?.md as GridSize} >
            <div
                className={classNames.container}
            >
                {data ? data : renderFormFields(property)}
            </div>
        </Grid>
    );
    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
                innerRef={formikRef}
            >
                {({ submitForm, touched, isSubmitting, values }) => {
                    return (
                        
                        <Form>
                            <div className={classNames.flex}>
                            <Grid justify="flex-start" container>
                                <UiText variant="hatchback_125" weight="medium_500" className={classNames.header2}>
                                    {purpose ? 'Edit Purpose' : 'New Purpose'}
                                </UiText>
                                </Grid>
                                <Grid justify="flex-end">
                                    <IconButton 
                                        aria-label="close" 
                                        onClick={() => {
                                            handleClose(true)
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </div>
                             
                            <div
                                className={
                                    isSmDown ? classNames.formContainer : ''
                                }
                            >
                                {propertyMappingGeneral.map(
                                    (property: PurposePropFieldsInterface) => (
                                            <Grid 
                                                container spacing={1}
                                                key={property?.key}
                                            >
                                                {GridItem(property)}
                                            </Grid>
                                    )
                                )}
                                
                            </div>
                                <div className={classNames.footerAction}>
                                    {isSubmitting ? (
                                        <Loader />
                                    ) : (
                                        <Grid justify="flex-start" container>
                                            <UiButton
                                                 btnType="secondary"
                                                 label="Cancel"
                                                 handleClick={() => {
                                                    handleClose?.();
                                                 }}
                                                 customClass={
                                                    classNames.modalButtons
                                                }
                                            />
                                            <UiButton
                                                 btnType="primary"
                                                 label="Save"
                                                 handleClick={submitForm}
                                                 customClass={
                                                    classNames.modalButtons
                                                }
                                            />
                                        </Grid>
                                    )}
                                </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default PurposeForm;