import { makeStyles } from "@material-ui/core";
import UiDialog from "../../../components/common/ui/UiDialog";
import UiText from "../../../components/common/ui/UiText";
import UiButton from "../../../components/common/ui/UiButton";
import { commonStyles, mergeStyle } from "../../../styles/commonStyles";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Icon from "../../../components/common/Icon";

const useStyles = makeStyles(() => ({
    dialogContainer: {
        "& .MuiPaper-root": {
            width: "640px",
            height: "374px",
            maxWidth: "700px",
            maxHeight: "400px",
        },
        "& .MuiDialogContent-root": {
            overflow: "hidden",
        },
    },
    margin32: {
        marginTop: "32px",
    },
}));

const FinishOnboarding = ({ open, handleClose }: any) => {
    const styles = commonStyles();
    const classes = useStyles();
    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            hideTitleSection={true}
            size="sm"
            customRootClass={classes.dialogContainer}
        >
            <div data-cy="payroll-setup-confirmation">
                <div className={mergeStyle(styles.flex, styles.flexEnd)}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <div className={styles.textCenter}>
                    <div
                        className={mergeStyle(
                            styles.flex,
                            styles.justifyCenter
                        )}
                        onClick={handleClose}
                    >
                        <Icon icon="thumbsup" />
                    </div>
                    
                    <UiText className={mergeStyle(styles.mt16, styles.mb16)}>
                        Company is not yet active in ADP platform. Please wait for the onboarding process to be completed!!
                    </UiText>
                    <div className={mergeStyle(styles.mt16, styles.mb16)}>
                        <UiButton
                            btnType="tertiary"
                            handleClick={handleClose}
                            label="Okay"
                            data-cy="close-modal-btn"
                        />
                    </div>
                </div>
            </div>
        </UiDialog>
    );
};

export default FinishOnboarding;
