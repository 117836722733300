import { makeStyles, Theme } from '@material-ui/core'
import { toRem16 } from '../../../../../../../styles/commonStyles'
import { ThemeColors } from '../../../../../../../styles/models/Colors.interface'

export const useMenuStyles = makeStyles<Theme, ThemeColors>((theme) => {
    return {
        container: {
            paddingRight: '1rem',
        },
        stepContainer: {
            '&::-webkit-scrollbar-thumb': {
                background: (colorTheme) => colorTheme.grey2700,
            },
            '& .MuiStepConnector-vertical': {
                padding: `0 ${toRem16(22)}`,
            },
            '& .MuiStepConnector-vertical span': {
                borderLeftWidth: `${toRem16(2)}`,
            },
            '& .MuiStepLabel-label': {
                color: (colorTheme) => colorTheme.black100,
            },
        },
        scrollContainer: {
            overflowY: 'auto',
            maxHeight: `calc(100vh - 15rem)`,
            [theme.breakpoints.down('sm')]: {
                maxHeight: 'unset',
                paddingRight: 0,
                maxWidth: '100%',
            },
            '&::-webkit-scrollbar-thumb': {
                background: (colorTheme) => colorTheme.grey2700,
            },
            '& .MuiStepConnector-vertical': {
                marginLeft: '20px',
            },
            '& .MuiStepConnector-vertical span': {
                borderLeftWidth: `${toRem16(2)}`,
            },
            '& .MuiStepLabel-label': {
                color: (colorTheme) => colorTheme.black100,
            },
        },
        progressContainer: {
            marginBottom: '1rem',
        },
        submitButton: {
            width: '100%',
            marginTop: '1rem',
            marginBottom: '1rem',
            '&.MuiButton-root.Mui-disabled': {
                opacity: '0.5',
                background: (colorTheme) => colorTheme.primary
            },
        },
        stepper: {
            marginTop: '0.5rem',
            padding: '0',
            '&::-webkit-scrollbar-thumb': {
                background: (colorTheme) => colorTheme.grey2700,
            },
            '& .MuiStepConnector-vertical': {
                padding: `0 ${toRem16(22)}`,
            },
            '& .MuiStepConnector-vertical span': {
                borderLeftWidth: `${toRem16(2)}`,
            },
            '& .MuiStepLabel-label': {
                color: (colorTheme) => colorTheme.black100,
            },
            '&.vto-steps': {
                '& > div:not(.MuiStepConnector-vertical)': {
                    zIndex: 1,
                },
                '& .MuiStepConnector-vertical': {
                    '& .MuiStepConnector-line': {
                        position: 'relative',
                        borderColor: (colorTheme) => colorTheme.grey200,
                    },
                },
            },

            '& .MuiStep-root:last-child .EmptyCircle:after, & .MuiStep-root:last-child .checkmark:after, & .MuiStep-root:last-child .checkedDashes:after':
                {
                    display: 'none',
                },
        },
        item: {
            borderRadius: '0.5rem',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: (colorTheme) => colorTheme.cyan200,
            },
        },
        itemWithStep: {
            padding: '0.5rem',
            marginLeft: '0.4rem',
            [theme.breakpoints.down('xs')]: {
                padding: '0.5rem 1rem',
                marginLeft: 0,
            },
        },
        itemWithoutStep: {
            padding: '0.5rem 1rem',
        },
        activeItem: {
            backgroundColor: (colorTheme) => colorTheme.cyan100,
            '&:hover': {
                backgroundColor: (colorTheme) => `${colorTheme.cyan100} !important`,
            },
        },
        step: {
            margin: `0 0.25rem 0px 2rem`
        },
        vtoStep: {
            padding: `0 0.25rem 0px 2rem`,
            borderRadius: '0.5rem',
            '&:hover': {
                backgroundColor: (colorTheme) => colorTheme.cyan200,
            },
            '& .icon.EmptyCircle:before, & .icon.EmptyCircle:after, & .icon.checkmark:before, & .icon.checkmark:after, & .icon.checkedDashes:before, & .icon.checkedDashes:after':
                {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    width: '2px',
                    height: 'calc((100% - 34px)/2)',
                    backgroundColor: (colorTheme) => colorTheme.grey200,
                    left: '10px',
                },
            '& .icon.EmptyCircle:before, & .icon.checkmark:before, & .icon.checkedDashes:before': {
                top: 0,
                borderBottomLeftRadius: '1px',
                borderBottomRightRadius: '1px',
            },
            '& .icon.EmptyCircle:after, & .icon.checkmark:after, & .icon.checkedDashes:after': {
                bottom: 0,
                borderTopLeftRadius: '1px',
                borderTopRightRadius: '1px',
            },
        },
        stepLabel: {
            cursor: 'pointer',
            padding: `${toRem16(6)} 0`,
            position: 'relative', // This need to be position relative for lines between steps to look fine.
        },
        lastStep: {
            marginBottom: '1rem',
        },
        itemIconContainer: {
            position: 'relative',
            display: 'flex',
            marginRight: '1rem',
            paddingLeft: '0.5rem',
        },
        itemIcon: {
            display: 'flex',
            height: '40px',
            width: '40px',
            minWidth: '40px',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#89D3C6',
            borderRadius: '50px',
        },
        stepIcon: {
            marginRight: '1.5rem',
            marginLeft: '-1px',
        },
        checkIcon: {
            position: 'absolute',
            right: `${toRem16(-8)}`,
            bottom: `${toRem16(-4)}`,
            '&.checkmarkDashes': {
                right: `${toRem16(-10)}`,
                bottom: `${toRem16(-10)}`,
            },
        },
        lastStepStyles: {
            marginBottom: '2rem'
        },
        centeredLoader: {
            margin: 'auto',
        },
    }
})