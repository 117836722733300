import SectionQuestionsRenderer from '../../sections/SectionQuestionsRenderer'

const ProfitLossStatement = ({ section }: any) => {    
    return (
        <SectionQuestionsRenderer
            section={section}
        />
    )
}

export default ProfitLossStatement
