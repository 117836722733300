import { Theme, makeStyles } from '@material-ui/core'
import { toRem16 } from '../../../../../../../styles/commonStyles'
import UiText from '../../../../../../common/ui/UiText'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import UiButton from '../../../../../../common/ui/UiButton'
import { useHistory } from 'react-router-dom'
import { ThemeColors } from '../../../../../../../styles/models/Colors.interface'
import Icon from '../../../../../../common/Icon'
import { SelectedPackage } from './SelectedPackage'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: toRem16(640),
            gap: toRem16(16),
            [theme.breakpoints.up('sm')]: {
                textAlign: 'center',
            },
        },
        nextStepsContainer: {
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            borderRadius: toRem16(16),
            padding: toRem16(16),
            display: 'flex',
            flexDirection: 'column',
            margin: `${toRem16(48)} 0`,
            gap: toRem16(16),
        },
        stepIndicatore: {
            display: 'flex',
            gap: toRem16(8),
            textAlign: 'left',
        },
        step: {
            backgroundColor: (colorTheme) => colorTheme.mint100,
            borderRadius: '50%',
            width: toRem16(40),
            height: toRem16(40),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        purchased: {
            width: toRem16(410),
            marginBottom: toRem16(40),
        },
    }
})

const whatNext = [
    'We will review your information and contact you when we are ready to discuss your tax return. It is critical that you respond promptly when we reach out to you.',
    'You will be required to review and sign your tax return before we file it.',
    'We’ll file your tax return with the IRS and your State. We’ll let you know once it has been approved.',
]

export default function Success({ selectedPackage }: any) {
    const theme = useThemeContext()
    const styles = useStyles(theme.colorTheme)
    const history = useHistory()

    const handleGoToDashboard = () => {
        history.push('/dashboard')
    }

    const Row = ({ text, step }: any) => {
        return (
            <div className={styles.stepIndicatore}>
                <div>
                    <UiText
                        className={styles.step}
                        variant="hatchback_125"
                        weight="bold_700"
                    >
                        {step}
                    </UiText>
                </div>
                <UiText>{text}</UiText>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Icon icon="checkedIcon" size={'60px'} />
            <UiText variant="truck_175" weight="bold_700">
                Thanks for confirming your information!
            </UiText>
            <UiText>
                Your taxes will be done in{' '}
                <span>
                    <b>about 14 days*</b>
                </span>
            </UiText>
            <UiText variant="moped_75" textColor={theme.colorTheme.grey400}>
                *This estimate is based on having all necessary information.{' '}
                <br /> Any missing information will result in a longer
                processing time.
            </UiText>

            <div className={styles.nextStepsContainer}>
                <UiText variant="suv_150" weight="medium_500">
                    What’s next?
                </UiText>
                {whatNext.map((text: string, index: number) => (
                    <Row text={text} step={index + 1} />
                ))}

                <UiText>
                    See the status of your tax return any time on your
                    <UiButton
                        btnType="hyperlink"
                        label="dashboard"
                        handleClick={handleGoToDashboard}
                    />
                </UiText>
            </div>
            {selectedPackage && (
                <div className={styles.purchased}>
                    <UiText variant="suv_150" weight="medium_500">
                        We'll also be reaching out soon regarding these new
                        services
                    </UiText>
                    <SelectedPackage selectedPackage={selectedPackage} />
                </div>
            )}
        </div>
    )
}
