import React from 'react'
import UiText from '../../../../common/ui/UiText'
import { makeStyles, Theme } from '@material-ui/core'
import { ThemeColors } from '../../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext'
import Icon from '../../../../common/Icon'
import { toRem16 } from '../../../../../styles/commonStyles'
import { useVtoContext } from '../../providers/VtoProvider'
import { discountCriteria } from '../../common/VtoUtils'
import { Alert } from '@material-ui/lab'

interface UpsellInfoCardProps {
    headerText?: string
    contentList?: string[]
    customContent?: React.ReactNode
    alertContent?: string | React.ReactNode
    upsell: any
}

const useStyles = makeStyles<Theme, ThemeColors>((theme) => ({
    container: {
        position: 'relative',
        border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        padding: '1.25rem',
        borderRadius: '0.5rem',
        '& .price': {
            marginTop: '0.2rem',
            marginBottom: '0.5rem',
        },
        margin: `${toRem16(16)} 0 ${toRem16(24)} 0`,
    },
    previousPrice: {
        textDecoration: 'line-through',
        color: (colorTheme) => colorTheme.grey400,
    },
    customLabel: {
        display: 'flex',
        '& .discount_text': {
            marginLeft: '0.5rem',
        },
    },
    discount: {
        width: 'max-content',
        maxWidth: '94%',
        borderRadius: toRem16(16),
        padding: `${toRem16(8)} ${toRem16(16)}`,
        backgroundColor: (colorTheme) => colorTheme.teal200,
        marginBottom: '1rem',
    },
}))

const UpsellInfoCard = ({
    contentList,
    customContent,
    upsell,
}: UpsellInfoCardProps) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { heading, discount, tooltip, product_key, detail1, detail2 } = upsell
    const { isBusinessVTO, currentVtoDetails, productPriceMap }: any =
        useVtoContext()
    const { haveDiscount } = discountCriteria(
        isBusinessVTO,
        currentVtoDetails?.business?.business_type ?? ''
    )

    const product = productPriceMap[product_key]
    const productPrice = product.annualPrice
    const discountedPrice = product.discountPrice

    const replacePrice = (text: string) => {
        return text.replace('{$}', `$${Math.round(discountedPrice / 12)}`)
    }

    const listItem = contentList
        ? contentList
        : [replacePrice(detail1), replacePrice(detail2)]

    const showDiscountedPrice = product.discount
        ? discountedPrice && haveDiscount
        : false
    return (
        <div className={styles.container}>
            <UiText variant="hatchback_125" weight="bold_700">
                {heading}
            </UiText>
            <div>
                {customContent ? (
                    customContent
                ) : (
                    <ul>
                        {listItem &&
                            listItem.map((item: any) => {
                                return (
                                    <li key={item}>
                                        <UiText>{item}</UiText>
                                    </li>
                                )
                            })}
                    </ul>
                )}
            </div>

            {showDiscountedPrice && (
                <UiText className={haveDiscount ? styles.previousPrice : ''}>
                    ${productPrice}
                </UiText>
            )}
            {productPrice && haveDiscount && (
                <UiText variant="suv_150" weight="bold_700" className="price">
                    $
                    {Math.round(
                        discountedPrice ? discountedPrice : productPrice
                    )}
                </UiText>
            )}

            {showDiscountedPrice && (
                <div className={styles.discount}>
                    <div className={styles.customLabel}>
                        <Icon icon={'discount'} />
                        <UiText
                            variant="moped_75"
                            weight="medium_500"
                            className="discount_text"
                        >
                            {discount}
                        </UiText>
                    </div>
                </div>
            )}

            {tooltip && <Alert severity="info">{tooltip}</Alert>}
        </div>
    )
}

export default UpsellInfoCard
