import { Message } from '../../models';
import {
    getResource,
    postResource,
    patchResource,
    deleteResource,
} from '../axiosService';

export function getMessages(account_id: string, params: any) {
    return getResource(
        `api/message-center/account/${account_id}/conversation`,
        undefined,
        params
    );
}

export function getUnreadMessages(accountId: string) {
    return getResource<Message[]>(
        `/api/message-center/account/${accountId}/conversation/unread`
    );
}

export function getMessagesCenterTopics() {
    return getResource('api/message-center/topic');
}

export function createMessage(account_id: string, body: any) {
    return postResource(
        `api/message-center/account/${account_id}/conversation`,
        body
    );
}

export function getMessage(account_id: string, conversation_id: string, isArchivedDB?: boolean) {
    const baseUrl = `api/message-center/account/${account_id}/conversation/${conversation_id}`;
    const url = isArchivedDB ? `${baseUrl}?archived_db=true` : baseUrl;
    return getResource(url, undefined);
}

export function replyToMessage(
    account_id: string,
    conersation_id: string,
    body: any
) {
    return postResource(
        `api/message-center/account/${account_id}/conversation/${conersation_id}/messages`,
        body,
        undefined
    );
}

export function markReadMessage(
    account_id: string,
    conersation_id: string,
    id: any,
    isArchivedDB?: boolean
) {
    const baseUrl = `api/message-center/account/${account_id}/conversation/${conersation_id}/messages`;
    const url = isArchivedDB ? `${baseUrl}?archived_db=true` : baseUrl;
    return patchResource(
        url,
        { filters: id, is_read: true },
        undefined
    );
}

export function archiveMessage(account_id: string, conersation_id: string) {
    return patchResource(
        `api/message-center/account/${account_id}/conversation/${conersation_id}`,
        { status: 'archived' },
        undefined
    );
}

export function removeAttachment(
    conversation_id: string,
    message_id: string,
    attachment_id: string,
    isArchivedDB?: boolean
) {
    const baseUrl = `api/message-center/conversation/${conversation_id}/message/${message_id}/attachment/${attachment_id}`;
    const url = isArchivedDB ? `${baseUrl}?archived_db=true` : baseUrl;
    return deleteResource(url);
}
