import {
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Typography,
    Divider,
    makeStyles,
    Theme,
    Grid,
    Box,
    Tooltip,
} from '@material-ui/core';
import { Fragment, useState } from 'react';

import PortalTabs from '../common/PortalTabs';
import TabPanelHolder from '../common/TabPanelHolder';
import Loader from '../common/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getMessageTime } from '../../utils/appUtil';
import { Link } from 'react-router-dom';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';

interface TabContentProps {
    isLoading: boolean | undefined;
    data: any;
    id: string;
}

function MessageCenterListView(props: {
    items: any[];
    loadMore: any;
    hasMore: boolean;
    personalAccountId: string;
    searchResult: boolean;
    activeData: any;
    archivedData: any;
    archivedDBData: any;
    selectedId?: string;
    handleTabChange?: any;
    isMessageLoading?: boolean;
}) {
    const { colorTheme } = useThemeContext();
    const [selectedTab, setSelectedTab] = useState(0);
    const classes = makeStyles((theme: Theme) => ({
        title: {
            padding: theme.spacing(2),
            top: theme.spacing(0),
            position: 'relative',
            display: 'inline',
        },
        listRoot: {
            width: '100%',
            height: 'calc(100vh - 261px)',
            overflow: 'auto',
            backgroundColor: theme.palette.background.paper,
        },
        inline: {
            display: 'inline',
        },
        timeText: {
            whiteSpace: 'nowrap',
            paddingTop: '6px',
            color: colorTheme.grey400,
            fontWeight: 'normal',
        },
        cardIcon: {
            width: '38px',
            left: '1px',
            opacity: '0.8',
            top: '7px',
        },
        navListItemGutters: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        newMessageButton: {
            height: '36px',
        },
        refeshButton: {
            fontSize: theme.spacing(3),
        },
        searchInput: {
            marginTop: theme.spacing(3),
            height: '40px',
            width: '100%',
        },
        archivedStatus: {
            marginRight: theme.spacing(3),
        },
        statusIcon: {
            position: 'relative',
            top: '3px',
            marginRight: theme.spacing(0),
        },
        selectedMessage: {
            background: `${colorTheme.green500} !important`,
        },
        messageBody: {
            wordWrap: 'break-word',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': 2
        },
        attachmentIconStyle: {
            fontSize: '15px',
            top: theme.spacing(0),
            position: 'relative',
        },
        subjectText: {
            maxWidth: 'calc(100% - 80px)',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        loaderStyle: {
            padding: '0.5rem 0',
        }
    }))();

    const Private = (
        <Fragment>
            <img
                className={classes.statusIcon}
                alt={`Private`}
                src={require(`../../assets/icons-svg/Private.svg`).default}
            />
            <Typography
                component='span'
                variant='subtitle2'
                color='textSecondary'
                gutterBottom={false}
            >
                Private
            </Typography>
        </Fragment>
    );

    const Archived = (
        <Box className={classes.archivedStatus} component='span'>
            <img
                className={classes.statusIcon}
                alt={`Archive`}
                src={require(`../../assets/icons-svg/Archived.svg`).default}
            />
            <Typography
                component='span'
                variant='subtitle2'
                color='textSecondary'
                gutterBottom={false}
            >
                {selectedTab == 1 ? 'Closed' : 'Archived'}
            </Typography>
        </Box>
    );

    const handleListTabChange = (event: any) => {
        if(props?.handleTabChange) {
            setSelectedTab(event);
            props?.handleTabChange(event);
        }
    }

    const Messages = (data: any, id?: string) => {
        return (
            <Fragment>
                <List className={classes.listRoot} id={id || 'scrollableDiv'}>
                    {data.items.length === 0 ? (
                        <Box textAlign='center'>
                            <Typography
                                variant='subtitle1'
                                color='textSecondary'
                            >
                                No Conversations Were Found
                            </Typography>
                        </Box>
                    ) : (
                        <InfiniteScroll
                            dataLength={data.items.length}
                            next={() =>
                                props.loadMore(
                                    id === 'archived' ? 'archived' :
                                    id === 'archivedDB' ? 'archivedDB' :
                                    id === 'active' ? 'active' : undefined
                                )
                            }
                            hasMore={data.hasMore}
                            scrollableTarget={id || 'scrollableDiv'}
             
                            loader={
                                <Grid
                                    key={'loader'}
                                    container
                                    direction='column'
                                    justify='center'
                                    alignItems='center'
                                    style={{
                                        paddingBottom: '16px',
                                        paddingTop: '16px',
                                    }}
                                >
                                    <Loader />
                                </Grid>
                            }
                        >
                            {data.items.map((i: any, index: number) => (
                                <Fragment key={index}>
                                    <ListItem
                                        component={Link}
                                        to={selectedTab === 2 ? `/message_center/${i.id}?archivedDB=true` : `/message_center/${i.id}`}
                                        button
                                        classes={{
                                            gutters: classes.navListItemGutters,
                                            selected: classes.selectedMessage,
                                        }}
                                        alignItems='flex-start'
                                        selected={props.selectedId === i.id}
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                alt='User Avatar'
                                                src={
                                                  (i.accountants &&
                                                        i.accountants.length > 0 &&
                                                        i.accountants[0].photo_link) ||
                                                    require(`../../assets/icons-svg/User-Avatar.svg`)
                                                        .default
                                                }
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                style: {
                                                    fontWeight: i.unread_messages_count
                                                        ? 'bold'
                                                        : 'normal',
                                                },
                                            }}
                                            primary={
                                                <Fragment>
                                                    <div>
                                                        {i.status === 'archived'
                                                            ? Archived
                                                            : ''}
                                                        {i.portal_account_id ===
                                                        props.personalAccountId
                                                            ? Private
                                                            : ''}
                                                    </div>
                                                    <Tooltip
                                                        title={i.subject}
                                                        aria-label='subject'
                                                    >
                                                        <div
                                                            className={
                                                                classes.subjectText
                                                            }
                                                        >
                                                            {i.subject}
                                                        </div>
                                                    </Tooltip>
                                                </Fragment>
                                            }
                                            secondary={
                                                <Box
                                                    component='span'
                                                    className={
                                                        classes.messageBody
                                                    }
                                                >
                                                    {i.last_message.portal_user
                                                        ? `${i.last_message.portal_user.first_name} ${i.last_message.portal_user.last_name}`
                                                        : `${
                                                              i.last_message
                                                                  .accountant
                                                                  .first_name
                                                          } ${i.last_message.accountant.last_name.substr(
                                                              0,
                                                              1
                                                          )}`}

                                                    {': '}
                                                    {i.last_message.body}
                                                    {!i.last_message.body &&
                                                    i.last_message.attachments
                                                        .length === 0
                                                        ? 'This file has been removed.'
                                                        : ''}
                                                    {i.last_message.attachments.map(
                                                        (
                                                            a: any,
                                                            index: number
                                                        ) => (
                                                            <Fragment
                                                                key={index}
                                                            >
                                                                <AttachFileIcon
                                                                    className={
                                                                        classes.attachmentIconStyle
                                                                    }
                                                                />
                                                                {a.file_name}
                                                            </Fragment>
                                                        )
                                                    )}
                                                </Box>
                                            }
                                        />
                                        <Typography
                                            className={classes.timeText}
                                            variant='caption'
                                        >
                                            {getMessageTime(
                                                i.last_message.date * 1000
                                            )}
                                        </Typography>
                                    </ListItem>
                                    {index === data.items.length - 1 ? (
                                        ''
                                    ) : (
                                        <Divider variant='inset' />
                                    )}
                                </Fragment>
                            ))}
                        </InfiniteScroll>
                    )}
                </List>
            </Fragment>
        );
    };

    const renderTabContent = ({ isLoading, data, id }: TabContentProps) => {
      return isLoading ? (
        <div className={`${classes.listRoot} ${classes.loaderStyle}`}>
          <Loader />
        </div>
      ) : (
        Messages(data, id)
      );
    };

    return (
      <Fragment>
        {props.searchResult ? (
          <Fragment>
            {Messages({
              items: props.items,
              hasMore: props.hasMore,
            })}
          </Fragment>
        ) : (
          <Box width="100%">
            <PortalTabs disablePadding={true} onTabChange={handleListTabChange}>
              <TabPanelHolder index={0} title="Active">
                {renderTabContent({
                    isLoading: props?.isMessageLoading,
                    data: props.activeData,
                    id: 'active',
                })}
              </TabPanelHolder>
              <TabPanelHolder index={1} title="Closed">
                {renderTabContent({
                    isLoading: props?.isMessageLoading,
                    data: props.archivedData,
                    id: 'archived',
                })}
              </TabPanelHolder>
              <TabPanelHolder index={2} title="Archived">
                {renderTabContent({
                    isLoading: props?.isMessageLoading,
                    data: props.archivedDBData,
                    id: 'archivedDB',
                })}
              </TabPanelHolder>
            </PortalTabs>
          </Box>
        )}
      </Fragment>
    );
}

export default MessageCenterListView;
