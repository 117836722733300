import { useState, useEffect } from 'react'
import { makeStyles, Theme, Hidden } from '@material-ui/core'
import UiText from '../../../../../../common/ui/UiText'
import { commonVtoStyles } from '../../../styles/commonVtoStyles'
import { toRem16 } from '../../../../../../../styles/commonStyles'
import LinearProgressBar from '../../../../../../common/LinearProgressBar'
import UiButton from '../../../../../../common/ui/UiButton'
import leftArrow from '../../../../../../../assets/icons-svg/LeftArrow.svg'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import Success from './Success'
import { useVtoContext } from '../../../../providers/VtoProvider'
import { getDateInYmd } from '../../../../../../../utils/dateUtil'
import WithPackages, { AdditionalProps, excluded } from './WithPackages'
import { useCurrentStore } from '../../../../../../common/hooks/useCurrentStore'
import { isRenewalAvailable } from '../../../../../../../services/vto/vtoSubmit'
import { expeditePage, SUBMIT_VTO } from '../../../../models/vto.const'
import EarlyRenewalModal from './EarlyRenewalModal'
import Disclosures from './Disclosures'
import UpsellPackages from './UpsellPackages'
import SubmitTaxInfo from './SubmitTaxInfo'
import { discountCriteria } from '../../../../common/VtoUtils'
import { useFormikContext } from 'formik'
import ChargeoverIframWithPaymentProcessing from '../../../../../../sales/ChargeoverIframWithPaymentProcessing'
import ScheduleRetirementPlanning from './RetirementPlanning'

interface ConsentDisclosureProps {
    hideContentPage: () => void
    percentage: number | string
}

const useStyles = makeStyles<Theme, { step: number }>((theme) => {
    return {
        fullWidthContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        btnAndProgressContainer: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& .MuiTypography-button': {
                fontWeight: 600,
            },
        },
        progressContainer: {
            width: '100%',
            maxWidth: '43rem',
        },
        contentContainer: {
            marginTop: '2rem',
            [theme.breakpoints.down('sm')]: {
                padding: '1rem 0',
            },
            width: '100%',
            maxWidth: (props) => (props.step < 2 ? toRem16(688) : 'unset'),
        },
        heading: {
            [theme.breakpoints.up('lg')]: {
                textAlign: 'center',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.125rem',
                fontWeight: 600,
            },
        },
        alertContainer: {
            margin: `${toRem16(24)} auto`,
            width: '100%',
            maxWidth: '25rem',
        },
        formContainer: {
            width: '100%',
        },
        emptyDiv: {
            width: '9rem',
        },
    }
})

type Props = ConsentDisclosureProps & AdditionalProps

const VTOSubmit: React.FC<Props> = ({
    hideContentPage,
    suggestedPackages,
    setExcludedProdut,
    excludedProduct,
    percentage,
}) => {
    const { colorTheme } = useThemeContext()
    const classes = commonVtoStyles(colorTheme)
    const [selectedPackage, setSelectedPackage] = useState<any>(null)
    const [noPackageSelected, setNoPackageSelected] = useState<any>(null)
    const [showSuccessScreen, setShowSuccessScreen] = useState(false)
    const [showRetirementCallScreen, setShowRetirementCallScreen] = useState(false);
    const [openChargeoverModal, setOpenChargeoverModal] = useState(false)
    const [renewalCheck, setRenewalCheck] = useState<boolean>(false) // TO DO
    const [renewaldata, setRenewaldata] = useState<any>({
        isModalOpen: false,
        renewalDate: '',
    })
    const [expedite, setExpedite] = useState<expeditePage>(
        expeditePage.SELECTED
    )
    const [submitWithExpedite, setSubmitWithExpedita] = useState<any>()
    const [isAutoPaymentProcessing, setIsAutoPaymentProcessing] =
        useState<boolean>(false)
    const {
        currentVtoDetails,
        loading,
        setLoading,
        updateVTO,
        vtoSubmitStep,
        setVtoSubmitStep,
        currentYearVTOProducts,
        selectedYear,
        isBusinessVTO,
        hasVTOPermission,
    }: any = useVtoContext()
    const { values }: { values: any } = useFormikContext()
    const { product } = useCurrentStore()

    const isSelectedFreeTaxExtension = currentVtoDetails.extension_upsell_opted
    const isDiscountApplied = discountCriteria(
        isBusinessVTO,
        currentVtoDetails?.business?.business_type ?? ''
    )
    const canShowRetirementCallScreen = currentVtoDetails.setup_retirement_plan_small_business && isBusinessVTO;
    useEffect(() => {
        handleExpedite()
        return () => {
            setVtoSubmitStep(1)
        }
    }, [])

    const styles = useStyles({ step: vtoSubmitStep })

    const goToNextStep = () => {
        setVtoSubmitStep((prev: number) => prev + 1)
        setLoading(false)
    }

    const handleExpedite = () => {
        // setExpedite(
        //     handleExpeditePage(businessType, industry, isExpeditedSelected)
        // )
        setExpedite(expeditePage.OPTION) // For testing only
    }

    const checkEarlyRenewal = () => {
        if (vtoSubmitStep === 1) {
            // That means section === "prepare_submit_tax" && currentStep.name === "submit_vto"
            if (hasVTOPermission()) {
                return false
            } else {
                return true
            }
        }
        return false
    }

    const checkIfRenewalAvailable = (value: any) => {
        setRenewalCheck(true)
        setLoading(true)
        isRenewalAvailable(selectedYear, isBusinessVTO)
            .then((data) => {
                if (!data.is_renewal_available) {
                    submitValues(value)
                } else {
                    setRenewaldata({
                        isModalOpen: true,
                        renewalDate: data.renew_date,
                        formValues: value,
                    })
                    setRenewalCheck(false)
                }
            })
            .catch(function (error) {
                setRenewalCheck(false)
                setLoading(false)
            })
    }

    const closeRenewalModal = () => {
        setRenewaldata({
            isModalOpen: false,
            renewalDate: '',
            formValues: null,
        })
        setLoading(false)
    }

    const submitValues = (values: any) => {
        if (values) {
            setLoading(true)
            const updatedVTOdata = {
                ...values,
                submitDetails: {
                    ...values.submitDetails,
                    // marking consent page completed

                    consent_disclosed_date: getDateInYmd(
                        values.submitDetails.consent_disclosed_date
                    ),
                },
                prepare_submit_tax_completed: true,
            }
            updateVTO(currentVtoDetails.id, updatedVTOdata, goToNextStep)
        }
    }

    const handleNext = (values: any) => {
        if (checkEarlyRenewal()) {
            checkIfRenewalAvailable(values)
        } else {
            submitValues(values)
        }
    }

    const handlePrevious = () => {
        if (vtoSubmitStep === 1) {
            hideContentPage()
        } else {
            setVtoSubmitStep((step: number) => step - 1)
        }
    }

    const handleSubmit = () => {
        if (productNeedToBePurchased().length) {
            setOpenChargeoverModal(true)
        } else {
            vtoFinalSubmit()
        }
        // To something to add expedite product
    }

    const markRecommendationCompleted = () => {
        setLoading(true)
        updateVTO(
            currentVtoDetails.id,
            {
                ...values,
                submitDetails: {
                    ...values.submitDetails,
                },
                recommendations_completed: true,
            },
            goToNextStep
        )
    }

    const handlePackageSelection = (selectedItem: any) => {
        setSelectedPackage(selectedItem)
        handleExpedite()
        markRecommendationCompleted()
    }

    const addThisProduct = (productId: string) => {
        return product.products.filter(
            (prod: any) => prod.product_id === productId
        )[0]
    }

    const handleNoThanks = () => {
        let needToBuy = {}
        setSelectedPackage(null)
        if (isBusinessVTO && excludedProduct !== excluded.BTP) {
            if (!hasVTOPermission()) {
                needToBuy = addThisProduct(currentYearVTOProducts['business'])
            }
        } else if (excludedProduct !== excluded.PTP && !hasVTOPermission()) {
            needToBuy = addThisProduct(currentYearVTOProducts['personal'])
        }
        setNoPackageSelected(needToBuy)
        markRecommendationCompleted()
    }

    const productNeedToBePurchased = (): string[] => {
        let ids: string[] = []
        if (selectedPackage) {
            ids = selectedPackage.products?.map((item: any) => item.product_id)
        } else if (noPackageSelected?.product_id) {
            ids = [noPackageSelected.product_id]
        }
        return ids
    }

    const handleClose = (message?: string) => {
        setOpenChargeoverModal(false)
        setSubmitWithExpedita(null)

        if (message === 'success') {
            canShowRetirementCallScreen ? setShowRetirementCallScreen(true)  : setShowSuccessScreen(true)
            vtoFinalSubmit()
        }
    }

    const vtoFinalSubmit = () => {
        const updatedVTOdata = {
            ...currentVtoDetails,
            status: 1,
        }
        updateVTO(currentVtoDetails.id, updatedVTOdata, () =>
            canShowRetirementCallScreen ? setShowRetirementCallScreen(true)  : setShowSuccessScreen(true)
        )
    }

    const handlePageTitle = () => {
        if (vtoSubmitStep === 1) {
            return SUBMIT_VTO.PREPARE_TO_SUBMIT
        } else if (vtoSubmitStep === 2) {
            return SUBMIT_VTO.RECOMMENDED_PACKAGES
        } else if (vtoSubmitStep === 4) {
            return SUBMIT_VTO.SUBMIT_SUCCESS
        }
        return isAutoPaymentProcessing
            ? SUBMIT_VTO.PAYMENT_PROCESSING
            : SUBMIT_VTO.SUBMIT_TAX_INFO
    }

    return (
        <div className={styles.fullWidthContainer}>
            {!showSuccessScreen && !showRetirementCallScreen && (
                <>
                    <div className={styles.btnAndProgressContainer}>
                        <Hidden smDown>
                            <UiButton
                                btnType="hyperlink"
                                label={
                                    vtoSubmitStep === 1
                                        ? 'Edit Tax Info'
                                        : 'Previous'
                                }
                                startIconSvg={leftArrow}
                                handleClick={handlePrevious}
                            />
                        </Hidden>
                        <div className={styles.progressContainer}>
                            <LinearProgressBar percentage={percentage} />
                        </div>
                        <div className={styles.emptyDiv}></div>
                    </div>
                    <div className={styles.contentContainer}>
                        <div>
                            {!loading && (
                                <UiText
                                    className={styles.heading}
                                    variant="truck_175"
                                    weight="bold_700"
                                >
                                    {handlePageTitle()}
                                </UiText>
                            )}

                            {vtoSubmitStep < 3 && (
                                <div className={styles.alertContainer}></div>
                            )}

                            <div className={classes.centeredContainer}>
                                <div className={styles.formContainer}>
                                    {vtoSubmitStep === 1 && (
                                        <Disclosures handleNext={handleNext} />
                                    )}
                                    {vtoSubmitStep === 2 && (
                                        <UpsellPackages
                                            packages={suggestedPackages}
                                            handlePackageSelection={
                                                handlePackageSelection
                                            }
                                            handleNoThanks={handleNoThanks}
                                            noPackageSelected={
                                                noPackageSelected
                                            }
                                        />
                                    )}
                                    {vtoSubmitStep === 3 && (
                                        <SubmitTaxInfo
                                            selectedPackage={selectedPackage}
                                            handleSubmit={handleSubmit}
                                            noPackageSelected={
                                                noPackageSelected
                                            }
                                            expedite={expedite}
                                            setSubmitWithExpedita={
                                                setSubmitWithExpedita
                                            }
                                            currentVtoDetails={
                                                currentVtoDetails
                                            }
                                            loading={loading}
                                            selectedYear={selectedYear}
                                            isBusinessVTO={isBusinessVTO}
                                            isAutoPaymentProcessing={
                                                isAutoPaymentProcessing
                                            }
                                            setNoPackageSelected={
                                                setNoPackageSelected
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <ChargeoverIframWithPaymentProcessing
                open={openChargeoverModal}
                productIds={
                    submitWithExpedite
                        ? [...productNeedToBePurchased(), submitWithExpedite]
                        : productNeedToBePurchased()
                }
                handleClose={handleClose}
                utmContent={
                    isBusinessVTO
                        ? 'Business+VTO+Additional+Services'
                        : 'Personal+VTO+Additional+Services'
                }
                otherParams={{
                    apply_discount: isDiscountApplied.haveDiscount,
                    apply_free_product: !!isSelectedFreeTaxExtension,
                    vto_type: isBusinessVTO ? 1 : 0,
                    vto_year: selectedYear,
                    paid_annually: true,
                }}
                autoPaymentProcessing={setIsAutoPaymentProcessing}
            />
            <EarlyRenewalModal
                closeRenewalModal={closeRenewalModal}
                renewaldata={renewaldata}
                submitValues={submitValues}
                setExcludedProdut={setExcludedProdut}
            />
            {showSuccessScreen && <Success selectedPackage={selectedPackage} />}
            {showRetirementCallScreen && (
                <ScheduleRetirementPlanning
                    withSuccessMessage
                    handleGoToNextSection={() => {
                        setShowSuccessScreen(true)
                        setShowRetirementCallScreen(false)
                    }}
                />
            )}
        </div>
    )
}

export default WithPackages(VTOSubmit)
