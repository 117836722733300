import { MULTI_STEP, VTO_STEPS } from '../models/vto.const'
import { UPLOAD_TYPES } from '../models/VtoDetails.model'
import {
    getCountByFileType,
    getFileCountByFileType,
    getFileUploadCount,
    getRequiredFiles,
} from '../steps/UploadStep/UploadFileUtils'
import { isStepDone } from './StepsUtil'
import { isFilledAnswer, isSectionCompleted, remainingQuestionCount } from './VtoQuestionUtils'
import { getValueByModelName } from './VtoUtils'

export const isUploadStepDone = (
    vtoDetails: any,
    formType: any,
    vtoMetaData: any
) => {
    const hasNoTypeCount = vtoDetails.uploads['no_' + formType + '_files']
    const countByFileType = getCountByFileType(vtoDetails, vtoMetaData)
    const fileTypeCount = countByFileType[formType]

    const hasFileUploadCount = getFileUploadCount(vtoDetails, vtoMetaData)

    if (formType) {
        return (
            !!hasNoTypeCount ||
            parseInt(fileTypeCount, 10) < 1 ||
            getFileCountByFileType(formType, vtoDetails, vtoMetaData, true)
        )
    }
    if (hasFileUploadCount === 0 && !vtoDetails.uploads.has_other_files) {
        return true
    }
    if (hasFileUploadCount === 0 && vtoDetails.uploads.has_other_files) {
        return vtoDetails?.uploads?.addition_files?.length > 0
    }
}

export const isUploadSectionFilled = (
    remainingFileCount: any,
    vtoDetails: any
) => {
    return (
        isFilledAnswer(vtoDetails.uploads.upload_type) &&
        //out upload
        ((vtoDetails.uploads.upload_type === UPLOAD_TYPES.OUTSIDE &&
            vtoDetails.uploads.has_upload_promise) ||
            //single upload
            (vtoDetails.uploads.upload_type === UPLOAD_TYPES.ALLINONE &&
                typeof vtoDetails.uploads.all_in_one !== 'undefined' &&
                vtoDetails.uploads.all_in_one.length > 0) ||
            //full upload
            (vtoDetails.uploads.upload_type === UPLOAD_TYPES.INDIVIDUALLY &&
                remainingFileCount === 0))
    )
}

/**
 * SUBMIT STEP
 */
export const isSubmitSectionCompleted = (vtoDetails: any) => {
    return (
        isFilledAnswer(vtoDetails.submitDetails.submit_confirm) &&
        vtoDetails.submitDetails.submit_confirm &&
        isFilledAnswer(vtoDetails.submitDetails.contact_phone) &&
        isFilledAnswer(vtoDetails.submitDetails.contact_time) &&
        isFilledAnswer(vtoDetails.submitDetails.consent_disclosed_by) && 
        vtoDetails?.prepare_submit_tax_completed
    )
}

export const isPackageSectionCompleted = (vtoDetails: any) => {
    return vtoDetails.recommendations_completed === true 
}

export const isTaxSubmitted = (vtoDetails: any) => {
    return vtoDetails?.status === 1
}

const isSubmitCheckCompleted: any = {
    [VTO_STEPS.PREPARE_TO_SUBMIT]: isSubmitSectionCompleted,
    [VTO_STEPS.RECOMMENDED_PACKAGES]: isPackageSectionCompleted,
    [VTO_STEPS.SUBMIT_TAX]: isTaxSubmitted
};

const VTO_SUBSECTIONS: any = [
    VTO_STEPS.PREPARE_TO_SUBMIT, 
    VTO_STEPS.RECOMMENDED_PACKAGES, 
    VTO_STEPS.SUBMIT_TAX]


/**
 * Returns uploaded file count by name
 */
export const getUploadFileCountByName = (fileName: any, vtoData: any) => {
    return vtoData?.uploads?.[fileName].length
}

export const isAllStepDoneExceptSubmit = (
    steps: any,
    remainingCount: any,
    vtoDetails: any,
    metaData: any
) => {
    let isAllStepDone = true
    steps?.forEach((step: any) => {
        const isDone =
            step.name === VTO_STEPS.UPLOAD_CENTER
                ? isUploadSectionFilled(remainingCount, vtoDetails)
                : isStepDone(step, metaData, vtoDetails)
        if (!isDone) {
            isAllStepDone = false
        }
    })
    return isAllStepDone
}

/**
 * @return {object} with sectionCompletion and questionCount
 * - used in StepMenu component
 */
export const getSectionInfo = (section: any, vtoMeta: any, vtoDetails: any) => {
    if ([VTO_STEPS.INTRODUCTION_STEP, VTO_STEPS.FORM_SCAN_STEP].includes(section)) {
        return {
            count: 0,
            isDone: true,
        } 
    }
    // UPLOADS_CENTER
    if(section === VTO_STEPS.UPLOAD_CENTER) {
        const { requiredFilesCount } = getRequiredFiles(vtoDetails, vtoMeta)
        
        return {
            count: requiredFilesCount,
            isDone: (requiredFilesCount === 0),
        }
    } else {
        const sectionDetail = vtoMeta.formattedSections[section]
        const count = remainingQuestionCount(sectionDetail, vtoDetails)
        return {
            count: count,
            isDone: count === 0 
        }
    }
}

export const isSectionsCompleted = (section: any, vtoDetails: any, metaData: any) => {
    let isStepDone = false
    if([VTO_STEPS.INTRODUCTION_STEP, VTO_STEPS.FORM_SCAN_STEP].includes(section)) {
        isStepDone = true
    } else {
        if (section === VTO_STEPS.SUBMIT_VTO) {
            if(isSubmitSectionCompleted(vtoDetails)) {
                isStepDone = true
            }
        } else {
            if(section === VTO_STEPS.UPLOAD_CENTER) {
                const { requiredFilesCount } = getRequiredFiles(vtoDetails, metaData)
                isStepDone = isUploadSectionFilled(requiredFilesCount, vtoDetails)
            } else {
                const sectionData = metaData.formattedSections[section]
                isStepDone = isSectionCompleted(sectionData, vtoDetails)
            }
        }
    }
    return isStepDone
}

/**
 * MenuItems are referred as Steps in VTO
 * Here we are passing only the MenuItems to calculate the completion percentage
 * we need to add 3 more steps to the total steps as SUBMIT_VTO steps are not included in the MenuItems
 * @param {object} steps - MenuItems
 * @param {object} metaData - VTO MetaData
 * @param {object} vtoDetails - VTO Details
 */
export const vtoCompletionPercent = (steps: any, metaData: any, vtoDetails: any) => {
    let count = 0
    let totalSections = 0
    let isAllStepExceptSubmit = true

    steps.forEach((step: any) => {
        if (step.type === MULTI_STEP) {
            let subStepsData = getValueByModelName(step.model, vtoDetails);
            // stepData refers to vtoDetails => {solePropertorship: data}
            subStepsData.forEach((stepData: any) => {
                step.sections.forEach((sectionName: any) => {
                    totalSections = totalSections + 1
                    let section = metaData.formattedSections?.[sectionName]
                    if (isSectionCompleted(section, stepData)) {
                        count = count + 1
                    } else {
                        isAllStepExceptSubmit = false
                    }
                })
            })
        } else {
            step.sections.forEach((sectionName: any) => {
                totalSections = totalSections + 1
                // submit step recommended package step
                if (VTO_SUBSECTIONS.includes(sectionName)) {
                    if(isSubmitCheckCompleted?.[sectionName]?.(vtoDetails)) {
                        count++;
                    } else {
                        return
                    }
                } else {
                    if ([VTO_STEPS.INTRODUCTION_STEP, VTO_STEPS.FORM_SCAN_STEP].includes(sectionName)) {
                        if(vtoDetails.sections_reviewed?.includes(sectionName)) {
                            count++;
                        } else {
                            isAllStepExceptSubmit = false;
                        }
                    } else {
                        if(sectionName) {
                            let section = metaData.formattedSections?.[sectionName]
                            if(sectionName === VTO_STEPS.UPLOAD_CENTER) {
                                const { requiredFilesCount } = getRequiredFiles(vtoDetails, metaData)
                                if(isUploadSectionFilled(requiredFilesCount, vtoDetails)) {
                                    count++;
                                }  else {
                                    isAllStepExceptSubmit = false;
                                }
                            } else {
                                if (isSectionCompleted(section, vtoDetails)) {
                                    count++;
                                } else {
                                    isAllStepExceptSubmit = false;
                                }
                            }
                        }
                    }
                }
            })
        }  
    })
    const percentage = ((count / totalSections) * 100).toFixed(0)
    return { percentage: percentage, isAllStepDone: isAllStepExceptSubmit }
}


export const hasReviewedAllSections = (menuItems: any, vtoDetails: any) => {
    return menuItems
        .filter((item: any) => item.name !== VTO_STEPS.UPLOAD_CENTER)
        .every((item: any) => {
            return item.sections.every((step: string) =>
                vtoDetails.sections_reviewed.includes(step)
            )
        })
}