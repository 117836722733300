import { Button, Divider, Drawer, Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { ThemeColors } from '../../../../../styles/models/Colors.interface'
import { toRem16 } from '../../../../../styles/commonStyles'
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import Icon from '../../../../common/Icon'
import { ALLOWED_NON_CSV_FILE_TYPES, ALLOWED_FILE_TYPES, isValidFile, NOT_ACCEPTING_CSVS_FILE_TYPES } from './UploadFileUtils'
import { InfoOutlined } from '@material-ui/icons'
import { showAlert, showInfo } from '../../../../../store/actions/feedback'
import { useDispatch } from 'react-redux'
import { useRef, useState } from 'react'
import UiText from '../../../../common/ui/UiText'
import ImportDocuments from './ImportDocuments'
import FileActions from './ImportDocument/FileActions'
import { DocumentType } from '../../../vto/models/vto.document-model'
import { useVtoContext } from '../../providers/VtoProvider'
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded'
import {
    removeUploadedFile,
    uploadFileToVto,
} from '../../../../../services/vto/vtoFileUpload'
import { requestErrorHandler } from '../../../../../services/formService'
import { UploadedFile } from '../../../../../models'
import { format, fromUnixTime } from 'date-fns'
import { COLORS } from '../../../../../variables/colors'
import UiButton from '../../../../common/ui/UiButton'
import { truncateText } from '../../common/VtoUtils'

export const getIconType = (mime: string) => {
    switch (mime) {
        case 'image/jpeg':
            return 'Image-Attachment'
        case 'image/png':
            return 'Image-Attachment'
        case 'application/pdf':
            return 'PDF'
        default:
            return 'Generic'
    }
}

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        topMargin: {
            marginTop: '1rem',
        },
        labeledTop: {
            marginTop: '0.5rem',
        },
        mailingInfo: {
            marginTop: toRem16(40),
        },
        alertMessage: {
            marginTop: '1rem',
            marginBottom: '1rem',
            backgroundColor: (colorTheme) => colorTheme.red300 + ' !important',
            color: (colorTheme) => colorTheme.primaryBlack + ' !important',
        },
        dropzone: {
            padding: '0.8rem',
            marginTop: '1rem',
            border: (colorTheme) => '2px dashed ' + colorTheme.grey200,
            [theme.breakpoints.down('xs')]: {
                padding: '1rem',
            },
        },
        uploadContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        textContent: {
            margin: '0 1rem 0 1rem',
        },
        fileList: {
            '&:first-child':{
                flex: 2,
                justifyContent: 'flex-start'
            },
            flex:1,
            display: 'flex',
            marginTop: '1rem',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: toRem16(15),
            '& span': {
                padding: '0 0 0 0.5rem',
            },
        },
        renderedFiles: {
            display: 'flex',
            gap: '0.5rem',
            justifyContent: 'space-between',
        },
        dividerStyles: {
            marginTop: '1rem',
            marginBottom: '0.5rem',
        },
        noDocumentText: {
            marginTop: '1rem',
            marginBottom: '1rem',
        },
        fileUploadContainer: {
            marginLeft: '1.45rem',
            [theme.breakpoints.down('xs')]: {
                marginLeft: '0',
            },
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            width: '100%',
            marginTop: '1rem',
            '& > button': {
                marginTop: '0.5rem',
            }
        },
        addDocument: {
            width: '100%',
            marginBottom: '1rem',
            '& .MuiButtonBase-root': {
                width: '100%',
            }
        },
        uploadBtn: {
            background: (colorTheme) => colorTheme.primaryWhite,
            color: (colorTheme) => colorTheme.black100,
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            padding: '0.053rem 1rem',
            borderRadius: '0.25rem',
            '&:hover': {
                background: (colorTheme) => colorTheme.primaryWhite,
            },
        },
        uploadBtnMobile: {
            background: (colorTheme) => colorTheme.tertiary,
            color: (colorTheme) => colorTheme.primaryWhite,
            '&:hover': {
                background: (colorTheme) => colorTheme.tertiary,
                color: (colorTheme) => colorTheme.primaryWhite,
            },
        },
        drawerPaper: {
            width: '100%',
            height: '18rem',
            borderTopLeftRadius: '1rem',
            borderTopRightRadius: '1rem',
        },
        drawerHeader: {
            padding: '0 1rem 0.75rem 1rem',
        },
        drawerContent: {
            padding: '1rem',
            maxHeight: `calc(${toRem16(400)} - ${toRem16(74)})`,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            '& > div': {
                width: '100%',
                '& button': {
                    width: '100%',
                }
            },
            '& > div:first-of-type': {
                marginBottom: '0.5rem',
            },
        },
        iconContainer: {
            display: 'flex',
            justifyContent: 'center',
            maxHeight: toRem16(24),
        },
        importContent: {
            '& > button p': {
                fontWeight: 600,
                fontSize: '1rem !important',
            }
        },
        cancelBtn: {
            marginTop: '1.5rem',
            '& .MuiButton-label .MuiTypography-button': {
                color: (colorTheme) => colorTheme.blue200,
                fontSize: '1rem',
                fontWeight: 600,
            }
        }
    }
})

const FileUpload = ({
    fileType, 
    isUploadCenter = true 
}: { fileType: DocumentType, isUploadCenter?: boolean }) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const dispatch = useDispatch()
    const dropzoneRef = useRef<DropzoneRef>(null)
    const { currentVtoDetails, selectedYear } = useVtoContext()
    const fileUploads = currentVtoDetails?.uploads?.[fileType] || []
    const [fileList, setFileList] = useState<any>([...fileUploads])
    const [fileToDelete, setFileToDelete] = useState<UploadedFile>()
    const [anchorEl, setAnchorEl] = useState<boolean>(false)
    const smDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

    const allowCSVtype = fileType ? !NOT_ACCEPTING_CSVS_FILE_TYPES?.includes(fileType) : true
    const acceptedFormat = allowCSVtype ? Object.keys(ALLOWED_FILE_TYPES) : Object.keys(ALLOWED_NON_CSV_FILE_TYPES) 
    const allowedUploads = allowCSVtype ? Object.values(ALLOWED_FILE_TYPES) : Object.values(ALLOWED_NON_CSV_FILE_TYPES) 

    const isFileTypeUnsupported = (files: File[]): boolean =>
        files.every((file) =>
            isValidFile(file, allowCSVtype)
        )

    const handleOpen = () => {
        setAnchorEl(true)
    }

    const handleClose = () => {
        setAnchorEl(false)
    }    

    const uploadFiles = (files: any) => {
        const vtoId = currentVtoDetails.id
        Promise.all(
            files.map((file: any) => {
                const formData = new FormData()
                formData.append('vtoId', vtoId)
                formData.append('type', fileType)
                formData.append('files[]', file)
                return uploadFileToVto(selectedYear, vtoId, formData)
            })
        ).then((res) => {
            const fileData = res.map((fileRes) => fileRes?.[0])
            let updatedFiles = [...fileList]
            if (fileToDelete) {
                updatedFiles = updatedFiles.filter(
                    (file: any) => file.id !== fileToDelete?.id
                )
            }

            setFileList([...updatedFiles, ...fileData])
            deleteCurrentFile()
        }).catch((error) => {
            if(error) {
                handleFileUnsupported(error?.message)
            }
        })
    }

    const replaceConfirmation = () => {
        dispatch(
            showAlert({
                alertText: 'The file has been replaced successfully',
                alertType: 'success',
            })
        )
    }

    /**
     * On Replace of file upload new & delete the current file
     */
    const deleteCurrentFile = () => {
        if (fileToDelete) {
            const vtoId = currentVtoDetails.id
            const fileId = fileToDelete?.id
            requestErrorHandler(
                removeUploadedFile(selectedYear, vtoId, {
                    file: fileId,
                    type: fileType,
                }).then((res: any) => {
                    replaceConfirmation()
                    setFileToDelete(undefined)
                }),
                true,
                () => {
                    setFileToDelete(undefined)
                }
            )
        }
    }

    /**
     * Remove files from local upload list
     */
    const removeFile = (fileId: string) => {
        const updatedFiles = fileList.filter((file: any) => file.id !== fileId)
        setFileList(updatedFiles)

    }

    const fileSize = (size: number) => {
        const sizeInMb =  size / (1024 * 1024)
        return sizeInMb.toFixed(2)
    }

    const uploadTime = (uploadTime: number) => {
        let time = fromUnixTime(uploadTime);
        return format(time, 'dd MMM yyyy');
    }

    const RenderUploadedFiles = () => {
        if (fileList.length === 0)
            return (
                <UiText
                    variant="motorcycle_90"
                    className={styles.noDocumentText}
                    textColor="textSecondary"
                >
                    No documents have been added yet
                </UiText>
            )
        return fileList.map((file: any, index: number) => {
            return (
                <div
                    className={styles.renderedFiles}
                    key={`${file?.id || file?.name}-${index}`}
                >
                    <div className={styles.fileList}>
                        <img
                            src={
                                require(`../../../../../assets/icons-svg/${getIconType(
                                    file.mime_type
                                )}.svg`).default
                            }
                            alt="document"
                        />
                        <span>{truncateText(file?.name || file?.filename, 30)}</span>
                    </div>
                    {!smDevice && (
                        <>
                            <div className={styles.fileList}>
                                {uploadTime(file.upload_date)}
                            </div>
                            <div className={styles.fileList}>
                                {fileSize(file.size)} MB
                            </div>
                        </>
                    )}
                    <FileActions
                        selectedFile={file}
                        fileType={fileType}
                        removeFile={() => {
                            removeFile(file.id)
                        }}
                        openDialog={() => {
                            setFileToDelete(file)
                            dropzoneRef?.current?.open()
                        }}
                    />
                </div>
            )
        })
    }

    const handleUploadFile = (files: File[]) => {
        if (!files.length) {
            return
        }

        if (!isFileTypeUnsupported(files)) {
            return
        }
        uploadFiles(files)
    }

    const handleFileUnsupported = (message: any) => {
        dispatch(
            showInfo({
                infoData: (
                    <div>
                        <InfoOutlined fontSize="large" />
                        <div>File(s) has unsupported format.</div>
                        <div>
                            Please upload only{' '}
                            <b>{message ? message : acceptedFormat.join(', ')}</b>
                        </div>
                    </div>
                ),
            })
        )
    }

    return (
        <>
            <div className={isUploadCenter ? styles.fileUploadContainer : ''}>
                <Dropzone onDrop={handleUploadFile} ref={dropzoneRef}>
                    {({ getRootProps, getInputProps }) => {
                        return (
                            <div
                                {...getRootProps({})}
                                className={styles.dropzone}
                                style={{
                                    display: smDevice ? 'none' : 'block',
                                }}
                            >
                                <div>
                                    <input
                                        {...getInputProps()}
                                        name="files[]"
                                        accept={allowedUploads.toString()}
                                    />
                                </div>
                                <div className={styles.uploadContainer}>
                                    <Button
                                        disabled={false}
                                        variant="contained"
                                        color="primary"
                                        startIcon={
                                            <Icon
                                                icon="upload"
                                                svgColor={
                                                    colorTheme.primaryWhite
                                                }
                                            />
                                        }
                                    >
                                        <UiText
                                            variant="motorcycle_90"
                                            weight="semi_bold_600"
                                        >
                                            Upload File
                                        </UiText>
                                    </Button>
                                    <UiText
                                        className={styles.textContent}
                                        variant="motorcycle_90"
                                        textColor="textSecondary"
                                    >
                                        Or
                                    </UiText>
                                    <div>
                                        <UiText>
                                            Drag & drop file here to upload
                                        </UiText>
                                        <UiText
                                            variant="motorcycle_90"
                                            textColor="textSecondary"
                                        >
                                            Supported file types: {acceptedFormat.join(', ')}
                                        </UiText>
                                    </div>
                                </div>
                            </div>
                        )
                    }}
                </Dropzone>
                {smDevice && (
                    <div className={styles.buttonContainer}>
                        <div className={styles.addDocument}>
                            <Button
                                className={styles.uploadBtn}
                                disabled={false}
                                //@ts-ignore
                                onClick={handleOpen}
                                startIcon={<Icon icon="add" />}
                            >
                                <UiText weight="semi_bold_600">
                                    Add Document
                                </UiText>
                            </Button>
                        </div>
                    </div>
                )}
                {!smDevice && (
                    <ImportDocuments
                        setFileList={setFileList}
                        fileType={fileType}
                    />
                )}
                <RenderUploadedFiles />
            </div>
            {isUploadCenter && (
                <Divider variant="fullWidth" className={styles.dividerStyles} />
            )}
            {smDevice && (
                    <Drawer
                        id="filter-drawer"
                        anchor="bottom"
                        open={anchorEl}
                        onClose={handleClose}
                        classes={{ paper: styles.drawerPaper }}
                    >
                        <div className={styles.drawerHeader}>
                            <div className={styles.iconContainer}>
                                <RemoveRoundedIcon
                                    fontSize="large"
                                    classes={{
                                        root: styles.iconRoot,
                                    }}
                                />
                            </div>
                            <UiText
                                variant="hatchback_125"
                                weight="semi_bold_600"
                            >
                                Add Documents
                            </UiText>
                        </div>
                        <div className={styles.drawerContent}>
                            <div>
                                <Button
                                    className={styles.uploadBtnMobile}
                                    disabled={false}
                                    //@ts-ignore
                                    onClick={() => dropzoneRef.current.open()}
                                    startIcon={
                                        <Icon
                                            icon="upload"
                                            svgColor={COLORS.primaryWhite}
                                        />
                                    }
                                >
                                    <UiText
                                        weight="semi_bold_600"
                                    >
                                        Upload
                                    </UiText>
                                </Button>
                            </div>
                            <div>
                                <UiText
                                    variant="motorcycle_90"
                                    textColor="textSecondary"
                                >
                                    Supported file types: .pdf, .png, .jpeg, .jpg
                                </UiText>
                            </div>
                            <div className={styles.importContent}>
                                <ImportDocuments
                                    setFileList={setFileList}
                                    fileType={fileType}
                                />
                                <UiButton
                                    customClass={styles.cancelBtn}
                                    btnType="hyperlink"
                                    label={'Cancel'}
                                    handleClick={handleClose}
                                />
                            </div>
                        </div>
                    </Drawer>
                )}
        </>
    )
}

export default FileUpload
