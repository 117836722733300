import { memo } from 'react'
import { useFormikContext } from 'formik'
import { Button, Hidden, makeStyles, Theme } from '@material-ui/core'
import UiButton from '../../../../../common/ui/UiButton'
import leftArrow from '../../../../../../assets/icons-svg/LeftArrow.svg'
import { ThemeColors } from '../../../../../../styles/models/Colors.interface'
import rightArrow from '../../../../../../assets/icons-svg/right-arrow-white.svg'
import { useThemeContext } from '../../../../../common/whiteLabel/ColorThemeContext'
import { useVtoContext } from '../../../providers/VtoProvider'
import Loader from '../../../../../common/Loader'
import SectionSummary from './SectionSummary'
import UiText from '../../../../../common/ui/UiText'
import { commonVtoStyles } from '../../styles/commonVtoStyles'
import { toRem16 } from '../../../../../../styles/commonStyles'
import { isVTOFormDirty } from '../../../common/VtoUtils'
import { VTO_STEPS } from '../../../models/vto.const'

const useStyles = makeStyles<Theme, ThemeColors>((theme) => {
    return {
        questionSectionContainer: {
            width: '100%',
            maxWidth: '40rem',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                minHeight: 'calc(100vh - 5.5rem)',
            },
        },
        questionContainer: {
            width: '100%',
            maxWidth: '30rem',
        },
        navBtn: {
            width: 'fit-content',
        },
        nxtBtn: {
            width: 'fit-content',
            '& span': {
                fontWeight: 600,
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        nextPrevContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 'auto',
            alignItems: 'center',
            '& > button:first-child': {
                marginBottom: '0.5rem',
            },
        },
        loaderContainer: {
            position: 'fixed',
            top: '50%',
            left: '72%',
            transform: `translate(-50%, -72%)`
        },
        fixedButtonContainer: {
            width: 'fit-content',
            marginLeft: '1rem',
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                width: '100%',
                boxSizing: 'border-box',
                bottom: 0,
                left: 0,
                right: 0,
                padding: '1rem',
                zIndex: 100,
                backgroundColor: (colorTheme) => colorTheme.primaryWhite,
                borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                marginLeft: 0,
            },
        },
        skipBtn: {
            color: (colorTheme) => colorTheme.blue200,
        },
        fixedBtn: {
            [theme.breakpoints.up('sm')]: {
                position: 'fixed',
                bottom: 0,
                left: toRem16(30),
                width: '100%',
                background: (colorTheme) => colorTheme.primaryWhite,
                paddingTop: '1.5rem',
                zIndex: 10,
                borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                alignItems: 'flex-start',
            },
        },
        placeholder: {
            height: toRem16(120),
            [theme.breakpoints.down('sm')]: {
                height: toRem16(161),
            },
        },
    }
})

export const NavigationButton = ({ label, startIconSvg, handleClick, className }: any) => (
    <Hidden smDown>
        <div className={className}>
            <UiButton
                btnType="hyperlink"
                label={label}
                startIconSvg={startIconSvg}
                customClass={useStyles(useThemeContext().colorTheme).navBtn}
                handleClick={handleClick}
            />
        </div>
    </Hidden>
)

const SectionQuestionsRenderer = memo((props: any) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const classes = commonVtoStyles(colorTheme)
    const { setErrors, values, initialValues }: any = useFormikContext()
    const { questionLoading, goToPreviousStep, handlePrev, submitValues, setShowUnsavedChangesModal, currentStep } = useVtoContext()
    
    /**
     * Setting errors to empty object and submitting the values directly here.
     * if there is error still the data will be submitted to VTO
     */
    const handleNextQuestion = () => {
        let formValues: any = values || {};
        setErrors({});
        if (currentStep === VTO_STEPS.BUSINESS_RENTAL_ACTIVITY) {
            const businessRental = formValues?.business?.rental_income;
            if (businessRental) {
                businessRental.forEach((item: any) => {
                    if (item.expenses) {
                        item.expenses.list = { ...item.expenses.list };
                    }
                });
                formValues = {
                    ...formValues,
                    business: {
                        ...formValues.business,
                        rental_income: businessRental,
                    },
                };
            }
        }
        let defaultQuestion: any = {};
        /**
         * Added this default_question attr to add questions with default value
         * if not provided during initial load, it will be set to true with questionFieldName
         */
        if (Object.keys(formValues?.default_questions).length) {
            // need to make this default question [] to object. {[questionFieldName]: true}
            Object.keys(formValues?.default_questions).forEach((key) => {
                defaultQuestion = {
                    ...defaultQuestion,
                    [key]: true,
                };
            });
            formValues.default_questions = { ...defaultQuestion };
        }
        if (formValues?.file_increment_questions && Object.keys(formValues?.file_increment_questions)?.length) {
            let incrementQuestions: any = {}
            // need to make this default question [] to object. {[questionFieldName]: true}
            Object.keys(formValues?.file_increment_questions).forEach((key) => {
                incrementQuestions = {
                    ...incrementQuestions,
                    [key]: formValues?.file_increment_questions[key],
                };
            });
            formValues.file_increment_questions = { ...incrementQuestions };
        }
    
        submitValues({
            ...formValues,
            sections_reviewed: [
                ...new Set([...formValues.sections_reviewed, currentStep]),
            ],
        });
    };
    
    // Go to previous step if there are no unsaved changes
    const handleGoToPreviousStep = () => {
        const dirty = isVTOFormDirty(initialValues, values);
        if(!dirty) return goToPreviousStep();
        setShowUnsavedChangesModal(true)
    }

    if(questionLoading) {
        return (
            <div className={styles.questionSectionContainer}>
            <div className={styles.loaderContainer}>
                <Loader />
            </div>
            </div>
        )
    }
    // Have to remove currentStep !== 'business_type' condition when we enable to into step
    return (
        <div className={styles.questionSectionContainer}>
            {currentStep !== 'business_type' && <NavigationButton
                className={classes.stickyTop}
                label="Previous"
                startIconSvg={leftArrow}
                handleClick={handleGoToPreviousStep}
            />}
            <SectionSummary section={props?.section} />
            <div className={styles.placeholder}></div>
            <div className={styles.fixedButtonContainer}>
                <div className={`${styles.nextPrevContainer} ${styles.fixedBtn}`}>
                    <UiButton
                        fullWidth
                        btnType="tertiary"
                        label={'Confirm & Continue'}
                        endIconSvg={rightArrow}
                        customClass={styles.nxtBtn}
                        handleClick={handleNextQuestion}
                    />
                    <UiText variant="moped_75">
                        (Scroll to the Bottom to confirm)
                    </UiText>
                    <UiText variant="moped_75">
                        *In order to file, you’ll need to answer all
                        questions
                    </UiText>
                </div>
                <Hidden mdUp>
                        <div className={styles.nextPrevContainer}>
                            <Button
                                className={styles.skipBtn}
                                variant="text"
                                onClick={() => handlePrev()}
                            >
                                <UiText weight="semi_bold_600">Previous</UiText>
                            </Button>
                        </div>
                </Hidden>
            </div>
        </div>
    )
})

export default SectionQuestionsRenderer
