export const API = {
    TOS : 'api/user/current/agreement',
    VTO : {
        CURRENT: 'api/account/:accountId/:vtoYear/vto',
        GET_VTO : 'api/account/:accountId/:vtoYear/vto/:id',
        METADATA : 'api/vto_metadata/:year',
        OCR: {
            START_SCAN: 'api/account/:accountId/:vtoYear/vto/:id/ocr/scan/:fileId/start',
            GET_PROGRESS: 'api/account/:accountId/:vtoYear/vto/:id/ocr/scan/status',
        },
        UPLOAD: {
            UPLOAD: 'api/account/:accountId/:vtoYear/vto/:id/upload',
            IMPORT: 'api/account/:accountId/:vtoYear/vto/:id/link_files',
            DELETE: 'api/account/:accountId/:vtoYear/vto/:id/unlink_file'
        },
        SALES_PRODUCT: `api/sales/product/:productId`
    },
}
    
export const USER = {
    TOS : 'api/user/current/agreement'
}
export const API_PATHS = {
    VEHICLES : {
        ALL : '/api/account/:id/mileage_tracker/vehicle?page=:page&perPage=:perPage',
        DELETE: '/api/account/:accountId/mileage_tracker/vehicle/:vehicleId',
        EDIT: '/api/account/:accountId/mileage_tracker/vehicle/:vehicleId',
        ADD: '/api/account/:accountId/mileage_tracker/vehicle'
    },
    USER: {
        TOS : 'api/user/current/agreement'
    }
}
