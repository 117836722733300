import {
    MULTI_STEP,
    NO_DEDUCTIONS_STEP,
    NO_INCOME_STEP,
    VTO_STEPS,
} from '../models/vto.const'
import { isSectionCompleted } from './VtoQuestionUtils'
import { getValueByModelName, hasConditionMatched } from './VtoUtils'

/**
 * Check if the step is visible based on the show_condition
 */
export const isStepVisible = (step: any, metaData: any, vtoDetails: any) => {
    let stepModelData = stepModel(step, vtoDetails)

    let stepSection = step.sections[0]
    if ([VTO_STEPS.INTRODUCTION_STEP, VTO_STEPS.FORM_SCAN_STEP].includes(stepSection)) {
        return true
    }

    let section = metaData?.formattedSections[stepSection]
    return !section
        ? false
        : section.show_condition
        ? hasConditionMatched(section.show_condition, stepModelData)
        : true
}
/**
 * Check if the section is visible based on the show_condition
 */
export const allowedSections = (step: any, vtoMetaData: any, getVtoDetails: any) => {
    let stepModelData = stepModel(step, getVtoDetails)

    let filteredSections = step.sections.filter((stepSection: any) => {
        if (
            stepSection === NO_INCOME_STEP ||
            stepSection === NO_DEDUCTIONS_STEP
        ) {
            return false
        }

        let section = vtoMetaData?.formattedSections[stepSection]
        return !section
            ? false
            : section.show_condition
            ? hasConditionMatched(section.show_condition, stepModelData)
            : true
    })
    return filteredSections
}
/**
 * multiple steps model
 */
export const stepModel = (step: any, vtoDetails: any) => {
    return step.type === MULTI_STEP
        ? getValueByModelName(step.model, vtoDetails)[step.index]
        : vtoDetails
}

export const isStepRequired = (step: any, vtoDetails: any, metaData: any) => {
    const sectionsData = metaData?.formattedSections?.[step.name] ?? []
    const showConditions = step?.show_condition ? step.show_condition : sectionsData?.show_condition
    return showConditions
        ? hasConditionMatched(showConditions, vtoDetails)
        : true
}

// TODO - @any type
export const allowedVtoSteps = (vtoSteps: any, vtoDetails: any, metaData: any) => {
    let allowedSteps: any = []
    vtoSteps.forEach((step: any) => {
        if(isStepRequired(step, vtoDetails, metaData)) {
            if (step.type === MULTI_STEP) {
                let stepList = getValueByModelName(step.model, vtoDetails)
                stepList.forEach((stepModel: any, index: number) => {
                    let stepI = { ...step }
                    stepI.index = index
                    stepI.name = step.name + '_$_' + index
                    stepI._name = step.name
                    stepI.title =
                        getValueByModelName(stepI.titleModel, stepModel) ||
                        step.title + ' ' + (index + 1)                    
                    allowedSteps.push(stepI)
                })
            } else {
                if([VTO_STEPS.INTRODUCTION_STEP, VTO_STEPS.FORM_SCAN_STEP].includes(step.name)) {
                    allowedSteps.push({
                        ...step,
                    })
                } else {
                    const hasSections = step.sections?.length >= 1
                    const filteredSections = hasSections ? allowedSections(step, metaData, vtoDetails) : step.sections;
                    allowedSteps.push({
                        ...step,
                        sections: filteredSections
                    })
                }   
            }
        }
    })
    return allowedSteps
}

export const isStepDone = (
    currentStep: any,
    vtoMetaData: any,
    vtoDetails: any
) => {
    let hasCompleted = true

    if ([VTO_STEPS.INTRODUCTION_STEP, VTO_STEPS.FORM_SCAN_STEP].includes(currentStep.name)) {
        return true
    }

    if (!isStepRequired(currentStep, vtoDetails, vtoMetaData)) {
        return true
    }
    
    // use case Personal VTO >  sole proprietorship or an LLC taxed as a sole proprietorship 
    // SolePropertorship > Step 1 ...etc
    if (currentStep.type === MULTI_STEP) {
        let subStepsData = getValueByModelName(currentStep.model, vtoDetails);
        // stepData refers to vtoDetails => {solePropertorship: data}
        subStepsData.forEach((stepData: any) => {
            currentStep.sections.forEach((sectionName: any) => {
                let section = vtoMetaData.formattedSections?.[sectionName]
                if (!isSectionCompleted(section, stepData)) {
                    hasCompleted = false
                }
            })
        })
    } else {
        currentStep.sections.forEach((sectionName: any) => {
            let section = vtoMetaData.formattedSections?.[sectionName]
            if (!isSectionCompleted(section, vtoDetails)) {
                hasCompleted = false
            }
        })
    }

    return hasCompleted
}
