import { ReactComponent as Activity } from '../../assets/icons-svg/Activity.svg'
import { ReactComponent as Add } from '../../assets/icons-svg/Add.svg'
import { ReactComponent as Alert } from '../../assets/icons-svg/AlertTriangle.svg'
import { ReactComponent as ArchiveWhite } from '../../assets/icons-svg/Archive-White.svg'
import { ReactComponent as Archived } from '../../assets/icons-svg/Archived.svg'
import { ReactComponent as Avatar } from '../../assets/icons-svg/Avatar.svg'
import { ReactComponent as BalanceSheetEmpty } from '../../assets/icons-svg/BalanceSheetEmpty.svg'
import { ReactComponent as Bank } from '../../assets/icons-svg/Bank.svg'
import { ReactComponent as Beta } from '../../assets/icons-svg/Beta.svg'
import { ReactComponent as Bookkeeping } from '../../assets/icons-svg/Bookkeeping.svg'
import { ReactComponent as BookkeepingInfo } from '../../assets/icons-svg/BookkeepingInfo.svg'
import { ReactComponent as BankingIcon } from '../../assets/icons-svg/Banking-icon.svg'
import { ReactComponent as CalendarIllustration } from '../../assets/icons-svg/Calendar-Illustration.svg'
import { ReactComponent as Calendar } from '../../assets/icons-svg/Calendar.svg'
import { ReactComponent as Call } from '../../assets/icons-svg/Call.svg'
import { ReactComponent as Check } from '../../assets/icons-svg/checkIcon.svg'
import { ReactComponent as CheckCircleSmall } from '../../assets/icons-svg/check-circle-small.svg'
import { ReactComponent as CheckCircle } from '../../assets/icons-svg/check-circle.svg'
import { ReactComponent as Clientbooks } from '../../assets/icons-svg/Clientbooks.svg'
import { ReactComponent as Clock } from '../../assets/icons-svg/clock.svg'
import { ReactComponent as Cloud } from '../../assets/icons-svg/Cloud.svg'
import { ReactComponent as Company } from '../../assets/icons-svg/Company.svg'
import { ReactComponent as CompanyCircle } from '../../assets/icons-svg/company-circle.svg'

import { ReactComponent as CreditCard } from '../../assets/icons-svg/Credit-Card.svg'
import { ReactComponent as Dashboard } from '../../assets/icons-svg/Dashboard.svg'
import { ReactComponent as Delete } from '../../assets/icons-svg/Delete.svg'
import { ReactComponent as DocumentIllustration } from '../../assets/icons-svg/Document-Illustration.svg'
import { ReactComponent as Document } from '../../assets/icons-svg/Document.svg'
import { ReactComponent as Documents } from '../../assets/icons-svg/Documents.svg'
import { ReactComponent as Dollar } from '../../assets/icons-svg/Payroll.svg'
import { ReactComponent as Download } from '../../assets/icons-svg/Download.svg'
import { ReactComponent as Email } from '../../assets/icons-svg/Email.svg'
import { ReactComponent as EmptyImage } from '../../assets/icons-svg/EmptyImage.svg'
import { ReactComponent as Fax } from '../../assets/icons-svg/Fax.svg'
import { ReactComponent as Edit } from '../../assets/icons-svg/Edit.svg'
import { ReactComponent as EmailSent } from '../../assets/icons-svg/EmailSent.svg'
import { ReactComponent as ExclamationMark } from '../../assets/icons-svg/ExclamationMark.svg'
import { ReactComponent as ExpensesEmpty } from '../../assets/icons-svg/ExpensesEmpty.svg'
import { ReactComponent as FileText } from '../../assets/icons-svg/File-Text.svg'
import { ReactComponent as FinancialTeam } from '../../assets/icons-svg/Financial Team.svg'
import { ReactComponent as Generic } from '../../assets/icons-svg/Generic.svg'
import { ReactComponent as GiftDisabled } from '../../assets/icons-svg/Gift-Disabled.svg'
import { ReactComponent as Gift } from '../../assets/icons-svg/Gift.svg'
import { ReactComponent as Graphic } from '../../assets/icons-svg/Graphic.svg'
import { ReactComponent as Hash } from '../../assets/icons-svg/Hash.svg'
import { ReactComponent as Hourglass } from '../../assets/icons-svg/Hourglass.svg'
import { ReactComponent as History } from '../../assets/icons-svg/History.svg'
import { ReactComponent as ImageAttachment } from '../../assets/icons-svg/Image-Attachment.svg'
import { ReactComponent as Image } from '../../assets/icons-svg/Image.svg'
import { ReactComponent as IncomeStatementEmpty } from '../../assets/icons-svg/IncomeStatementEmpty.svg'
import { ReactComponent as Info } from '../../assets/icons-svg/Info.svg'
import { ReactComponent as Invoice } from '../../assets/icons-svg/Invoice.svg'
import { ReactComponent as InvoicestEmpty } from '../../assets/icons-svg/InvoicestEmpty.svg'
import { ReactComponent as JournalEntry } from '../../assets/icons-svg/Journal Entry.svg'
import { ReactComponent as Layer } from '../../assets/icons-svg/Layer.svg'
import { ReactComponent as LeadingCircle } from '../../assets/icons-svg/Leading-Circle.svg'
import { ReactComponent as Ledger } from '../../assets/icons-svg/Ledger.svg'
import { ReactComponent as LLC } from '../../assets/icons-svg/LLC.svg'
import { ReactComponent as Location } from '../../assets/icons-svg/Location.svg'
import { ReactComponent as Locations } from '../../assets/icons-svg/Locations.svg'
import { ReactComponent as Lock } from '../../assets/icons-svg/Lock.svg'
import { ReactComponent as Logout } from '../../assets/icons-svg/logout.svg'
import { ReactComponent as Menu } from '../../assets/icons-svg/Menu.svg'
import { ReactComponent as Messages } from '../../assets/icons-svg/Messages.svg'
import { ReactComponent as Mileage } from '../../assets/icons-svg/Mileage.svg'
import { ReactComponent as Mobile } from '../../assets/icons-svg/Mobile.svg'
import { ReactComponent as NonProfit } from '../../assets/icons-svg/Non_Profit.svg'
import { ReactComponent as Note } from '../../assets/icons-svg/Note.svg'
import { ReactComponent as NotFound } from '../../assets/icons-svg/NotFound.svg'
import { ReactComponent as Notifications } from '../../assets/icons-svg/Notifications.svg'
import { ReactComponent as Payroll } from '../../assets/icons-svg/Payroll.svg'
import { ReactComponent as PDF } from '../../assets/icons-svg/PDF.svg'
import { ReactComponent as Percentage } from '../../assets/icons-svg/Percentage.svg'
import { ReactComponent as Private } from '../../assets/icons-svg/Private.svg'
import { ReactComponent as Discount } from '../../assets/icons-svg/Discount.svg'
import { ReactComponent as Profile } from '../../assets/icons-svg/Profile.svg'
import { ReactComponent as Purpose } from '../../assets/icons-svg/Purpose.svg'
import { ReactComponent as Reconciliations } from '../../assets/icons-svg/Reconciliations.svg'
import { ReactComponent as Recycle } from '../../assets/icons-svg/Recycle.svg'
import { ReactComponent as Refresh } from '../../assets/icons-svg/Refresh.svg'
import { ReactComponent as ReportsDoc } from '../../assets/icons-svg/Report-Doc.svg'
import { ReactComponent as Reports } from '../../assets/icons-svg/Reports.svg'
import { ReactComponent as RightMark } from '../../assets/icons-svg/RightMark.svg'
import { ReactComponent as SCorporation } from '../../assets/icons-svg/S_Corporation.svg'
import { ReactComponent as Send } from '../../assets/icons-svg/Send.svg'
import { ReactComponent as SendDark } from '../../assets/icons-svg/SendDark.svg'
import { ReactComponent as Settings } from '../../assets/icons-svg/Settings.svg'
import { ReactComponent as Sms } from '../../assets/icons-svg/Sms.svg'
import { ReactComponent as Tick } from '../../assets/icons-svg/Tick.svg'
import { ReactComponent as Text } from '../../assets/icons-svg/Text.svg'
import { ReactComponent as Tag } from '../../assets/icons-svg/Tag.svg'
import { ReactComponent as TaxPlan } from '../../assets/icons-svg/Tax Plan.svg'
import { ReactComponent as Taxes } from '../../assets/icons-svg/Taxes.svg'
import { ReactComponent as ThumbsUp } from '../../assets/icons-svg/ThumpsUp.svg'
import { ReactComponent as ToDoList } from '../../assets/icons-svg/To-Do-List.svg'
import { ReactComponent as TransactionsByAccount } from '../../assets/icons-svg/Transactions-By-Account.svg'
import { ReactComponent as Transactions } from '../../assets/icons-svg/Transactions.svg'
import { ReactComponent as Trips } from '../../assets/icons-svg/Trips.svg'
import { ReactComponent as Trust } from '../../assets/icons-svg/Trust.svg'
import { ReactComponent as Undo } from '../../assets/icons-svg/arrowUnpublish.svg'
import { ReactComponent as Union } from '../../assets/icons-svg/Union.svg'
import { ReactComponent as Upload } from '../../assets/icons-svg/Upload.svg'
import { ReactComponent as UploadCloud } from '../../assets/icons-svg/Upload-Cloud.svg'
import { ReactComponent as UploadAvatar } from '../../assets/icons-svg/User-Avatar.svg'
import { ReactComponent as User } from '../../assets/icons-svg/User.svg'
import { ReactComponent as Vector } from '../../assets/icons-svg/Vector.svg'
import { ReactComponent as Vehicle } from '../../assets/icons-svg/Vehicles.svg'
import { ReactComponent as Vertical } from '../../assets/icons-svg/Vertical.svg'
import { ReactComponent as Website } from '../../assets/icons-svg/Website.svg'
import { ReactComponent as WhiteDelete } from '../../assets/icons-svg/WhiteDelete.svg'
import { ReactComponent as Work } from '../../assets/icons-svg/Work.svg'
import { ReactComponent as Help } from '../../assets/icons-svg/Help.svg'
import { ReactComponent as DeleteTrash } from '../../assets/icons-svg/DeleteTrashNew.svg'
import { ReactComponent as LeftArrow } from '../../assets/icons-svg/LeftArrow.svg'
import { ReactComponent as RightArrow } from '../../assets/icons-svg/RightArrow.svg'
import { ReactComponent as CheckedIcon } from '../../assets/icons-svg/Checked-Icon.svg'
import { ReactComponent as InfoIcon } from '../../assets/icons-svg/InfoI.svg'
import { ReactComponent as CompleteIconPrimary } from '../../assets/icons-svg/CompleteIconPrimary.svg'
import { ReactComponent as EmptyCircle } from '../../assets/icons-svg/EmptyCircle.svg'
// import { ReactComponent as BusinessTypeIcon } from '../../assets/icons-svg/business_type.svg'
import { ReactComponent as BusinessBookkeepingIcon } from '../../assets/icons-svg/business_bookkeeping.svg'
// import { ReactComponent as BusinessOwnerIcon } from '../../assets/icons-svg/business_owners.svg'
import { ReactComponent as RequiredDocIcon } from '../../assets/icons-svg/required_documents.svg'
// import { ReactComponent as BusinessHomeExpensesIcon } from '../../assets/icons-svg/business_home_expenses.svg'
import { ReactComponent as BusinessSpecialDeductionsInformation } from '../../assets/icons-svg/business_special_deductions_information.svg'
// import { ReactComponent as BusinessTypeIcon } from '../../assets/icons-svg/business_type.svg'
import { ReactComponent as Checkmark } from '../../assets/icons-svg/Checkmark.svg'
import { ReactComponent as CheckmarkDashes } from '../../assets/icons-svg/checked-dashes.svg'
import { ReactComponent as CheckedDashes } from '../../assets/icons-svg/check-dashes.svg'
import { ReactComponent as DetailsIcon } from '../../assets/icons-svg/LeadingIcon.svg'
import { ReactComponent as LockIcon } from '../../assets/icons-svg/lock-icon-new.svg'
import { ReactComponent as PlayIcon } from '../../assets/icons-svg/Play-Video.svg'
import { ReactComponent as StarIcon } from '../../assets/icons-svg/star-icon.svg'
import { ReactComponent as CheckmarkGreen } from '../../assets/icons-svg/checked-circle-green.svg'
import { ReactComponent as IntroductionIcon } from '../../assets/icons-svg/vto-menu/btp/introduction.svg'
import { ReactComponent as BusinessTypeIcon } from '../../assets/icons-svg/vto-menu/btp/business_type.svg'
import { ReactComponent as BusinessInfoIcon } from '../../assets/icons-svg/vto-menu/btp/business_info.svg'
import { ReactComponent as BusinessOwnersIcon } from '../../assets/icons-svg/vto-menu/btp/business_owners.svg'
import { ReactComponent as BusinessAutomobilesIcon } from '../../assets/icons-svg/vto-menu/btp/business_automobiles.svg'
import { ReactComponent as BusinessHomeExpensesIcon } from '../../assets/icons-svg/vto-menu/btp/business_home_expenses.svg'
import { ReactComponent as BusinessPaymentsIcon } from '../../assets/icons-svg/vto-menu/btp/business_payments.svg'
import { ReactComponent as BusinessAssetsAndLiabilitiesIcon } from '../../assets/icons-svg/vto-menu/btp/business_assets_liabilities.svg'
import { ReactComponent as SpecialDeductionsIcon } from '../../assets/icons-svg/vto-menu/btp/special_deductions.svg'
import { ReactComponent as UploadCenterIcon } from '../../assets/icons-svg/vto-menu/btp/upload_center.svg'
import { ReactComponent as Form1040ScanIcon } from '../../assets/icons-svg/vto-menu/ptp/1040_form_scan.svg'
import { ReactComponent as PersonalInfoIcon } from '../../assets/icons-svg/vto-menu/ptp/personal_info.svg'
import { ReactComponent as FilingStatusIcon } from '../../assets/icons-svg/vto-menu/ptp/filing_status.svg'
import { ReactComponent as HomeAddressIcon } from '../../assets/icons-svg/vto-menu/ptp/home_address.svg'
import { ReactComponent as DependantsIcon } from '../../assets/icons-svg/vto-menu/ptp/dependants.svg'
import { ReactComponent as PersonalIncomeIcon } from '../../assets/icons-svg/vto-menu/ptp/personal_income.svg'
import { ReactComponent as PersonalDeducationsIcon } from '../../assets/icons-svg/vto-menu/ptp/personal_deductions.svg'
import { ReactComponent as EducationalSavingPlanIcon } from '../../assets/icons-svg/vto-menu/ptp/educational.svg'
import { ReactComponent as SolePropreitorshipIcon } from '../../assets/icons-svg/vto-menu/ptp/sole_proprietorship.svg'
import { ReactComponent as InputCheckIcon } from '../../assets/icons-svg/vto-menu/input_check.svg'
import { ReactComponent as CalendarRoundIcon } from '../../assets/icons-svg/calendar-green.svg'
import { ReactComponent as DollarUpdated } from '../../assets/icons-svg//vto-menu/dollar-updated.svg'
import { ReactComponent as MissingInputIcon } from '../../assets/icons-svg/Missing-Heavy.svg'
import { ReactComponent as PrefilledInputIcon } from '../../assets/icons-svg/Pre-filled.svg'

const IconList = {
    activity: <Activity />,
    add: <Add />,
    alert: <Alert />,
    archiveWhite: <ArchiveWhite />,
    archived: <Archived />,
    avatar: <Avatar />,
    balanceSheetEmpty: <BalanceSheetEmpty />,
    bank: <Bank />,
    beta: <Beta />,
    bookkeeping: <Bookkeeping />,
    bookkeepingInfo: <BookkeepingInfo />,
    bankingIcon: <BankingIcon />,
    call: <Call />,
    calendarIllustration: <CalendarIllustration />,
    calendar: <Calendar />,
    check: <Check />,
    checkCircleSmall: <CheckCircleSmall />,
    checkCircle: <CheckCircle />,
    clientbooks: <Clientbooks />,
    clock: <Clock />,
    cloud: <Cloud />,
    company: <Company />,
    companyCircle: <CompanyCircle/>,
    creditCard: <CreditCard />,
    dashboard: <Dashboard />,
    delete: <Delete />,
    documentIllustration: <DocumentIllustration />,
    document: <Document />,
    documents: <Documents />,
    download: <Download />,
    dollar: <Dollar />,
    edit: <Edit />,
    email: <Email />,
    emptyImage: <EmptyImage />,
    emailSent: <EmailSent />,
    exclamationMark: <ExclamationMark />,
    expensesEmpty: <ExpensesEmpty />,
    fax: <Fax />,
    fileText: <FileText />,
    financialTeam: <FinancialTeam />,
    generic: <Generic />,
    giftDisabled: <GiftDisabled />,
    gift: <Gift />,
    graphic: <Graphic />,
    hash: <Hash />,
    history: <History />,
    hourglass: <Hourglass />,
    imageAttachment: <ImageAttachment />,
    image: <Image />,
    incomeStatementEmpty: <IncomeStatementEmpty />,
    info: <Info />,
    invoice: <Invoice />,
    invoicestEmpty: <InvoicestEmpty />,
    journalEntry: <JournalEntry />,
    layer: <Layer />,
    leadingCircle: <LeadingCircle />,
    ledger: <Ledger />,
    llc: <LLC />,
    location: <Location />,
    locations: <Locations />,
    lock: <Lock />,
    logout: <Logout />,
    menu: <Menu />,
    messages: <Messages />,
    mileage: <Mileage />,
    mobile: <Mobile />,
    nonProfit: <NonProfit />,
    note: <Note />,
    notFound: <NotFound />,
    notifications: <Notifications />,
    payroll: <Payroll />,
    pdf: <PDF />,
    percentage: <Percentage />,
    private: <Private />,
    discount: <Discount />,
    profile: <Profile />,
    purpose: <Purpose />,
    reconcillations: <Reconciliations />,
    recycle: <Recycle />,
    refresh: <Refresh />,
    reportsDoc: <ReportsDoc />,
    reports: <Reports />,
    right: <RightMark />,
    sCorporation: <SCorporation />,
    send: <Send />,
    sendDark: <SendDark />,
    settings: <Settings />,
    sms: <Sms />,
    tag: <Tag />,
    taxPlan: <TaxPlan />,
    taxes: <Taxes />,
    tick: <Tick />,
    text: <Text />,
    thumbsup: <ThumbsUp />,
    toDoList: <ToDoList />,
    transactionsByAccount: <TransactionsByAccount />,
    transactions: <Transactions />,
    trips: <Trips />,
    trust: <Trust />,
    undo: <Undo />,
    union: <Union />,
    upload: <Upload />,
    uploadCloud: <UploadCloud />,
    uploadAvatar: <UploadAvatar />,
    user: <User />,
    vector: <Vector />,
    vehicle: <Vehicle />,
    vertical: <Vertical />,
    work: <Work />,
    website: <Website />,
    whiteDelete: <WhiteDelete />,
    help: <Help />,
    deleteTrash: <DeleteTrash />,
    leftArrow: <LeftArrow />,
    rightArrow: <RightArrow />,
    checkedIcon: <CheckedIcon />,
    InfoIcon: <InfoIcon />,
    CompleteIconPrimary: <CompleteIconPrimary />,
    EmptyCircle: <EmptyCircle />,
    business_bookkeeping: <BusinessBookkeepingIcon />,
    required_documents: <RequiredDocIcon />,
    business_special_deductions_information: (
        <BusinessSpecialDeductionsInformation />
    ),
    checkmark: <Checkmark />,
    checkmarkDashes: <CheckmarkDashes />,
    checkedDashes: <CheckedDashes/>,
    detailsIcon: <DetailsIcon />,
    lockIcon: <LockIcon />,
    playIcon: <PlayIcon/>,
    starIcon: <StarIcon />,
    CheckmarkGreen: <CheckmarkGreen />,
    default_icon_vtomenu: <BusinessTypeIcon />,
    introduction_vtomenu_btp: <IntroductionIcon />,
    business_type_vtomenu_btp: <BusinessTypeIcon />,
    business_info_vtomenu_btp: <BusinessInfoIcon />,
    business_owners_vtomenu_btp: <BusinessOwnersIcon />,
    business_automobiles_vtomenu_btp: <BusinessAutomobilesIcon />,
    business_home_expenses_vtomenu_btp: <BusinessHomeExpensesIcon />,
    business_payments_vtomenu_btp: <BusinessPaymentsIcon />,
    business_assets_liabilities_vtomenu_btp: <BusinessAssetsAndLiabilitiesIcon />,
    special_deductions_vtomenu_btp: <SpecialDeductionsIcon />,
    upload_center_vtomenu_btp: <UploadCenterIcon />,
    introduction_vtomenu: <IntroductionIcon />,
    "1040_form_scan_vtomenu": <Form1040ScanIcon />,
    general_vtomenu: <PersonalInfoIcon />,
    filing_status_vtomenu: <FilingStatusIcon />,
    home_address_vtomenu: <HomeAddressIcon/>,
    dependants_vtomenu: <DependantsIcon />,
    personal_income_vtomenu: <PersonalIncomeIcon />,
    personal_deductions_vtomenu: <PersonalDeducationsIcon />,
    upload_center_vtomenu: <UploadCenterIcon />,
    educational_vtomenu: <EducationalSavingPlanIcon />,
    sole_proprietorship_vtomenu: <SolePropreitorshipIcon />,
    input_check_icon: <InputCheckIcon />,
    missingInput: <MissingInputIcon />,
    prefilledInput: <PrefilledInputIcon />,
    calendarRound: <CalendarRoundIcon/>,
    dollarUpdated: <DollarUpdated/>
}

export default IconList