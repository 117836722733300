import { Theme, makeStyles } from '@material-ui/core'
import { ThemeColors } from '../../../../../styles/models/Colors.interface'

export const commonVtoStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        centeredContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        contentSpacing: {
            marginTop: '1rem',
            marginBottom: '1rem',
        },
        topMargin: {
            marginTop: '1rem',
        },
        bottomMargin: {
            marginBottom: '1rem',
        },
        centeredLoader: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        stickyTop: {
            position: 'sticky',
            top: 0,
            background: (colorTheme) => colorTheme.primaryWhite,
            zIndex: 10
        }
    }
})
