import {
    Checkbox,
    FormControlLabel,
    Hidden,
    makeStyles,
    Theme,
} from '@material-ui/core'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { useVtoContext } from '../../vto/providers/VtoProvider'
import VtoOpening from '../../../../assets/images/vto/vto-opening.png'
import UiText from '../../../common/ui/UiText'
import UiButton from '../../../common/ui/UiButton'
import { toRem16 } from '../../../../styles/commonStyles'

export const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    imageStyle: {
        height: '100%',
        width: '100%',
        maxHeight: `${toRem16(400)}`,
        maxWidth: `${toRem16(600)}`,
    },
    tosMargin: {
        marginTop: '2rem',
        marginBottom: '1.5rem',
    },
    spacer: {
        height: '60px',
    },
    fixedButtonContainer: {
        '& button span': {
            fontWeight: 600,
        },
        '& .tertiary-btn:disabled': {
            background: (colorTheme) => colorTheme.primary,
            opacity: '0.25',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '1rem',
            backgroundColor: (colorTheme) => colorTheme.primaryWhite,
            borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
        },
    },
}))

export default function GetVTOStarted({
    tosAccepted,
    setTosAccepted,
    handleNext,
}: any) {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { selectedYear, isPersonalVTO } = useVtoContext()
    return (
        <div>
            <img
                src={VtoOpening}
                alt="vtoStartImage"
                className={styles.imageStyle}
            />
            <div className={styles.tosMargin}>
                <UiText variant="suv_150" weight="bold_700">
                    Start {isPersonalVTO ? 'Personal' : 'Business'} Tax Prep{' '}
                    {selectedYear}
                </UiText>
                <UiText className={styles.my16}>
                    Your accountant will begin preparing your tax return once
                    you submit your Tax Information.
                </UiText>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={tosAccepted}
                            onChange={(event) => {
                                setTosAccepted(event.target.checked)
                            }}
                        />
                    }
                    label={
                        <UiText variant="motorcycle_90">
                            I accept the{' '}
                            <a
                                className={styles.link}
                                href="https://1800accountant.com/terms-of-services"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms and Conditions
                            </a>
                        </UiText>
                    }
                />
            </div>
            <Hidden mdUp>
                <div className={styles.spacer}></div>
            </Hidden>
            <div className={styles.fixedButtonContainer}>
                <UiButton
                    btnType="tertiary"
                    handleClick={handleNext}
                    disabled={!tosAccepted}
                    label="Get Started"
                />
            </div>
        </div>
    )
}
