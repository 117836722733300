import Axios from 'axios';
import { ApiConfig, Config } from '../../models';
import { getApiConfig } from '../../services/apiService';

export const SET_CONFIG = 'SET_CONFIG';

let apiConfigLoaded = false;

let configValue = {
    local: {} as any,
    apiConfig: {} as ApiConfig,
};

var local_configs = {
    sections: 'config/sections.json',
    account_steps: 'config/setup_steps.json',
    report_sections: 'config/report_sections.json',
    // settings_sections: 'config/settings_sections.json',
    // categories_sections: 'config/categories_sections.json',
    // profile_sections: 'config/profile_sections.json',
    // connection_steps: 'config/connection_steps.json',
    // invoices_types: 'config/invoices_types.json',
    // features: 'config/features.json',
    // vto_faq: 'config/vto_faq.json',
    // popular_banks: 'config/popular_banks.json',
    filters: 'config/filters.json',
    // calendar_slots: 'config/calendar_slots.json',
    // tax_suggestions_pages: 'config/tax_suggestions_pages.json'
    estimatedTaxesModal: 'config/estimatedTaxesModal.json',
    company_details_steps: 'config/company_details_steps.json',
    portal_access_plan: 'config/portal_access_plan.json',
    vto_faq: 'config/vto_faq.json',
    adp_company_details_steps: 'config/adp_company_details_steps.json',
};

export interface ConfigAction {
    type: string;
    state: Config;
}

export type Action = ConfigAction;

export const setConfigData = (data: any): ConfigAction => ({
    type: SET_CONFIG,
    state: data,
});

export function loadConfig() {
    return (dispatch: any) => {
        return new Promise<any>((resolve, reject) => {
            Object.entries(local_configs).forEach(([key, value]) => {
                Axios.get(value).then((res) => {
                    configValue.local[key] = res.data;
                    if (allConfigLoaded()) {
                        dispatch(setConfigData(configValue));
                        return resolve(null);
                    }
                });
            });
            getApiConfig<ApiConfig>().then((res: any) => {
                configValue.apiConfig = res;
                apiConfigLoaded = true;
                //TODO: Post loading process

                if (configValue.apiConfig.business) {
                    configValue.apiConfig.industry_type_other = configValue.apiConfig.business.industries.find(
                        (item) => item.name === 'Other'
                    );
                }
                configValue.local.combined_route_sections = [
                    ...configValue.local.sections.portal_sections,
                    ...configValue.local.sections.expandable_sections
                        .map((es: any) => es.child)
                        .flat(),
                    ...configValue.local.sections.non_nav_sections
                ];
                if (allConfigLoaded()) {
                    dispatch(setConfigData(configValue));
                    return resolve(null);
                }
            });
        });
    };
}

function allConfigLoaded() {
    let result = apiConfigLoaded;
    Object.entries(local_configs).forEach(([key, value]) => {
        if (typeof configValue.local[key] === 'undefined') {
            result = false;
        }
    });
    return result;
}
