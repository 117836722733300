import { Form } from 'formik'
import { makeStyles } from '@material-ui/core'
import {
    SectionType,
    VTOQuestionSectionProps,
} from '../../../../../../models/vto/VtoInfo'
import Owners from './section-questions/Owners'
import Vehicles from './section-questions/Vehicles'
import Employees from './section-questions/Employees'
import HomeOffice from './section-questions/HomeOffice'
import BusinessInfo from './section-questions/BusinessInfo'
import BalanceSheet from './section-questions/BalanceSheet'
import BusinessEntityType from './section-questions/BusinessEntityType'
import ProfitLossStatement from './section-questions/ProfitLossStatement'
import SpecialDeductionsInformation from './section-questions/SpecialDeductionsInformation'
import Bookkeeping from './section-questions/Bookkeeping'
import RentalActivity from './section-questions/RentalActivity'
import CommonSection from './section-questions/CommonSection'

const useStyles = makeStyles(() => {
    return {
        sectionContainer: {
            display: 'flex',
            flex: 1,
        },
        form: {
            width: '100%',
        },
    }
})

const NoSectionFound = () => <div>No section found</div>

const Section = (props: any) => {
    const { section }: { section: SectionType } = props
    let SectionComponent: React.FC = NoSectionFound
    switch (section as SectionType) {
        case 'business_type':
            SectionComponent = BusinessEntityType
            break
        case 'business_info':
            SectionComponent = BusinessInfo
            break
        case 'business_automobiles':
            SectionComponent = Vehicles
            break
        case 'business_home_expenses':
            SectionComponent = HomeOffice
            break
        case 'business_payments':
            SectionComponent = Employees
            break
        case 'business_bookkeeping':
            SectionComponent = Bookkeeping
            break
        case 'business_balance':
            SectionComponent = BalanceSheet
            break
        case 'business_profit':
            SectionComponent = ProfitLossStatement
            break
        case 'business_rental_activity':
            SectionComponent = RentalActivity
            break
        case 'business_special_deductions_information':
            SectionComponent = SpecialDeductionsInformation
            break
        default:
            SectionComponent = CommonSection
            break
    }
    return <SectionComponent {...props} section={section} />
}

const VTOQuestionSection = (props: VTOQuestionSectionProps) => {
    const styles = useStyles()

    return (
        <div className={styles.sectionContainer}>
            <Form className={styles.form}>
                <Section section={props.section} />
            </Form>
        </div>
    )
}

export default VTOQuestionSection
