import React from 'react';
import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useThemeContext } from '../whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { toRem16 } from '../../../styles/commonStyles';

interface ButtonProps {
    handleClick?: (result?: any) => void;
    label?: string;
    startIconSvg?: string;
    disabled?: boolean;
    btnType: 'primary' | 'secondary' | 'tertiary' | 'hyperlink';
    customClass?: any
    icon?: any
    type?: 'button' | 'submit'
    dataCy?: string
    endIconSvg?: any, 
    endIcon?: any
    fullWidth?: boolean | undefined;  
    customLabel?: any
    btnRef?: any
}
const styles = makeStyles<Theme, ThemeColors>((theme: Theme) =>
    createStyles({
        primary: {
            background: (colorTheme) => colorTheme.primaryGreen,
            color: (colorTheme) => colorTheme.primaryWhite,
            padding: `${toRem16(10)} 1rem`,
            '&:hover': {
                background: (colorTheme) => colorTheme.green300,
                color: (colorTheme) => colorTheme.primaryWhite,
            },
            '&:disabled': {
                background: (colorTheme) => colorTheme.grey2300,
            },
        },
        secondary: {
            background: 'transparent',
            color: (colorTheme) => colorTheme.grey1000,
            padding: `${toRem16(10)} 1rem`,
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        },
        tertiary: {
            background: (colorTheme) => colorTheme.primary,
            color: (colorTheme) => colorTheme.primaryWhite,
            padding: `${toRem16(10)} 1rem`,
            '&:hover': {
                background: (colorTheme) => colorTheme.orange500,
                color: (colorTheme) => colorTheme.primaryWhite
            },
            '&:disabled': {
                background: (colorTheme) => colorTheme.grey200,
            },
        },
        hyperlink: {
            color: (colorTheme) => colorTheme.blue200,
            fontWeight: 600,
            fontSize: '15px',
            lineHeight: '24px',
            letterSpacing: '0.02em',
        }
    })
)

export default function UiButton(props: ButtonProps) {
    const { handleClick,
        label, 
        startIconSvg,
        disabled, 
        btnType, 
        customClass,
        icon, 
        dataCy = '', 
        endIconSvg, 
        endIcon,
        fullWidth,
        customLabel,
        type='button',
        btnRef,
    } = props;
    const { colorTheme } = useThemeContext()
    const classes = styles(colorTheme);

    return (
        <Button
            ref={btnRef}
            type={type}
            disabled={disabled || false}
            onClick={handleClick}
            className={`${classes[btnType]} ${customClass} ${btnType}-btn`}
            startIcon={
                startIconSvg ? <img src={startIconSvg} alt='icon' /> : icon ? icon : null
            }
            endIcon={ endIconSvg ? <img src={endIconSvg} alt='icon' /> : endIcon ? endIcon : null}
            data-cy={dataCy}
            fullWidth={fullWidth || false}
        >
            {customLabel ? customLabel : label && <Typography variant='button'>{label} </Typography>}
        </Button>
    );
}
