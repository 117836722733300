import moment from 'moment'
import {
    ApplicationStore,
    DocumentQueryObject,
    FilterConfig,
} from '../../../../../../models'
import { convertBytesToSize } from '../../../../../../utils/documentUtil'
import store from '../../../../../../store'

export const getChipsData = (documentFilters: DocumentQueryObject) => {
    const { config } = store.getState() as ApplicationStore
    const documentFiltersConfig: any = config.local.filters as FilterConfig

    let chips: any[] = []

    Object.keys(documentFilters).forEach((filter) => {
        switch (filter) {
            case 'period':
                // If custom period is selected the date range else period
                if (documentFilters.period === 'custom') {
                    chips.push({
                        label: `${moment(documentFilters.minDate * 1000).format(
                            'DD MMM, YYYY'
                        )} - ${moment(documentFilters.maxDate * 1000).format(
                            'DD MMM, YYYY'
                        )}`,
                        searchKey: 'period',
                    })
                } else {
                    documentFilters.period !== 'all' &&
                        chips.push({
                            label: documentFiltersConfig.periods[
                                documentFilters.period
                            ] as string,
                            searchKey: 'period',
                        })
                }
                return chips
            case 'size':
                const convertedSize = convertBytesToSize(
                    documentFilters.size.value
                )
                convertedSize.size &&
                    chips.push({
                        label: `${
                            documentFilters.size.operator === 'gt' ? '>' : '<'
                        } ${convertedSize.size} ${convertedSize.sizeType}`,
                        searchKey: 'size',
                    })
                return chips
        }
    })

    return chips
}
