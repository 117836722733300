import { useFormikContext } from 'formik';
import { getStateFields } from '../../../common/VtoHelpers';
import UiFormOptions from './UiFormOptions';

const UiStateFieldQuestions = (props: any) => {
    const { values } = useFormikContext()
    const stateFields = getStateFields(values)
    
    return (
        <div>
            {stateFields?.map((field: any, index: number) => {
                if(field.options?.faq){
                    props = {...props, options: {...props.options, faq: field.options.faq } }
                }
                return (
                    <div key={index}>
                        <UiFormOptions
                            {...props} 
                            parentModel={props?.model} 
                            type="string"
                            index={0} 
                            title={field?.title} 
                            model={field?.name}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default UiStateFieldQuestions;