import { useEffect, useState, useRef } from 'react'

export function useScroll({
    threshold = 0,
    root = null,
    rootMargin = '0%',
}: IntersectionObserverInit): [any, IntersectionObserverEntry | undefined] {
    const elementRef = useRef<HTMLDivElement | null>(null)

    const [entry, setEntry] = useState<IntersectionObserverEntry>()

    const frozen = entry?.isIntersecting

    const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
        setEntry(entry)
    }

    useEffect(() => {
        const node = elementRef?.current

        if (!node || typeof IntersectionObserver !== 'function') return

        const observerParams = { threshold, root, rootMargin }
        const observer = new IntersectionObserver(updateEntry, observerParams)

        observer.observe(node)

        return () => observer.disconnect()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        elementRef?.current,
        JSON.stringify(threshold),
        root,
        rootMargin,
        frozen,
    ])

    return [elementRef, entry]
}
