import store from '../../store';
import { getGeneratedOnDate } from '../../utils/dateUtil';
import { getResource } from '../axiosService';

export function getTaxPrepWidgetData(
    accountId: number,
    vtoYear: number
): Promise<any> {
    const apiUrl = `/cbapi/app.php/api/account/${accountId}/vto${vtoYear}/vtowidget`;
    return getResource(apiUrl);
}
export function getVtoPdfUrl(
    accountId: number | string,
    vtoYear: number,
    vto: number | string | undefined
): string {
    const apiUrl = `/cbapi/app.php/api/account/${accountId}/vto${vtoYear}/vto/${vto}/pdf/async`;
    const accessToken = store.getState().auth.access_token;
    const generatedOnDateVal = generatedOnDate();
    return `${apiUrl}?access_token=${accessToken}&generated_on=${generatedOnDateVal}`;
}

export function getPersonalVtoDetails(
    accountId: string,
    vtoYear: number
): Promise<any> {
    const apiUrl = `/cbapi/app.php/api/account/${accountId}/vto${vtoYear}/vto?type=0`
    return getResource(apiUrl)
}

const generatedOnDate = () => {
    return getGeneratedOnDate();
};