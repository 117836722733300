import { useStore } from 'react-redux';
import { AppData, ApplicationStore, Business, Config } from '../../../models'

export const useCurrentStore = (): {
    currentBusiness: Business
    currentBusinessId: string,
    currentAccountId: string,
    currentAccount: any,
    currentUser: any,
    currentConfig: Config,
    personalAccount: any,
    appData: AppData,
    accessLevel: any,
    personalAccountId: string 
    product: any
} =>{
    const store: ApplicationStore = useStore().getState();
    const appData: AppData = store.appData

    const { current_business: currentBusiness, 
        current_account: currentAccount, 
        user: currentUser, 
        personal_account: personalAccount
    } = appData

    const currentBusinessId = currentBusiness?.id as string
    const currentAccountId = currentAccount?.id
    const personalAccountId = personalAccount?.id
    const currentConfig: Config = store.config
    const accessLevel = store.auth.accessLevel
    const product = store.product

    return {
        currentBusiness,
        currentBusinessId,
        currentAccountId,
        currentAccount,
        currentUser,
        currentConfig,
        personalAccount,
        appData,
        accessLevel,
        personalAccountId,
        product
    }
}