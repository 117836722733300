import React from 'react';
import { Theme, Tooltip, IconButton, makeStyles } from '@material-ui/core'
import { COLORS } from '../../variables/colors'
import UiText from './ui/UiText'
interface infoTooltipProps {
    tooltipText: string
    customNode?: React.ReactNode
    iconSrc?: string
    customStyle?: {}
    placement?: 'top' | 'right' | 'bottom' | 'left',
    top?: string
}

const useStyles = (customStyle: any) =>
    makeStyles((theme: Theme) => ({
        customWidth: {
            maxWidth: customStyle?.width || '256px',
            minWidth: customStyle?.minWidth,
            background: customStyle?.background || COLORS.secondary,
            borderRadius: '4px ',
            padding: '12px 16px ',
            width: customStyle?.width || '288px',
            top: customStyle?.top || '0',
            color: customStyle?.background || COLORS.primaryWhite,
        },
    }))

const InfoTooltip = ({
    tooltipText,
    customNode,
    customStyle = {},
    placement = 'bottom',
}: infoTooltipProps) => {
    const classes = useStyles(customStyle)()

    return (
        <Tooltip
            title={<UiText variant="motorcycle_90">{tooltipText}</UiText>}
            enterTouchDelay={0}
            arrow
            classes={{ tooltip: classes.customWidth }}
            placement={placement}
        >
            <span>
                {customNode ? (
                    customNode
                ) : (
                    <IconButton aria-label="Info">
                        <img
                            alt={`Info Icon`}
                            src={
                                require(`../../assets/icons-svg/Info.svg`)
                                    .default
                            }
                        />
                    </IconButton>
                )}
            </span>
        </Tooltip>
    )
}

export default InfoTooltip
