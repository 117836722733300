import Started from '../../../../../../assets/images/vto/started.png'
import Submitted from '../../../../../../assets/images/vto/submitted.png'
import Preparation from '../../../../../../assets/images/vto/preparation.png'
import ManagerReview from '../../../../../../assets/images/vto/manager_review.png'
import ClientReview from '../../../../../../assets/images/vto/client_review.png'
import EFile from '../../../../../../assets/images/vto/efile.png'
import Completed from '../../../../../../assets/images/vto/completed.png'
import NeedAdditionalInfo from '../../../../../../assets/images/vto/need_additional_info.png'
import { useVtoContext } from '../../../providers/VtoProvider'
import {
    CurrentStepType,
    TaxPreparationTitle,
} from '../../../../../../models/vto/VtoInfo'
import { Theme, makeStyles } from '@material-ui/core'
import { toRem16 } from '../../../../../../styles/commonStyles'
import { ApStatusMapping } from './constant'

const useStyles = makeStyles((theme: Theme) => {
    return {
        imageStyle: {
            height: toRem16(360),
            width: toRem16(640),
            objectFit: 'cover'
        },
    }
})

const TaxPrepStatuses = {
    Started: 'Started',
    InfoSubmitted: 'Info Submitted',
    Preparation: 'Preparation',
    InternalReview: 'Internal Review',
    ClientReview: 'Client Review',
    EFile: 'E-File',
    Completed: 'Completed',
} as const

const StepWiseImageMapping = (status: TaxPreparationTitle) => {
    switch (status) {
        case TaxPrepStatuses.Started:
            return Started
        case TaxPrepStatuses.InfoSubmitted:
            return Submitted
        case TaxPrepStatuses.Preparation:
            return Preparation
        case TaxPrepStatuses.InternalReview:
            return ManagerReview
        case TaxPrepStatuses.ClientReview:
            return ClientReview
        case TaxPrepStatuses.EFile:
            return EFile
        case TaxPrepStatuses.Completed:
            return Completed
        default:
            break
    }
}

const StatusImage = () => {
    const { currentBusinessVto, currentPersonalVto, isBusinessVTO } = useVtoContext()
    const styles = useStyles()
    const vtoData = isBusinessVTO ? currentBusinessVto : currentPersonalVto
    const currentStatus = vtoData?.tax_preparation_current_step as CurrentStepType
    const currentApStatus = vtoData?.tax_ap_status as string
    const currentImage = currentApStatus === ApStatusMapping.ADDITIONAL_INFO_REQUEST_SENT ? NeedAdditionalInfo:  StepWiseImageMapping(currentStatus.title)

    return (
        <img
            src={currentImage}
            alt={'status-wise-info'}
            className={styles.imageStyle}
        />
    )
}

export default StatusImage
