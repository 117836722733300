import { Box, Button, makeStyles, Theme } from '@material-ui/core'
import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { isValidDocumentMime } from '../../../../utils/documentUtil'
import { useDispatch } from 'react-redux'
import { showInfo } from '../../../../store/actions/feedback'
import { InfoOutlined } from '@material-ui/icons'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import UiText from '../../../common/ui/UiText'
import {
    MIME_IMAGES,
    MIME_TYPES,
} from '../../../dashboard/tax-prep/constants/tax-prep.const'
import Loader from '../../../common/Loader'
import { toRem16 } from '../../../../styles/commonStyles'

interface DocumentUpload {
    size: number
    is_preview_available: boolean
    mime_type: string
    id: string
    filename: string
    portal_account_id: string
    deleted: boolean
    upload_date: number
    type: string
    is_read_by_user: boolean
    is_read_by_accountant: boolean
    path: string
    is_owned_by_accountant: boolean
}

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    content: {
        padding: `${toRem16(15)} ${toRem16(15)}`,
    },
    fileName: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: toRem16(15),
        '& span': {
            padding: '0 0 0 0.5rem',
        },
    },
    snackbarRoot: {
        bottom: '0px',
        'justify-content': 'center',
        position: 'relative',
    },
    snackbarContentDiv: {
        height: toRem16(50),
        display: 'flex',
        justifyContent: 'center',
    },
    actionStyles: {
        display: 'flex',
        justifyContent: 'center',
    },
    actionButton: {
        color: (colorTheme) => colorTheme.primaryWhite,
        borderLeft: (colorTheme) => `1px solid ${colorTheme.primaryWhite}`,
        borderRadius: 0,
    },
    dropzone: {
        padding: '1.5rem',
        border: (colorTheme) => `2px dashed ${colorTheme.grey200}`,
        width: '90%',
        [theme.breakpoints.down('xs')]: {
            padding: '1rem',
        },
    },
    marginText: {
        margin: '1rem 0 1rem 0',
    },
}))

export const getUploadedDocImageType = (mimeType: string) => {
    if (mimeType === MIME_TYPES.IMG_JPEG || mimeType === MIME_TYPES.IMG_PNG) {
        return MIME_IMAGES.ATTACHMENT_IMG
    }
    if (mimeType === MIME_TYPES.APP_PDF) {
        return MIME_IMAGES.PDF
    }
    return MIME_IMAGES.GENERIC
}

const Upload1040Form = (props: any) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { currentConfig } = useCurrentStore()
    const allowedMimeTypes =
        currentConfig.apiConfig.file_center.allowed_mime_types
    const allowedDocumentTypes =
        currentConfig.apiConfig.file_center.allowed_extensions
    const { colorTheme } = useThemeContext()
    const styles = useStyle(colorTheme)
    const [allUploadedFiles, setAllUploadedFiles] = useState<any>()

    const isFileTypeUnsupported = (files: File[]): boolean =>
        files.every((file) =>
            isValidDocumentMime(
                { allowedMimeTypes, allowedDocumentTypes },
                file
            )
        )
    const handleFileUnsupported = () => {
        dispatch(
            showInfo({
                infoData: (
                    <div>
                        <InfoOutlined fontSize="large" />
                        <div>File(s) has unsupported format.</div>
                        <div>
                            Please upload only{' '}
                            <b>{allowedDocumentTypes.join(', ')}</b>
                        </div>
                    </div>
                ),
            })
        )
    }

    const uploadFiles = async (files: File[]) => {
        // handling upload of files
    }
    const handleUploadFile = (files: File[]) => {
        if (!files.length) {
            return
        }

        if (isFileTypeUnsupported(files)) {
            handleFileUnsupported()
            return
        }
        uploadFiles(files)
    }

    const getImageBasedOnMimeType = (mimeType: string) => {
        const imageType = getUploadedDocImageType(mimeType)
        return (
            <img
                src={
                    require(`../../../../assets/icons-svg/${imageType}.svg`)
                        .default
                }
                alt="document"
            />
        )
    }
    const renderUploadedFiles = () => {
        if (!allUploadedFiles) return
        return allUploadedFiles.map((file: DocumentUpload) => {
            return (
                <div className={styles.fileName}>
                    {getImageBasedOnMimeType(file.mime_type)}{' '}
                    <span>{file.filename}</span>
                </div>
            )
        })
    }

    const acceptedFormat = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
    ]

    return (
        <div className={styles.content}>
            {isLoading ? (
                <Loader />
            ) : (
                <Box mt={2} mb={4}>
                    <UiText variant="suv_150" weight="bold_700">
                        Save time by importing your Form 1040 from last year
                    </UiText>
                    <UiText className={styles.marginText}>
                        We will scan your Form 1040 and automatically add all
                        relevant information to this year’s tax info for you to
                        confirm.
                    </UiText>
                    <Dropzone onDrop={handleUploadFile}>
                        {({ getRootProps, getInputProps }) => {
                            return (
                                <Box
                                    {...getRootProps({})}
                                    className={styles.dropzone}
                                    textAlign="center"
                                >
                                    <UiText
                                        weight="medium_500"
                                        variant="hatchback_125"
                                    >
                                        Drop files here to upload
                                    </UiText>
                                    <Box my={3}>
                                        <input
                                            {...getInputProps()}
                                            name="files[]"
                                            accept={acceptedFormat.toString()}
                                        />
                                    </Box>
                                    <Box my={3}>
                                        <Button
                                            disabled={false}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Select Files
                                        </Button>
                                    </Box>
                                    <Box my={3}>
                                        <UiText textColor="secondary">
                                            Supported file types: .pdf, .png,
                                            .jpeg, .jpg
                                        </UiText>
                                    </Box>
                                </Box>
                            )
                        }}
                    </Dropzone>
                </Box>
            )}
            {renderUploadedFiles()}
        </div>
    )
}

export default Upload1040Form
