export const COLORS = {
    primary: '#F15F22',
    secondary: '#3F5261',
    tertiary: '#3B7A80',

    primaryWhite: '#FFFFFF',
    primaryBlack: '#000000',
    primaryGreen: '#2DA38D',

    orange100: '#E54604',
    orange200: '#F34B04',
    orange300: '#FD5E2A',
    orange400: '#F3831A',
    orange500: '#DE571F',

    grey100: '#E7EAF0',
    grey200: '#12172433',
    grey300: '#C2CCDB',
    grey400: '#121724a6',
    grey500: '#58595C',
    grey600: '#00000014',
    grey700: '#0000001f',
    grey800: '#1217246b', 
    grey900: '#0000000a',
    grey1000: '#000000a6',
    grey1200: '#ECE8E2',
    grey1300: '#00000024',
    grey1400: '#00000033',
    grey1500: '#21212114',
    grey1600:'#f5f5f9',
    grey1700: '#12172480',
    grey1800: '#1217248c',
    grey1900: '#00000042',  
    grey2000: '#e0e0e0',
    grey2100: '#EFF7FD',
    grey2200: '#D9E6F0',
    grey2300: '#B4C3D0',
    grey2400: '#DCDCE6',
    grey2500: '#F6F4F2',
    grey2600: '#12172414',
    grey2700: '#BCBCBC',

    green100: '#3b7a80a6',
    green200: '#3B7A80',
    green300: '#1f7262',
    green400: '#148672',
    green500: '#dbf6e7',
    green600: '#1cb05c',
    green700: '#274F54',
    green800 : '#b5f885',
    green900: '#6EE565',
    green1000: '#3b7a801f',
    //green1000: '#11AB7D',
    green1100:'#3B7A801F',
    
    white100: '#ffffff87', 
    white200: '#E3EFF6',

    blue200: '#1776B6', 
    blue300: '#1776b61f',
    blue400: '#154C79',
    blue500: '#e7eaf080',
    blue600: '#0780A6',
    blue700: '#B5D5EE',


    black100: '#121724e8',
    black200: '#121724',
    
    black400: '#CED1D6',
    black500: '#000000de',
    black600: '#343A40',

    cyan100: '#2da38d29',
    cyan200: '#2DA38D14',

    red100: '#BE2436',
    red200: '#DE2644',
    red300: 'DE26441F',
    red400: '#E0284A',
    red500: '#F15A22',
    red600: '#E44A17',
    red700: '#DE26441F',

    yellow100: '#FFCF7C',
    yellow200: '#fabd2129',
    yellow700:"#784E03",


    teal100: '#ffffffd1',
    teal200: '#89D3C6',
    teal300: '#087B9A',

    beige100: '#EFE7DA',
    mint100: '#A9F2DC',
    //all these will be gone - after new dashboard is deployed
    test1: '#11AB7D',
    test2: '#074680',
    test3: '#150565',
    test4: '#D9E2F0',
    
    test5: '#FABD21',
    test6: '#7A0B32',
    test7: '#3B0549',
    grey101: '#E5E5E5', //revisit
}
