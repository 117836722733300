import { useEffect, useState } from 'react'
import moment from 'moment'
import { Formik } from 'formik'
import { Hidden, Theme, makeStyles } from '@material-ui/core'
import { toRem16 } from '../../../../../../../styles/commonStyles'
import UiText from '../../../../../../common/ui/UiText'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import UiButton from '../../../../../../common/ui/UiButton'
import { ThemeColors } from '../../../../../../../styles/models/Colors.interface'
import Icon from '../../../../../../common/Icon'
import UiFormControlSelection from '../../form-controls/UiFormControl'
import {
    addAppointment,
    getSlotsByTopic,
} from '../../../../../../../services/apiService'
import { useCurrentStore } from '../../../../../../common/hooks/useCurrentStore'
import Loader from '../../../../../../common/Loader'
import { getUserTimeZoneAbbr } from '../../../../../../../utils/dateUtil'

const useStyles = makeStyles<Theme, {withSuccessMessage: boolean, colorTheme: ThemeColors}>((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: toRem16(16),
            maxWidth: ({withSuccessMessage}) => withSuccessMessage ? toRem16(640) : '100%',
            textAlign: 'center',

            [theme.breakpoints.down('sm')]: {
                maxWidth: '100% !important'
             },
        },
        scheduleMeetingContainer: {
            display: 'flex',
            flexDirection: 'column',
            margin: `${toRem16(48)} 0`,
            gap: toRem16(16),
        },
        slotsContainer: {
            maxWidth: ({withSuccessMessage}) => withSuccessMessage ? toRem16(640) : toRem16(720),
            margin: ({withSuccessMessage}) => withSuccessMessage ? '1rem auto 0' : '1rem 0 0',
            display: 'flex',
            gap: '1rem',
            flexWrap: 'wrap',

            ['& button']: {
                width: ({withSuccessMessage}) => withSuccessMessage ? toRem16(148) : toRem16(106),
            },

            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                ['& button']: {
                    width: `${toRem16(98)} !important`,
                },
             },
        },
        info: {
            display: 'flex',
            alignItems: 'center',
            margin: '0.5rem 0',
            ['& p']: {
                marginLeft: '1rem',
            },
        },
        tertiaryBtn: {
            height: toRem16(42),
            maxWidth: toRem16(360),

            '&:disabled': {
                background: ({colorTheme}) => colorTheme.primary,
                opacity: 0.25,
            },
        },
        apptDetails: {
            display: 'flex',
            margin: '1.5rem 0',
            borderRadius: '0.5rem',
            border: ({colorTheme}) => `1px solid ${colorTheme.grey200}`,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1.5rem 0',
            width: '100%',
            maxWidth: toRem16(415)
        },
        loaderContainer: {
            minHeight: toRem16(200),
        },
        mt16: {
            marginTop: '1rem',
        },
        my24: {
            marginTop: toRem16(24),
            marginBottom: toRem16(24),
        },
        mt8: {
            marginTop: toRem16(8),
        },
        mb24: {
            marginBottom: toRem16(24),
        },
        w360: {
            width: toRem16(360),
            maxWidth: '100%'
        },
        selectedSlotBtn: {
            border: ({colorTheme}) => `2px solid ${colorTheme.green700}`,
            background: ({colorTheme}) => colorTheme.teal200,

            '&:hover': {
                background: ({colorTheme}) => colorTheme.teal200,
            },

            '& > span.MuiButton-label > p': {
                display: 'flex',
                gap: '0.25rem'
            }
        },
        btnLoadingContainer: {
            height: toRem16(42),
            marginBottom: '2rem',
            display: 'flex',
            placeItems: 'center',
        },
        textLeftSm: {
              [theme.breakpoints.down('sm')]: {
                alignSelf: 'flex-start',
                textAlign: 'left',
             },
        }
    }
})

export default function ScheduleRetirementPlanning({ handleGoToNextSection, withSuccessMessage = false }: { handleGoToNextSection: () => void, withSuccessMessage?:boolean }) {
    const { colorTheme } = useThemeContext()
    const styles = useStyles({ colorTheme, withSuccessMessage })
    const [slots, setSlots] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const { appData } = useCurrentStore()
    const [isApptConfirmed, setIsApptConfirmed] = useState(false)
    const [filteredSlots, setfilteredSlots] = useState([])
    const [selectedSlot, setSelectedSlot] = useState<number | null>(null)
    const [schedulingCall, setSchedulingCall] = useState(false);
    const clientTimeZone = getUserTimeZoneAbbr()

    const accountantId = appData.user?.financial_pod?.tax_advisor?.accountant_id
    const apptTopic = 'Retirement Appt'

    useEffect(() => {
        setLoading(true)
        const getSlotsData = async () => {
            const slots: any = await getSlotsByTopic(apptTopic, accountantId)
            const filteredSlots = getFilteredSlots(slots)

            setSlots(slots)
            setfilteredSlots(filteredSlots)
            setLoading(false)
        }

        getSlotsData()
    }, [])

    const getFilteredSlots = (
        slots: any,
        targetDate: string = moment().format('YYYY-MM-DD')
    ) => {
        if (!slots.slots) return []
        return slots.slots[apptTopic].filter((timestamp: number) => {
            return moment(timestamp * 1000).format('YYYY-MM-DD') === targetDate
        })
    }

    const handleSubmit = (values: any) => {
        const filteredSlots = getFilteredSlots(slots, values.date)
        setSelectedSlot(null)
        setfilteredSlots(filteredSlots)
    }

    const handleConfirmAppt = () => {
        setSchedulingCall(true);
        const apptObj = {
            topic: apptTopic,
            description: '',
            start_time: selectedSlot,
            date: moment.unix(selectedSlot!).startOf('day').valueOf() / 1000,
            accountant_id: accountantId,
        }
        addAppointment(apptObj).then(() => {
            setIsApptConfirmed(true)
            setSchedulingCall(false);
        }).catch(()=> {
            setSchedulingCall(false);
        })
    }

    const getClientTime = (): string => {
        const clientTime: string = new Date().toLocaleTimeString('en-US', {
            timeZone: clientTimeZone,
        })
        return formatTime(clientTime)
    }

    const formatTime = (time: string) => {
        const spittedTime = time.split(/\:+/)
        return `${spittedTime[0]}:${spittedTime[1]} ${time.split(/\s+/)[1]}`
    }

    const slotInClientTimezone = (slot: number) => {
        const slotTime: string = new Date(slot * 1000).toLocaleTimeString(
            'en-US',
            {
                timeZone: clientTimeZone,
            }
        )
        return formatTime(slotTime)
    }

    return (
        <div className={styles.container}>
            {withSuccessMessage && !isApptConfirmed && (
                <>
                    <Icon icon="checkedIcon" size={'60px'} />
                    <UiText variant="truck_175" weight="bold_700">
                        Thanks for confirming your information!
                    </UiText>
                    <UiText className={styles.textLeftSm}>
                        Your taxes will be done in{' '}
                        <span>
                            <b>about 14 days*</b>
                        </span>
                    </UiText>
                    <UiText
                        variant="moped_75"
                        textColor={colorTheme.grey400}
                        className={`${styles.mb24} ${styles.textLeftSm}`}
                    >
                        *This estimate is based on having all necessary
                        information. <Hidden smDown><br /></Hidden>Any missing information will result
                        in a longer processing time.
                    </UiText>
                </>
            )}
            {!isApptConfirmed ? (
                <>
                    <UiText variant="truck_175" weight="bold_700">
                        Schedule your Retirement Planning Call
                    </UiText>
                    <UiText>
                        You can decrease your tax liability by setting up a
                        retirement plan for your small business.
                    </UiText>

                    <UiText
                        className={withSuccessMessage ? '' : styles.my24}
                        variant="hatchback_125"
                        weight="semi_bold_600"
                    >
                        Choose a Date and Time
                    </UiText>

                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={{ date: moment().format('YYYY-MM-DD') }}
                    >
                        {({ submitForm, values, setFieldValue }) => {
                            return (
                                <div className={styles.w360}>
                                    <UiFormControlSelection
                                        type="KeyboardDatePicker"
                                        dateType="string"
                                        disablePastDate={true}
                                        fieldName="date"
                                        endIcon={<Icon icon="calendar" />}
                                        dateFormat="dddd, MMMM D, YYYY"
                                        size="medium"
                                        onDateChange={(value: any) => {
                                            const dateObj = new Date(value)
                                            const date =
                                                dateObj.getDate() < 10
                                                    ? `0${dateObj.getDate()}`
                                                    : dateObj.getDate()
                                            const month =
                                                dateObj.getMonth() < 9
                                                    ? `0${
                                                          dateObj.getMonth() + 1
                                                      }`
                                                    : dateObj.getMonth() + 1
                                            const dateStr = `${dateObj.getFullYear()}-${month}-${date}`
                                            setFieldValue('date', dateStr)
                                            submitForm()
                                        }}
                                    />
                                </div>
                            )
                        }}
                    </Formik>

                    {loading ? (
                        <Loader customClass={styles.loaderContainer} />
                    ) : (
                        <div className={styles.slotsContainer}>
                            {filteredSlots.length > 0 ? (
                                filteredSlots?.map(
                                    (slot: any, index: number) => (
                                        <UiButton
                                            btnType="secondary"
                                            key={index}
                                            handleClick={() => {
                                                if (schedulingCall) return
                                                setSelectedSlot(slot)
                                            }}
                                            customLabel={
                                                <UiText weight="semi_bold_600">
                                                    {selectedSlot === slot && (
                                                        <Icon
                                                            icon="CompleteIconPrimary"
                                                            svgColor={
                                                                colorTheme.green700
                                                            }
                                                        />
                                                    )}
                                                    {slotInClientTimezone(slot)}
                                                </UiText>
                                            }
                                            customClass={
                                                selectedSlot === slot
                                                    ? styles.selectedSlotBtn
                                                    : ''
                                            }
                                        ></UiButton>
                                    )
                                )
                            ) : (
                                <UiText variant="moped_75">
                                    No slot available
                                </UiText>
                            )}
                        </div>
                    )}

                    <div className={styles.info}>
                        <Icon
                            strokeColor={colorTheme.grey400}
                            size="20px"
                            icon="clock"
                        />
                        <UiText
                            textColor="textSecondary"
                            variant="motorcycle_90"
                            weight="medium_500"
                        >
                            All times are displayed in{' '}
                            {`${clientTimeZone} (${getClientTime()})`}
                        </UiText>
                    </div>

                    {schedulingCall ? (
                        <div className={styles.btnLoadingContainer}>
                            <Loader size={30} />
                        </div>
                    ) : (
                        <UiButton
                            customClass={styles.tertiaryBtn}
                            fullWidth
                            btnType="tertiary"
                            customLabel={
                                <UiText weight="semi_bold_600">
                                    Confirm Appointment
                                </UiText>
                            }
                            handleClick={handleConfirmAppt}
                            disabled={!selectedSlot || schedulingCall}
                        />
                    )}

                    <UiButton
                        customClass={styles.mb24}
                        handleClick={handleGoToNextSection}
                        btnType="hyperlink"
                        customLabel={
                            <UiText weight="semi_bold_600">Ask me later</UiText>
                        }
                    />
                </>
            ) : (
                <>
                    <UiText variant="truck_175" weight="bold_700">
                        Your appointment was scheduled!
                    </UiText>
                    <UiText>
                        We look forward to meeting with you to discuss
                        retirement planning for your small business!
                    </UiText>
                    <div className={styles.apptDetails}>
                        <Icon icon="calendarRound" />
                        <UiText className={styles.mt16} weight="semi_bold_600">
                            Retirement Planning
                        </UiText>

                        <UiText>
                            {moment(selectedSlot! * 1000).format(
                                'ddd, MMM D, YYYY • '
                            )}
                            {slotInClientTimezone(selectedSlot!)}{' '}
                            {clientTimeZone}
                        </UiText>
                    </div>
                    <UiButton
                        handleClick={handleGoToNextSection}
                        customClass={styles.tertiaryBtn}
                        fullWidth
                        btnType="tertiary"
                        customLabel={
                            <UiText weight="semi_bold_600">Next</UiText>
                        }
                    />
                    <UiText
                        className={styles.mt16}
                        textColor="textSecondary"
                        variant="motorcycle_90"
                    >
                        You can reschedule your appointment anytime in the
                        Calendar.
                    </UiText>
                </>
            )}
        </div>
    )
}
