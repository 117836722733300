export const INPUT_TYPE_QUESTIONS =  [
    'money', 'string', 'text', 'bool', 'number', 'checkbox', 
    'choice', 'date', 'email', 'mask', 'secure-mask',
    'file-bool', 'file-count', 'option', 'select', 'percentage'
]
export const CHOICE_TYPE_QUESTION = 'choice'
export const DISSOLUTION_TYPE_QUESTION = 'dissolution'
export const PERMISSION_TYPE_QUESTION ='permission'
export const CHECKBOX_SET_QUESTION ='checkbox-set'
export const FILE_SET_QUESTION ='file-set'
export const EXPENSE_TYPE_QUESTION = 'expenses'
export const STATE_FIELD_TYPE_QUESTION= 'state-fields'
export const EXPENSE_PROFIT_TYPE_QUESTION = 'expenses-profit'
export const EXPENSE_TOTAL_TYPE_QUESTION = 'expenses-total'

export const LIST_TYPE_QUESTION = 'questions-list'
export const LIST_SELECTABLE_TYPE_QUESTION = 'questions-list-selectable' 
export const SET_TYPE_QUESTION = 'questions-set'


export const VIEW_TYPES = ['expenses-profit', 'expenses-total', 'success'];

// QUESTION MODELS
export const BUSINESS_BALANCE_REVENUE_MODEL = 'business.balance_revenue'
export const BUSINESS_UPSELL_SALES_TAX_MODEL = 'business.upsell.sales_tax'
export const BUSINESS_UPSELL_ADVISORY = 'business.upsell.advisory';
export const OWNERSHIP_PERCENTAGE = 'share_percent';

export const EXCLUDE_QUESTION_TYPES = ['success']
