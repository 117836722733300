import { BusinessType } from '../models/vto-config.model'
import { useVtoContext } from '../providers/VtoProvider'
import { getValueByModelName } from './VtoUtils'

const GetBusinessGroup = (businessName: string, currentVtoConfig: any) => {
    const businessTypes = currentVtoConfig?.business_types as BusinessType[]
    return businessTypes.find((type: any) => type.name === businessName)?.group
}

const GetStateAbbr = (stateName: string, currentVtoConfig: any) => {
    const states = currentVtoConfig?.states
    return states?.find((state: any) => state.id === stateName)?.abbr
}

const ConfigStateFields = (state: string, businessType: string) => {
    const { currentVtoConfig } = useVtoContext()
    let stateAbbr: string | undefined = GetStateAbbr(state, currentVtoConfig)
    let businessTypeGroup = GetBusinessGroup(businessType, currentVtoConfig)
    let stateTaxIdConfig: any = currentVtoConfig?.year2023?.state_tax_id_fields

    return stateTaxIdConfig &&
        stateTaxIdConfig[stateAbbr as string] &&
        stateTaxIdConfig[stateAbbr as string][businessTypeGroup as string]
        ? stateTaxIdConfig[stateAbbr as string][businessTypeGroup as string]
        : []
}

export const getStateFields = (vtoData: any) => {
    let stateFields = ConfigStateFields(
        vtoData.business.form_state,
        vtoData.business.business_type
    )
    if (vtoData.business.is_multiple_registered_states) {
        vtoData.business.domestic_states.forEach((s: any) => {
            let configStateFields = ConfigStateFields(
                s.state,
                vtoData.business.business_type
            )
            stateFields = [...new Set([...stateFields, ...configStateFields])]
        })
    }
    return stateFields
}

const sumExpenses = function (expenses: any) {
    var sum = 0
    if (!expenses || typeof expenses.list === 'undefined') {
        return 0
    }
    for (var i in expenses.list) {
        var e = expenses.list[i]
        if (e.checked && typeof e.value !== 'undefined' && e.value) {
            sum += parseFloat(e.value)
        }
    }
    if (expenses.has_others) {
        for (var oi in expenses.others) {
            var oe = expenses.others[oi]
            if (oe.checked && typeof oe.value !== 'undefined' && oe.value) {
                sum += parseFloat(oe.value)
            }
        }
    }

    return sum
}

var isHasNoExpensesAllowed = function (expenses: any) {
    if (expenses) {
        if (expenses.has_others) {
            return false
        }
        for (var i in expenses.list) {
            var e = expenses.list[i]
            if (e.checked) {
                return false
            }
        }
    }
    return true
}

const profit = (question: any, data: any) => {   
    let revenue = getValueByModelName(question.options.revenue, data, question?.parentModel, question?.index)
    let expenses = getValueByModelName(question.options.expenses, data, question?.parentModel, question?.index)
    let profit =
        (typeof revenue === 'object' ? sumExpenses(revenue) : revenue) -
        (typeof expenses === 'object' ? sumExpenses(expenses) : expenses)
    return profit
}

var isHasSpouse = function (vtoPersonalInfoGeneral: any) {
    if (typeof vtoPersonalInfoGeneral === 'undefined') {
        return false
    }
    return (
        vtoPersonalInfoGeneral.filing_status === 'married_separately' ||
        vtoPersonalInfoGeneral.filing_status === 'married_jointly'
    )
}

var isChildDependant = (dependant: any) => {
    return (
        typeof dependant.relationship !== 'undefined' &&
        (dependant.relationship === 'son' ||
            dependant.relationship === 'daughter' ||
            dependant.relationship === 'foster' ||
            dependant.relationship === 'sister' ||
            dependant.relationship === 'grandchild' ||
            dependant.relationship === 'brother' ||
            dependant.relationship === 'niece' ||
            dependant.relationship === 'nephew')
    )
}

interface Person {
    [key: string]: string | undefined
}

const userName = (
    object: Person,
    placeholder: string,
    prefix: string,
    idx?: number
): string => {
    if (!object[`${prefix}first_name`] && !object[`${prefix}last_name`]) {
        return `${placeholder}${idx !== undefined ? idx + 1 : ''}`
    } else if (object[`${prefix}first_name`] && object[`${prefix}last_name`]) {
        return `${object[`${prefix}first_name`]} ${
            object[`${prefix}last_name`]
        }`
    } else {
        return (
            object[`${prefix}first_name`] || object[`${prefix}last_name`] || ''
        )
    }
}

const userNames = (
    list: Person[],
    placeholder: string,
    prefix: string
): string[] => {
    return list.map((object, idx) => userName(object, placeholder, prefix, idx))
}

interface VTO {
    personalInfo: {
        general: Person
    }
}

const me = (vto: VTO): string[] => {
    return [userName(vto.personalInfo.general, 'You', '')]
}

const meAndSpouse = (vto: VTO): string[] => {
    return [
        userName(vto.personalInfo.general, 'You', ''),
        userName(vto.personalInfo.general, 'Spouse', 'spouses_'),
    ]
}

const customList = (name: string, vto: any) => {
    let options = []

    if (name === 'Dependants') {
        if (vto.personalInfo.dependants_has_dependant) {
            options = vto.personalInfo.dependants.map(
                (dependant: any, i: number) => {
                    const dependantName = userName(
                        dependant,
                        `Dependent ${i + 1}`,
                        ''
                    )
                    return {
                        name: dependantName,
                        title: dependantName,
                        id: `dependant${i}`,
                    }
                }
            )
        }
    } else if (name === 'Childs') {
        if (vto.personalInfo.dependants_has_dependant) {
            options = vto.personalInfo.dependants
                .filter(isChildDependant)
                .map((dependant: any, i: number) => {
                    const dependantName = userName(
                        dependant,
                        `Dependent ${i + 1}`,
                        ''
                    )
                    return {
                        name: dependantName,
                        title: dependantName,
                        id: `dependant${i}`,
                    }
                })
        }
    } else if (name === 'MeAndSpouse') {
        const myName = userName(vto.personalInfo.general, 'You', '')
        options = [{ name: myName, title: myName, type: 'me' }]

        if (isHasSpouse(vto.personalInfo.general)) {
            const spouseName = userName(
                vto.personalInfo.general,
                'Spouse',
                'spouses_'
            )
            options.push({
                name: spouseName,
                title: spouseName,
                type: 'spouse',
            })
        }
    } else if (name === 'MeSpouseAndDependants') {
        const myName = userName(vto.personalInfo.general, 'You', '')
        options = [{ name: myName, title: myName, id: 'my' }]

        if (isHasSpouse(vto.personalInfo.general)) {
            const spouseName = userName(
                vto.personalInfo.general,
                'Spouse',
                'spouses_'
            )
            options.push({ name: spouseName, title: spouseName, id: 'spouse' })
        }

        if (vto.personalInfo.dependants_has_dependant) {
            vto.personalInfo.dependants
                .filter(isChildDependant)
                .forEach((dependant: any, i: number) => {
                    const dependantName = userName(
                        dependant,
                        `Dependent ${i + 1}`,
                        ''
                    )
                    options.push({
                        name: dependantName,
                        title: dependantName,
                        id: `dependant${i}`,
                    })
                })
        }
    }

    return options
}

export const VtoHelpers = {
    getBusinessGroup: GetBusinessGroup,
    getStateAbbr: GetStateAbbr,
    configStateFields: ConfigStateFields,
    getStateFields: getStateFields,
    getExpensesSum: sumExpenses,
    isHasNoExpensesAllowed,
    getProfitOrLoss: profit,
    userName: userName,
    userNames: userNames,
    me: me,
    meAndSpouse: meAndSpouse,
    customList: customList,
}
