import { useCallback, useEffect, useState } from 'react'
import { getDismissedPopups, getTasksByAccountant } from '../../../services/apiService/dashboard';
import { showError } from '../../../services/formService';
import { useCurrentStore } from '../../common/hooks/useCurrentStore';
import { DismissPopupsData, DISMISS_POPUP_NAME, Task, TaskData, TASK_STATUSES } from '../TaxEngagementPlan/TaxEngagementPlan'

interface EngagementPlanData{
    tasks: TaskData,
    popups: DismissPopupsData
}
export const useTaxEngagementPlan = () =>{
    const [taxEngagementData, setTaxEngagementData] = useState<TaskData>();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [totalTasksCompletedCount, setTotalTasksCompletedCount] = useState<number>(0);
    const [totalTasksCount, setTotalTasksCount] = useState(0);
    const [isShowDismissPopup, setIsShowDismissPopup] = useState(false);
    const [taskCompletionPercentage, setTaskCompletionPercentage] = useState<number>(0);
    const { currentAccountId } = useCurrentStore();

    const setCompletedTaskPercentage = (data: Task[]) => {
        let count = 0
        data.forEach((value: Task) => {
            if (value.status === TASK_STATUSES.COMPLETE) {
                count++
            }
        })

        let completionPercentage = Math.round((count / data.length) * 100)
        setTotalTasksCompletedCount(count)
        setTaskCompletionPercentage(completionPercentage)
    }

    const setTotalTasksCountValue = (tasks: Task[]) => {
        if (tasks.length) {
            setTotalTasksCount(tasks.length)
        }
    }
    const getDismissPopupStatus = useCallback(() =>{
        return getDismissedPopups(currentAccountId as string)
    },[currentAccountId])
    
    const getTasksForAccountant = useCallback(() => {
        return getTasksByAccountant(currentAccountId as string);
    },[currentAccountId])

    const checkIfDismissPopupToBeShown = (financialYear : number, popupData : DismissPopupsData) =>{
        const taxEngagemenPopupFlag = DISMISS_POPUP_NAME + '_' + financialYear;
        if(!popupData.pop_ups.includes(taxEngagemenPopupFlag)){
            setIsShowDismissPopup(true)
        }
    }
    const getTaxEngagementData = useCallback(() => {
        setIsDataLoading(true)
        const promise1 = getTasksForAccountant();
        const promise2 = getDismissPopupStatus();
        Promise.all([promise1, promise2]).then((res : unknown) =>{
            setIsDataLoading(false);
            const data = res as EngagementPlanData[];
            const engagementData = data[0] as unknown as TaskData;
            const popupData = data[1] as unknown as DismissPopupsData;
            setTaxEngagementData(engagementData);
            setCompletedTaskPercentage(engagementData.tasks);
            setTotalTasksCountValue(engagementData.tasks);
            checkIfDismissPopupToBeShown(engagementData.current_financial_year, popupData);
        })
        .catch((error) => {
            setIsDataLoading(false)
            showError(error?.statusText)
        })
    },[getDismissPopupStatus, getTasksForAccountant])
    
    useEffect(() => {
        getTaxEngagementData()
    }, [getTaxEngagementData])
    return {
        taxEngagementData,
        isDataLoading,
        totalTasksCompletedCount,
        totalTasksCount,
        isShowDismissPopup,
        taskCompletionPercentage,
        getTaxEngagementData
    }
}