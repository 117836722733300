import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    makeStyles,
    Theme,
    Typography,
    Hidden,
    IconButton,
} from '@material-ui/core';

import { connect, useDispatch } from 'react-redux';
import UiDialog from '../../common/ui/UiDialog';
import { Form, Formik, Field } from 'formik';
import DeleteIcon from '../../../assets/icons-svg/Delete.svg';
import UiFormField from '../../common/ui/UiFormField';
import Loader from '../../common/Loader';
import { ApplicationStore, Config } from '../../../models/store';
import Alert from '@material-ui/lab/Alert';
import UiFormControlSelection from '../../common/ui/UiFormControlSelection';
import AccountSelector from './AccountSelector';
import AmountField from './AmountField';
import CategorySelector from './CategorySelector';
import { isSplitValid } from '../../../utils/transactionUtil';
import moment from 'moment';
import {
    addTransaction,
    updateTransaction,
} from '../../../services/transactionsService';
import { formSubmitErrorHandler } from '../../../services/formService';
import { ITransaction } from '../../../models/transaction-models';
import CloseIcon from '@material-ui/icons/Close';
import Exclude from '../../../assets/icons-svg/ExcludeDark.svg';
import { ReviewDialog } from '../../common/ReviewDialog';
import { showAlert } from '../../../store/actions/feedback';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';
import { ThemeColors } from '../../../styles/models/Colors.interface';

interface NewTransactionModalProps {
    open: boolean;
    handleClose: (result?: any, action?: any) => void;
    config: Config;
    accountId: string;
    businessId: string;
    isEdit?: Boolean;
    editData?: any;
    selectedData?: any;
    deleteData?: any;
    accountList: any;
    selectedAccount?: any;
    refreshTransactions?: () => void;
    accountantMode: boolean;
    setUpdatedTransaction?: (transaction: ITransaction) => void;
    setExcludeSingleEntry?: (transaction: ITransaction) => void;
    isDisableDate? : boolean;
    isDisableAmount? : boolean
}

const initialFormValues = {
    date: moment.utc().hour(12).minute(0).second(0).unix(),
    amount: '0.00',
    description: '',
    customer_name: '',
    account: '',
    category: '',
    split: [],
};

const useStyle = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    marginVertical: {
        marginTop: 20,
        marginBottom: 20,
    },
    marginBottom: {
        marginBottom: 20,
    },
    reviewOrderButton: {
        '&:not(:disabled)': {
            backgroundColor: (colorTheme) => colorTheme.orange300,
            color: (colorTheme) => colorTheme.primaryWhite,
        },
    },
    autocomplete: {
        '& .MuiInputBase-fullWidth': {
            height: '40px',
            width: '222px',
            '& .MuiAutocomplete-input': {
                padding: '0px',
            },
        },
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        padding: '0.5rem 1rem 0rem 1rem',
        '& button:nth-child(1)': {
            marginRight: '10px',
            width: 57,
        },
        '& button:nth-child(2)': {
            marginRight: '10px',
            width: 'fit-content',
        },
    },
    seconderyActionButtonOutline: {
        height: 36,
        color: (colorTheme) => `${colorTheme.black100} !important`,
    },
    buttonGap: {
        marginRight: theme.spacing(2),
    },
    loader: {
        width: '113px',
        marginRight: '10px',
        '& .MuiCircularProgress-root': {
            width: '30px !important',
            height: '30px !important',
        },
    },
    title: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: (colorTheme) => colorTheme.grey400
    },
    formContainer: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
            height: 'calc(100vh - 105px)',
            overflowY: 'auto',
            maxHeight: '640px',
        },
    },
    moveRight: {
        textAlign: 'right',
    },
    rowContainer: {
        padding: '0.5rem 0rem 1rem 0rem',
        borderBottom: (colorTheme) => `1px solid  ${colorTheme.grey200}`,
    },
    detailContainer: {
        padding: '1rem',
    },
    closeItem: {
        padding: '12px 0px !important',
        cursor: 'pointer',
    },
    fieldContainer: {
        padding: '1rem 0rem',
    },
    splitTransactionBtn: {
        '& .MuiButton-startIcon': {
            transform: 'rotate(90deg)',
        },
    },
    topHeader: {
        display: 'flex',
        borderBottom: (colorTheme) => `1px solid  ${colorTheme.grey200}`,
        flexDirection: 'column',
        padding: '10px',
        '& .title': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '18px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            color: (colorTheme) => colorTheme.black100,
        },
    },
}));

function NewTransactionModal({
    open,
    handleClose,
    accountId,
    businessId,
    isEdit = false,
    selectedData,
    deleteData,
    accountList,
    selectedAccount,
    refreshTransactions,
    setUpdatedTransaction,
    setExcludeSingleEntry,
    accountantMode,
    isDisableDate,
    isDisableAmount
}: NewTransactionModalProps) {
    const useContainerStyles = makeStyles((theme: Theme) => ({
        container: {
            '& .MuiPaper-root': {
                height: 816,
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: '100%',
                    maxHeight: '100%',
                    top: accountantMode ? '40px' : '0px',
                    margin: '0px',
                    '& .MuiDialogTitle-root': {
                        display: 'none',
                    },
                },
                '& .MuiDialogContent-root': {
                    padding: '8px 0px',
                    '& .top-section': {
                        padding: '0px 24px',
                        height: '672px',
                        overflowY: 'auto',
                        '& .alert': {
                            height: '36px',
                            width: 'inherit',
                            marginBottom: theme.spacing(2),
                            [theme.breakpoints.down('sm')]: {
                                height: 'fit-content',
                            },
                        },
                        '& .delete-section': {
                            display: 'flex',
                        },
                        [theme.breakpoints.down('sm')]: {
                            height: '100%',
                            maxHeight: '100%',
                            padding: '0px',
                        },
                    },
                    [theme.breakpoints.down('sm')]: {
                        padding: '0px 0px',
                    },
                },
            },
        },
    }));
    const { colorTheme } = useThemeContext()
    const styles = useStyle(colorTheme);
    const dispatch = useDispatch();
    const containerStyle = useContainerStyles();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [initialValues, setinitialValues] = useState(initialFormValues);
    const [reviewFlag, setReviewFlag] = useState(false);
    useEffect(() => {
        if (isEdit && open) {
            const initValues = {
                category: '',
                ...JSON.parse(JSON.stringify(selectedData)),
                date: selectedData.date,
            };
            setinitialValues({ ...initValues });
        } else {
            setinitialValues({
                ...initialFormValues,
                account: selectedAccount?.account,
            });
        }
    }, [isEdit, open]);
    const checkReviewModal = (data: any) => {
        setReviewFlag(data.show_review_widget);
    };
    const onSubmit = (formik: any, { setStatus, setFieldError }: any) => {
        if (formik.split.length && !isSplitValid(formik.split, formik)) {
            setStatus({
                error: 'Transaction Split Amount should be equal Original Amount',
            });
            return;
        }

        if (formik.split.length) {
            formik.split.pop();

            if (formik.split.length === 1) {
                formik.category = formik.split[0].category;
                formik.split = [];
            }
        }
        setFormSubmitted(true);

        if (isEdit) {
            formSubmitErrorHandler(
                updateTransaction(
                    accountId,
                    businessId,
                    selectedData.id,
                    formik
                ).then((res) => {
                    setFormSubmitted(false);
                    setUpdatedTransaction?.(res as ITransaction);
                    refreshTransactions?.();
                    handleClose();
                }),
                () => {
                    setFormSubmitted(false);
                },
                setFieldError
            );
        } else {
            formSubmitErrorHandler(
                addTransaction(formik, accountId, businessId).then((res) => {
                    setFormSubmitted(false);
                    refreshTransactions?.();
                    handleClose();
                    checkReviewModal(res);
                }),
                () => {
                    setFormSubmitted(false);
                },
                setFieldError
            );
        }
    };

const showCantDoAlertMsg = (action: string) => {
    const msg = `It's not possible to ${action} transaction with reconciled status. Please undo reconciliation in order to ${action} it.`;
    dispatch (
        showAlert ({
            alertType: 'error',
            alertText: msg
        })
    );
}
const checkAndExclude = (selectedData: ITransaction) => {
    if(selectedData.reconciliation) {
        showCantDoAlertMsg('exclude');
    }
    else {
        //@ts-ignore
        setExcludeSingleEntry(selectedData);
    }
    
}

const checkAndDelete = (selectedData: ITransaction) => {
    if(selectedData.reconciliation) {
        showCantDoAlertMsg('delete');
    }
    else { //@ts-ignore
      if (selectedData?.id)
        deleteData?.deleteHandleOption(selectedData);
    }
}
    return (
        <>
            {reviewFlag && <ReviewDialog />}{' '}
            <UiDialog
                open={open}
                handleClose={handleClose}
                title={
                    isEdit
                        ? `Edit Transaction #${selectedData?.number}`
                        : 'New Transaction'
                }
                size='sm'
                customRootClass={containerStyle.container}
            >
                <Box>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                    >
                        {(formik) => {
                            return (
                                <Form>
                                    <Box className='top-section'>
                                        <Hidden mdUp>
                                            <Box className={styles.topHeader}>
                                                <Box
                                                    display='flex'
                                                    alignItems='center'
                                                >
                                                    <Box>
                                                        <IconButton
                                                            aria-label='close'
                                                            color='inherit'
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Box>
                                                    <Box className='title'>
                                                        {isEdit
                                                            ? `Edit Transaction #${selectedData?.number}`
                                                            : 'New Transaction'}
                                                    </Box>
                                                </Box>
                                                <Box
                                                    display='grid'
                                                    gridTemplateColumns='repeat(2,1fr)'
                                                    gridGap='15px'
                                                >
                                                    <Button
                                                        variant='outlined'
                                                        color='secondary'
                                                        fullWidth
                                                        onClick={handleClose}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    {formSubmitted ? (
                                                        <Box
                                                            className={
                                                                styles.loader
                                                            }
                                                        >
                                                            <Loader />
                                                        </Box>
                                                    ) : (
                                                        <Button
                                                            variant='contained'
                                                            fullWidth
                                                            color='primary'
                                                            type='submit'
                                                            disabled={
                                                                !formik.isValid ||
                                                                formSubmitted
                                                            }
                                                            onClick={() => {
                                                                formik.submitForm();
                                                            }}
                                                        >
                                                            {isEdit
                                                                ? 'Save Changes'
                                                                : 'Create Transaction'}
                                                        </Button>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Hidden>
                                        <Box className={styles.formContainer}>
                                            <Grid
                                                container
                                                justify='space-between'
                                            >
                                                <Grid item>
                                                    <Typography variant='h6'>
                                                        Details
                                                    </Typography>
                                                </Grid>
                                                {isEdit &&
                                                    !(
                                                        (selectedData?.is_aggregated ||
                                                        selectedData?.is_plaid) &&
                                                            selectedData?.reconciliation
                                                    ) && (
                                                        <Grid
                                                            item
                                                            className='delete-section'
                                                        >
                                                            {!(selectedData?.is_aggregated || selectedData?.is_plaid) &&
                                                            selectedData?.id ? (
                                                                <Button
                                                                    className={
                                                                        styles.seconderyActionButtonOutline
                                                                    }
                                                                    classes={{
                                                                        root: styles.buttonGap,
                                                                    }}
                                                                    variant='outlined'
                                                                    startIcon={
                                                                        <img
                                                                            alt={`Delete`}
                                                                            src={
                                                                                DeleteIcon
                                                                            }
                                                                        />
                                                                    }
                                                                    onClick={() => {
                                                                        checkAndDelete(selectedData);
                                                                    }}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            ) : null}
                                                            {!selectedData?.excluded &&
                                                            (selectedData?.is_aggregated || selectedData?.is_plaid) &&
                                                            !selectedData?.reconciliation ? (
                                                                <Button
                                                                    className={
                                                                        styles.seconderyActionButtonOutline
                                                                    }
                                                                    classes={{
                                                                        root: styles.buttonGap,
                                                                    }}
                                                                    variant='outlined'
                                                                    startIcon={
                                                                        <img
                                                                            alt={`Eclude`}
                                                                            src={
                                                                                Exclude
                                                                            }
                                                                        />
                                                                    }
                                                                    onClick={() => {
                                                                        checkAndExclude(selectedData);
                                                                    }}
                                                                >
                                                                    Exclude
                                                                </Button>
                                                            ) : null}
                                                        </Grid>
                                                    )}
                                            </Grid>
                                            {formik.status?.error && (
                                                <Alert
                                                    className='alert'
                                                    severity='warning'
                                                    onClose={() => {
                                                        formik.setStatus({
                                                            error: '',
                                                        });
                                                    }}
                                                >
                                                    {formik.status?.error}
                                                </Alert>
                                            )}

                                            <Grid item xs={12} md={6}>
                                                <Box
                                                    className={
                                                        styles.fieldContainer
                                                    }
                                                >
                                                    <UiFormControlSelection
                                                        label='Select Date'
                                                        type='date'
                                                        fieldName='date'
                                                        dateType='string'
                                                        showFloatingLabel={true}
                                                        disabled={isDisableDate}
                                                    ></UiFormControlSelection>
                                                </Box>
                                            </Grid>
                                            <Grid container spacing={0}>
                                                <AccountSelector
                                                    accountList={accountList}
                                                    currentAccountId={
                                                        selectedAccount?.account
                                                    }
                                                    disabled={true}
                                                />
                                            </Grid>
                                            {isEdit &&
                                            !selectedData?.is_manual &&
                                            formik.values.split.length ? (
                                                ''
                                            ) : (
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <Box marginY={3}>
                                                            <Typography variant='subtitle1'>
                                                                Amount
                                                                (Required)
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Field
                                                            component={
                                                                AmountField
                                                            }
                                                            name='amount'
                                                            fieldname='amount'
                                                            disabled={
                                                                !(
                                                                    !isEdit ||
                                                                    selectedData?.is_manual ||
                                                                    (accountantMode && selectedData?.is_initial)
                                                                ) || isDisableAmount
                                                            }
                                                            value={
                                                                formik.values
                                                                    .amount
                                                            }
                                                            validate={(
                                                                value: any
                                                            ) =>
                                                                !(
                                                                    accountantMode &&
                                                                    !selectedData?.is_manual &&
                                                                    selectedData?.is_initial
                                                                )
                                                                &&
                                                                ( 
                                                                    !value ||
                                                                    Number.parseFloat(
                                                                        value
                                                                    )  === 0 
                                                                )
                                                                    ? 'Amount is required'
                                                                    : ''
                                                            }
                                                        ></Field>
                                                    </Grid>
                                                </Grid>
                                            )}

                                            <Box marginY={3}>
                                                <UiFormField
                                                    fastField={false}
                                                    type='text'
                                                    labelSize={0}
                                                    label={
                                                        isEdit
                                                            ? parseFloat(
                                                                  formik.values
                                                                      .amount
                                                              ) < 0
                                                                ? 'Payee (Optional)'
                                                                : 'Customer (Optional)'
                                                            : 'Customer (Optional)'
                                                    }
                                                    showFloatingLabel={true}
                                                    fieldName='customer_name'
                                                />
                                            </Box>

                                            <UiFormField
                                                type='textarea'
                                                labelSize={0}
                                                // placeholder='Write details of the journal entry here.'
                                                label='Description (Optional)'
                                                showFloatingLabel={true}
                                                fieldName='description'
                                            />

                                            <Box>
                                                <CategorySelector
                                                    accountId={accountId}
                                                    businessId={businessId}
                                                    isEdit={
                                                        isEdit ? true : false
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Hidden smDown>
                                        <Box className={styles.footer}>
                                            <Button
                                                variant='outlined'
                                                color='secondary'
                                                fullWidth
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>
                                            {formSubmitted ? (
                                                <Box className={styles.loader}>
                                                    <Loader />
                                                </Box>
                                            ) : (
                                                <Button
                                                    variant='contained'
                                                    fullWidth
                                                    color='primary'
                                                    type='submit'
                                                    disabled={
                                                        !formik.isValid ||
                                                        formSubmitted
                                                    }
                                                    onClick={() => {
                                                        formik.submitForm();
                                                    }}
                                                >
                                                    {isEdit
                                                        ? 'Save Changes'
                                                        : 'Create Transaction'}
                                                </Button>
                                            )}
                                        </Box>
                                    </Hidden>
                                </Form>
                            );
                        }}
                    </Formik>
                </Box>
            </UiDialog>
        </>
    );
}
const mapStateToProps = ({ config, appData }: ApplicationStore) => ({
    config,
    accountId: appData.current_account_id,
    businessId: appData.current_business_id,
    accountantMode: appData.user.accountant_mode,
});
export default connect(mapStateToProps)(NewTransactionModal);
