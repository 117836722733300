import moment from 'moment'
import truncate from 'lodash/truncate'
import {
    hasSpecificPermission,
    isDissolutionQuestionVisible,
} from './VtoQuestionUtils'
import { expeditePage } from '../models/vto.const'
import { FAQ, FAQsList } from '../models/VtoDetails.model'
import { isEqual, omit } from 'lodash'
import store from '../../../../store'
import { hasPermission } from '../../../../services/aclService'
import {
    DEFAULT_FORM_VALUES,
    DEFAULT_SOLE_PROPRIETORSHIPS_VALUES,
    NORMALIZE_SOLE_PROPRIETORSHIPS_FIELDS,
    NORMALIZE_VTO_FIELDS,
} from '../core/components/TaxPrepStatuses/constant'
import { normalizeURL } from '../core/components/TaxPrepStatuses/TaxprepStatusUtils'
import { getFromLocalStorage } from '../../../../services/localStorageService'

const FILE_QUESTION_TYPES = [
    'file-bool',
    'file-count',
    'file-set',
    'file-collector',
]
const FILE_SET_QUESTION = 'file-set'
const ALREADY_VISITED = 'already_visited'

export const IgnoreValidationForZero = [
    'business.balance_revenue',
    'business.balance_returns',
    // Skip zero validation for 'vehicles -> add automobiles fields
    'price',
    'upfront_lease_price',
    'lease_price',
    'fuel_oil_service_cost',
    'insurance_registration_cost',
    'repair_replacement_cost',
    'care_maintenance_cost',
    'other_expenses',
    'forms_count.business_1099MISC',
    // 'vehicles -> add automobiles fields ends here.
    // rental activities -> rent received
    'rent_amount',
]

/**
 * @returns the value of attribute(modelName) from the VtoApiConfig
 * @param VtoDetails - currentVtoDetails
 * @param modelName - i.e - `personalInfo.business.name`
 */
//TODO - remove any type
export const getValueByModelName = (
    modelName: string,
    vtoDetails: any,
    parentModel?: string,
    index?: string | number
) => {
    let value = vtoDetails
    if (parentModel && index !== undefined) {
        const parentKeys = parentModel.split('.')
        for (const key of parentKeys) {
            if (value && value.hasOwnProperty(key)) {
                value = value[key]
            } else {
                return undefined
            }
        }
        if (Array.isArray(value)) {
            value = value[Number(index)]
        }
    }
    if (!modelName) {
        return value
    }

    const keys = modelName.split('.')
    for (const k of keys) {
        if (value && value.hasOwnProperty(k)) {
            value = value[k]
        } else {
            return undefined // return undefined if the key is not found
        }
    }
    return value
}

/**
 * @param values - possible values (i.e values - [LLC] )
 * of the model (`personalInfo.business.type`) in array
 * @param modelValue - value of `personalInfo.business.type`
 * @returns boolean
 * Check if the value of the attribute is matched with
 * the possible values
 */

export const hasModelValue = (possibleValues: any[], modelValue: any) => {
    // special case handling -- [[]] === [] should return true
    if (Array.isArray(possibleValues) && Array.isArray(modelValue)) {
        if (
            possibleValues.length === 1 &&
            possibleValues[0].length === 0 &&
            modelValue.length === 0
        ) {
            return true
        }
    }
    return possibleValues?.includes(modelValue)
}

/**
 *
 * @param upsell
 * @param data
 * @returns boolean
 */
export const hasUpsellConditionMatched = (upsell: any, data: any): boolean => {
    if (upsell) {
        let upsellConditions = upsell.show_condition
        for (let i in upsellConditions) {
            if (upsellConditions[i].type === 'permission') {
                return hasSpecificPermission(upsellConditions[i])
            } else if (upsellConditions[i].type === 'dissolution') {
                return isDissolutionQuestionVisible(data)
            } else if (upsellConditions[i].model && upsellConditions[i].value) {
                let upsellValues = upsellConditions[i].value
                let modelValue = getValueByModelName(
                    upsellConditions[i].model,
                    data
                )
                return (
                    modelValue === upsellValues[0] ||
                    upsellValues.includes(modelValue)
                )
            } else if (upsellConditions[i].model && upsellConditions[i].min) {
                let modelValue = getValueByModelName(
                    upsellConditions[i].model,
                    data
                )
                let upsellValues = upsellConditions[i].min
                return modelValue > upsellValues
            } else {
                return true
            }
        }
    }
    return true
}

/**
 * Compares values with the model given condition, returns if matched
 * @param condition - show_condition / disable_condition
 * @param data - currentVtoDetails
 * @param parent -
 * @returns boolean
 *
 */
export const hasConditionMatched = (
    condition: any,
    data: any,
    parent?: any
): boolean => {
    if (condition) {
        if (Array.isArray(condition)) {
            for (let i in condition) {
                if (!hasConditionMatched(condition[i], data, parent)) {
                    return false
                }
            }
            return true
        } else {
            let model = getValueByModelName(condition.model, data)
            if (parent && typeof model === 'undefined') {
                model = getValueByModelName(condition.model, parent)
            }

            if (condition.filter_condition) {
                model = model.filter((item: any) => {
                    const filterCondition = condition.filter_condition
                    if (filterCondition.values) {
                        return (
                            filterCondition.values.indexOf(
                                item[filterCondition.model]
                            ) !== -1
                        )
                    } else if (filterCondition.not_values) {
                        return (
                            filterCondition.values.indexOf(
                                item[filterCondition.model]
                            ) === -1
                        )
                    } else {
                        return true
                    }
                })
            }

            if (condition?.values || condition?.value) {
                return hasModelValue(
                    condition.values || condition?.value,
                    model
                )
            } else {
                return !hasModelValue(condition.not_values, model)
            }
        }
    }
    return true
}

/**
 * Prepare condition for the questions
 */
export const collectConditions = (conditions: any[], question: any) => {
    if (question.show_condition) {
        conditions.push(question.show_condition)
    }
    if (question.disable_condition) {
        conditions.push(question.disable_condition)
    }
    return conditions
}

/**
 *
 */
export const collectFilesConditions = (
    section: any,
    files: any,
    otherConditions: any
) => {
    section.questions.forEach((question: any) => {
        let conditions: any = []
        collectConditions(conditions, question)
        if (FILE_QUESTION_TYPES.includes(question.type)) {
            if (question.type === FILE_SET_QUESTION) {
                for (let i in question.files) {
                    files[question.files[i].model] =
                        otherConditions.concat(conditions)
                }
            } else {
                files[question.model] = otherConditions.concat(conditions)
            }
        } else if (question.questions) {
            collectFilesConditions(
                question,
                files,
                otherConditions.concat(conditions)
            )
        } else {
        }
    })
}

/**
 * @returns object with file name as key and conditions as value
 * contains form names with conditions to be checked
 *
 * `forms_count._1099_misc_form`: [{model: 'business.has_issued_1099_misc_form', value: [true]}],
 * forms_count.balance_sheet`: [{model: 'business.business.has_bookkeeping', value: [false]}]
 * ....
 *
 */
export const buildFileConditions = (vtoDetails: any, vtoMetaData: any) => {
    let steps = vtoMetaData.Steps[vtoDetails?.type ? 'business' : 'personal']
    let files = {}
    steps.forEach((step: any) => {
        step.sections.forEach((sec: any) => {
            let conditions: any = []
            const stepConditions = collectConditions(conditions, step)
            let section = vtoMetaData?.formattedSections[sec]
            if (section && section.questions) {
                let sectionCondition = collectConditions(
                    stepConditions,
                    section
                )
                collectFilesConditions(section, files, sectionCondition)
            }
        })
    })
    return files
}

/**
 * eg. filename: `mileage_log_personal_sole`
 * @returns boolean / check if fileIsRequired
 */
export const isFileAllowed = (
    filename: string,
    vtoDetails: any,
    vtoConfig: any,
    parent?: any
) => {
    let cashKey =
        '_' + (vtoDetails.type ? 'business' : 'personal') + 'filesConditions'
    if (!vtoConfig[cashKey]) {
        vtoConfig[cashKey] = buildFileConditions(vtoDetails, vtoConfig)
    }

    if (!vtoConfig[cashKey]['forms_count.' + filename]) {
        return true
    } else {
        return hasConditionMatched(
            vtoConfig[cashKey]['forms_count.' + filename],
            vtoDetails,
            parent
        )
    }
}

export const isStepRequired = (step: any, data: any, parent: any) => {
    return step.show_condition
        ? hasConditionMatched(step.show_condition, data, parent)
        : true
}

export const findPermissionBasedOnUpsellQues = (type: any, que: string) => {
    switch (que) {
        case 'annual_report':
            return 'ANNUAL_REPORT'
        case 'sales_tax':
            return 'SALES_AND_USE_TAX'
        case 'dissolution':
            return 'ARTICLES_DISSOLUTION'
        case 'operating_agreement':
            return 'OPERATING_AGREEMENT'
        case 'quarterly_estimated':
            return 'ESTIMATED_TAXES'
        case 'personal_finance':
            return 'FINANCIAL_PLANNING'
        case 'audit_defense':
            return type ? 'AUDIT_BUSINESS' : 'AUDIT_PERSONAL'
        default:
            return ''
    }
}

export const makeAnnualFrequency = (priceBook: any) => {
    if (priceBook.billing_frequency === 'Monthly') {
        return parseFloat(priceBook.first_charge_amount) * 12
    }
    return parseFloat(priceBook.first_charge_amount)
}

/**
 * The salesTax and annualReport arrays are picked from the upsell question show conditions.
 * To Do -> make it dynamic
 */

const salesTax = (): string[] => {
    return [
        'AP',
        'AU',
        'CF',
        'DE',
        'EC',
        'EN',
        'FB',
        'FL',
        'HB',
        'HO',
        'RC',
        'RT',
        'SM',
        'SH',
        'SI',
        'TL',
        'VS',
    ]
}
const annualReport = (): string[] => {
    return [
        'AL',
        'AK',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'FL',
        'GA',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',
        'JO',
    ]
}

export const checkParentQuestion = (
    upsell: string,
    vtoData: any,
    vtoConfig: any,
    isBusinessVTO?: boolean
) => {
    switch (upsell) {
        case 'annual_report':
            return annualReport().includes(
                getValueByModelName('business.address.state', vtoData)
            )
        case 'sales_tax':
            return salesTax().includes(
                getValueByModelName('business.industry', vtoData)
            )
        case 'dissolution':
            return getValueByModelName('business.is_final_return', vtoData)
        case 'operating_agreement':
            return getValueByModelName(
                'business.is_ownership_percentage_changed',
                vtoData
            )
        case 'quarterly_estimated':
            return getValueByModelName(
                'additionalQuestions.has_estimated_tax_payments',
                vtoData
            )
        case 'personal_finance':
            return getValueByModelName(
                'personalInfo.dependants_has_dependant',
                vtoData
            )
        case 'dedicated_bookkeeping':
            return (
                getValueByModelName('business.has_bookkeeping', vtoData) ===
                false
            )
        case 'payroll':
            return getValueByModelName('business.has_employees', vtoData)
        case 'audit_defense':
            if (isBusinessVTO) {
                const haveStatement =
                    getValueByModelName('forms_count.balance_profit', vtoData) >
                    0
                const greaterThan75K =
                    getValueByModelName('business.balance_revenue', vtoData) >
                    75000
                const hasBookkeeping =
                    getValueByModelName('business.has_bookkeeping', vtoData) ===
                    false
                return !haveStatement && greaterThan75K && hasBookkeeping
            }
            return (
                getValueByModelName('forms_count.irs_corespondence', vtoData) >
                0
            )
        case 'advisory':
            return true
    }
}

// step -> vtoStep
export const getStepModel = (step: any) => {
    const stepModel = step?.model
    const stepIndex = step?.name?.split('_$_')[1]
    return { stepModel, stepIndex }
}

export const isBalanceSheetSection = (section: any) => {
    return section.name === 'business_balance'
}

const importantDates = {
    february28: moment().month(1).date(28).endOf('day'),
    march31: moment().month(2).endOf('month').endOf('day'),
    april30: moment().month(3).date(30).endOf('day'),
    march1: moment().month(2).date(1).startOf('day'),
    march14: moment().month(2).date(14).endOf('day'),
    march15: moment().month(2).date(15).endOf('day'),
    april1: moment().month(3).date(1).startOf('day'),
    april12: moment().month(3).date(12).endOf('day'),
    april13: moment().month(3).date(13).endOf('day'),
    april15: moment().month(3).date(15).endOf('day'),
    may1: moment().month(4).date(1).startOf('day'),
    may13: moment().month(4).date(13).endOf('day'),
    may14: moment().month(4).date(14).endOf('day'),
    yearStart: moment().startOf('year'), // January 1st
    yearEnd: moment().endOf('year'), // December 31st
}

const businessTypes = {
    sCorp: ['S', 'LLC_S', 'partnership', 'LLC_Partnership'],
    cCorp: ['C', 'LLC_C'],
    nonProfit: ['NP'],
    otherCcorp: ['C', 'LLC_C', 'sole', 'LLC_Partnership'],
}

const isBusinessType = (type: string, typesArray: string[]) =>
    typesArray.includes(type)

const shouldShowForBusinessType = (
    businessType: string,
    industry: string,
    start: moment.Moment,
    end: moment.Moment
): boolean => {
    const today = moment()
    return (
        (isBusinessType(businessType, businessTypes.sCorp) &&
            today.isBetween(start, end, null, '[]')) ||
        (isBusinessType(businessType, businessTypes.cCorp) &&
            today.isBetween(start, end, null, '[]')) ||
        (isBusinessType(industry, businessTypes.nonProfit) &&
            today.isBetween(start, end, null, '[]'))
    )
}

export const expediteFirstDeadline = (
    businessType: string,
    industry: string
) => {
    const today = moment()
    let hasToShow = true // Default option

    if (
        (isBusinessType(businessType, businessTypes.sCorp) &&
            today.isAfter(importantDates.february28)) ||
        (isBusinessType(businessType, businessTypes.cCorp) &&
            today.isAfter(importantDates.march31)) ||
        (isBusinessType(industry, businessTypes.nonProfit) &&
            today.isAfter(importantDates.april30))
    ) {
        hasToShow = false
    }

    return {
        expediteOption: 1,
        hasToShow,
    }
}

export const expediteSecondDeadline = (
    businessType: string,
    industry: string
) => {
    let hasToShow = false // Default option

    if (
        shouldShowForBusinessType(
            businessType,
            industry,
            importantDates.march1,
            importantDates.march15
        ) ||
        shouldShowForBusinessType(
            businessType,
            industry,
            importantDates.april1,
            importantDates.april13
        ) ||
        shouldShowForBusinessType(
            businessType,
            industry,
            importantDates.may1,
            importantDates.may14
        )
    ) {
        hasToShow = true
    }

    return {
        expediteOption: 2,
        hasToShow,
    }
}

export const discountCriteria = (isBusiness: boolean, businessType: string) => {
    const today = moment()
    /**
     * Defult value should be
     * haveDiscount = false
     * howMuch = 1
     * 1 == No discount
     * 0.8 === 20% discount
     */
    let haveDiscount = true

    if (isBusiness) {
        const discountCriteria = [
            { type: businessTypes.sCorp, date: importantDates.march15 },
            { type: businessTypes.otherCcorp, date: importantDates.april15 },
        ]

        const hasDiscount = discountCriteria.some(
            ({ type, date }) =>
                type.includes(businessType) && today.isBefore(date)
        )
        if (hasDiscount) {
            haveDiscount = true
        }
    } else {
        if (today.isBefore(importantDates.april15)) {
            haveDiscount = true
        }
    }
    return {
        haveDiscount,
    }
}

export const handleExpeditePage = (
    businessType: string,
    industry: string,
    isExpediteSelecteInIntroPage: boolean
) => {
    const today = moment()
    let expediteOption = expeditePage.SELECTED // Default option

    if (!isExpediteSelecteInIntroPage) {
        if (businessTypes.sCorp.includes(businessType)) {
            expediteOption = today.isBetween(
                importantDates.yearStart,
                importantDates.march14,
                null,
                '[]'
            )
                ? expeditePage.OPTION
                : expeditePage.NOT_AVAILABLE
        } else if (businessTypes.cCorp.includes(businessType)) {
            expediteOption = today.isBetween(
                importantDates.yearStart,
                importantDates.april12,
                null,
                '[]'
            )
                ? expeditePage.OPTION
                : expeditePage.NOT_AVAILABLE
        } else if (businessTypes.nonProfit.includes(industry)) {
            expediteOption = today.isBetween(
                importantDates.yearStart,
                importantDates.may13,
                null,
                '[]'
            )
                ? expeditePage.OPTION
                : expeditePage.NOT_AVAILABLE
        }
    }
    return expediteOption
}

export function collectFaqs(
    questions: any,
    faqList: FAQsList,
    stateFields: any
) {
    const result: FAQ[] = []
    function traverse(questionArray: any) {
        questionArray.forEach((question: any) => {
            if (question.options && question.options.faq) {
                const faqs = faqList[question.options.faq.configKey]
                if (faqs) {
                    result.push(...faqs)
                }
            }

            if (question.type === 'state-fields') {
                stateFields.forEach((state: any) => {
                    const faqs = faqList[state.options?.faq?.configKey]
                    if (faqs) {
                        result.push(...faqs)
                    }
                })
            }

            if (question.questions && Array.isArray(question.questions)) {
                traverse(question.questions)
            }
        })
    }

    traverse(questions)

    return result
}

export const getTheDiscountedPrice = (
    originPrice: number,
    productkey: string,
    currentVtoYearConfig: any
) => {
    const howMuchDiscount = Number(
        currentVtoYearConfig?.product_discounts[productkey] ?? 0
    )
    return originPrice - (originPrice * howMuchDiscount) / 100
}

export const createMap = (obj: any, parentKey = '', result: any = {}) => {
    for (let key in obj) {
        // Skip inherited properties
        if (!obj.hasOwnProperty(key)) continue

        // Create new key by combining parentKey and current key
        const newKey = parentKey ? `${parentKey}.${key}` : key

        // If the current value is an object, and not null, recursively call the function
        if (
            typeof obj[key] === 'object' &&
            obj[key] !== null &&
            !Array.isArray(obj[key])
        ) {
            createMap(obj[key], newKey, result)
        }
        // If it's an array, handle each array item
        else if (Array.isArray(obj[key])) {
            obj[key].forEach((item, index) => {
                // Recursively process each array item if it's an object
                if (typeof item === 'object' && item !== null) {
                    createMap(item, `${newKey}.${index}`, result)
                } else {
                    result[`${newKey}[${index}]`] = item
                    // check here from where data is coming
                }
            })
        }
        // If it's a primitive value, just add it to the result map
        else {
            result[newKey] = obj[key]
        }
    }

    return result
}

const PREFILLED_ARRAY_NAME = [
    'user_answered_questions',
    'admin_answered_questions',
    'default_answered_questions',
]

/**
 * create map creates the keys like this ->
 * ex.  default_answered_question.business.has_irs_s_corp_approval_letter
 * This method will eleminate the first key from the nested key and check if the key exists in the object
 * i.e -> business.has_irs_s_corp_approval_letter & we can directly check if the key exists in the object or not.
 * Three types of keys are there.
 * i.e user_answered_questions/default_answered_questions/admin_answered_questions
 */
const checkNestedKeyAndRemoveFirst = (
    vto: any,
    key: string,
    isPrefilled = false
) => {
    const keys = isPrefilled ? key.split('.') : key.split('.').slice(1)
    const modifiedKey = keys.join('.')

    let current = vto
    for (const k of keys) {
        if (
            current[k] === undefined ||
            current[k] === null ||
            current[k] === ''
        ) {
            // Key path doesn't exist
            return { exists: false, modifiedKey }
        }
        current = current[k]
    }

    // Key path exists
    return { exists: true, modifiedKey }
}

export const createDataOriginMap = (keyObject: any, vto: any) => {
    const dataOrigin: any = {}

    // Helper function to update `dataOrigin` based on key origin type
    const updateDataOrigin = (
        origin: string,
        key: string,
        isPrefilled = false
    ) => {
        const { exists, modifiedKey } = checkNestedKeyAndRemoveFirst(
            vto,
            key,
            isPrefilled
        )

        // If the key exists, set the origin type in `dataOrigin`
        if (exists && dataOrigin[modifiedKey] === undefined) {
            dataOrigin[modifiedKey] = origin
        }
    }

    Object.keys(keyObject).forEach((key: string) => {
        const firstKey = key.split('.')[0]

        // Determine the origin based on the prefix and update `dataOrigin`
        if (PREFILLED_ARRAY_NAME.includes(firstKey)) {
            switch (firstKey) {
                case 'user_answered_questions':
                    updateDataOrigin('user', key)
                    break
                case 'admin_answered_questions':
                    updateDataOrigin('admin', key)
                    break
                case 'default_questions':
                    updateDataOrigin('default', key)
                    break
            }
        } else {
            // if the key doesn't exist in any of these three
            if (
                !vto.user_answered_questions?.[key] &&
                !vto.admin_answered_questions?.[key] &&
                !Object.keys(vto.default_questions).includes(key)
            ) {
                updateDataOrigin('prefilled', key, true)
            }
            if (Object.keys(vto.default_questions).includes(key)) {
                updateDataOrigin('default', key, true)
            }
        }
    })

    return dataOrigin
}

export function truncateText(fileName: string, maxLength = 75) {
    const dotIndex = fileName.lastIndexOf('.')

    if (dotIndex === -1 || dotIndex === 0) {
        return truncate(fileName, { length: maxLength })
    }

    const name = fileName.slice(0, dotIndex)
    const extension = fileName.slice(dotIndex)

    if (fileName.length <= maxLength) {
        return fileName
    }

    const truncatedNameLength = maxLength - extension.length

    const truncatedName = truncate(name, { length: truncatedNameLength })

    return `${truncatedName}${extension}`
}

export const areAllValuesNull = (arg: any): boolean => {
    if (Array.isArray(arg)) {
        // If the argument is an array, check all elements recursively
        return arg.every((item) => areAllValuesNull(item))
    }

    if (typeof arg === 'object' && arg !== null) {
        // If the argument is an object, check all values recursively
        return Object.values(arg).every((value) => areAllValuesNull(value))
    }

    // For non-object, non-array values, return true if the value is null
    return arg === null
}

const normalizeProprietorships = (initialValues: any, values: any) => {
    const initialProprietorshipsValues = initialValues.soleProprietorships
    const currentProprietorshipsValues = values.soleProprietorships

    for (let i = 0; i < initialProprietorshipsValues.length; i++) {
        for (let j = 0; j < NORMALIZE_SOLE_PROPRIETORSHIPS_FIELDS.length; j++) {
            const field = NORMALIZE_SOLE_PROPRIETORSHIPS_FIELDS[j]
            const initialVal = getValueByModelName(
                field,
                initialProprietorshipsValues[i]
            )
            const currentVal = getValueByModelName(
                field,
                currentProprietorshipsValues[i]
            )
            const defaultVal = DEFAULT_SOLE_PROPRIETORSHIPS_VALUES[field]

            const nullValues = areAllValuesNull(currentVal)
            const currentValueSameAsDefaultValue = isEqual(
                currentVal,
                defaultVal
            )

            if (typeof initialVal === 'undefined') {
                values = {
                    ...values,
                    soleProprietorships: values.soleProprietorships.map(
                        (item: any, index: number) =>
                            index === i ? omit(item, field) : item
                    ),
                }
            } else if (initialVal === null && currentVal === defaultVal) {
                values = {
                    ...values,
                    soleProprietorships: values.soleProprietorships.map(
                        (item: any, index: number) =>
                            index === i
                                ? updateNestedValue({ ...item }, field, null)
                                : item
                    ),
                }
            } else if (
                initialVal?.length === 0 &&
                (nullValues || currentValueSameAsDefaultValue)
            ) {
                values = {
                    ...values,
                    soleProprietorships: values.soleProprietorships.map(
                        (item: any, index: number) =>
                            index === i
                                ? updateNestedValue({ ...item }, field, [])
                                : item
                    ),
                }
            }
        }
    }
    return values
}

const normalize = (initialValues: any, values: any) => {
    // converting object with null properties or default value to []
    // [{amount: null, description:null}] --> [];
    for (let i = 0; i < NORMALIZE_VTO_FIELDS.length; i++) {
        const field = NORMALIZE_VTO_FIELDS[i]
        const initialVal = getValueByModelName(field, initialValues)
        const currentVal = getValueByModelName(field, values)
        const defaultVal = DEFAULT_FORM_VALUES[field]

        const nullValues = areAllValuesNull(currentVal)
        const currentValueSameAsDefaultValue = isEqual(currentVal, defaultVal)

        if (field === 'soleProprietorships') {
            values = normalizeProprietorships(initialValues, values)
        } else if (initialVal === null && currentVal === defaultVal) {
            values = updateNestedValue({ ...values }, field, null)
        } else if (
            initialVal?.length === 0 &&
            (nullValues || currentValueSameAsDefaultValue)
        ) {
            values = updateNestedValue({ ...values }, field, [])
        }
    }

    return values
}

function updateNestedValue(obj: any, path: string, value: any) {
    const keys = path.split('.') // Split the path into individual keys
    const lastKey = keys.pop() // Extract the last key
    let current = obj

    // Traverse the object to the correct nested level
    for (const key of keys) {
        if (!current[key] || typeof current[key] !== 'object') {
            current[key] = {} // Create an object if the path doesn't exist
        }
        current = current[key]
    }

    // Update the target key
    current[lastKey!] = value
    return obj
}

const removePropertiesToIgnore = (object: any) => {
    return {
        ...object,
        business: omit(object.business, 'balance_net_revenue'),
        soleProprietorships: object.soleProprietorships.map(
            ({ balance_net_revenue, forms_count, ...rest }: any) => ({
                ...rest,
                forms_count: omit(
                    rest.forms_count,
                    'state_driver_license_no_proof_personal'
                ),
            })
        ),
        uploads: omit(object.uploads, 'upload_type'),
        forms_count: omit(object.forms_count, [
            'state_driver_license_no_proof_personal',
            'state_driver_license_no_proof_business',
        ]),
    }
}

export const isVTOFormDirty = (initialValues: any, currentValues: any) => {
    return !isEqual(
        removePropertiesToIgnore(initialValues)!,
        removePropertiesToIgnore(normalize(initialValues, currentValues))
    )
}

export const hasExpeditedPermission = (isBusinessVTO: boolean) => {
    const currentStore = store.getState()
    const personalAccount = currentStore.appData.personal_account
    const currentAccount = currentStore.appData.current_account
    return hasPermission(
        isBusinessVTO ? 'EXPEDITED_BUSINESS' : 'EXPEDITED_PERSONAL',
        isBusinessVTO ? currentAccount : personalAccount
    )
}

export const downloadFile = (downloadURL: string) => {
    let newURL = normalizeURL(downloadURL)
    newURL = newURL.replace('http:', 'https:')
    const link = document.createElement('a')
    link.setAttribute('id', 'download-link')
    link.target = '_blank'
    link.href = `${newURL}?access_token=${getFromLocalStorage('access_token')}`
    link.click()
    let downloadLink = document.getElementById('download-link')
    downloadLink && downloadLink?.parentNode?.removeChild(downloadLink)
}

export const getAlreadyVisitedData = (personalId: string): boolean => {
    return localStorage.getItem(ALREADY_VISITED) === personalId
}

export const setAlreadyVisitedData = (personalId: string) => {
    localStorage.setItem(ALREADY_VISITED, `${personalId}`)
}