import React, { useState } from 'react';
import UiDialog from '../../../common/ui/UiDialog';
import {
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { Location } from '../../../../models/locations';
import LocationForm from '../forms/LocationForm';
import Loader from '../../../common/Loader';
import { ApplicationStore } from '../../../../models';
import { connect } from 'react-redux';
interface LocationModalProps {
    open: boolean;
    handleClose: (result?: any) => void;
    location?: Location | null;
    setSelectedLocation?: React.Dispatch<Location | undefined>;
    loadLocations?: () => void;
    formikRef?: any;
    setLocationUpdated?: React.Dispatch<boolean>;
    locationCallback?: any;
    isFormTouched?: (result?: any) => void;
    onlyForOneTrip?: boolean
    appData:any;
}

const useStyles = makeStyles(() => ({
    root: {
        marginTop: '0.75rem',
    },
    customPaper: {
        height: '100%',
        width: '100%',
        margin: '0rem',
        maxHeight: '100%',
    },
    maxDesktopView: {
        height: '100%',
    },
    t40:{
        top: '40px'
    }
}));

function LocationModal({
    open,
    handleClose,
    location,
    setSelectedLocation,
    loadLocations,
    formikRef,
    setLocationUpdated,
    locationCallback,
    isFormTouched,
    onlyForOneTrip,
    appData
}: LocationModalProps) {

    const styles = useStyles();
    const theme = useTheme();
    // isMobileDevice
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const [loading, setLoading] = useState(false);
    const { user } = appData;

    return (
        <UiDialog
            open={open}
            handleClose={handleClose}
            title={` ${location ? 'Edit' : 'Create New'} Location`}
            size="sm"
            hideTitleSection={isXsDown}
            customDialogStyles={
                isXsDown
                    ? { paper: `${styles.customPaper} ${user?.accountant_mode ? styles.t40 : ''}` }
                    : { paper: styles.maxDesktopView }
            }
        >
            <div style={{ height: '100%' }}>{ 
                loading 
                ? 
                    <Loader /> 
                : 
                    <LocationForm
                        location={location}
                        handleClose={handleClose}
                        setSelectedLocation={setSelectedLocation}
                        loadLocations={loadLocations}
                        formikRef={formikRef}
                        setLocationUpdated={setLocationUpdated}
                        locationCallback={locationCallback}
                        setLoading={setLoading}
                        isFormTouched={isFormTouched}
                        onlyForOneTrip={onlyForOneTrip}
                    />
                }
            </div>
        </UiDialog>
    );
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
});

export default connect(mapStateToProps)(LocationModal);
