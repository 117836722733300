import { Hidden, IconButton, makeStyles } from '@material-ui/core'
import qs from 'qs'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { showError } from '../../../../../../services/formService'
import { uncategorizedTransactionImport } from '../../../../../../services/vto/vtoCore'
import Loader from '../../../../../common/Loader'
import UiDialog from '../../../../../common/ui/UiDialog'
import UiText from '../../../../../common/ui/UiText'
import { useVtoContext } from '../../../providers/VtoProvider'
import { UncategorizedTransactionPage } from '../../../models/vto.detail-info-model'
import Icon from '../../../../../common/Icon'
import UiButton from '../../../../../common/ui/UiButton'
import CloseIcon from '@material-ui/icons/Close'
import { toRem16 } from '../../../../../../styles/commonStyles'

const useStyles = makeStyles((theme) => ({
    dialogRoot: {
        maxWidth: `${toRem16(480)} !important`,
    },
    modalContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        paddingTop: toRem16(4),
        paddingBottom: toRem16(16),
    },
    iconContainer: {
        marginBottom: '1.5rem',
    },
    icon: {
        fontSize: '2rem',
        color: theme.palette.success.main,
    },
    mb16: {
        marginBottom: '1rem',
    },
    mb24: {
        marginBottom: '1.5rem',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '1rem 0',
        gap: '1rem',
        alignItems: 'flex-start',
    },
    successContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    txnsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    closeIcon: {
        marginLeft: 'auto',
        padding: 0,
    },
    heightAuto: {
        height: 'auto !important',
    },
}))

function VTOUncategorizedTransactionModal({
    open,
    handleClose,
}: {
    open: boolean
    handleClose: () => void
}) {
    const classes = useStyles()
    const [status, setStatus] = useState<UncategorizedTransactionPage | null>(
        null
    )
    const [unCatTransCount, setUnCatTransCount] = useState(0)
    const [loading, setLoading] = useState<boolean>(false)
    const { importBalanceSheetDetails, currentVtoDetails } = useVtoContext()
    const { selectedYear } = useVtoContext()
    const history = useHistory()
    const bookkeepingDataRef = useRef(
        currentVtoDetails?.business?.is_bookkeeping_data_imported
    )

    const isBookkeepingDataImported = bookkeepingDataRef.current

    useEffect(() => {
        open &&
            !isBookkeepingDataImported &&
            fetchUncategorizedTransactionData()
    }, [open, selectedYear])

    const fetchUncategorizedTransactionData = async () => {
        try {
            setLoading(true)
            const result: any = await uncategorizedTransactionImport(
                selectedYear
            )
            setUnCatTransCount(result.itemsCount)
            if (result.itemsCount === 0) {
                await importBalanceSheetDetails()
                setStatus(UncategorizedTransactionPage.SUCCESS_PAGE)
            } else if (result.itemsCount > 0 && result.itemsCount < 25) {
                setStatus(
                    UncategorizedTransactionPage.FEW_UNCATEGORIZED_TRANSACTIONS
                )
            } else {
                setStatus(
                    UncategorizedTransactionPage.MANY_UNCATEGORIZED_TRANSACTIONS
                )
            }
        } catch (error) {
            showError('Error fetching uncategorized transactions')
        } finally {
            setLoading(false)
        }
    }

    const jsonToQueryString = (json: any) => {
        return qs.stringify(json)
    }

    const goToUncatTrans = () => {
        const startDate = new Date(selectedYear, 0, 1)
        const offset = startDate.getTimezoneOffset()
        const startDateTS = (startDate.getTime() - offset * 60 * 1000) / 1000
        const endDate = new Date(selectedYear, 11, 31)
        const endDateTS = (endDate.getTime() - offset * 60 * 1000) / 1000

        const rqlData = jsonToQueryString({
            from_date: startDateTS,
            to_date: endDateTS,
            period: 'custom',
            type: 'uncategorized',
        })
        const encodeURL = encodeURIComponent(rqlData)

        handleClose()

        window.open(
            `${window.origin}/portal/#/transactions?search=${encodeURL}`,
            '_blank'
        )

    }

    const handleCloseSuccessModal = () => {
        bookkeepingDataRef.current =
            currentVtoDetails?.business?.is_bookkeeping_data_imported
        handleClose()
    }

    const isSuccess = status === UncategorizedTransactionPage.SUCCESS_PAGE
    const isFewTxns =
        status === UncategorizedTransactionPage.FEW_UNCATEGORIZED_TRANSACTIONS
    const isManyTxns =
        status === UncategorizedTransactionPage.MANY_UNCATEGORIZED_TRANSACTIONS

    return (
        <UiDialog
            onExited={() => setStatus(null)}
            open={open}
            title="Import Transactions"
            handleClose={handleClose}
            size="sm"
            customDialogStyles={{ paper: classes.dialogRoot }}
            hideTitleSection
        >
            <div className={classes.modalContainer}>
                {loading && <Loader />}
                {!loading && (
                    <>
                        {isSuccess && (
                            <div className={classes.successContainer}>
                                <IconButton
                                    className={classes.closeIcon}
                                    edge="start"
                                    onClick={handleCloseSuccessModal}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <div className={classes.iconContainer}>
                                    <Icon size="80px" icon="checkCircleSmall" />
                                </div>
                                <UiText
                                    weight="bold_700"
                                    variant="truck_175"
                                    className={classes.mb16}
                                >
                                    Success!
                                </UiText>
                                {isBookkeepingDataImported ? (
                                    <UiText className={classes.mb16}>
                                        Your tax organizer has been updated{' '}
                                        <Hidden xsDown>
                                            <br />
                                        </Hidden>
                                        with your latest bookkeeping data.
                                    </UiText>
                                ) : (
                                    <UiText className={classes.mb16}>
                                        Your bookkeeping data has been imported
                                        from{' '}
                                        <Hidden xsDown>
                                            <br />
                                        </Hidden>
                                        ClientBooks.
                                    </UiText>
                                )}
                                <div>
                                    <UiButton
                                        handleClick={handleCloseSuccessModal}
                                        btnType="tertiary"
                                        customLabel={
                                            <UiText weight="semi_bold_600">
                                                Continue
                                            </UiText>
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        {(isFewTxns || isManyTxns) && (
                            <div className={classes.txnsContainer}>
                                <UiText
                                    variant="suv_150"
                                    weight="semi_bold_600"
                                    className={classes.mb24}
                                >
                                    You Have Uncategorized Transactions
                                </UiText>
                                <UiText className={classes.mb16}>
                                    It looks like you have {unCatTransCount}{' '}
                                    uncategorized transactions. Speed up your
                                    tax preparation by categorizing them now!
                                </UiText>
                                <UiText>
                                    Once categorized, you can return here to
                                    import your bookkeeping data and finish your
                                    filing.
                                </UiText>
                                <div className={classes.buttonContainer}>
                                    <UiButton
                                        handleClick={goToUncatTrans}
                                        btnType="tertiary"
                                        customLabel={
                                            <UiText weight="semi_bold_600">
                                                View Uncategorized Transactions
                                            </UiText>
                                        }
                                    />

                                    {isFewTxns && (
                                        <UiButton
                                            handleClick={handleClose}
                                            btnType="secondary"
                                            customLabel={
                                                <UiText weight="semi_bold_600">
                                                    Resume Tax Preparation
                                                </UiText>
                                            }
                                        />
                                    )}
                                </div>
                                <UiText
                                    textColor="secondary"
                                    variant="motorcycle_90"
                                >
                                    You won’t be able to import your bookkeeping
                                    data from ClientBooks until your
                                    transactions are categorized.
                                </UiText>
                            </div>
                        )}

                        {isBookkeepingDataImported && !status && (
                            <div className={classes.txnsContainer}>
                                <UiText
                                    variant="suv_150"
                                    weight="semi_bold_600"
                                    className={classes.mb24}
                                >
                                    Do you want to update your ClientBooks
                                    bookkeeping data?
                                </UiText>
                                <UiText className={classes.mb16}>
                                    This will overwrite any bookkeeping data you
                                    have already added to the Tax Organizer.
                                </UiText>
                                <UiText>This cannot be undone.</UiText>
                                <div className={classes.buttonContainer}>
                                    <UiButton
                                        handleClick={
                                            fetchUncategorizedTransactionData
                                        }
                                        btnType="tertiary"
                                        customLabel={
                                            <UiText weight="semi_bold_600">
                                                Update Bookkeeping Data
                                            </UiText>
                                        }
                                    />

                                    <UiButton
                                        handleClick={handleClose}
                                        btnType="secondary"
                                        customLabel={
                                            <UiText weight="semi_bold_600">
                                                Cancel
                                            </UiText>
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </UiDialog>
    )
}

export default VTOUncategorizedTransactionModal
