import SectionQuestionsRenderer from '../../sections/SectionQuestionsRenderer'

const HomeOffice = ({ section }: any) => {
    return (
        <>
            <SectionQuestionsRenderer section={section} />
        </>
    )
}

export default HomeOffice
