import { CancelToken } from 'axios'
import {
    FileRemoveParams,
    FileUploadParams,
} from '../../components/taxes/vto/models/vto-api-params'
import store from '../../store'
import { getResource, postResource, putResource } from '../axiosService'
import { API } from '../constants/api.const'


const getStoreData = () => {
    const currentStore = store.getState()
    const businessId = currentStore.appData.current_business_id
    const accountId = currentStore.appData.current_account_id
    return {
        businessId,
        accountId,
    }
}

// Upload document
// API_URL: `api/account/{accountId}/vto{year}/vto/{id}/upload`
export const uploadFileToVto = (year: number, vtoId: string, formData: any) => {
    const { accountId } = getStoreData()
    const API_URL = `${API.VTO.UPLOAD.UPLOAD}`
        .replace(':accountId', accountId)
        .replace(':vtoYear', `vto${year}`)
        .replace(':id', vtoId)
    return postResource(API_URL, formData)
}

/**
 * Remove uploaded file from document center
 * API_URL: `api/account/{accountId}/vto{year}/vto/{id}/unlink_file`
 */
export const removeUploadedFile = (
    year: number,
    vtoId: string,
    uploadParams: FileRemoveParams
) => {
    const { accountId } = getStoreData()
    const API_URL = `${API.VTO.UPLOAD.DELETE}`
        .replace(':accountId', accountId)
        .replace(':vtoYear', `vto${year}`)
        .replace(':id', vtoId)
    return postResource(API_URL, uploadParams)
}
/**
 * import file
 * API_URL: `api/account/{accountId}/vto{year}/vto/{id}/link_files`
 */
export const importFile = (
    year: number,
    vtoId: string,
    uploadParams: FileUploadParams
) => {
    const { accountId } = getStoreData()
    const API_URL = `${API.VTO.UPLOAD.IMPORT}`
        .replace(':accountId', accountId)
        .replace(':vtoYear', `vto${year}`)
        .replace(':id', vtoId)
    return putResource(API_URL, uploadParams)
}

export function startOCR(year: number, vtoId: string, params: any): Promise<any> {
    const { accountId } = getStoreData()
    const API_URL = `${API.VTO.OCR.START_SCAN}`
        .replace(':accountId', accountId)
        .replace(':vtoYear', `vto${year}`)
        .replace(':id', vtoId)
        .replace(':fileId', params.fileId)
    return getResource(API_URL)
}

export function getOCRProgress(year: number, vtoId: string): Promise<any> {
    const { accountId } = getStoreData()
    const API_URL = `${API.VTO.OCR.GET_PROGRESS}`
        .replace(':accountId', accountId)
        .replace(':vtoYear', `vto${year}`)
        .replace(':id', vtoId)
    return getResource(API_URL)
}