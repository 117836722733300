import { useMediaQuery, Theme } from '@material-ui/core'

const useDeviceSize = () => {
    const isMobileDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'), { noSsr: true} 
    )
    
    const isDesktop = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up('sm'), { noSsr: true} 
    )

    const isXLDesktop = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up('xl'), { noSsr: true} 
    )

    const isSmDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'), { noSsr: true}
    )

    const isXsDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'), { noSsr: true} 
    )

    return {
        isMobileDevice,
        isDesktop,
        isSmDevice,
        isXsDevice,
        isXLDesktop
    }
}

export default useDeviceSize
