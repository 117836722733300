import {
    getValueByModelName,
    hasConditionMatched,
    isFileAllowed,
} from '../../common/VtoUtils'
import {
    ConfigFiles,
    UPLOAD_TYPES,
} from '../../models/vto-config.model'
import { FileDetails, FileGroupName, FileInfo } from '../../models/vto.document-model'
import { MULTI_STEP } from '../../models/vto.const'
import { VtoDetails } from '../../models/vto.detail-info-model'
import { isEmpty } from '../../../../../utils/appUtil'

export const acceptedFormat = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
    'text/csv',
]

export const NOT_ACCEPTING_CSVS_FILE_TYPES = [
    'us_passport_proof_personal',
    'state_driver_license_no_proof_personal',
    'id_issued_by_state_or_local_government_proof_personal',
    'us_passport_proof_business',
    'foreign_passport_proof_business',
    'state_driver_license_no_proof_business',
    'id_issued_by_state_or_local_government_proof_business'
];


export const ALLOWED_FILE_EXTENSIONS: string[] = [
    '.pdf', '.png', '.jpeg', '.jpg',
];

export const ALLOWED_MIME_TYPES: string[] = [
    'application/pdf', 'image/png', 'image/jpeg',
]

export const ALLOWED_FILE_TYPES = {
    'csv': 'text/csv',
    'png': 'image/png',
    'jpeg': 'image/jpeg',
    'jpg': 'image/jpeg',
    'pdf': 'application/pdf'
};

export const ALLOWED_NON_CSV_FILE_TYPES = {
    'png': 'image/png',
    'jpeg': 'image/jpeg',
    'jpg': 'image/jpeg',
    'pdf': 'application/pdf'
};

export const isValidFile = (file: any, allowCSV: boolean = true) => {
    // Allowed file extensions and their corresponding MIME types
    const allowedTypes: any =  allowCSV ? ALLOWED_FILE_TYPES : ALLOWED_NON_CSV_FILE_TYPES 
    
    // Extract the file extension
    const fileExtension = file.name.split('.').pop().toLowerCase();
    
    // Get the file's MIME type
    const mimeType = file.type.toLowerCase();
    
    // Check if both the file extension and MIME type are valid
    return allowedTypes?.[fileExtension] === mimeType;
}

export const FORM_GROUP_MAPPING: any = {
    '1095': '1095 Forms',
    '1098': '1098 Forms',
    '1099': '1099 Forms',
    w2: 'W2 Forms',
    other: 'Other Forms and Documents',
    'identificationsOption': 'Identification Upload Options'
}

const OWNERS_UIN_FILE_MAPPING: any = {
    'id_issued_by_state_or_local_government': 'id_issued_by_state_or_local_government_proof_business',
    'us_passport': 'us_passport_proof_business',
    'foreign_passport': 'foreign_passport_proof_business',
    'state_drivers_license': 'state_driver_license_no_proof_business',
}

/**
 * fileName: ex: `social_1099SSA`, `_1099_misc_form`, `unemployment_1099G`
 * fileType: ex: `1099`, `w2`, `other`
 */

/**
 * @param
 * vtoDetails -> vtoDetails 
 * vtoMetaData -> currentYearMetaData
 */
export const getFileUploadCount = (vtoDetails: any, vtoMetaData: any) => {
    let sum = 0
    for (let i in vtoDetails.forms_count) {
        let cnt = getFileCountByName(i, vtoDetails, vtoMetaData)
        if (cnt === true) {
            sum += 1
        } else if (cnt === '*') {
            sum += 0
        } else {
            sum += parseInt(cnt as string, 10) || 0
        }
    }
    return sum
}

/**
 * This function returns the count for the owners file data in the business info step
 * - IDENTIFICATION UPLOAD OPTIONS section
 * @param name - name of the file
 * @param vtoDetails - vto details
 */
export const businessInfoStepUpload = (name: any, vtoDetails: any): number => {
    let count: number = 0
    const subSteps = getValueByModelName('business.owners', vtoDetails);
    subSteps.forEach((subStep: any) => {
        if (subStep.forms_count && subStep.forms_count[name]) {
            let uinData = getValueByModelName('owner_uin', subStep);
            let fileName = OWNERS_UIN_FILE_MAPPING?.[uinData]
            if(fileName === name) {
                count += subStep.forms_count[fileName];
            }
        }    
    })
    return count
    
}

export const getFileCountByName = (
    name: string,
    vtoDetails: any,
    vtoMetaData: any //currentYearMetaData
): boolean | number | string => {
    let count: any = filterFileCount(name, vtoDetails, vtoMetaData)
    //Sole counters
    let steps = vtoMetaData?.Steps?.[vtoDetails.type ? 'business' : 'personal']
    steps.forEach((step: any) => {
        if (step.type === MULTI_STEP) {
            let subSteps = getValueByModelName(step.model, vtoDetails)
            if(subSteps?.length > 0) {
                subSteps.forEach((subStep: any) => {
                    if (subStep?.forms_count && subStep?.forms_count?.[name]) {
                        if (isFileAllowed(name, subStep, vtoMetaData, vtoDetails)) {
                            count += subStep.forms_count[name]
                        }
                    }
                })
            }
        }
        if(step.name === 'business_info') {
            count += businessInfoStepUpload(name, vtoDetails)
        }
    })
    return count
}

const filterFileCount = (name: string, vtoDetails: any, vtoMetaData: any) => {
    var cnt = vtoDetails.forms_count[name]
    if (cnt && !isFileAllowed(name, vtoDetails, vtoMetaData)) {
        cnt = 0
    }
    return cnt === '*' ? '*' : cnt === true ? 1 : parseInt(cnt, 10) || 0
}

/**
 * @returns Object with file type and count
 * ex: 
 * @param
 * vtoDetails: - current year vto details
 * vtoConfig API - > vtoConfig -> `vto2023`: {}
 * @return ex - {
 *  '1099_form': 0, 'form': 1, 'other': 1
 * }
 * 
 */
export const getCountByFileType = (vtoDetails: VtoDetails, currentYearVtoConfig: any) => {
    let fileTypeCount: any = {}
    Object.keys(vtoDetails.forms_count).forEach((fileName: string) => {
        const count = vtoDetails.forms_count[fileName as keyof typeof vtoDetails.forms_count]
        if (count) {
            const configFile = currentYearVtoConfig?.files?.[fileName]
            const typeCount = fileTypeCount?.[configFile?.type] ?? 0

            fileTypeCount = {
                ...fileTypeCount,
                [configFile.type]: count + typeCount,
            }
        }
    })

    return fileTypeCount
}

/**
 * @returns No of file uploaded by name
 * @param fileName - social_1099SSA`, `_1099_misc_form`, `unemployment_1099G`
 * @param vtoDetails - vto details
 */
export const getUploadedFileCountByName = (fileName: string, vtoDetails: any) =>  !!vtoDetails.uploads[fileName] ? vtoDetails.uploads[fileName].length : 0;

/**
 * 
 * @returns Object with file type and count
 *  ex: 
 *  fileCount: null
    fileName: "state_driver_license_no_proof_business"
    form: true
    title: "Driver’s License (Business Info)" (name of file)
    type: "identificationsOption" (type of available file types)
 */

export const getRequiredFiles = (
    vtoDetails: VtoDetails,
    vtoMetaData: any
) : {
    filesCountData: FileInfo,
    requiredFilesCount: number
}=> {
    
    let filesCountData: any = {}
    if (isEmpty(vtoDetails)) {
        return { filesCountData, requiredFilesCount: 0 }
    }
    const fileConditions = vtoMetaData?.FileConditions
    Object.keys(vtoDetails.forms_count).forEach((fileName: string) => {
        let showFile = false
        let fileCountByName: any = 0
        const formCount =
            vtoDetails.forms_count[
                fileName as keyof typeof vtoDetails.forms_count
            ]
        if (formCount) {
            const showCondition = fileConditions['forms_count.' + fileName]
            showFile = hasConditionMatched(showCondition, vtoDetails)
        }
        else {
            /**
             * sometimes form_count is not increased. so fileCount is not available
             * so explicitly checking the file count with model check
             */
            fileCountByName = getFileCountByName(fileName, vtoDetails, vtoMetaData)

            if(fileCountByName) {
                showFile = true
            }
        }
        // config file data - for: "", form: "Birth/Adoption certificate", step: "General Info", type: "other"
        const configFile: any = vtoMetaData?.files?.[fileName]
        // form title - Additional Questions (Sole Proprietorship)
        const title = getFileName(configFile, vtoDetails.type)
        if (showFile) {
            const configFilesArr =
                filesCountData?.[configFile?.type]?.files ?? []
            const typeCount =
                filesCountData?.[configFile?.type]?.totalFiles ?? 0
            const updatedFiles = [
                ...configFilesArr,
                {
                    fileName: fileName,
                    fileCount: formCount ? formCount : fileCountByName,
                    title: title,
                    type: configFile?.type,
                    form: !!configFile?.form,
                },
            ]

            filesCountData = {
                ...filesCountData,
                [configFile.type]: {
                    groupName: FORM_GROUP_MAPPING[configFile?.type],
                    groupType: configFile.type,
                    totalFiles: (formCount ? formCount : fileCountByName) + typeCount,
                    files: updatedFiles,
                },
            }
        }
    })

    const requiredFilesCount = getRemainingFileUploadCount(filesCountData, vtoDetails)

    // for mail or fax uploads mark requiredFilesCount as 0
    if(vtoDetails?.uploads?.upload_type === UPLOAD_TYPES.OUTSIDE &&
        vtoDetails?.uploads?.has_upload_promise) {
        return { filesCountData, requiredFilesCount: 0 }
    }
    return { filesCountData, requiredFilesCount}
}

export const getFileName = (currentFile: ConfigFiles, vtoType: number) => {

    // const section  = (${
    //     isSoleStep && vtoType
    //         ? 'Additional Questions'
    //         : isSoleStep && vtoType
    //         ? isSoleStep
    //         : currentFile?.step
    // })
    //const isSoleStep = currentFile?.step === VTO_STEPS.SOLE_PROPRIETORSHIP
    const isForm = currentFile?.form
    const title = currentFile?.title


    return `${isForm ? isForm : title ? title : ''}${
        currentFile?.for ? ' for ' + currentFile?.for : ''
    }`
}


/**
 * @param fileType - 1099_form, form, other
 * @param vtoDetails - current year vto details
 * @param vtoConfig - current year vto config
 * @param MetaData - current year meta data
 */
// used in section utils
export const getFileCountByFileType = (fileType: any, vtoDetails: any, vtoMetaData: any, checkUploaded= true) => {
    let count = 0;
    let countOptional = 0;
    const files = vtoMetaData.files
    for(let fileName in files){
        let file = files[fileName];
        if (file.type === fileType){
            let fileCount: any = getFileCountByName(fileName, vtoDetails, vtoMetaData);
            if (checkUploaded){
                if (parseInt(getUploadedFileCountByName(fileName, vtoDetails), 10) < parseInt(fileCount, 10)){
                    return false;
                }
            }
            if (fileCount === '*'){
                countOptional++;
            }else{
                count += fileCount;
            }
        }
    }

    return countOptional ? ''+count+(countOptional ? '+':'') : count;
}


export function uploadedFileCount(fileName: string, vtoDetails: any){
    return vtoDetails?.uploads?.[fileName]?.length
}
/**
 * Remaining file upload counts.
 * 
 * @param ex- fileGroups - {
 * 'identificationsOption': {
 *  files: [{
 *          fileCount:1
 *               fileName:"us_passport_proof_business"
 *               form:true
 *               title:"Passport (Business Info)"
 *              type: "identificationsOption"}  
 *      }],
 *  groupName: "Identification Upload Options",
 *  groupType: "identificationsOption",
 *  totalFiles: 1
 *  }
 */
export const getRemainingFileUploadCount = (fileGroups: FileInfo, vtoDetails: any) => {
    if(Object.keys(fileGroups).length === 0) {
        return 0
    }
    let requiredCount = 0;
    let uploadedFilesCount = 0;
    
    for (const fileGroupKey in fileGroups) {
        if (fileGroups.hasOwnProperty(fileGroupKey)) {
            const groupDetails = fileGroups[fileGroupKey as FileGroupName];
            const files: FileDetails[] = groupDetails?.files;

            requiredCount += groupDetails.totalFiles;
            
            if (vtoDetails?.uploads?.[`no_${fileGroupKey}_files`]) {
                uploadedFilesCount += groupDetails.totalFiles;
                continue;
            }

            for (const file in files) {
                const currentFile = files[file];
                const currentFileUploads = vtoDetails?.uploads?.[currentFile.fileName] || []
                uploadedFilesCount += Math.min(currentFile.fileCount, currentFileUploads.length);
            }
        }
    }
    //returns count of remaining files to upload
    return requiredCount - uploadedFilesCount
}