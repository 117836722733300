import { DeductionsInfo } from './vto-deduction-info'
import { IncomeInfo } from './vto-income-info.model'
import { VtoBusinessDetails } from './vto.business-model'
import { DocumentType, UploadInfo } from './vto.document-model'
export interface VtoDetails {
    accounting_process: any
    additionalQuestions: AdditionalQuestions
    additional_information_request_notification: boolean
    additional_information_request_text: null | string
    assist_next_year: AssistNextYearInfo
    business: VtoBusinessDetails
    business_bank_sync_message: string | null
    can_delete: boolean
    check_business_bank_sync: boolean
    deductions: DeductionsInfo
    estimated_completion: null | string
    enquire_current_life_insurance: any | null
    federal_tax: any | null
    form_8879_received_at: any | null
    forms_count: { [key in DocumentType]: null | number }
    submitDetails: SubmitDetails
    tax_preparation_current_step: TaxPrepCurrentStep
    tax_preparation_status: string
    has_files_pdf: boolean
    has_upsells: boolean
    id: string
    type: number
    income: IncomeInfo
    ocr: OCRInfo
    personalInfo: any
    portal_account: PortalAccount
    products: ProductInfo
    sales_source: any | null
    setup_retirement_plan_small_business: any | null
    show_review_widget: boolean
    soleProprietorships: any[]
    start_date: string 
    state_taxes: any | null
    status: number
    submission_date: any | null
    timezone: null | string | number
    title: string
    user_modified: boolean
    watched_video: boolean
    tax_ap_id: any | null
    tax_ap_status: string
    tax_ap_task_type: any | null
    tax_preparation_completed_steps: string[]
    uploads: UploadInfo
}

export interface SubmitDetails {
    additional_info: string | null
    consent_disclosed_by: string | null
    consent_disclosed_date: string | null
    contact_phone: string
    contact_time: string
    is_consent_disclosed: boolean
    submit_confirm: string | null
    uncompleted: boolean
}

export interface TaxPrepCurrentStep {
    hint: string
    title: string
    is_significant: boolean
}

export interface PortalAccount {
    id: string
    name: string
    sf_account_id: string
    in_salesforce: boolean
    customer_number: string
    account_status: string
}

export interface ProductInfo {
    has_no_products: any | null
    has_purchased_bundle: boolean
    purchased_products: any[]
    selected_products: any[]
}

export interface AdditionalQuestions {
    direct_deposit_bank_account: string | null
    direct_deposit_bank_account_confirm: string | null
    direct_deposit_bank_name: string | null
    direct_deposit_bank_routing: string | null
    direct_deposit_bank_type: string | null
    estimated_tax_payments: any[]
    foreign_assets_description: string | null
    has_direct_deposit: boolean | null
    has_estimated_tax_payments: boolean | null
    has_foreign_assets: boolean | null
    irs_correspondence_description: string | null
}

export interface OCRInfo {
    data: any
    files: any[]
    form_types: string
    message: null | string
    progress: number
    started_time: number | null | string
    status: string
}

export interface AssistNextYearInfo {
    business_funding: any
    life_insurance: any
    network_access: any
    not_needed: any
    other: any
    other_description: any
}

export enum UncategorizedTransactionPage {
    FEW_UNCATEGORIZED_TRANSACTIONS = 'FEW_UNCATEGORIZED_TRANSACTIONS',
    MANY_UNCATEGORIZED_TRANSACTIONS = 'MANY_UNCATEGORIZED_TRANSACTIONS',
    SUCCESS_PAGE = 'UNCATEGORIZED_SUCCESS_PAGE',
}