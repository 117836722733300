import { hasPermission } from '../../../../services/aclService'
import { productPurchaseOptions } from '../models/vto-question.model'
import { VtoHelpers } from './VtoHelpers'
import { hasDefaultValue, isFilledAnswer } from './VtoQuestionUtils'
import { getValueByModelName } from './VtoUtils'

/**
 * Based on the config Name (i.e -> customList or other configName) and vtoConfig, it will return the array from VTO config
 * @param propOptions
 * @param vtoConfig
 * @param values // curentVtoDetails
 */
export const getDropdownOptions = (
    propOptions: any,
    vtoConfig: any,
    values: any
) => {
    if (propOptions?.configName && vtoConfig) {
        return getValueByModelName(propOptions.configName, vtoConfig)
    } else if (propOptions?.customList && values) {
        return VtoHelpers.customList(propOptions?.customList, values)
    }
    return []
}

/**
 * Account will be business and personal account based on vtoType = 0 or 1
 * @param account - personal or business account
 */
export const purchasedProductCount = (
    account: any,
    purchaseOptions: productPurchaseOptions
) => {
    if (
        ['schedule_e', 'schedule_c', 'business_schedule_e'].includes(
            purchaseOptions.product
        )
    ) {
        return (
            (purchaseOptions.free_permissions +
                hasPermission(purchaseOptions.acl, account)) *
            purchaseOptions.items_per_permission
        )
    }
    return
}

/**
 * Conditions check for adding a empty object in the array 
 * some cases like options with purchased flow we need to check if the free_permissions is 
 * greater than 0 and get the count of purchased items based on the free_permissions & 
 * items_per_permission (after purchase) based on the purchased items count we allow add 
 * the empty object in the field array By Default we add one empty object in the field array 
 * if the array length is 0 if there is purchase options then we check the items count available 
 * for purchase and allow user to add the empty object
 * @param account - field account based on VTO
 * @param hasBuyOption - field buy options based on question model
 * @param arrayVal - field array values based on question model
 */

export const CheckFieldArrayDetails = (
    account: any,
    hasBuyOption: any,
    arrayVal: any[]
): {
    addMore: boolean
    allowPurchase: boolean
    addEmptyItem: boolean
} => {
    let addMore = true,
        allowPurchase = false,
        addEmptyItem = false
    let itemPurchased: any = 0
    if (hasBuyOption) {
        itemPurchased = purchasedProductCount(account, hasBuyOption)
        addEmptyItem =
            itemPurchased > arrayVal?.length && arrayVal?.length === 0
        addMore = itemPurchased > arrayVal?.length
        allowPurchase = arrayVal?.length === itemPurchased
    } else {
        if (arrayVal?.length === 0) {
            addEmptyItem = true
        }
    }
    return { addMore, allowPurchase, addEmptyItem }
}

export const isSolePrepBusiness = (
    currentQuestionModel: string,
    values: any
) => {
    return (
        currentQuestionModel === 'business.owners' &&
        getValueByModelName('business.business_type', values) === 'sole'
    )
}


export const setNestedProperty = (obj: any, question: any, value: any) => {
    const quesDefaultVal = question?.options?.defaultValue;
    const keys: any = question?.model?.split(".");
    let current = obj;    
    keys.forEach((key: string | number, index: number) => {
        if (index === keys.length - 1) {
            current[key] = hasDefaultValue(quesDefaultVal) ? quesDefaultVal : value; 
        } else {
            // If the key does not exist or is not an object, create an empty object
            current[key] = current[key] || {};
            current = current[key];
        }
    });
};