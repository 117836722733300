import { Route, Switch } from 'react-router-dom'
import VtoProvider from '../vto/providers/VtoProvider'
import BusinessTaxInformation from './BusinessTaxInformation'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import { useContext, useEffect } from 'react'
import { Container, Theme, makeStyles } from '@material-ui/core'
import VTO from '../vto/core/components/VTOMain/VTO'

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            height: '100%',
            width: '100%',
            padding: '1rem',
            boxSizing: 'border-box',
            [theme.breakpoints.down('sm')]: {
                padding: '0',
            }
        },
    }
})

const BusinessInfoTaxRoutes = () => {
    const classes = useStyles()
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)

    useEffect(() => {
        setActiveRouteHeading('Business Tax Information')
    }, [setActiveRouteHeading])

    return (
        <VtoProvider>
            <Switch>
                <Route path="/tax-organizer/business" exact>
                    <BusinessTaxInformation />
                </Route>
                <Route path={`/tax-organizer/business/:vtoYear/:id`} exact>
                    <div className={classes.container}>
                        <VTO />
                    </div>
                </Route>
                <Route path={`/tax-organizer/business/:vtoYear/:id/:wildcard`}>
                    <Container className={classes.container}>
                        <VTO />
                    </Container>
                </Route>
            </Switch>
        </VtoProvider>
    )
}

export default BusinessInfoTaxRoutes
