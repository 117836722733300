import Lottie from 'react-lottie'

const UiLottie = ({
    lottieData,
    height = 80,
    width = 80,
    loop = true,
    autoplay = true,
}: {
    lottieData: any
    height?: number
    width?: number,
    loop?: boolean,
    autoplay?: boolean
})=> {
    const defaultOptions = {
        loop: loop,
        autoplay: autoplay,
        animationData: lottieData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    return (
        <Lottie options={defaultOptions} height={height} width={width} />
    )
}

export default UiLottie