export const StatusTitleMapping = {
    started: {
        title: 'Confirm Your Tax Information',
        hint: 'Before we can prepare your taxes, you need to confirm your tax information.',
    },
    submitted: {
        title: 'Processing Tax Information',
        hint: 'We’ve received your tax information and will begin preparing your tax return shortly.<br/><br/>If you need to change anything before filing with government agencies, send a message to your accountant through the Message Center.',
    },
    preparation: {
        title: 'Preparing Your Tax Return',
        hint: 'Your accountant is preparing your tax return.<br/><br/>If you need to change anything before filing with government agencies, send a message to your accountant through the Message Center.',
    },
    manager_review: {
        title: 'Reviewing Internally',
        hint: 'A manager is reviewing your Tax Return. <br/><br/>We will contact you when we are ready to discuss your tax return. It is critical that you respond promptly when we reach out to you.',
    },
    client_review: {
        title: 'Review Your Tax Return',
        hint: 'Please review and complete Form 8879 in order to file your tax return with government agencies.<br/><br/>If you need to change anything before we file with government agencies, send a message to your accountant through the Message Center.',
    },
    e_file: {
        title: 'Awaiting Government Approval',
        hint: 'Your tax return has been e-filed and is awaiting approval by government agencies.',
    },
    completed: {
        title: 'Tax Return Completed',
        hint: 'Your Business Tax Return was e-filed and approved by government agencies.',
    },
}
export const STATUS_SUBMITTED = 1

export const AdditionalStatusMapping = {
    title: 'Need Additional Info',
    hint: 'In order to continue preparing your taxes, we need you to provide some additional information.',
}

export const ApStatusMapping = {
    "ADDITIONAL_INFO_REQUEST_SENT": "additional_info_request_sent"
}

export const US_COUNTRY = 'US';

export const COUNTRY_QUESTION_PATTERNS = [
    // Note:- Do not change the order of the array items
    /^(business|income|personalInfo)\.(owners|rental_income|movements)\.\d+\.address\.country$/,
    /^personalInfo\.general\.address\.country$/
];

export const AUTOCOMPLETE_FIELDS = [
    'business.address.state',
    'business.address.country',
    'business.form_state',
    'business.industry',
    'address.country',
    'address.state',
    'personalInfo.general.address.state',
    'personalInfo.general.address.country',
    'personalInfo.general.driver_license_state',
    'personalInfo.general.home_of_record_state',
    'personalInfo.general.currently_stationed_state',
    "personalInfo.general.spouses_driver_license_state",
    "industry",
    "registration_state",
    "state",
    "care_provider_state"
]

export const NORMALIZE_VTO_FIELDS = [
    'business.has_k1_from_trust',
    'business.is_final_return',
    'business.is_multiple_registered_states',
    'business.is_ownership_percentage_changed',
    'business.is_zero_return',
    'business.upsell.advisory',
    'business.upsell.dedicated_bookkeeping',
    'business.donate_physical_goods_year',
    'business.loss_10_per_by_casualty_or_theft',
    'forms_count.foreign_passport_proof_business',
    'forms_count.id_issued_by_state_or_local_government_proof_business',
    'forms_count.us_passport_proof_business',
    'forms_count.mileage_log_personal_business',
    'personalInfo.general.has_driver_license',
    'personalInfo.general.is_active_duty_military',
    'personalInfo.general.is_citizen',
    'personalInfo.general.is_ip_pin',
    'personalInfo.health_insurance_has_insurance',
    'income.debt_income',
    'income.foreign_income',
    'income.gambling_income',
    'income.jury_income',
    'income.other_income',
    'income.royalty_income',
    'income.assets_income_list',
    'income.rental_income',
    'deductions.health_savings',
    'deductions.charitable_expenses',
    'deductions.charitable_non_cash_expenses',
    'deductions.adoption_expenses',
    'deductions.charitable_organizations',
    'deductions.childs_expenses',
    'deductions.energy_saving_expenses',
    'deductions.gambling_expenses',
    'deductions.major_sales_expenses',
    'deductions.theft_expenses',
    'deductions.homebuyer_home_payments',
    'deductions.other_expenses',
    'deductions.vehicle_expenses',
    'deductions.moving_expenses_miles',
    'deductions.retirement_plans',
    'personalInfo.has_moved',
    'forms_count.k1',
    'forms_count.estimated_tax_payment_confirmation',
    'forms_count.irs_corespondence',
    'forms_count.mileage_log_personal_income',
    'forms_count.prior_depreciation_schedule',
    'forms_count.rental_hud',
    'forms_count.business_1099MISC',
    'forms_count.energy_saving_appliance_receipt',
    'additionalQuestions.has_foreign_assets',
    'additionalQuestions.has_direct_deposit',
    'soleProprietorships'
]

export const DEFAULT_FORM_VALUES: Record<string, any> = {
    'business.has_k1_from_trust': false,
    'business.is_final_return': false,
    'business.is_multiple_registered_states': false,
    'business.is_ownership_percentage_changed': false,
    'business.is_zero_return': false,
    'business.upsell.advisory': true,
    'business.upsell.dedicated_bookkeeping': true,
    'business.donate_physical_goods_year': false,
    'business.loss_10_per_by_casualty_or_theft': false,
    'forms_count.foreign_passport_proof_business': 0,
    'forms_count.id_issued_by_state_or_local_government_proof_business': 0,
    'forms_count.us_passport_proof_business': 0,
    'forms_count.mileage_log_personal_business': 0,
    'personalInfo.general.has_driver_license': true,
    'personalInfo.general.is_active_duty_military': false,
    'personalInfo.general.is_citizen': true,
    'personalInfo.general.is_ip_pin': false,
    'personalInfo.health_insurance_has_insurance': true,
    'personalInfo.has_moved': false,
    'forms_count.k1': 0,
    'forms_count.estimated_tax_payment_confirmation': 0,
    'forms_count.irs_corespondence': false,
    'forms_count.mileage_log_personal_income': 0,
    'forms_count.prior_depreciation_schedule': 0,
    'forms_count.rental_hud': 0,
    'forms_count.business_1099MISC': 0,
    'forms_count.energy_saving_appliance_receipt': 0,
    'additionalQuestions.has_foreign_assets': false,
    'additionalQuestions.has_direct_deposit': true,
    'deductions.moving_expenses_miles': 0,
    'income.rental_income': [
        {
            address: {
                address: null,
                city: null,
                country: 'US',
                state: null,
                zip_code: null,
            },
            available_for_rent_date: null,
            days_rented: null,
            expenses: {
                has_none: null,
                has_others: null,
                list: {},
                others: {},
            },
            forms_count: {
                prior_depreciation_schedule: null,
                rental_hud: null,
            },
            has_depreciation_schedule: null,
            is_250_hours_rental_services: null,
            is_bookkeeped: null,
            is_maintained_separate_books_for_income_and_expenses: null,
            is_purchased: null,
            is_records_maintained_for_time_logs: null,
            is_sold: null,
            profit: null,
            property_description: null,
            property_type: null,
            purchase_date: null,
            purchase_price: null,
            rent_amount: null,
            rent_start_date: null,
            was_available_for_rent: null,
        },
    ],
    'deductions.health_savings': [
        {
            account: null,
            amount: null,
            duration: 1,
            expenses: null,
            total: null,
            type: null,
        },
    ],
    'deductions.childs_expenses': [
        {
            care_provider_address: null,
            care_provider_city: null,
            care_provider_name: null,
            care_provider_paid_amount: null,
            care_provider_state: null,
            care_provider_tax_id: null,
            care_provider_tax_id_type: 'ssn',
            care_provider_zip_code: null,
            name: null,
        },
    ],
    'deductions.retirement_plans': [
        {
            amount: null,
            contributor: null,
            date: null,
            type: null,
            year: 2024,
        },
    ],
}

export const NORMALIZE_SOLE_PROPRIETORSHIPS_FIELDS = [
    'is_business_operational',
    'is_registered_multiple_states',
    'is_this_final_tax_return_for_2024',
    'personalInfo',
    'personalInfo.general.spouses_has_driver_license',
    'personalInfo.general.spouses_is_citizen',
    'personalInfo.general.spouses_is_ip_pin',
    'personalInfo.health_insurance_has_insurance',
    'has_payments_1099'
]

export const DEFAULT_SOLE_PROPRIETORSHIPS_VALUES: Record<string, any> = {
    is_business_operational: true,
    is_registered_multiple_states: false,
    is_this_final_tax_return_for_2024: false,
    "personalInfo.general.spouses_has_driver_license": true,
    "personalInfo.general.spouses_is_citizen": true,
    "personalInfo.general.spouses_is_ip_pin": false,
    "personalInfo.health_insurance_has_insurance": true,
    personalInfo: {
        general: {
            spouses_has_driver_license: true,
            spouses_is_citizen: true,
            spouses_is_ip_pin: false,
        },
        health_insurance_has_insurance:true
    },
    'has_payments_1099': false
}