import { DocumentType } from './vto.document-model'

export interface PersonalSection {
    title: string
    name: string
    sections: string[]
    long?: boolean
    type?: string
    model?: string
    titleModel?: string
    show_condition?: Condition[]
}

export enum UPLOAD_TYPES {
    INDIVIDUALLY = 'individually',
    ALLINONE = 'all_in_one',
    OUTSIDE = 'outside',
}

export type VtoUploadTypes =
    | UPLOAD_TYPES.ALLINONE
    | UPLOAD_TYPES.INDIVIDUALLY
    | UPLOAD_TYPES.OUTSIDE
export interface BusinessSection {
    title: string
    name: string
    sections: string[]
    long?: boolean
    show_condition?: Condition[]
}

export interface ConfigOptions {
    configUploadTypes: string
    configFileTypes: string
    configFileGroups: string
    contacts: Contacts
    disclaimer: Disclaimer
}
export interface Disclaimer {
    message: string
    show_condition: ShowCondition[]
}
export interface Contacts {
    title: string
    lines: string[]
}
export interface Condition {
    model: string
    values: (string | number | boolean)[]
    not_values?: (string | number | boolean)[]
}

interface PersonalData {
    personal: PersonalSection[]
}

interface BusinessData {
    business: BusinessSection[]
}

export const personalFormData: PersonalData = {
    personal: [
        {
            title: 'Personal Info',
            name: 'personal_info',
            sections: [
                'general',
                'filing_status',
                'home_address',
                'dependants',
                'educational',
                'renters',
                'k12',
                'volunteer',
            ],
        },
        // Other personal sections - TO DO
    ],
}
export const businessFormData: BusinessData = {
    business: [
        {
            title: 'Essential Services',
            name: 'business_essential_services',
            sections: [
                'business_es_personal_tax_prep',
                'business_es_expedited_tax_prep',
                'business_es_audit_defence',
            ],
            show_condition: [
                {
                    model: 'has_upsells',
                    values: [true],
                },
            ],
        },
        // Other business sections - TO DO
    ],
}

interface QuestionOptions {
    required: boolean
    placeholder?: string
    mask?: string
    cch_mask?: string
    defaultValue?: any
    image?: string
    configName?: string
    optionsKey?: string
    optionsLabel?: string
    optionsCCHLabel?: string
    optionsLacerteLabel?: string
    min?: number
    max?: number
    divided?: boolean
    file_name?: string
}

interface Question {
    type: string
    title: string
    model: string
    options: QuestionOptions
    show_condition?: Array<{ model: string; values: any[] }>
    questions?: Question[]
    files?: { model: string; title: string }[]
    data_map?: Array<{ source: boolean; destination: string }>
    toggle?: number
    action?: string
    code_number?: number
    field?: string
    mask?: string
}

export interface SectionData {
    name: string
    title: string
    type: string
    questions: Question[]
}
export interface VTOMetaData {
    Steps: {
        personal: PersonalSection[]
        business: BusinessSection[]
    }
    CCH: unknown
    Lacerte: unknown
    Sections: SectionData[],
    formattedSections?: any
}

export type SectionsMap = {
    [key: string]: any
}

export type NavMenuItems = [
    {
        title: string
        key: string
    }
]
interface LacerteInfo {
    [key: string]: {
        [key: string]: {
            code_number: number
            field: string
            toggle: number
        }
    }
}
export const QUESTION_TYPES = {
    SELECT: 'select',
    QUESTIONS_SET: 'questions-set',
    BOOL: 'bool',
    FILE_COUNT: 'file-count',
}
export interface SelectData {
    lacerte: LacerteInfo
    model: string
    title: string
    type: string
    options: {
        configName: string
        optionsKey: string
        optionsLabel: string
        hint: string
        hint_condition: {
            model: string
            values: string[]
            required: boolean
        }
    }
}

export interface ShowCondition {
    model: string
    values: string[]
}
interface SuccessInfo {
    type: 'success'
    title: string
    show_condition?: ShowCondition[]
    options?: {
        required: boolean
    }
}
export interface BoolQuestion {
    type: 'bool'
    title: string
    model: string
    options: {
        required: boolean
    }
    isDynamicQuestion?: boolean
}
export interface FileCountQuestion {
    type: 'file-count'
    model: string
    show_condition: ShowCondition[]
    options: {
        hidden: boolean
        defaultValue: number
        file_name: string
        required: boolean
    }
    isDynamicQuestion?: boolean
}

export interface QuestionsSet {
    type: 'questions-set'
    show_condition: ShowCondition[]
    questions: (BoolQuestion | FileCountQuestion)[]
}
export type QuestionDataItem = SelectData | SuccessInfo | QuestionsSet
export type Questions = BoolQuestion | FileCountQuestion
interface Countries {
    id: string
    abbr: string
    name: string
    iso_abbr: string
}
interface States {
    id: string
    abbr: string
    timezone: {
        id: string
        abbr: string
        name: string
    }
    name: string
}
interface Industries {
    id: string
    abbr: string
    name: string
}
interface BusinessTypes {
    name: string
    title: string
    group: string
}
interface BusinessEntityTypes {
    name: string
    title: string
    cch_title: string
}
interface MonthsInYear {
    name: number
    value: number
}
interface AdoptionExpenses {
    name: string
    title: string
}
interface BalanceExpenses {
    name: string
    title: string
}
interface ContactTime {
    name: string
    id: string
}
interface FarmExpenses {
    name: string
    title: string
    lacerte: LacerteInfo
}
interface FarmRevenue {
    name: string
    title: string
}
export interface Filing {
    name: string
    title: string
    cch_title: number
}
export interface The1040_Value {
    code_number: number
    field: Field
    suffix?: number
    description_code?: number
}

export enum Field {
    Amount = 'amount',
}

export interface The1065 {
    '400': The1040_Value
}
export interface BalanceExpenseLacerte {
    '1040'?: { [key: string]: The1040_Value }
    '1065'?: The1065
    '1120'?: The1065
    '1120S'?: The1065
}
export interface Expense {
    name: string
    title: string
    hint?: string
    lacerte?: BalanceExpenseLacerte
}
export interface AdoptionExpense {
    name: string
    title: string
}

export interface RentalExpense {
    name: string
    title: string
    lacerte: unknown
}
export interface RentalProperty {
    name: string
    val: string
}
export interface UploadTypes {
    individually: Upload
    all_in_one: Upload
    outside: Upload
}
export interface Upload {
    title: string
    description: string
}
export interface Images {
    document_id_image: string
}

export interface YearWiseVTOConfig {
    adoption_expenses_years: [
        {
            year: number
        }
    ]
    business_types: [
        {
            name: string
            title: string
        }
    ]
    files: {
        [key in DocumentType]: ConfigFiles
    }
    filing: []
    loan2008years: []
    product: {
        business: string
        business_schedule_e: string | null
        personal: string
        schedule_c: string | null
        schedule_e: string | null
    }
    product_discounts: any
    retirement_years: [
        {
            year: number
        }
    ],
    state_tax_id_fields: {}
}

export interface FAQ {
    id: string
    question: string
    answer: string
    html?: string
    selected?: boolean
}

export interface FAQsList {
    [key: string] : FAQ[]
}

export type FileGroupName = '1095' | '1098' | '1099' |  'w2' | 'other'
export interface FileGroups {
    name: FileGroupName
    title: string
}
export interface VTOConfigData {
    countries: Countries[]
    states: States[]
    industries: Industries[]
    business_types: BusinessTypes[]
    business_entity_types: BusinessEntityTypes[]
    months_in_year: MonthsInYear[]
    adoption_expenses: AdoptionExpenses[]
    balance_expenses: BalanceExpenses[]
    contact_time: ContactTime[]
    farm_expenses: FarmExpenses[]
    farm_revenue: FarmRevenue[]
    filing: Filing[]
    home_expenses: Expense[]
    moving_expenses: Expense[]
    relationships: AdoptionExpense[]
    rental_expenses: RentalExpense[]
    rental_properties: RentalProperty[]
    retirement_plans: AdoptionExpense[]
    contributors: AdoptionExpense[]
    file_groups: FileGroups[]
    upload_types: UploadTypes
    images: Images
    faq_list: FAQsList
    [key: string]: any
    year2015?: YearWiseVTOConfig
    year2016?: YearWiseVTOConfig
    year2017?: YearWiseVTOConfig
    year2018?: YearWiseVTOConfig
    year2019?: YearWiseVTOConfig
    year2020?: YearWiseVTOConfig
    year2021?: YearWiseVTOConfig
    year2022?: YearWiseVTOConfig
    year2023?: YearWiseVTOConfig
    year2024?: YearWiseVTOConfig
}


export interface ConfigFiles {
    step: string,
    type: [FileGroupName],
    for: string,
    form: string
}

export type VTOConfigOptions =
    | Countries[]
    | States[]
    | Industries[]
    | BusinessTypes[]
    | BusinessEntityTypes[]
    | MonthsInYear[]
    | AdoptionExpenses[]
    | BalanceExpenses[]
    | ContactTime[]
    | FarmExpenses[]
    | FarmRevenue[]
    | Filing[]
    | Expense[]
    | RentalExpense[]
    | RentalProperty[]
    | UploadTypes[]
    | Images[]

export enum VTO_TYPES {
    BUSINESS = 'business',
    PERSONAL = 'personal',
}
export type VtoTypes = VTO_TYPES.BUSINESS | VTO_TYPES.PERSONAL

export enum VtoType {
    business = 1,
    personal = 0,
}

export const MISSING_TITLES : MissingTitles = {
    'upload_center' : 'Required Documents'
}
interface MissingTitles {
    [key: string]: string;
}