import {
    Checkbox,
    FormControlLabel,
    Hidden,
    makeStyles,
    Theme,
    useMediaQuery,
} from '@material-ui/core'
import UiButton from '../../../common/ui/UiButton'
import UiText from '../../../common/ui/UiText'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { commonStyles, toRem16 } from '../../../../styles/commonStyles'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import rightArrow from '../../../../assets/icons-svg/right-arrow-white.svg'
import VtoOpening from '../../../../assets/images/vto/vto-opening.png'

export const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        toSStyle: {
            maxWidth: '100%',
            margin: '1rem auto 0 auto',
            padding: '1rem',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            color: (colorTheme) => colorTheme.black100,
            '& .Mui-checked': {
                color: (colorTheme) => colorTheme.tertiary,
            },
            '& button': {
                maxWidth: '360px',
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                '& button': {
                    maxWidth: '100%',
                },
            },
        },
        borderTopSm: {
            [theme.breakpoints.down('sm')]: {
                borderTop: (colorTheme) => `1px solid ${colorTheme.black100}`,
            },
        },
        tosMargin: {
            marginTop: '2rem',
            marginBottom: '1.5rem',
        },
        imageStyle: {
            height: '100%',
            width: '100%',
            maxHeight: `${toRem16(400)}`,
            maxWidth: `${toRem16(600)}`,
        },
        center: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        list: {
            counterReset: 'item',
            textAlign: 'left',
            listStyleType: 'none',
            paddingLeft: 0,

            '& > li': {
                counterIncrement: 'item',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1.5rem',
            },

            '& > li:before': {
                content: 'counter(item, decimal)',
                backgroundColor: (colorTheme) => colorTheme.teal200,
                color: (colorTheme) => colorTheme.grey400,
                borderRadius: '50%',
                display: 'inline-block',
                minWidth: toRem16(40),
                height: toRem16(40),
                lineHeight: toRem16(40),
                textAlign: 'center',
                marginRight: '1rem',
                fontWeight: 600,
            },
        },
        bookkeepingCta: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            justifyItems: 'center',

            '& > button': {
                flex: 1,
                justifyContent: 'space-between',
            },

            [theme.breakpoints.up('sm')]: {
                maxWidth: '328px',
                margin: '0 auto',
            },
        },
        prevBtnContainer: {
            display: 'flex',
            justifyContent: 'flex-start',

            '& > button': {
                maxWidth: 'max-content',
                margin: '1rem 0 2rem 0',
            },
        },
        fixedButtonContainer: {
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                padding: '1rem',
                backgroundColor: (colorTheme) => colorTheme.primaryWhite,
                borderTop: (colorTheme) => `1px solid ${colorTheme.grey200}`,
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
            },
        },
        spacer: {
            height: '60px',
        },
        tosLink: {
            '& a': {
                color: (colorTheme) => colorTheme.blue200,
            },
        },
        container: {
            maxWidth: '640px',
            color: (colorTheme) => colorTheme.black100,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                textAlign: 'left',
            },
        },
        detailedContainer: {
            maxWidth: toRem16(640),
            margin: '0 auto',
            color: (colorTheme) => colorTheme.black100,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                textAlign: 'left',
            },
        },
        title: {
            marginTop: '1rem',
            [theme.breakpoints.down('xs')]: {
                alignSelf: 'flex-start',
                textAlign: 'left',
            },
        },
        detailStepTitle: {
            [theme.breakpoints.down('xs')]: {
                textAlign: 'left',
            },
        },
        videoButton: {
            '& span': {
                fontWeight: 600,
            },
        },
        mb10: {
            marginBottom: toRem16(10),
        },
        mb40: {
            marginBottom: toRem16(40),
        },
    }
})

const IntroStep = ({
    selectedYear,
    setTosAccepted,
    goToNextStep,
    tosAccepted,
    apiLoading,
}: any) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const commonClasses = commonStyles()
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )

    return (
        <div className={styles.toSStyle}>
            <img
                src={VtoOpening}
                alt="vtoStartImage"
                className={styles.imageStyle}
            />
            <div className={styles.tosMargin}>
                <UiText
                    variant="suv_150"
                    weight={smDevice ? 'semi_bold_600' : 'medium_500'}
                >
                    Start Personal Tax Prep {selectedYear}
                </UiText>
                <UiText className={commonClasses.mtb16}>
                    Your accountant will begin preparing your tax return once
                    you submit your Tax Information.
                </UiText>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={tosAccepted}
                            onChange={(event: any) => {
                                setTosAccepted(event.target.checked)
                            }}
                        />
                    }
                    label={
                        <UiText
                            className={styles.tosLink}
                            variant="motorcycle_90"
                        >
                            I accept the{' '}
                            <a
                                className={styles.link}
                                href="https://1800accountant.com/terms-of-services"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms and Conditions
                            </a>
                        </UiText>
                    }
                />
            </div>
            <Hidden mdUp>
                <div className={styles.spacer}></div>
            </Hidden>
            <div className={styles.fixedButtonContainer}>
                <UiButton
                    fullWidth
                    btnType="tertiary"
                    handleClick={goToNextStep}
                    endIconSvg={smDevice ? rightArrow : ''}
                    disabled={!tosAccepted || apiLoading}
                    label="Get Started"
                />
            </div>
        </div>
    )
}
export default IntroStep
