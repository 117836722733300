
import SectionQuestionsRenderer from '../../sections/SectionQuestionsRenderer'

const Vehicles = ({ section}: any) => {
    return (
        <>
            <SectionQuestionsRenderer
             section={section}
            />
        </>
    )
}

export default Vehicles
