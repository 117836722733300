import { Formik } from 'formik'
import { VTOSteps } from './VTOSteps'
import Loader from '../../../../../common/Loader'
import { useVtoContext } from '../../../providers/VtoProvider'

const VTO = () => {
    const { loading, currentVtoDetails, submitValues } = useVtoContext()
    const onSubmit = async (values: any) => {
        await submitValues(values)
    }

    if (loading && !currentVtoDetails?.id) {
        return <Loader />
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{...currentVtoDetails}}
            onSubmit={onSubmit}
            validateOnMount={true}
            validateOnBlur={true}
        >
            {() => <VTOSteps />}
        </Formik>
    )
}

export default VTO
