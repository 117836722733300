import {
    Button,
    makeStyles,
    Theme,
    TextField,
    InputAdornment,
    Paper,
    Box,
    Typography,
    Divider,
} from '@material-ui/core'
import { getIn } from 'formik'
import { Autocomplete } from '@material-ui/lab'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'

const useClasses = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    textFieldRoot: {
        '& .MuiInputLabel-root': {
            top: '-7px',
        },
    },
    autocompleteRoot: {
        padding: '0px 0px !important',

        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: theme.spacing('0', 3),
        },
    },
    splitButtonContainer: {
        width: '100%',
        margin: theme.spacing(3, '0'),
    },
    splitTransactionBtn: {
        '& .MuiButton-startIcon': {
            transform: 'rotate(90deg)',
        },
    },
    closeItem: {
        padding: '12px 0px !important',
        cursor: 'pointer',
    },
    autocomplete: {
        '& .MuiInputBase-fullWidth': {
            height: '40px',
            width: '222px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            '& .MuiAutocomplete-input': {
                padding: '0px',
            },
        },
    },
    createSegment: {
        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
    },
    parentMenu: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    parentTitle: {
        width: '33%',
    },
    renderOptionStyle: {
        width: '100%',
    },
}))

const menuLink = ({ children, ...other }: any, listButtonAction: any) => {
    // borderBottom={`1px solid ${colorTheme.grey200}`}
    return (
        <Paper {...other}>
            {children}
            <Box>
                <Button
                    startIcon={<AddIcon />}
                    onMouseDown={(event) => {
                        event.preventDefault()
                    }}
                    onClick={(event) => {
                        event.preventDefault()
                        listButtonAction(true)
                    }}
                >
                    Create a New Job
                </Button>
            </Box>
        </Paper>
    )
}

const LocationAutocomplete = ({
    listData,
    setFieldValue,
    listButtonAction,
    label,
    placeholder,
    values,
    validationError,
    ...props
}: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useClasses(colorTheme)
    const fieldError = getIn(props.form.errors, props.field.name)
    const showError =
        getIn(props.form.touched, props.field.name) && !!fieldError
    return (
        <Autocomplete
            id="category-select-options"
            fullWidth
            options={listData as any}
            value={values?.job}
            getOptionLabel={(option: any) => option?.title}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    placeholder={placeholder}
                    name="job"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    error={showError || validationError}
                    helperText={
                        validationError || showError ? props.form.errors[props.field.name] : ''
                    }
                />
            )}
            renderOption={(option: any) => (
                <div className={classes.renderOptionStyle}>
                    <div>{option.title}</div>
                    <Typography variant="caption">
                        {option?.parent_title}
                    </Typography>
                    <Divider />
                </div>
            )}
            onChange={(event, item: any) => {
                setFieldValue('job', item)
            }}
            PaperComponent={(child: any) => {
                return menuLink(child, listButtonAction)
            }}
        />
    )
}

export default LocationAutocomplete
