import { VTOConfigData } from '../../../../models/VtoDetails.model';

export const getConfigByName = (names: string, configData: VTOConfigData) => {
   let config = configData
   const keys = names.split('.')
   
    for (const k of keys) {
        if (config && config.hasOwnProperty(k)) {
            config = config[k]
        } else {
            return
        }
    }
    return config;
}