import { Divider, makeStyles } from '@material-ui/core'
import UiFormOptions from './UiFormOptions'
import { useFormikContext } from 'formik'
import { getValueByModelName, hasConditionMatched } from '../../../common/VtoUtils'
import { useEffect } from 'react'

const useStyles = makeStyles((theme) => {
    return {
        questionSectionContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                minHeight: 'calc(100vh - 5.5rem)',
            },
        },
        questionContainer: {
            width: '100%',
            maxWidth: '30rem',
            marginTop: '1rem',
        },
    }
})

const UiFormCheckboxSet = (props: any) => {
    const styles = useStyles()
    const { questions, index, parentModel, options, model } = props || {}
    const QuesModel = parentModel ? `${parentModel}.${index}` : model
    const { values, setFieldValue }: { values: any, setFieldValue: any } = useFormikContext()

    // filter out the questions that are not supposed to be shown
    // OEHA-24175 - Deduction checkbox-set
    const filteredQuestions = questions.filter((question: any) => {
        return hasConditionMatched(question?.show_condition, values)
    })

    const getValue = (modelName: string) =>  getValueByModelName(modelName, values)
    const shouldDisableNoneOption = () => {
        for (let i in filteredQuestions){
            const questionModel = `${QuesModel}.${questions[i].model}`
            const questionVal = getValue(questionModel)
            if (questionVal){
                return true;
            }
        }
        if(options?.has_others && 
            getValue(`${QuesModel}.${options?.has_others?.model}`)){
            return true
        } 
        return false
    }


    const disableNone = shouldDisableNoneOption()

    useEffect(() => {
        if(disableNone){
            setFieldValue(`${QuesModel}.${options?.has_none.model}`, false)
        }
    },[disableNone])

    
    return (
        <div className={styles.questionContainer}>
            {options?.divided && <Divider variant={'fullWidth'} />}
            {filteredQuestions?.map((question: any, index: number) => {
                return (
                    <div key={`question-${index}`}>
                        <UiFormOptions
                            key={index}
                            {...question}
                            model={`${QuesModel}.${question.model}`}
                            index={index}
                            isPartOfCheckboxSet={true}
                        />
                    </div>
                )
            })}
            {options?.has_others && (
                <UiFormOptions
                    key={'others'}
                    type={'checkbox'}
                    {...options?.has_others}
                    model={`${QuesModel}.${options?.has_others.model}`}
                    index={index}
                />
            )}
            {options?.has_others_description &&
                values?.[model]?.[options?.has_others?.model as string] && (
                    <UiFormOptions
                        key={'has_others_description'}
                        {...options?.has_others_description}    
                        model={`${QuesModel}.${options?.has_others_description.model}`}
                        index={index}
                        isRequired={true}
                        fastField={false}
                    />
                )}
            {options?.has_none && (
                <UiFormOptions
                    fastField={false}
                    key={'has_none'}
                    type={'checkbox'}
                    {...options?.has_none}
                    model={`${QuesModel}.${options?.has_none.model}`}
                    index={index}
                    disabled={disableNone}
                    onChange={(e: any)=> {
                        setFieldValue(`${model}.${options?.has_none.model}`, e.target.checked)
                    }}
                />
            )}
        </div>
    )
}

export default UiFormCheckboxSet
