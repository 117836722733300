import { Hidden, makeStyles, Theme } from '@material-ui/core'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { useVtoContext } from '../../vto/providers/VtoProvider'
import UiText from '../../../common/ui/UiText'
import UiButton from '../../../common/ui/UiButton'
import { mergeStyle, toRem16 } from '../../../../styles/commonStyles'
import UiLottie from '../../../common/ui/UiLottie'
import WaveAnimation from '../../../../assets/animations/Wave.json'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import Icon from '../../../common/Icon'
import WatchIntroVideo from '../../vto/core/components/WatchVideoModal'
import { useState } from 'react'

export const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => ({
    container: {
        maxWidth: '640px',
        margin: '0 auto',
        color: (colorTheme) => colorTheme.black100,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'left',
        },
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            '& p': {
                alignSelf: 'flex-start',
            },
        },
    },
    mt16: {
        marginTop: '1rem',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    mt32: {
        marginTop: '2rem',
    },
    list: {
        counterReset: 'item',
        textAlign: 'left',
        listStyleType: 'none',
        paddingLeft: 0,

        '& > li': {
            counterIncrement: 'item',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1.5rem',
        },

        '& > li:before': {
            content: 'counter(item, decimal)',
            backgroundColor: (colorTheme) => colorTheme.teal200,
            color: (colorTheme) => colorTheme.grey400,
            borderRadius: '50%',
            display: 'inline-block',
            minWidth: '40px',
            height: '40px',
            lineHeight: '40px',
            textAlign: 'center',
            marginRight: '1rem',
            fontWeight: 600,
        },
    },
    my16: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    mb40: {
        marginBottom: toRem16(40),
    },
    mb10: {
        marginBottom: toRem16(10),
    },
    spacer: {
        height: '60px',
    },
}))

export default function Welcome({ step, nextPageButton }: any) {
    const [openVideoModal, setOpenVideoModal] = useState(false)

    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { selectedYear } = useVtoContext()
    const {
        currentUser: { first_name, last_name },
    } = useCurrentStore()
    return (
        <div className={styles.container}>
            <div className={styles.center}>
                <UiLottie lottieData={WaveAnimation} />
                <UiText
                    className={mergeStyle(styles.mt16, styles.capitalize)}
                    variant="suv_150"
                    weight="bold_700"
                >
                    {`Hi, ${first_name} ${last_name},`}
                </UiText>
            </div>
            <div className={mergeStyle(styles.mt16, styles.mb40)}>
                <UiText variant="suv_150" weight="bold_700">
                    Welcome to Tax Year {selectedYear}
                    <br />
                    Business Tax Preparation!
                </UiText>
                <UiText className={styles.my16}>
                    We know taxes can be overwhelming. Our goal is to make this
                    experience as easy as possible—and to get you the most money
                    back of course!
                </UiText>
            </div>
            <div className={`${styles.mt32} ${styles.mb40}`}>
                <UiText variant="suv_150" weight="bold_700">
                    Here’s what to expect:
                </UiText>
                <ol className={styles.list}>
                    <li>
                        <UiText weight="semi_bold_600">
                            Your accountants have collected data from you
                            throughout the year and compiled it here
                        </UiText>
                    </li>
                    <li>
                        <UiText weight="semi_bold_600">
                            You’ll review your information and confirm your
                            identity
                        </UiText>
                    </li>
                    <li>
                        <UiText weight="semi_bold_600">
                            Sit back and relax—your Expert Tax Preparer will
                            take care of the rest
                        </UiText>
                    </li>
                </ol>
            </div>
            <div className={styles.mb40}>
                {/* <UiText className={styles.mb10}>
                    Any questions? You can watch the intro video or chat with us
                    anytime.
                </UiText>
                <UiButton
                    fullWidth
                    btnType="hyperlink"
                    customLabel={
                        <UiText weight="semi_bold_600">
                            Introduction Video
                        </UiText>
                    }
                    icon={
                        <Icon
                            icon="playIcon"
                            svgColor={colorTheme.blue200}
                            customClass={styles.svg}
                        />
                    }
                    handleClick={() => setOpenVideoModal(true)}
                /> */}
            </div>
            <Hidden mdUp>
                <div className={styles.spacer}></div>
            </Hidden>
            {nextPageButton()}
            <WatchIntroVideo
                videoWidth="100%"
                open={openVideoModal}
                handleClose={() => {
                    setOpenVideoModal(false)
                }}
            />
        </div>
    )
}
