import { VTOConfigData, VTOMetaData, VtoTypes } from '../../components/taxes/vto/models/VtoDetails.model';
import { VtoInfo } from '../../models/vto/VtoInfo';
import store from '../../store'
import {
    getResource, patchResource, postResource, putResource
} from '../axiosService';
import { API } from '../constants/api.const';

enum VtoType {
    business = 1,
    personal = 0
}

const getStoreData = () => {
    const currentStore = store.getState()
    const businessId = currentStore.appData.current_business_id;
    const accountId = currentStore.appData.current_account_id
    const personalAccId = currentStore.appData.personal_account.id
    return {
        businessId,
        accountId,
        personalAccId
    }
}

export function getVTOConfig() : Promise<VTOConfigData[]> {
    const api = `api/vto_config`;
    return getResource(api);
}

export function createVTO(year: number, data: any){
    const { accountId } = getStoreData()
    const API_URL = `${API.VTO.CURRENT}`.replace(':accountId', accountId).replace(':vtoYear', `vto${year}`)
    return postResource(API_URL, data);
}

/**
 * GET API - `api/account/{accountId}/vto{year}/vto?type={1}` or type=0
 */
export function getVtoYearInfo(year: number, vtoType: VtoTypes){
    const { accountId } = getStoreData()
    const api = `api/account/${accountId}/vto${year}/vto?type=${VtoType[vtoType]}`
    return getResource<VtoInfo[]>(api);
}

/**
 * GET VTO DETAILS - `api/account/{accountId}/vto{year}/vto/:id`
 * @returns whole VTO details with data elements for a specific Id()
 */

export function getVtoDetails(year: number, vtoId: string){
    const { accountId } = getStoreData()
    const api = `api/account/${accountId}/vto${year}/vto/${vtoId}`
    return getResource<any>(api); //TODO
}


/**
 * Fetches the balance sheet data for a specific year.
 * 
 * @param year - The year for which to fetch the balance sheet data.
 * 
 * @returns A promise that resolves with the balance sheet data.
 */
export function uncategorizedTransactionImport(year: number) {
    const startDate = new Date(year, 0, 1)
    const offset = startDate.getTimezoneOffset()
    const startDateTS = (startDate.getTime() - offset * 60 * 1000) / 1000

    const endDate = new Date(year, 11, 31)
    const endDateTS = (endDate.getTime() - offset * 60 * 1000) / 1000
    const { accountId, businessId } = getStoreData()
    const api = `api/account/${accountId}/business/${businessId}/transaction?from_date=${startDateTS}&to_date=${endDateTS}&type=uncategorized`
    return getResource(api)
}


/**
 * POST API - `api/account/{accountId}/vto{year}/vto?type={1}` or type=0
 */
export function createVto(year: number, vtoType: 'business' | 'personal'){
    const { accountId } = getStoreData()
    const api = `api/account/${accountId}/vto${year}/vto?type=${VtoType[vtoType]}`
    return postResource<VtoInfo>(api, {
        type: VtoType[vtoType]
    });
}

/**
 * PUT API - `api/account/{accountId}/vto{year}/vto/{vtoId}`
 */
export function updateVtoData(year: number, vtoId: string, data: any){
    const { accountId } = getStoreData()
    const api = `api/account/${accountId}/vto${year}/vto/${vtoId}`
    return putResource(api, data);
}

export function getVTOMetaData(year: number) : Promise<VTOMetaData>{
    const url = `${API.VTO.METADATA}`.replace(':year', year.toString());
    return getResource(url);
}

export function bypassSecondaryPaymentMethod(data: {
    bypass_secondary_payment: boolean
}) {
    const { personalAccId } = getStoreData()
    const api = `api/account/${personalAccId}/bypass_secondary_payment`
    return patchResource(api, data)
}
