import { makeStyles, Theme } from '@material-ui/core'
import UiButton from '../../../../../../common/ui/UiButton'
import UiText from '../../../../../../common/ui/UiText'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../../../../styles/models/Colors.interface'
import { toRem16 } from '../../../../../../../styles/commonStyles'
import { ReactComponent as TiltedLabel } from '../../../../../../../assets/icons-svg/TiltedLabel.svg'
import ProductList from './ProductList'
import { discountCriteria } from '../../../../common/VtoUtils'
import { useVtoContext } from '../../../../providers/VtoProvider'

const useStyles = makeStyles<Theme, ThemeColors>((theme) => {
    return {
        package: {
            position: 'relative',
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            padding: '2.5rem 1rem 1rem 1rem',
            borderRadius: toRem16(16),
            textAlign: 'center',
            ['& > p:first-child, & > p:nth-child(2), & > button']: {
                marginBottom: '0.5rem',
            },
            ['& button + p']: {
                marginBottom: '1rem',
            },
            ['& ul']: {
                paddingLeft: 0,
                textAlign: 'left',
                marginLeft: '1rem',
            },
        },
        bestDeal: {
            backgroundColor: (colorTheme) => colorTheme.mint100,
            borderRadius: '0.5rem',
            padding: '0.5rem',
            position: 'absolute',
            left: '50%',
            top: 0,
            transform: 'translate(-50%,-50%)',
        },
        otherDeal: {
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            backgroundColor: (colorTheme) => colorTheme.primaryWhite,
            borderRadius: '0.5rem',
            padding: '0.5rem',
            position: 'absolute',
            left: '50%',
            top: 0,
            transform: 'translate(-50%,-50%)',
        },
        frequency: {
            display: 'inline',
        },
        discountBlok: {
            // minHeight: toRem16(65),
        },
        discount: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: toRem16(8),
            marginBottom: toRem16(24),
            textAlign: 'center',
        },
        originalPrice: {
            textDecoration: 'line-through',
        },
        extraInfo: {
            height: toRem16(120),
            marginBottom: toRem16(24),
            textAlign: 'left',
        },
    }
})

interface PackageProps {
    item: {
        packageTitle: string
        isSelected: boolean
        totalPrice: number
        discountedPrice: number
        products: any[]
        extraInfo: string
        deal: string
        bestDeal?: boolean
    }
    handleSelect: (id: any) => void
    index: number
}

export function PackageCard({ item, handleSelect, index }: PackageProps) {
    const {
        packageTitle,
        totalPrice,
        discountedPrice,
        products,
        extraInfo,
        deal,
        bestDeal,
    } = item
    const theme = useThemeContext()
    const styles = useStyles(theme.colorTheme)

    const { isBusinessVTO, currentVtoDetails }: any = useVtoContext()
    const { haveDiscount } = discountCriteria(
        isBusinessVTO,
        currentVtoDetails?.business?.business_type ?? ''
    )
    const packagePrice = () => {
        return Math.round(Number(totalPrice / 12))
    }
    return (
        <div className={styles.package} key={packageTitle}>
            <UiText variant="suv_150">{packageTitle}</UiText>
            <UiText variant="suv_150" weight="bold_700">
                ${Math.round(Number(discountedPrice / 12))}
                <UiText weight="bold_700" className={styles.frequency}>
                    /month
                </UiText>
            </UiText>
            <div className={styles.discountBlok}>
                {haveDiscount && (
                    <>
                        <UiText className={styles.originalPrice}>
                            ${packagePrice()}/month
                        </UiText>
                        <div>
                            <UiText
                                variant="motorcycle_90"
                                weight="medium_500"
                                textColor="primary"
                            >
                                <span className={styles.discount}>
                                    <TiltedLabel /> Save 20% when you purchase
                                    today
                                </span>
                            </UiText>
                        </div>
                    </>
                )}
            </div>

            <UiButton
                handleClick={() => {
                    handleSelect(item)
                }}
                btnType="tertiary"
                label="Add & Continue"
                fullWidth
            />
            <UiText variant="moped_75">Billed Annually</UiText>
            <UiText className={styles.extraInfo}>{extraInfo}</UiText>
            {products.map((product, itr) => (
                <ProductList
                    product={product}
                    key={`${'product_list_'}${itr}`}
                />
            ))}
            <div className={index === 1 ? styles.bestDeal : styles.otherDeal}>
                <UiText variant="motorcycle_90" weight="medium_500">
                    {deal}
                </UiText>
            </div>
        </div>
    )
}
