import { TOSUpdate } from '../../components/dashboard/TOSModal/TOSModal';
import {
    getResource,
    patchResource,
} from '../axiosService';
import { API } from '../constants/api.const';

export function updateAgreeTOS(data: TOSUpdate) {
    return patchResource(API.TOS, data);
}
export function getTOSData(){
    return getResource(API.TOS);
}