import SectionQuestionsRenderer from '../../sections/SectionQuestionsRenderer'

const Bookkeeping = ({ section }: any) => {
    return (
        <SectionQuestionsRenderer
           section={section}
        />
    )
}

export default Bookkeeping