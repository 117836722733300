export enum GREETING_MESSAGES {
    MORNING = 'Good Morning',
    AFTERNOON = 'Good Afternoon',
    EVENING = 'Good Evening',
}

export enum renderFor {
    BUSINESS = 'Business',
    PERSONAL = 'Personal',
}

export enum productPurchaseLink {
    Business = 'https://1800accountant.lightning.force.com/lightning/r/Product2/01tf1000003JjrcAAC/view',
    Personal = 'https://1800accountant.lightning.force.com/lightning/r/01tf1000003JjrbAAC/view',
}

export enum DOCUMENTS_UPLOAD {
    BUSINESS = 'business',
    PERSONAL = 'personal',
}
export enum MIME_TYPES {
    IMG_JPEG = 'image/jpeg',
    IMG_PNG = 'image/png',
    IMG_JPG = 'image/jpg',
    APP_PDF = 'application/pdf',
}
export enum MIME_IMAGES {
    ATTACHMENT_IMG = 'Image-Attachment',
    PDF = 'PDF',
    GENERIC = 'Generic',
}

export enum STATUS_TEXT {
    TAX_PREPARATION_STATUS = 'Tax Preparation Status',
    NEED_AUTHORIZATION = 'Need Authorization',
}
export enum DOCUMENT_UPLOAD_BUTTONS {
    UPLOAD_DOCUMENTS = 'Upload Documents',
    UPLOAD_FORM_8879 = 'Complete Form 8879',
}

export enum MESSAGES {
    UPLOAD_SUCCESS = 'success',
}

export const DOCUMENTS_8879_HEADER = 'Upload Signed Form 8879'
export const SUPPORTED_MIME_TYPE = [
    'application/pdf',
    'image/png',
    'image/jpeg',
    'image/jpg',
]
export const MAX_FILE_SIZE = 15
export const ALLOWED_DOCUMENT_TYPES = ['.pdf', '.png', '.jpeg', '.jpg']

export const SUPPORTED_1040_MIME_TYPE = ['application/pdf']
export const ALLOWED_1040_DOCUMENT_TYPES = ['.pdf']

export const toolTipInfo =
    'This estimate is based on having all pertinent information. Any missing information will result in a longer processing time.'

export const STATUS_RETURN_COMPLETED = 'Completed'
export const STATUS_COMPLETED = 'completed'
export const STATUS_CLIENT_REVIEW = 'Client Review'
export const INTERNAL_REVIEW = 'Internal Review'
export const ESTIMATED_COMPLETION_DAYS = '14 Days'

export const TOTAL_STEPS = 5

export const ESTIMATED_COMPLETION_MESSAGES =
    'This estimate is based on having all pertinent information. Any missing information will result in a longer processing time.'

export const USER_INFO_MESSAGES = {
    CLIENT_REVIEW:
        'Your tax return has passed our internal review department and a copy has been provided via the message center.',
    E_FILE: 'We have received your signed e-file authorization forms and have submitted your tax return. We will follow up once they have been accepted.',
    ACA_STATUS_TEXT:
        'Please review your tax return. If no changes are required, electronically sign the e-file authorization forms.',
    ACA_STATUS_TEXT_NO_ESIGN:
        'Please review your tax return. If no changes are required, upload the signed e-file authorization forms.',
    UPLOAD_DOC_STATUS: `We’re currently working on your taxes. We’ll let you know if we need anything more from you. <br/> If you have any additional data you want to share with us, click the button below.`,
    INCOMPLETE:
        'Your accountant will begin preparing your tax return once you submit your Tax Information.',
    INTERNAL_REVIEW: 'A manager is reviewing your Tax Return.',
    PREPARATION: 'Your accountant is preparing your tax return.',
    ADDITIONAL_INFO_NEEDED:
        'In order to continue preparing your taxes we need you to provide some additional information.',
    DISCARDED_C:
        'Your business tax details will be added to Schedule C and included with your 1040.',
    TAX_RETURN_COMPLETED: 'Your tax return has been e-filed.',
}

export const FEDERAL_TAX_TYPE = {
    FEDERAL_TAX_DUE: 'Federal Tax Due',
    FEDERAL_TAX_REFUND: 'Federal Tax Refund',
    FEDERAL_TAX_RETURN: 'Federal Tax Return',
}

export const STATE_TAX_TYPE = {
    STATE_TAX_DUE: 'State Tax Due',
    STATE_TAX_REFUND: 'State Tax Refund',
    STATE_TAX_RETURN: 'State Tax Return',
}

export const STATUS_MAPPING = {
    Started: {
        title: 'Tax Organizer Incomplete',
        currentStep: 1,
    },
    Preparation: {
        title: 'Preparation',
        currentStep: 2,
    },
    'Internal Review': {
        title: 'Internal Review',
        currentStep: 3,
    },
    'Additional Info Needed': {
        title: 'Need Additional Info',
        currentStep: 2,
    },
    'Client Review': {
        title: 'Need Authorization',
        currentStep: 4,
    },
    'E-File': {
        title: 'Tax Return Completed',
        currentStep: 6,
    },
    Completed: {
        title: 'Tax Return Completed',
        currentStep: 6,
    },
}

export const progressStepperContent = [
    {
        label: 'Started',
        content:
            'Your accountant will begin preparing your tax return once you submit your Tax Information.',
        currentStep: 0,
    },
    {
        label: 'Information Submitted',
        content:
            'We have received your tax information and will begin preparing your tax return shortly.',
        currentStep: 1,
    },
    {
        label: 'Preparation',
        content:
            'Your accountant is preparing your tax return. If you want to add or change anything, you will have the opportunity to discuss with your accountant before anything is filed with the IRS.',
        currentStep: 2,
    },
    {
        label: 'Internal Review',
        content: 'A manager is reviewing your Tax Return.',
        currentStep: 3,
    },
    {
        label: 'Client Review',
        content:
            'Please review and sign off your Tax Return. If you want to add or change anything, you will have the opportunity to discuss with your accountant before anything is filed with the IRS.',
        currentStep: 4,
    },
    {
        label: 'E-File',
        content: 'Your tax return has been e-filed.',
        currentStep: 5,
    },
    {
        label: 'Complete',
        content: 'Your tax return has been e-filed.',
        currentStep: 6,
    },
]

export const progressStepperForDiscardedScheduleC = [
    ...progressStepperContent.slice(0, 4),
    {
        label: 'Combined with your Personal Tax Return',
        content:
            'Your business tax details will be added to Schedule C and included with your 1040.',
        currentStep: 4,
    },
]

export const TAX_PREP_STEPS = [
    'Started',
    'Info Submitted',
    'Preparation',
    'Internal Review',
    'Client Review',
    'E-File',
    "Manager's Review",
    'Completed',
]

export const TAX_TYPE_8879_FLOW = [
    'tax_1120_Corporation',
    'tax_1120_s_corporation',
    'amended_1120_corporation',
    'amended_1120_s_corporation',
    'tax_1065_partnership',
    'amended_1065_partnership',
    'tax_1023_1024_non_profit',
    'tax_business_tbd',
]

export const TAX_ACCOUNTANT_MAPPING_AP = [
    'data_collection',
    'pre_prep_comp_review',
    'awaiting_auto_flow',
    'auto_flowed_missing_documents',
    'preparation',
    'preparation_data_entered',
    'additional_info_request_sent',
    'additional_info_received',
    'extension_filed_awaiting_client_info',
    'awaiting_internal_bookkeeping',
    'sta_review',
    'review',
    'review_owes',
    'review_in_progress',
    'corrections_needed',
    'corrections_addressed',
    'internal_bookkeeping_completed',
]

export const OWNER_MAPPING_AP = [
    'to_be_posted',
    'to_be_posted_owes',
    're_posting',
    'awaiting_client_approval',
    'awaiting_client_approval_owes',
    'internal_completed_govt_forms_unavailable',
    'revisions_needed',
    'revision_made',
    'e_filed_awaiting_govt_acceptance',
    'rejections',
    'completed',
    'completed_paper_filed',
    'e_file_complete_state_paper_filed',
    'long_term_pending',
    'discard',
    'discarded_schedule_c',
    'e_filed_awaiting_govt_acceptance_hand_signed',
    'lead',
]

export const DISCARDED_SCHEDULE_C = 'discarded_schedule_c'

export const TAX_PREP_STATUSES_WIDGET_EXCLUDE_ICONS = [
    'completed',
    'completed_paper_filed',
    'e_file_complete_state_paper_filed',
    'long_term_pending',
    'discarded_schedule_c'
]

export const VTO_SUBMITTED_STATUS = 1