import UiLottie from '../../../common/ui/UiLottie'
import WaveAnimation from '../../../../assets/animations/Wave.json'
import { commonStyles } from '../../../../styles/commonStyles'
import UiText from '../../../common/ui/UiText'
import { useStyles } from './IntroStep'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ArrowForward } from '@material-ui/icons'
import UiButton from '../../../common/ui/UiButton'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'

const OverviewStep = ({ goToNextStep, selectedYear, hideBtn = false }: any) => {
    const common = commonStyles()
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const {
        currentUser: { first_name, last_name },
    } = useCurrentStore()

    return (
        <div className={styles.container}>
            <div className={styles.center}>
                <UiLottie lottieData={WaveAnimation} />
                <UiText
                    className={`${common.mt16} ${common.capitalize} ${styles.title}`}
                    variant="suv_150"
                    weight="bold_700"
                >
                    {`Hi, ${first_name} ${last_name},`}
                </UiText>
            </div>
            <div className={`${common.mt16} ${common.mb40}`}>
                <UiText variant="suv_150" weight="bold_700">
                    Welcome to Tax Year {selectedYear} <br/> Personal Tax Preparation!
                </UiText>
                <UiText className={common.mtb16}>
                    We know taxes can be overwhelming. Our goal is to make this
                    experience as easy as possible—and to get you the most money
                    back of course!
                </UiText>
            </div>
            {!hideBtn && (
                <div className={styles.fixedButtonContainer}>
                    <UiButton
                        fullWidth
                        btnType="tertiary"
                        endIcon={<ArrowForward />}
                        handleClick={goToNextStep}
                        label="Next"
                    />
                </div>
            )}
        </div>
    )
}

export default OverviewStep
