import { useFormikContext } from 'formik'
import UiText from '../../../../../common/ui/UiText'
import UiFormControlSelection from './UiFormControl'
import { makeStyles } from '@material-ui/core'
import { useMemo } from 'react'
import { getValueByModelName } from '../../../common/VtoUtils'


const useStyles = makeStyles(()=>{
    return {
        singleQuestionContainer: {
            margin: '1rem 0 1rem 0'
        }
    }
})

const UiFileSetQuestions = (props: any) => {

    const {
        model,
        title,
        customPlaceholder,
        files
    } = props

    const { placeholder } =
        props?.options || {}

    const questionFieldName = props?.parentModel
        ? `${props?.parentModel}.${props?.index}.${model}`
        : model

    const { values, setFieldValue } = useFormikContext<any>()
    const styles = useStyles()

    const isFileChecked = useMemo(() => {
        const checked = files?.filter((file: any)=> getValueByModelName(file?.model, values))
       return checked?.length > 0
    },[files, values])


    const isFieldRequired = props.options?.required ||props.isRequired;
    return (
        <div>
            <div>
                <UiText variant={'car_100'} weight={'regular_400'}>
                    {title}{isFieldRequired && '*'}
                </UiText>
            </div>
            <div className={styles.singleQuestionContainer}>
                {files?.map((file: any, index: number) => {
                    return (
                        <div key={`question-${index}`} >
                            <UiFormControlSelection
                                fastField={true}
                                type="checkbox"
                                label={file?.title}
                                placeholder={placeholder || customPlaceholder}
                                fieldName={file?.model}
                                cypressId={model}
                                inlineLabel={file?.title}
                                onChange={(e: any) => {
                                    if(e.target.checked) {
                                        setFieldValue(file?.model, 1)
                                    } else {
                                        setFieldValue(file?.model, 0)
                                    }
                                }}
                            />
                        </div>
                    )
                })}
                <UiFormControlSelection
                    fastField={false}
                    type="checkbox"
                    label={`No, I didn't receive any documents`}
                    placeholder={placeholder || customPlaceholder}
                    fieldName={questionFieldName}
                    cypressId={model}
                    inlineLabel={`No, I didn't receive any documents`}
                    disabled={isFileChecked}
                    onChange={(e: any) => {
                        setFieldValue(model, e.target.checked)
                    }}
                />
            </div>
        </div>
    )

}

export default UiFileSetQuestions