import { useState } from 'react'
import {
    IconButton,
    Menu,
    MenuItem,
    makeStyles,
    Theme,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { ThemeColors } from '../../../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../../../common/whiteLabel/ColorThemeContext'
import UiText from '../../../../../common/ui/UiText'
import { ContainedDeleteButton } from '../../../../../common/DeleteButton'
import UiConfirmationDialog from '../../../../../common/ui/UiConfirmationDialog'
import Loader from '../../../../../common/Loader'
import { useVtoContext } from '../../../providers/VtoProvider'
import { removeUploadedFile } from '../../../../../../services/vto/vtoFileUpload'
import { requestErrorHandler } from '../../../../../../services/formService'
import { showAlert } from '../../../../../../store/actions/feedback'
import { toRem16 } from '../../../../../../styles/commonStyles'
import { UploadedFile } from '../../../../../../models'
import { DocumentType } from '../../../models/vto.document-model'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Icon from '../../../../../common/Icon'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        deleteButton: {},
        replaceButton: {},
        deleteLoader: {
            verticalAlign: 'middle',
            display: 'inline-flex',
            marginBottom: toRem16(2),
        },
        deleteFile: {
            marginTop: '2rem',
            marginBottom: '2rem',
        },
    }
})

//TODO - replace any with correct type
const FileActions = ({
    selectedFile,
    fileType,
    removeFile,
    fetchVto = true,
    openDialog,
    vtoDetails,
}: {
    selectedFile: UploadedFile
    fileType: DocumentType
    removeFile: (fileId: string) => void
    fetchVto?: boolean
    openDialog: () => void
    vtoDetails?:any;
}) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const dispatch = useDispatch()
    const { selectedYear, currentVtoDetails } = useVtoContext()
    const [anchorElDot, setAnchorElDot] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
        useState<boolean>(false)

    const openDot = Boolean(anchorElDot)
    const currentVto = vtoDetails || currentVtoDetails;

    const handleCloseDotMenu = () => {
        setAnchorElDot(null)
    }

    const closeConfirmMenu = () => {
        setShowDeleteConfirmModal(false)
    }

    const postDeleteAction = () => {
        closeConfirmMenu()
        const fileId = selectedFile.id
        removeFile(fileId)
        deleteConfirm()
    }

    const deleteCurrentFile = () => {
        const vtoId = currentVto.id
        const fileId = selectedFile.id
        setLoading(true)
        requestErrorHandler(
            removeUploadedFile(selectedYear, vtoId, {
                file: fileId,
                type: fileType,
            }).then((res: any) => {
                setLoading(false)
                postDeleteAction()
            }),
            true,
            () => {
                closeConfirmMenu()
            }
        )
    }

    const deleteConfirm = () => {
        dispatch(
            showAlert({
                alertText: 'The file has been removed successfully',
                alertType: 'success',
            })
        )
    }

    return (
        <div>
            <UiConfirmationDialog
                open={showDeleteConfirmModal}
                message={
                    <div>
                        <UiText weight="semi_bold_600" variant="hatchback_125">
                            Do you want to delete this document?
                        </UiText>

                        <UiText className={styles.deleteFile}>
                            {selectedFile?.name || selectedFile?.filename}
                        </UiText>
                    </div>
                }
                handleClose={() => {
                    setShowDeleteConfirmModal(false)
                }}
                confirmNode={
                    <>
                        {loading ? (
                            <div className={styles.deleteLoader}>
                                <Loader size={30} />
                            </div>
                        ) : (
                            <ContainedDeleteButton
                                variant="contained"
                                showDeleteIcon
                                onClick={() => {
                                    deleteCurrentFile()
                                }}
                                startIcon={<DeleteForeverIcon />}
                                data-cy="delete-action-btn"
                            >
                                Delete
                            </ContainedDeleteButton>
                        )}
                    </>
                }
                cancelButtonText="Cancel"
            />
            <IconButton
                onClick={(event) => {
                    anchorElDot
                        ? setAnchorElDot(null)
                        : setAnchorElDot(event.currentTarget)
                }}
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                id="long-menu-edt"
                anchorEl={anchorElDot}
                keepMounted
                open={openDot}
                onClose={handleCloseDotMenu}
            >
                <MenuItem
                    className={styles.replaceButton}
                    onClick={() => {
                        handleCloseDotMenu()
                        openDialog()
                    }}
                >
                    <Icon icon="refresh" />
                    &nbsp; &nbsp;<UiText>Replace</UiText>
                </MenuItem>
                <MenuItem
                    className={styles.deleteButton}
                    onClick={() => {
                        setShowDeleteConfirmModal(true)
                        handleCloseDotMenu()
                    }}
                >
                    &nbsp;
                    <Icon icon="delete" />
                    &nbsp; &nbsp;<UiText> Delete</UiText>
                </MenuItem>
            </Menu>
        </div>
    )
}

export default FileActions
