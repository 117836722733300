// Popup modal that appears before the PVTO, if BusinessVTO is not filled updateBusinessVto

import { IconButton, makeStyles } from '@material-ui/core'
import UiText from '../../common/ui/UiText'
import { useHistory } from 'react-router-dom'
import UiDialog from '../../common/ui/UiDialog'
import { Clear } from '@material-ui/icons'
import { toRem16 } from '../../../styles/commonStyles'
import UiButton from '../../common/ui/UiButton'
import { useVtoContext } from '../vto/providers/VtoProvider'

const useStyles = makeStyles(() => {
    return {
        flexContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        marginText: {
            marginTop: toRem16(18),
        },
        buttonStyles: {
            margin: '2rem 0 2rem 0',
            display: 'flex',
            justifyContent: 'center',
        },
        textContainer: {
            textAlign: 'center',
            '& .link-btn': {
                marginTop: '0.8rem',
                marginBottom: '0.8rem',
            },
        },
    }
})

const BusinessDetailsReminderModal = ({
    open,
    handleClose,
}: {
    open: boolean
    handleClose: () => void
}) => {
    const classes = useStyles()
    const history = useHistory()
    const { selectedYear } = useVtoContext()
    return (
        <div>
            <UiDialog
                open={open}
                handleClose={handleClose}
                size="sm"
                hideTitleSection
            >
                <div className={classes.textContainer}>
                    <div className={classes.flexContainer}>
                        <div>
                            <IconButton
                                onClick={handleClose}
                                data-cy="close-move-modal"
                            >
                                <Clear />
                            </IconButton>
                        </div>
                    </div>
                    <UiText variant="truck_175" weight="bold_700">
                        Your business tax info is incomplete
                    </UiText>
                    <UiText className={classes.marginText}>
                        We recommend that you complete your business tax info
                        before doing your personal tax preparation. We will need
                        your business details in order to complete your personal
                        tax return.
                    </UiText>
                    <div className={classes.buttonStyles}>
                        <UiButton
                            btnType="tertiary"
                            handleClick={() => {
                                history.push('/tax-organizer/business')
                            }}
                            label="Go to Business Tax Info"
                        />
                    </div>
                    <div className={'link-btn'}>
                        <UiButton
                            btnType="hyperlink"
                            handleClick={handleClose}
                            customLabel={
                                <UiText
                                    variant="motorcycle_90"
                                    weight="semi_bold_600"
                                >
                                    I don’t have Business Taxes for{' '}
                                    {selectedYear}
                                </UiText>
                            }
                        />
                    </div>
                </div>
            </UiDialog>
        </div>
    )
}

export default BusinessDetailsReminderModal
