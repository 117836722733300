import { makeStyles, Typography, Button, Theme } from '@material-ui/core';
import { Fragment, useState, useEffect, useRef } from 'react';
import UiDialog from '../common/ui/UiDialog';
import { refreshToken, logout } from '../../services/authService';
import { getFromLocalStorage } from '../../services/localStorageService';
import Loader from '../common/Loader';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';

export default function LogoutModal() {
    const { colorTheme } = useThemeContext()
    let [showLogoutModal, setShowLogoutModal] = useState(false);
    const timerIdRef = useRef(0);
    let [second, setSecond] = useState(0);
    let ttl = 1 * 60 * 1000;
    const [refreshTokenInProgress, setRefreshTokenInProgress] = useState(false);

    const classes = makeStyles((theme: Theme) => ({
        formActions: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px 0px',
            marginTop: 10,
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
        },
        bodySection: {
            paddingTop: 20,
            paddingBottom: theme.spacing(5),
        },
        cancelButton: {
            marginLeft: theme.spacing(2),
            color: `${colorTheme.black100} !important`,
            [theme.breakpoints.down('xs')]: {
                margin: theme.spacing(2, 0),
            },
        },
        TextCenter: {
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                textAlign: 'left',
            },
        },
    }))();

    const handleModalClose = (result: any) => {
        if (result === false) {
            logout();            
        } else {
            setRefreshTokenInProgress(true);
            refreshToken().then(() => {
                setShowLogoutModal(false);
                stopTracking();
                setRefreshTokenInProgress(false);
                trackLogout();
            });
        }
    };

    const stopTracking = () => {
        clearInterval(timerIdRef.current);
        timerIdRef.current = 0;
    };

    const timeToLogout = (auth: any) => {
        return (
            auth &&
            auth.expires_in * 1000 + auth.logged_in - 90 * 1000 <
                new Date().getTime()
        );
    };

    const timeToShowModal = (auth: any, ttl: any) => {
        return (
            auth &&
            auth.expires_in * 1000 + auth.logged_in - ttl - 90 * 1000 <
                new Date().getTime()
        );
    };

    const timeLeft = (auth: any) => {
        return (
            auth &&
            Math.round(
                (auth.expires_in * 1000 +
                    auth.logged_in -
                    90 * 1000 -
                    new Date().getTime()) /
                    1000
            )
        );
    };

    useEffect(() => {
        return () => clearInterval(timerIdRef.current);
    }, []);

    const trackLogout = () => {
        let auth = getFromLocalStorage('auth');
        if (timerIdRef.current) {
            return;
        }
        //stopTracking();
        timerIdRef.current = window.setInterval(function () {
            auth = getFromLocalStorage('auth');
            if (timeToLogout(auth)) {           
                logout();            
            } else {
                if (showLogoutModal) {
                    return;
                }
                if (timeToShowModal(auth, ttl)) {
                    /*if (app.page && app.page.name === 'vto') {
                    callback(true);
                    return;
                }*/
                    setSecond(timeLeft(auth));
                    setShowLogoutModal(true);
                }
            }
        }, 1000);
    };

    if (!timerIdRef.current) {
        trackLogout();
    }

    return (
        <UiDialog
            open={showLogoutModal}
            handleClose={handleModalClose}
            title=''
            size='sm'
            hideTitleSection={true}
        >
            <Fragment>
                <div className={classes.bodySection}>
                    <Fragment>
                        <Typography
                            className={classes.TextCenter}
                            variant='h6'
                            style={{ marginBottom: 24 }}
                        >
                            To protect your information, you will be
                            <br />
                            automatically signed out in {second} seconds.
                        </Typography>
                        <Typography
                            className={classes.TextCenter}
                            variant='body1'
                        >
                            To remain signed in, extend you session.
                        </Typography>
                    </Fragment>
                </div>
                {refreshTokenInProgress ? (
                    <Loader justifyContent='center' alignItems='center' />
                ) : (
                    <div className={classes.formActions}>
                        <Button
                            variant='contained'
                            onClick={() => handleModalClose(true)}
                            color='primary'
                        >
                            Extend Session
                        </Button>
                        <Button
                            variant='outlined'
                            onClick={() => handleModalClose(false)}
                            className={classes.cancelButton}
                        >
                            Sign Out Now
                        </Button>
                    </div>
                )}
            </Fragment>
        </UiDialog>
    );
}
