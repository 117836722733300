import UiFormControlSelection from './UiFormControl'

const UiOptionsQuestions = (props: any)=> {
    const {
        model,
        selectOptions
    } = props

    const { 
        optionsKey,
        optionsLabel
    } = props?.options || {}

    const questionFieldName = props?.parentModel
        ? `${props?.parentModel}.${props?.index}.${model}`
        : model
 
    return (
        <div>
            <UiFormControlSelection
                fastField={true}
                type="select"
                optionKey={optionsLabel}
                optionValue={optionsKey}
                fieldName={questionFieldName}
                optionsData={selectOptions}
            /> 
        </div>
    )
}

export default UiOptionsQuestions