import { useVtoContext } from '../../../providers/VtoProvider'
import { Container, Grid, Hidden, makeStyles, Theme } from '@material-ui/core'
import leftArrow from '../../../../../../assets/icons-svg/LeftArrow.svg'
import VTOQuestionSection from './VTOQuestionSection'
import RequiredDocumentsStep from '../../../steps/UploadStep/RequiredDocumentsStep'
import { StepMenu } from './StepMenu/StepMenu'
import BusinessIntroduction from '../../../steps/Introduction/BusinessIntroduction'
import PersonalIntroduction from '../../../steps/Introduction/PersonalIntroduction'
import useDeviceSize from '../../../../../../hooks/useDeviceSize'
import VTOSubmit from './vtoSubmit/VTOSubmit'
import { toRem16 } from '../../../../../../styles/commonStyles'
import { hasReviewedAllSections, isAllStepDoneExceptSubmit, vtoCompletionPercent } from '../../../common/SectionUtil'
import LinearProgressBar from '../../../../../common/LinearProgressBar'
import UiButton from '../../../../../common/ui/UiButton'
import { useMenuStyles } from './StepMenu/MenuStyles'
import { useThemeContext } from '../../../../../common/whiteLabel/ColorThemeContext'
import Individual1040FormUpload from '../../../../personal-tax-information/Steps/1040/Individual1040FormUpload'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { NavigationButton } from '../sections/SectionQuestionsRenderer'
import { commonVtoStyles } from '../../styles/commonVtoStyles'
import FaqDrawer from '../../../../../common/FaqDrawer'
import { BUSINESS_MOBILE_ROUTE, BUSINESS_VTO_ROUTE, HiddenFaqSidebarSections, PERSONAL_MOBILE_ROUTE, PERSONAL_VTO_ROUTE } from '../../../models/vto.const'
import { ThemeColors } from '../../../../../../styles/models/Colors.interface'
import Icon from '../../../../../common/Icon'
import UiText from '../../../../../common/ui/UiText'
import { FAQ } from '../../../models/VtoDetails.model'
import { UnsavedSectionWarning } from '../../../../../payroll/company/UnsavedSectionWarning'
import { useFormikContext } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { isVTOFormDirty } from '../../../common/VtoUtils'
import { getRequiredFiles } from '../../../steps/UploadStep/UploadFileUtils'
import Loader from '../../../../../common/Loader'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            gap: '2rem',
            [theme.breakpoints.down('sm')]: {
                padding: '0',
                display: 'block',
            },
        },
        content: {
            paddingLeft: '0.5rem',
            overflowY: 'auto',
            maxHeight: `calc(100vh - ${toRem16(147)})`,
            [theme.breakpoints.down('sm')]: {
                maxHeight: 'unset',
                padding: 0,
                width: '100%',
            },
            '&::-webkit-scrollbar-thumb':{
                background: (colorTheme) => colorTheme.grey2700
            }
        },
        gridContainer: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                padding: '0',
            },
        },
        desktopContainer: {
            display: 'flex',
        },
        btnActionsContainer: {
            padding: '0 1rem',
        },
        stepsContainer: {
            '& > div:first-of-type': {
                padding: '0 0.5rem',
                [theme.breakpoints.down('sm')]: {
                    padding: '0',
                },
            },
        },
        positionFixedTrick: {
            // used to position confirm & continue button w.r.t this grid container.
            [theme.breakpoints.up('md')]: {
                transform: 'translateX(0)',
            },
        },
        helpBtnContainer: {
            position: 'fixed',
            bottom: toRem16(24),
            right: toRem16(24),
            boxShadow: '0px 4px 16px 0px #00000029',
            borderRadius: toRem16(48),
            backgroundColor: (colorTheme) => colorTheme.primaryWhite,
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            height: toRem16(40),

            [theme.breakpoints.down('sm')]: {
                bottom: toRem16(169),
                height: 'auto',
            },
        },
        helpBtn: {
            padding: '1rem',

            [theme.breakpoints.up('md')]: {
                height: '100%',
            },

            '& svg path': {
                strokeOpacity: 1,
            },
        },
    }
})

const IntroductionStep = ({ isBusinessVTO }: any) => {
    return (
        <>
            {isBusinessVTO ? (
                <BusinessIntroduction />
            ) : (
                <PersonalIntroduction />
            )}
        </>
    )
}

const StepScan1040Form = () => {
    const { colorTheme } = useThemeContext()
    const classes = commonVtoStyles(colorTheme)
    const { goToPreviousStep } = useVtoContext()

    return (
        <>
            {/* <div className={classes.stickyTop}>
                <NavigationButton
                    label="Previous"
                    startIconSvg={leftArrow}
                    handleClick={goToPreviousStep}
                />
            </div> //have to uncomment whe we enable the intro step*/}
            <Individual1040FormUpload isUnderVTOSection={true} />
        </>
    )
}

const RenderStep = ({ step, isBusinessVTO }: any) => {
    switch (step) {
        case 'introduction':
            return <IntroductionStep isBusinessVTO={isBusinessVTO} />
        case 'upload_center':
            return <RequiredDocumentsStep />
        case '1040_form_scan':
            return <StepScan1040Form />
        default:
            return <VTOQuestionSection section={step} />
    }
}

export const VTOSteps = () => {
    const [submitting, setSubmitting] = useState(false);
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const classes = useMenuStyles(colorTheme)
    const { isSmDevice, isXLDesktop, isXsDevice } = useDeviceSize()

    const {
        onMenuItemClick,
        currentStep,
        currentYearMetaData,
        currentVtoDetails,
        isBusinessVTO,
        fullSectionView,
        setFullSectionView,
        setHighlightIncompleteSections,
        menuItems,
        questionsContainerRef,
        vtoAllowedSteps,
        currentStepFaqs,
        setCurrentStep,
        setOpenFaqs,
        setCurrentStepFaqs,
        showUnsavedChangesModal,
        setShowUnsavedChangesModal,
        goToPreviousStep,
        submitValues,
        setParentStep,
        updateVTO
    } = useVtoContext()
    const { setErrors, values, resetForm, validateForm, initialValues, touched, setTouched } = useFormikContext()
    const history = useHistory();
    const parentStepRef = useRef<any>();
    const currentStepRef = useRef<string|null>(null);

    const matchMenu = useRouteMatch([BUSINESS_VTO_ROUTE, PERSONAL_VTO_ROUTE])
    const matchContent = useRouteMatch(
        [BUSINESS_MOBILE_ROUTE, PERSONAL_MOBILE_ROUTE]
    )

    const completionPercentage = () => {
        if (!currentYearMetaData?.Steps) return {percentage: 0, isAllStepDone: false}
        return vtoCompletionPercent(
            vtoAllowedSteps,
            currentYearMetaData,
            currentVtoDetails
        )
    }

    const handleFormReset = () => {
        resetForm()
        validateForm().then((errors: any) => setTouched({ ...touched, ...errors }))
    }

    const handleOnMenuItem = (parentStep: any, menu: string) => {
        parentStepRef.current = null;
        currentStepRef.current = null;

        onMenuItemClick(menu);
        setParentStep(parentStep);
        handleFormReset();
        if (isXsDevice) {
            history.push(`${history.location.pathname}/${menu}`)
        }
    }

    const handleMenuItemClick = (parentStep: any, menu: string) => {
        const dirty = isVTOFormDirty(initialValues, values);

        if (!dirty) return handleOnMenuItem(parentStep, menu)

        parentStepRef.current = parentStep
        currentStepRef.current = menu
        setShowUnsavedChangesModal(true);
    }

    const { percentage } = completionPercentage()

    const { requiredFilesCount } = getRequiredFiles(
        values as any,
        currentYearMetaData
    )
    const isCompleted =
        currentYearMetaData?.Steps &&
        isAllStepDoneExceptSubmit(
            menuItems,
            requiredFilesCount,
            values,
            currentYearMetaData
        )
    

    const submitUploadsData = () => {
        setSubmitting(true);
        const val = values as any;
        const sectionsReviewed = hasReviewedAllSections(menuItems, values);

        if(isCompleted && sectionsReviewed) {
            updateVTO(val?.id, values, ()=> {
                setFullSectionView(true)
                setSubmitting(false)
            }, true)
        } else {
            updateVTO(val?.id, values, ()=> {
                setSubmitting(false)
                setHighlightIncompleteSections(true)
            })
        }
    }


    useEffect(()=> {
         // run a validation on the form to get the errors
        // needed on initial mount of step
        validateForm().then((errors: any) =>
            setTouched({ ...touched, ...errors })
        )
    },[currentStep])

    const renderMenu = () => {
        return (
            <Grid
                item
                md={4}
                sm={12}
                justify="flex-start"
                alignItems="flex-start"
                className={classes.gridContainer}
            >
                {isSmDevice && currentStep && !menuItems?.length ? null : (
                    <div>
                        <div className={styles.btnActionsContainer}>
                            <LinearProgressBar
                                percentage={percentage}
                            />
                            <UiButton
                                customClass={classes.submitButton}
                                handleClick={submitUploadsData}
                                label="Submit Your Tax Info"
                                btnType="tertiary"
                                disabled={submitting}
                            />
                        </div>
                        <div className={styles.stepsContainer}>
                            <StepMenu
                                //@ts-ignore
                                menuItems={menuItems}
                                onMenuItemClick={handleMenuItemClick}
                                currentStep={currentStep}
                                showContentPage={() => {
                                    setFullSectionView(true)
                                }}
                            />
                        </div>
                    </div>
                )}
            </Grid>
        )
    }

    const renderContent = () => {
        if (submitting) {
            return (
                <div className={classes.centeredLoader}>
                    <Loader />
                </div>
            )
        }

        return (
            <Grid
                className={styles.positionFixedTrick}
                item
                xs={12}
                md={8}
                justify="center"
            >
                <div ref={questionsContainerRef} className={styles.content}>
                    {isSmDevice && currentStep === '' ? null : (
                        <RenderStep
                            step={currentStep}
                            isBusinessVTO={isBusinessVTO}
                        />
                    )}
                </div>
            </Grid>
        )
    }

    const handleGoBackfromSubmitFlow = () => {
        if (isBusinessVTO) {
            setCurrentStep('business_type')
        } else {
            setCurrentStep('general')
        }
        setFullSectionView(false)
    }

    const handleUnsavedChangesModal = (action:string) => {
        const parentStep = parentStepRef.current;
        const currentStep = currentStepRef.current;
        setShowUnsavedChangesModal(false);

        const discardHandler = currentStep ? handleOnMenuItem : goToPreviousStep;

        if(action === 'discard'){
            resetForm() 
            setTimeout(()=>discardHandler(parentStep, currentStep!),0)
        }
        if(action === 'save'){
            setErrors({})
            submitValues(values, () => {
                discardHandler(parentStep, currentStep!);
            })
        }
    }

    const currentStepHaveFaqs =
        !!currentStepFaqs.length &&
        !HiddenFaqSidebarSections.includes(currentStep)

    return (
        <Container maxWidth="lg">
            {fullSectionView ? (
                <VTOSubmit
                    hideContentPage={handleGoBackfromSubmitFlow}
                    percentage={percentage}
                />
            ) : (
                <Grid container spacing={2}>
                    <Hidden mdUp>
                        {matchMenu && !matchContent && renderMenu()}
                        {matchContent && renderContent()}
                    </Hidden>
                    <Hidden smDown>
                        {renderMenu()}
                        {renderContent()}
                    </Hidden>

                    {isXLDesktop ? (
                        currentStepHaveFaqs ? (
                            <FaqDrawer />
                        ) : null
                    ) : (
                        <>
                            <FaqDrawer />
                            {currentStepHaveFaqs && (
                                <div className={styles.helpBtnContainer}>
                                    <UiButton
                                        customClass={styles.helpBtn}
                                        icon={
                                            <Icon
                                                icon="info"
                                                strokeColor={colorTheme.blue200}
                                            />
                                        }
                                        btnType="hyperlink"
                                        customLabel={
                                            <UiText weight="semi_bold_600">
                                                Help
                                            </UiText>
                                        }
                                        handleClick={() => {
                                            setCurrentStepFaqs((prev: FAQ[]) =>
                                                prev.map((faq) => ({
                                                    ...faq,
                                                    selected: false,
                                                }))
                                            )
                                            setOpenFaqs(true)
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}

                    <UnsavedSectionWarning
                        size="xs"
                        open={!!showUnsavedChangesModal}
                        handleModalAction={handleUnsavedChangesModal}
                    />
                </Grid>
            )}
        </Container>
    )
}
