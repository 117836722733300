import { useContext, useEffect, useState } from 'react'
import {
    Button,
    Grid,
    List,
    ListItem,
    makeStyles,
    Theme,
    Typography,
    Icon,
    Hidden,
    useMediaQuery,
    Link,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { usePlaidLink } from 'react-plaid-link'
import { ApplicationStore } from '../../../models'
import { connect } from 'react-redux'
import LinkButton from '../../common/LinkButton'
import { useHistory } from 'react-router-dom'
import {
    getBankAccountGroupList,
    getFinicityFullModal,
} from '../../../services/apiService/bankAccount'
import { saveBusinessDetails } from '../../../services/apiService'
import { showInfo } from '../../../store/actions/feedback'
import {
    getBankingPageLinkToken,
    getBankingPageExchangeToken,
} from '../../../services/apiService/bankAccount'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import '../../../styles/finicity.css'
import { commonStyles, mergeStyle } from '../../../styles/commonStyles'
import { useCurrentStore } from '../../common/hooks/useCurrentStore'
import UiSnackbarAlert from '../../common/ui/UiSnackbarAlert'
import Loader from '../../common/Loader'
import ConnectedAccounts from './ConnectedAccounts'
import ManualAccountModal from './ManualAccountModal'
import {
    BANKING_PAGE_ERRORS,
    QUERY_SUBSTRING,
    REDIRECT_URL,
} from './constants/banking.const'
import { ConnectionType } from './constants/banking.const'
import UiText from '../../common/ui/UiText'
import { useBankDetailsState } from './provider/BankDetailsProvider'
import { hasPermission } from '../../../services/aclService'

declare global {
    interface Window {
        finicityConnect: any
    }
}

const styles = makeStyles((theme: Theme) => ({
    rootManual: {
        flexGrow: 1,
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        flexDirection: 'column',
    },
    title: {
        fontSize: '34px',
        lineHeight: '40px',
        textAlign: 'center',
    },
    smTitle: {
        marginTop: '20px',
        marginLeft: '20px',
    },
    marginContent: {
        marginTop: '40px',
        marginBottom: '30px',
    },
    title2: {
        fontSize: '20px',
    },
    listRoot: {
        backgroundColor: theme.palette.background.paper,
        marginTop: '16px',
    },
    listItem: {
        width: '100%',
        marginLeft: '11px',
        paddingTop: '7px',
        paddingBottom: '7px',
    },
    info: {
        width: '100%',
        fontSize: theme.breakpoints.down('sm') ? '12px' : '18px',
        textAlign: 'center',
    },
    info2: {
        width: '100%',
        marginTop: '12px',
        textAlign: 'center',
    },
    separator: {
        width: '100%',
        textAlign: 'center',
        margin: '50px',
    },
    newBankButton: {
        width: theme.breakpoints.down('md') ? '200px' : '300px',
            },
    checkImg: {
        width: '22px',
    },
    closeManual: {
        top: -'5px',
    },
    overlay: {
        width: '100%',
        height: '100vh',
        top: 0,
        left: 0,
        background: 'rgba(0,0,0,0.8)', // to do
        position: 'fixed',
        zIndex: 1400,
    },
    modalContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // to do
        zIndex: 1301,
    },
    upgrade: {
        '&:hover': {
            cursor: 'pointer',
        },
        marginLeft: '.4rem',
    },
}))

function NewBank(props: any) {
    const classes = styles()
    const commonStyle = commonStyles()
    const history = useHistory()
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const [loading, setLoading] = useState(false)
    const [showFinicityModal, setShowFinicityModal] = useState(false)
    const [showConnectedAccounts, setShowConnectedAccount] = useState(false)
    const [openManualAccountModal, setOpenManualAccountModal] = useState(false)

    const [linkToken, setLinkToken] = useState<string | null>(null)
    const [publicToken, setPublicToken] = useState<string | null>(null)
    const [plaidBankAccountInfo, setPlaidBankAccountInfo] = useState({}); 
    const [metaData, setMetaData] = useState<any>(null)
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [reloadToken, setReloadToken] = useState<boolean>(false)
    const isOAuthRedirect = window.location.href.includes('?oauth_state_id=')

    const {
        currentAccountId,
        currentBusinessId,
        currentBusiness,
        accessLevel,
        currentUser,
    } = useCurrentStore()

    const { bankGroups, isFincity, loading: loadBankingProviderStatus, error }  = useBankDetailsState()
    const connectionType = !isFincity
        ? ConnectionType.Plaid
        : ConnectionType.Finicity

    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )

    const { personal_account } = props.appData;
    const BOOKKEEPING_PERMISSION = 'ALL_BOOKKEEPING'

    const getBankAccounts = () => {
        return getBankAccountGroupList(
            currentAccountId,
            currentBusinessId || ''
        )
    }

    const resetOAuthState = () => {
        localStorage.removeItem('bankingLinkToken')
        window.location.replace(window.location.href.split('?')[0])
        setReloadToken(true)
    }

    useEffect(() => {
        error && setErrorMessage(BANKING_PAGE_ERRORS.GENERIC_ERROR)
    }, [error])

    useEffect(() => {
        setLoading(true)
        const token = localStorage.getItem('bankingLinkToken')
        if (token && isOAuthRedirect) {
            setLinkToken(token)
            setLoading(false)
            return
        }
        getBankingPageLinkToken(currentBusinessId || '')
            .then((res) => {
                setLinkToken(res?.link_token)
                localStorage.setItem('bankingLinkToken', res.link_token)
                setLoading(false)
                setReloadToken(false)
            })
            .catch((err) => {
                setErrorMessage(
                    err?.response?.data?.message ||
                        BANKING_PAGE_ERRORS.GENERIC_ERROR
                )
                setLoading(false)
                setShowSnackbar(true)
            })
    }, [currentBusinessId, reloadToken])

    useEffect(() => {
        if (publicToken) {
            setLoading(true)
            getBankingPageExchangeToken(currentBusinessId || '', metaData)
                .then((res) => {
                    history.push('/bank_accounts')
                    setLoading(false)
                    localStorage.removeItem('bankingLinkToken')
                })
                .catch((err) => {
                    saveBusinessWithPlaidAccount(metaData)
                    setErrorMessage(
                        err?.statusText || err?.response?.data?.message ||
                            BANKING_PAGE_ERRORS.GENERIC_ERROR
                    )
                    if (isOAuthRedirect) {
                        resetOAuthState()
                    }
                    setLoading(false)
                    setShowSnackbar(true)
                })
        }
    }, [publicToken, currentBusinessId])

    const saveBusinessWithPlaidAccount = (metaData?: any) => {
        const plaidAccountMetadata = {
            ...plaidBankAccountInfo,
            link_token: linkToken,
            status: 'Failure',
            link_session_id: metaData?.link_session_id,
            last_access_at: Math.floor(Date.now() / 1000),
        }

        const businessData = {
            ...currentBusiness,
            plaid_bank_account_info: plaidAccountMetadata,
        }

        return saveBusinessDetails(
            businessData,
            currentAccountId,
            currentBusinessId as string
        ).then((res) => {
            return res
        })
    }

    const onSuccess = async (publicToken: string, metadata: any) => {
        localStorage.removeItem('bankingLinkToken')
        try {
             setLoading(false)
            setMetaData(metadata)
            setPlaidBankAccountInfo({...plaidBankAccountInfo, link_session_id: metaData?.link_session_id})
            setPublicToken(publicToken)
        } catch (err: any) {
            setLoading(false)
            setErrorMessage(
                err?.response?.data?.message ||
                    BANKING_PAGE_ERRORS.GENERIC_ERROR
            )
        }
    }

    const onExit = (err: any, metadata: any) => {
        if (err != null) {
            const error = err?.error_message
            setErrorMessage(
                `${error?.charAt(0).toUpperCase()}${error?.slice(1)}` ||
                    BANKING_PAGE_ERRORS.GENERIC_ERROR
            )
            setShowSnackbar(true)
            if (isOAuthRedirect) {
                resetOAuthState()
            }
        }
        saveBusinessWithPlaidAccount(metadata)
    }

    const config: any = {
        token: linkToken,
        onSuccess,
        onExit,
    }

    if (isOAuthRedirect) {
        const baseUrl = window.location.origin
        const url = window.location.href
        const queryString = url.split('?')[1]
        const redirectURL = url.includes('localhost')
            ? `${REDIRECT_URL.UAT}/${QUERY_SUBSTRING}${queryString}`
            : `${baseUrl}/${QUERY_SUBSTRING}${queryString}`
        config.receivedRedirectUri = redirectURL
    }

    const { open: openPlaidLink, ready } = usePlaidLink({
        ...config,
    })

    useEffect(() => {
        if (isOAuthRedirect && ready) {
            setErrorMessage('')
            openPlaidLink()
        }
    }, [ready, openPlaidLink, isOAuthRedirect])

    useEffect(() => {
        setActiveRouteHeading('Banking')
        const script = document.createElement('script')
        script.src =
            'https://connect2.finicity.com/assets/sdk/finicity-connect.min.js'
        script.async = true
        document.body.appendChild(script)
    }, [setActiveRouteHeading])

    const checkIcon = (
        <Icon>
            <img
                alt={`link`}
                className={classes.checkImg}
                src={require(`../../../assets/icons-svg/checkIcon.svg`).default}
            />
        </Icon>
    )

    const closeFinicityModal = () => {
        setShowFinicityModal(false)
        window.finicityConnect.destroy()
    }

    const openFinicityModal = () => {
        Promise.all([getFinicityUrl()])
            .then((res: any) => {
                setShowFinicityModal(true)
                window.finicityConnect.launch(res[0].link, {
                    selector: '#finicity-iframe-container',
                    success: function (data: any) {
                        closeFinicityModal()
                        setLoading(false)
                        setShowConnectedAccount(true)
                    },
                    cancel: function () {
                        closeFinicityModal()
                    },
                    error: function () {
                        closeFinicityModal()
                    },
                    loaded: function (data: any) {},
                })
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const getFinicityUrl = () => {
        const { current_business_id } = props.appData
        return getFinicityFullModal(current_business_id)
    }
 
    if (loading || loadBankingProviderStatus) {
        return (
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{ height: '100%' }}
            >
                <Loader />
            </Grid>
        )
    }

    const handleBankingSelection = () => {
        !isFincity? openPlaidLink() : openFinicityModal()
    }

    const isOAuthFlow = isOAuthRedirect && !publicToken && errorMessage === '';
    return isOAuthFlow ? (
        <></>
    ) : (
        <div>
            <ConnectedAccounts
                openModal={showConnectedAccounts}
                handleClose={() => {
                    setShowConnectedAccount(false)
                    history.push('/bank_accounts')
                }}
            />
            <ManualAccountModal
                openModal={openManualAccountModal}
                handleClose={() => {
                    setOpenManualAccountModal(false)
                }}
            />
            <div className={classes.marginContent}></div>
            <Hidden smUp>
                <span className={classes.smTitle}>Banking</span>
            </Hidden>
            <Typography
                variant={smDevice ? 'subtitle1' : 'h2'}
                className={smDevice ? classes.smTitle : classes.title}
            >
                Simplify your experience by <br /> securely connecting your bank
            </Typography>

            <div
                className={
                    smDevice
                        ? ''
                        : mergeStyle(
                              commonStyle.flex,
                              commonStyle.justifyCenter
                          )
                }
            >
                <List className={classes.listRoot}>
                    <ListItem>
                        {checkIcon}
                        <Typography
                            variant="body2"
                            className={classes.listItem}
                        >
                            Always up to date so you know your business’s
                            financial health
                        </Typography>
                    </ListItem>
                    <ListItem>
                        {checkIcon}
                        <Typography
                            variant="body2"
                            className={classes.listItem}
                        >
                            Saves your time with automatic transaction syncing
                        </Typography>
                    </ListItem>
                    <ListItem>
                        {checkIcon}
                        <Typography
                            variant="body2"
                            className={classes.listItem}
                        >
                            Stay in control of your data with a safe and secure
                            connection
                        </Typography>
                    </ListItem>
                </List>
            </div>
            <div
                className={mergeStyle(
                    commonStyle.flex,
                    commonStyle.justifyCenter,
                    classes.marginContent
                )}
            >
                <Button
                    className={classes.newBankButton}
                    variant="contained"
                    color="primary"
                    disabled={
                        error ||
                        (!accessLevel.platform &&
                            !hasPermission(BOOKKEEPING_PERMISSION, personal_account) &&
                            !currentUser.accountant_mode &&
                            bankGroups.length === 1)
                    }
                    startIcon={<AddIcon />}
                    onClick={handleBankingSelection}
                >
                    Connect my Bank
                </Button>
            </div>
            <Typography
                variant="body2"
                color="textSecondary"
                className={classes.info2}
            >
                Accounts are securely connected through {connectionType}.
            </Typography>
            {!accessLevel.platform && !hasPermission(BOOKKEEPING_PERMISSION, personal_account) && !currentUser.accountant_mode && (
                <UiText className={classes.info2}>
                    Need to connect more than one bank?
                    <Link
                        className={classes.upgrade}
                        onClick={() => {
                            history.push('/purchase/upgrade-portal-access')
                        }}
                    >
                        Upgrade your Plan
                    </Link>
                </UiText>
            )}
            <Typography
                variant="body2"
                color="textSecondary"
                className={classes.info2}
            >
                Or
            </Typography>

            <div
                className={mergeStyle(
                    commonStyle.flex,
                    commonStyle.justifyCenter,
                    classes.marginContent
                )}
            >
                <LinkButton
                    disabled={
                        !accessLevel.platform &&
                        !hasPermission(BOOKKEEPING_PERMISSION, personal_account) &&
                        !currentUser.accountant_mode &&
                        bankGroups.length === 1
                    }
                    onClick={() => setOpenManualAccountModal(true)}
                >
                    Create a Manual Account
                </LinkButton>
            </div>

            <Typography
                variant="body2"
                color="textSecondary"
                className={classes.info}
            >
                Manual accounts do not connect directly to the bank <br /> and
                will require manual entry of all transactions.
            </Typography>

            {showFinicityModal ? (
                <div onClick={() => closeFinicityModal()}>
                    <div id="finicity-iframe-container"></div>
                    <Grid className={classes.overlay}></Grid>
                </div>
            ) : (
                <Grid></Grid>
            )}
            <UiSnackbarAlert
                open={showSnackbar}
                message={errorMessage}
                handleClose={() => {
                    setShowSnackbar(false)
                }}
                actionButtonColor={'primary'}
                severity="error"
            />
        </div>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
})

export default connect(mapStateToProps, { showInfo })(NewBank)
