import { Grid, Hidden, Theme } from '@material-ui/core'
import UiText from '../../common/ui/UiText'
import Icon from '../../common/Icon'
import TaxPreparationStatuses from '../vto/core/components/TaxPrepStatuses/TaxPreparationStatuses'
import { useVtoContext } from '../vto/providers/VtoProvider'
import { makeStyles } from '@material-ui/styles'
import { toRem16 } from '../../../styles/commonStyles'
import Loader from '../../common/Loader'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import StatusImage from '../vto/core/components/TaxPrepStatuses/StatusImage'
import { useWidgetContext } from '../../dashboard/tax-prep/provider/WidgetProvider'
import AccountantDetails from '../vto/core/components/TaxPrepStatuses/AccountantDetails'
import { ReactComponent as InfoIcon } from '../../../assets/icons-svg/InfoI.svg'
import useDeviceSize from '../../../hooks/useDeviceSize'
import InfoTooltip from '../../common/InfoTooltip'
import { ESTIMATED_COMPLETION_DAYS, OWNER_MAPPING_AP, renderFor, TAX_ACCOUNTANT_MAPPING_AP } from '../../dashboard/tax-prep/constants/tax-prep.const'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        container: {
            height: '100%',
        },
        listContent: {
            paddingBottom: theme.spacing(3),
            width: 'inherit',
            height: '100%',
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up(1601)]: {
                justifyContent: 'flex-start',
            },
            marginRight: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginTop:'1rem',
                padding:'1rem',
                borderTop: (colorTheme) => `1px solid ${colorTheme.black100}`,
            },
        },
        menuStyle: {
            '& .MuiButtonBase-root': {
                height: `${toRem16(40)} !important`,
                padding: `${toRem16(14)} !important`,
                border: (colorTheme) => `1px solid ${colorTheme.black100}`,
            },
        },
        stepContainer: {
            marginTop: '2.5rem',
            [theme.breakpoints.down('sm')]: {
                marginTop:0
            },
            '& .accountant-container': {
                height: `${toRem16(50)}`
            }
        },
        stepList: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '1rem',
            '& .step-name': {
                margin: '1rem',
            },
            '& > div': {
                display: 'flex',
                alignItems: 'center',
            }
        },
        estimatedInfoContent: {
            marginLeft: '2.4rem',
            backgroundColor: colorTheme => colorTheme.blue300,
            borderRadius: '4px',
            padding: '1rem',
        },
        rightSection: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start', 
            flexDirection: 'column',
            height: '100%',
            marginTop: '2.5rem'
        }
    }
})
const ESTIMATED_COMPLETION_TOOLTIP_TEXT = 'This estimate is based on having all pertinent information. Any missing information will result in a longer processing time.'

export const getEstimatedCompletionDate = (widgetData: any) => {
    if (widgetData?.tax_ap_status === 'additional_info_request_sent') {
        return 'Waiting on Additional Info'
    } else if (widgetData?.estimated_completion < 0) {
        return 'Shortly'
    } else if (widgetData?.estimated_completion > 14) {
        return ESTIMATED_COMPLETION_DAYS
    } else if (widgetData?.estimated_completion) {
        return `${widgetData?.estimated_completion} Days`
    } else {
        return ESTIMATED_COMPLETION_DAYS
    }
}

const EstimatedCompletion = ({ taxPrepCompletion }:  any) => {
    const { colorTheme }: { colorTheme: ThemeColors } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { isSmDevice } = useDeviceSize()
    return (
        <div className={styles.stepList}>
            <div>
                <Icon icon="clock" strokeColor={colorTheme.secondary} />
                <UiText
                    className="step-name"
                    variant="motorcycle_90"
                    weight="medium_500"
                >
                    Estimated Completion: {taxPrepCompletion}
                </UiText>
            </div>

            {isSmDevice && (
                <div className={styles.icon}>
                    <InfoIcon />
                </div>
            )}
        </div>
    )
}

const BusinessVto = () => {
    const { colorTheme }: { colorTheme: ThemeColors} = useThemeContext()
    const styles = useStyles(colorTheme)
    const { loading, isBusinessVTO, currentVto } =
    useVtoContext()

    const {
        stateTax,
        taxPrepData,
    } = useWidgetContext()   

    const widgetData = isBusinessVTO ? taxPrepData.business : taxPrepData.personal
    const vtoType =  isBusinessVTO ? 'Business' : 'Personal'
    const { isSmDevice } = useDeviceSize();
    
    const taxPrepCompletion = getEstimatedCompletionDate(widgetData)

    if (loading) {
        return <Loader />
    }
    return (
        <Grid container spacing={1} className={styles.content}>
            <Grid item md={5} sm={12}>
                <div className={styles.stepContainer}>
                    <div className={'accountant-container'}>
                        <AccountantDetails
                            status={widgetData?.tax_ap_status}
                            type={'Business'}
                        />
                    </div>
                    {currentVto?.tax_preparation_current_step?.title !==
                        'Completed' && (
                        <>
                            {!isSmDevice ? (
                                <EstimatedCompletion taxPrepCompletion={taxPrepCompletion}/>
                            ) : (
                                <InfoTooltip
                                    tooltipText={
                                        ESTIMATED_COMPLETION_TOOLTIP_TEXT
                                    }
                                    customStyle={{
                                        width: 'auto',
                                        minWidth: '20.5rem',
                                        top: '-1.7rem',
                                    }}
                                    placement="bottom"
                                    customNode={<EstimatedCompletion />}
                                />
                            )}
                            {!isSmDevice && (
                                <div>
                                    <div
                                        className={styles.estimatedInfoContent}
                                    >
                                        <UiText variant="motorcycle_90">
                                            This estimate is based on having all
                                            pertinent information. Any missing
                                            information will result in a longer
                                            processing time.
                                        </UiText>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    <TaxPreparationStatuses
                        stateTax={stateTax}
                    />
                </div>
            </Grid>
            <Hidden smDown>
                <Grid item md={1} sm={12}></Grid>
                <Grid item md={6} sm={12}>
                    <div className={styles.rightSection}>
                        <StatusImage />
                    </div>
                </Grid>
            </Hidden>
        </Grid>
    )
}

export default BusinessVto
