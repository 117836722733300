import { useVtoContext } from '../vto/providers/VtoProvider'
import Loader from '../../common/Loader'
import PersonalVTO from './PersonalVto'
import WidgetProvider from '../../dashboard/tax-prep/provider/WidgetProvider'
import InitVTO from '../business-tax-information/InitVto'

const PersonalTaxInfo = () => {
    const { loading, currentPersonalVto, selectedYear } = useVtoContext()

    if (loading) {
        return <Loader />
    }

    return (
        <WidgetProvider vtoSelectYear={selectedYear}>
            <>{currentPersonalVto?.title ? <PersonalVTO /> : <InitVTO />}</>
        </WidgetProvider>
    )
}

export default PersonalTaxInfo
