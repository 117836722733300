import { Theme, makeStyles } from '@material-ui/core'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../../../../styles/models/Colors.interface'
import { toRem16 } from '../../../../../../../styles/commonStyles'
import UiText from '../../../../../../common/ui/UiText'
import UiButton from '../../../../../../common/ui/UiButton'
import { PackageCard } from './PackageCard'
import { aboutPackagePage } from '../../../../models/vto.const'
import { useVtoContext } from '../../../../providers/VtoProvider'
import Loader from '../../../../../../common/Loader'

interface StepThreeProps {
    packages: any
    handlePackageSelection: (id: string) => void
    handleNoThanks: () => void
    noPackageSelected: any
}

const useStyles = makeStyles<Theme, ThemeColors>((theme) => {
    return {
        heading: {
            minWidth: 'unset',
            margin: '0 0 3rem 0',
            [theme.breakpoints.up('lg')]: {
                maxWidth: toRem16(800),
                textAlign: 'center',
                margin: `0 auto ${toRem16(56)}`,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: toRem16(16),
                lineHeight: toRem16(24),
            },
        },
        packagesContainer: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            marginBottom: toRem16(24),
            gap: toRem16(24),
            [theme.breakpoints.up('md')]: {
                gridTemplateColumns: '1fr 1fr 1fr',
            },
        },
        details: {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: toRem16(24),
            marginBottom: toRem16(48),
        },
        package: {
            position: 'relative',
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            padding: '2.5rem 1rem 1rem 1rem',
            borderRadius: toRem16(16),
            textAlign: 'center',
            '& > p:first-child, & > p:nth-child(2), & > button': {
                marginBottom: '0.5rem',
            },
            '& button + p': {
                color: (colorTheme) => colorTheme.tertiary,
                marginBottom: '1rem',
            },
            '& ul': {
                paddingLeft: 0,
                textAlign: 'left',
                marginLeft: '1rem',
            },
        },
        bestDeal: {
            backgroundColor: (colorTheme) => colorTheme.mint100,
            borderRadius: '0.5rem',
            padding: '0.5rem',
            position: 'absolute',
            left: '50%',
            top: 0,
            transform: 'translate(-50%,-50%)',
        },
        otherDeal: {
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            backgroundColor: (colorTheme) => colorTheme.primaryWhite,
            borderRadius: '0.5rem',
            padding: '0.5rem',
            position: 'absolute',
            left: '50%',
            top: 0,
            transform: 'translate(-50%,-50%)',
        },
        btnContainer: {
            display: 'flex',
            justifyContent: 'center',
            '& .MuiTypography-button': {
                fontWeight: 600,
            },
        },
        spacingMd: {
            marginTop: `${toRem16(24)}`,
        },
        spacingLg: {
            marginTop: `${toRem16(56)}`,
        },
        contentSpacing: {
            marginTop: '1rem',
            marginBottom: '1rem',
        },
        frequency: {
            display: 'inline',
        },
        discountBlok: {
            minHeight: toRem16(65),
        },
        discount: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: toRem16(8),
            marginBottom: toRem16(24),
            textAlign: 'center',
        },
        originalPrice: {
            textDecoration: 'line-through',
        },
        extraInfo: {
            marginBottom: toRem16(32),
        },
        noThanksHelper: {
            textAlign: 'center',
            marginBottom: toRem16(48),
        },
        notes: {
            display: 'flex',
            gap: toRem16(8),
            marginBottom: toRem16(4),
        },
    }
})

export default function UpsellPackages(props: StepThreeProps) {
    const {
        packages,
        handlePackageSelection,
        handleNoThanks,
    } = props
    const theme = useThemeContext()
    const { loading } = useVtoContext()
    const styles = useStyles(theme.colorTheme)

    if (loading) {
        return <Loader/>
    }

    return (
        <div className={styles.stepThreeContainer}>
            <div>
                <div className={styles.details}>
                    <UiText variant="suv_150">
                        Take the stress out of managing your business
                        finances—choose a package that fits your needs and
                        unlock expert accounting support that saves you time and
                        money.
                    </UiText>
                    <UiText variant="suv_150">
                        Offers only available through this online experience.
                    </UiText>
                    <UiText variant="motorcycle_90">
                        We will add your selection to your subscription and
                        charge the card that is on your account.
                    </UiText>
                </div>

                <div className={styles.packagesContainer}>
                    {packages.map((item: any, index: any) => (
                        <PackageCard
                            item={item}
                            handleSelect={handlePackageSelection}
                            index={index}
                            key={`${index}_package_card`}
                        />
                    ))}
                </div>

                <div className={styles.btnContainer}>
                    <UiButton
                        handleClick={handleNoThanks}
                        btnType="hyperlink"
                        label="No Thanks"
                    />
                </div>
                <div className={styles.noThanksHelper}>
                    <UiText variant="moped_75">
                        This will opt out of any upgrades you selected when
                        reviewing your tax information.
                    </UiText>
                </div>

                <div>
                    {aboutPackagePage.map((item: any, serial: number) => (
                        <div className={styles.notes} key={`desc_${serial}`}>
                            <UiText variant="motorcycle_90">
                                {serial + 1}.
                            </UiText>
                            <UiText variant="motorcycle_90">{item}</UiText>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
