import { ESign } from '../../components/dashboard/tax-prep/modals/Form8879ESignModal'
import { VtoType } from '../../components/dashboard/tax-prep/models/tax-prep.interface'
import {
    Folder,
    paginatedDocumentResponse,
    SearchDocumentApiParams,
    UploadedFile,
} from '../../models'
import {
    deleteResource,
    getResource,
    patchResource,
    postResource,
    putResource,
} from '../axiosService'

export function getFolders(accountId: string) {
    return getResource<Folder[]>(`/api/account/${accountId}/file_center/folder`)
}

export function getFile(accountId: string, fileId: string) {
    return getResource(
        `/api/account/${accountId}/file_center/file/${fileId}/download_preview`,
        undefined,
        undefined,
        false,
        'blob'
    )
}

export function uploadFile(url: string, file: File) {
    const formData = new FormData()
    formData.append('files[]', file, file.name)
    return postResource(url, formData)
}

export function uploadFilesToFolder(
    accountId: string,
    path: string,
    type: 'business' | 'personal',
    files: File[],
    selectedYear?: string
) {
    return Promise.all(
        files.map((file) => {
            const formData = new FormData()
            formData.append('path', path)
            formData.append('type', type)
            formData.append('files', file)
            if(selectedYear){
                formData.append('track_event_selected_year', selectedYear)
            }
            return postResource(
                `api/account/${accountId}/file_center/upload`,
                formData
            )
        })
    )
}

export function searchDocuments(
    accountId: string,
    {
        page = 1,
        perPage = 10,
        term,
        minDate,
        maxDate,
        period,
        type = 'all',
        path,
        minSize,
        maxSize,
    }: SearchDocumentApiParams
) {
    return getResource<paginatedDocumentResponse<UploadedFile>>(
        `/api/account/${accountId}/file_center/file`,
        undefined,
        {
            page,
            perPage,
            minSize,
            maxSize,
            term,
            type,
            minDate,
            maxDate,
            period,
            path,
        }
    )
}

export function updateFileRead(
    accountId: string,
    fileId: string,
    updateFileRead: {
        is_read_by_user: boolean
    }
) {
    return putResource(`/api/account/${accountId}/file_center/file/${fileId}`, {
        id: fileId,
        ...updateFileRead,
    })
}

export function updateDocument(
    accountId: string,
    fileId: string,
    toBeUpdated: {
        filename?: string
        path?: string
        portal_account_id?: string
        type?: 'business' | 'personal'
    }
) {
    return putResource(`/api/account/${accountId}/file_center/file/${fileId}`, {
        id: fileId,
        ...toBeUpdated,
    })
}

export function deleteFile(accountId: string, fileId: string) {
    return deleteResource(
        `/api/account/${accountId}/file_center/file/${fileId}`
    )
}

export function taxDocumentUpload(
    type: VtoType,
    year: string,
    accountId: string,
    taxApId: string,
    files: File[]
) {
    const url = `/api/account/${accountId}/widget/taxes/tax_document/upload`;
    const uploadSequentially = async () => {
        const results = [];
        for (const file of files) {
            const formData = new FormData();
            formData.append('year', year);
            formData.append('type', type);
            formData.append('files', file);
            formData.append('tax_ap_id', taxApId);
            const result = await postResource(url, formData);
            results.push(result);
        }
        return results;
    };

    return uploadSequentially();
}

export function form8879ESignatureSubmit(
    signature: string,
    accountId: string,
    taxApId: string
) : Promise<ESign>{
    const url = `/api/account/${accountId}/accounting_process/${taxApId}/esign`
    const data = {
        signature,
        form_id: 8879,
    }
    return postResource(url, data)
}

export function upload1040File(
    year: string,
    accountId: string,
    vtoId: string,
    files: File[]
) {
    const url = `/api/account/${accountId}/vto${year}/vto/${vtoId}/upload`
    return Promise.all(
        files.map((file) => {
            const formData = new FormData()
            formData.append('type', 'ocr')
            formData.append('files', file)
            formData.append('vtoId', vtoId)
            return postResource(url, formData)
        })
    )
}

export function get1040File(
    year: string,
    accountId: string,
    vtoId: string,
) {
    const url = `/api/account/${accountId}/vto${year}/vto/${vtoId}/ocr`;
    return getResource(url);
}


export function post1040UserConsent(
    accountId: string,
    upload_1040_consent: boolean
) {
    const url = `api/account/${accountId}/upload_1040_consent`
    return patchResource(url, { upload_1040_consent })
}