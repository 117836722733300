import { useEffect } from 'react'
import {
    IconButton,
    makeStyles,
    Step,
    StepLabel,
    Theme,
    useMediaQuery,
} from '@material-ui/core'
import {
    commonStyles,
    mergeStyle,
    toRem16,
} from '../../../../../../../styles/commonStyles'
import Icon from '../../../../../../common/Icon'
import UiText from '../../../../../../common/ui/UiText'
import IconList from '../../../../../../common/IconList'
import { ThemeColors } from '../../../../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { useVtoContext } from '../../../../providers/VtoProvider'
import { useFormikContext } from 'formik'
import { MULTI_STEP } from '../../../../models/vto.const'
import { getStepModel } from '../../../../common/VtoUtils'
import { getSectionInfo } from '../../../../common/SectionUtil'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        textContainer: {
            marginTop: toRem16(3),
        },
        icon: {
            marginRight: '0.5rem',
        },
        stepper: {
            marginTop: '0.5rem',
            padding: '0',
        },
        item: {
            borderRadius: '0.5rem',
            cursor: 'pointer',
        },
        itemWithStep: {
            padding: '0.5rem',
            marginLeft: '0.6rem',
            [theme.breakpoints.down('xs')]: {
                padding: '0.5rem 1rem',
                marginLeft: 0
            }
        },
        itemWithoutStep: {
            padding: '0.5rem 1rem',
        },
        activeItem: {
            backgroundColor: '#2DA38D29',
        },
        step: {
            padding: '0 24px',
        },
        stepLabel: {
            cursor: 'pointer',
        },
        lastStep: {
            marginBottom: '1rem',
        },
        itemIconContainer: {
            position: 'relative',
            display: 'flex',
        },
        itemIcon: {
            marginRight: '1rem',
            paddingLeft: '0.5rem',
        },
        stepIcon: {
            marginRight: '1.5rem',
            marginLeft: toRem16(12),
        },
        checkIcon: {
            position: 'absolute',
            right: '0.5rem',
            bottom: `${toRem16(-4)}`,
        },
        lastStepStyles: {
            marginBottom: '2rem',
        },
        label: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        stepContainer: {
            '&::-webkit-scrollbar-thumb': {
                background: (colorTheme) => colorTheme.grey2700,
            },
            '& .MuiStepConnector-vertical': {
                padding: `0 ${toRem16(22)}`,
            },
            '& .MuiStepConnector-vertical span': {
                borderLeftWidth: `${toRem16(2)}`,
            },
            '& .MuiStepLabel-label': {
                color: (colorTheme) => colorTheme.black100,
            },
        },
        arrowIcon: {
            padding: '0.5rem',
            [theme.breakpoints.down('xs')]: {
                paddingRight: '1.5rem',
            },
        },
        errorTextContainer: {
            display: 'flex',
            marginTop: '0.125rem',
            '& .errorText': {
                marginLeft: toRem16(8),
                color: (colorTheme) => colorTheme.red400,
            },
        },
    }
})

export const ErrorText = ({ errorText }: { errorText: string }) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    return (
        <div className={styles.errorTextContainer}>
            <Icon icon="missingInput" size="20px" />
            <span className="errorText">{errorText}</span>
        </div>
    )
}

export const MissingCountSubtitle = ({
    count,
    highlightInfo,
}: {
    count: null | number
    highlightInfo?: boolean
}) => {
    const classes = commonStyles()
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    if (count === 0 || count === null) return null
    return (
        <div className={classes.flex}>
            {highlightInfo && <Icon icon="alert" size="20px" />}
            &nbsp;
            <UiText
                variant={'motorcycle_90'}
                textColor={highlightInfo ? 'error' : 'textSecondary'}
                weight={highlightInfo ? 'semi_bold_600' : 'medium_500'}
                className={styles.textContainer}
            >
                {count} missing
            </UiText>
        </div>
    )
}

export const RenderStep = ({
    step,
    isActiveStep,
    isLastStep,
    onMenuItemClick,
    sectionInfo,
    setParentStep,
    styles,
    highlightInfo = false,
    hasVisibleSections = false,
    isCollapsed = false,
    handleCollapse,
}: {
    step: any
    onMenuItemClick: (parentStep: any, step: string) => void
    isActiveStep: boolean
    isLastStep: boolean
    sectionInfo: {
        isDone: boolean
        count: number
    }
    setParentStep: any
    styles: any
    highlightInfo?: boolean
    hasVisibleSections?: boolean
    isCollapsed?: boolean
    handleCollapse?: (key: string) => void
}) => {
    const commonClasses = commonStyles()
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const smDevice = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    )
    const { isBusinessVTO, currentVtoDetails, currentYearMetaData } = useVtoContext()

    

    const getMenuIcon = (stepName: string): keyof typeof IconList => {
        let key = `${stepName}_vtomenu` as keyof typeof IconList
        if (isBusinessVTO) {
            key = key.concat('_btp') as keyof typeof IconList
        }
        if (IconList[key]) return key
        return 'default_icon_vtomenu'
    }

    const getIsSectionReviewed = () => {
        return step.sections.every((step:string) => currentVtoDetails.sections_reviewed.includes(step));  
    }

    const getIsSectionCompleted = () => {
        return hasVisibleSections ? !getIsSubsectionsNotComplete() : sectionInfo?.isDone;
    }

    const getIsSubsectionsNotComplete = () => {
        return step.sections.some((section:any) => {
            const { stepModel, stepIndex } = getStepModel(step);
            const sectionInfo = getSectionInfo(
                section,
                currentYearMetaData,
                step?.type === MULTI_STEP
                    ? currentVtoDetails?.[stepModel][
                          stepIndex
                      ]
                    : currentVtoDetails
            )
            return !sectionInfo?.isDone;
        })
    }

    const sectionsData = step.sections;
    const isSectionReviewed = getIsSectionReviewed();
    const isSectionCompleted = getIsSectionCompleted();

    return (
        <Step
            className={`${styles.item}
                            ${
                                sectionsData.length > 0
                                    ? styles.itemWithStep
                                    : styles.itemWithoutStep
                            }
                            ${isActiveStep ? styles.activeItem : ''}  
                            ${isLastStep ? styles.lastStepStyles : ''}
                        `}
            onClick={() => {
                const stepName =
                    sectionsData.length > 0 ? sectionsData[0] : step.name
                onMenuItemClick(step, stepName)
            }}
        >
            <StepLabel
                StepIconComponent={() => (
                    <div className={styles.itemIconContainer}>
                        <Icon
                            customClass={styles.itemIcon}
                            icon={getMenuIcon(step.name)}
                        />
                        <Icon
                            customClass={styles.checkIcon}
                            icon={
                                isSectionCompleted && isSectionReviewed
                                    ? 'checkmark'
                                    : isSectionCompleted && !isSectionReviewed
                                    ? 'checkmarkDashes'
                                    : 'exclamationMark'
                            }
                        />
                    </div>
                )}
            >
                <div className={classes.label}>
                    <div className={commonClasses.fullWidth}>
                        <div
                            className={
                                hasVisibleSections
                                    ? mergeStyle(
                                          commonClasses.flex,
                                          commonClasses.alignCenter
                                      )
                                    : ''
                            }
                        >
                            <UiText
                                className="title"
                                variant="hatchback_125"
                                weight="medium_500"
                            >
                                {step.title}
                            </UiText>
                            {hasVisibleSections && (
                                <div className={commonClasses.mlauto}>
                                    <IconButton
                                        classes={{
                                            root: classes.arrowIcon,
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            handleCollapse?.(step.name)
                                        }}
                                    >
                                        {!isCollapsed ? (
                                            <KeyboardArrowUpIcon />
                                        ) : (
                                            <KeyboardArrowDownIcon />
                                        )}
                                    </IconButton>
                                </div>
                            )}
                        </div>
                        {!isSectionCompleted && (
                            <MissingCountSubtitle
                                count={sectionInfo?.count}
                                highlightInfo={highlightInfo}
                            />
                        )}  
                        {isSectionCompleted && !isSectionReviewed && (
                            <UiText
                                variant='motorcycle_90'
                                textColor={highlightInfo ? 'error' : 'textSecondary'}
                                weight={highlightInfo ? 'semi_bold_600' : 'medium_500'}                 
                                className={styles.textContainer}
                            >
                                Needs to be reviewed and confirmed
                            </UiText>
                        )}
                    </div>
                    {smDevice && <Icon icon="rightArrow" />}
                </div>
            </StepLabel>
        </Step>
    )
}

export const RenderSection = ({
    section,
    styles,
    sectionInfo,
    activeSection,
    availableSections,
    highlightInfo = false,
}: any) => {
    const { currentVtoDetails } = useVtoContext()

   const isSectionReviewed = currentVtoDetails.sections_reviewed.includes(section);

    return (
        <div className={styles.stepLabel}>
            <StepLabel
                StepIconComponent={() => (
                    <Icon
                        customClass={styles.stepIcon}
                        icon={
                            sectionInfo?.isDone && isSectionReviewed
                                ? 'checkmark'
                                : sectionInfo?.isDone && !isSectionReviewed
                                ? 'checkedDashes'
                                : 'EmptyCircle'
                        }
                        size="24px"
                    />
                )}
            >
                <div>
                    <UiText
                        className="title"
                        variant="hatchback_125"
                        weight={activeSection ? 'medium_500' : 'regular_400'}
                    >
                        {availableSections?.[section].title}
                    </UiText>
                </div>
                {!sectionInfo?.isDone ? (
                    <MissingCountSubtitle
                        count={sectionInfo?.count}
                        highlightInfo={highlightInfo}
                    />
                ) : sectionInfo?.isDone && !isSectionReviewed ? (
                    <UiText
                        variant="motorcycle_90"
                        textColor={highlightInfo ? 'error' : 'textSecondary'}
                        weight={highlightInfo ? 'semi_bold_600' : 'medium_500'}
                        className={styles.textContainer}
                    >
                        Needs to be reviewed and confirmed
                    </UiText>
                ) : null}
            </StepLabel>
        </div>
    )
}
