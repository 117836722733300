import { makeStyles, Theme } from '@material-ui/core'
import { ThemeColors } from '../../../../../../../styles/models/Colors.interface'
import { toRem16 } from '../../../../../../../styles/commonStyles'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import UiText from '../../../../../../common/ui/UiText'
import ProductList from './ProductList'

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        container: {
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            borderRadius: toRem16(16),
            padding: toRem16(16),
            marginTop: toRem16(24),
        },
        productItem: {
            marginTop: toRem16(24),
        },
        spacingSm: {
            marginTop: '1rem',
        },
    }
})

export const SelectedPackage = ({ selectedPackage }: any) => {
    const theme = useThemeContext()
    const styles = useStyles(theme.colorTheme)
    return (
        <div className={styles.container}>
            {selectedPackage && (
                <>
                    <UiText
                        className={styles.spacingSm}
                        textAlign="center"
                        weight="bold_700"
                    >
                        {selectedPackage.packageTitle}
                    </UiText>
                    <UiText className={styles.spacingSm}>
                        {selectedPackage.extraInfo}
                    </UiText>
                    <div className={styles.productItem}>
                        {selectedPackage.products.map((product: any) => (
                            <ProductList product={product} />
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}
