import store from '../../store'
import { getResource, postResource } from '../axiosService'

const getStoreData = () => {
    const currentStore = store.getState()
    const businessId = currentStore.appData.current_business_id
    const accountId = currentStore.appData.current_account_id
    const personalId = currentStore.appData.personal_account.id
    return {
        businessId,
        accountId,
        personalId,
    }
}
/**
 *
 * @param year
 * @returns is_renewal_available
 */

export function isRenewalAvailable(year: number, isBusinessVTO: boolean) {
    const { accountId, personalId } = getStoreData()
    const accId = isBusinessVTO ? accountId : personalId
    const api = `api/account/${accId}/vto${year}/is_renewal_available`
    return getResource<any>(api)
}

export function vtoRenewSubscription(
    year: number,
    isBusinessVTO: boolean,
    utmContent: string
) {
    const { accountId, personalId } = getStoreData()
    const accId = isBusinessVTO ? accountId : personalId
    const api = `api/account/${accId}/vto${year}/renew_subscription`
    return postResource(api, { year, utm_content: utmContent })
}

export function getCreditCardCount() {
    const api = `api/charge_over/get_credit_card_counts`
    return getResource(api)
}

export function getCustomerPortalLoginUrl(params: any) {
    const api = `/api/charge_over/get_login_url`
    return getResource(api, undefined, params)
}
