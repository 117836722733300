import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
export const commonStyles = makeStyles((theme: Theme) => ({
    flex: {
        display: 'flex',
    },
    flexEnd: {
        justifyContent: 'flex-end',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    spaceAround: {
        justifyContent: 'space-around',
    },
    capitalize: {
        textTransform: 'capitalize',
        textDecoration: 'capitalize',
    },
    alignCenter: {
        alignItems: 'center',
    },
    textRight: {
        textAlign: 'right'
    },
    textLeft: {
        textAlign: 'left'
    },
    textCenter: {
        textAlign: 'center',
    },
    fullWidth: {
        width: '100%',
    },
    fullHeight: {
        height: '100%',
    },
    mr16: {
        marginRight: '16px',
    },
    ml16: {
        marginLeft: '16px',
    },
    mt8: {
        marginTop: '8px',
    },
    mb8: {
        marginBottom: '8px',
    },
    mt16: {
        marginTop: '16px',
    },
    mb16: {
        marginBottom: '16px',
    },
    mt24: {
        marginTop: '24px',
    },
    listItemZeroGutters: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    m0: {
        margin: '0 !important',
    },
    mt0: {
        marginTop: '0',
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    mb1: {
        marginTop: theme.spacing(1),
    },
    ml2: {
        marginLeft: theme.spacing(2),
    },
    mt10: {
        marginTop: theme.spacing(10),
    },
    mr8: {
        marginRight: theme.spacing(2),
    },
    mr3: {
        marginRight: theme.spacing(3),
    },
    mr24: {
        marginRight: '24px'
    },
    mx10: {
        marginLeft: '10px',
        marginRight: '10px'
    },    
    mb10: {
        marginBottom: '10px'
    },
    mtb16: {
        marginTop: "16px",
        marginBottom: "16px"
    },
    mt32: {
        marginTop: "32px"
    },
    mb32: {
        marginBottom: "32px"
    },
    mb40: {
        marginBottom: toRem16(40)
    },
    mrauto:{
        marginRight: 'auto'
    },
    mlauto:{
        marginLeft: 'auto'
    },
    mt12: {
        marginTop: toRem16(12)
    },
    heading: {
        fontSize: '1.5rem',
        fontWeight: 700,
        lineHeight: '28px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.125rem',
        },
    },
    textDecorationNone: {
        textDecoration: 'none'
    },
    preWhiteSpace: {
        whiteSpace: 'pre'
    },
    gap8: {
        gap:"0.5rem"
    },
    p8:{
        padding: '8px'
    },
    hidden: {
        visibility: 'hidden'
    },
    lineThrough: {
        textDecoration: 'line-through',
    },
    mxauto: {
        marginRight: 'auto',
        marginLeft: 'auto'
    }
}));

export const mergeStyle = (...styles: any) => {
    return clsx(...styles);
};

export const spaceBetween = () => {
    const styles = commonStyles();
    return clsx(styles.flex, styles.spaceBetween);
};

export function toRem16(value: number) {
    const remValue = value / 16 + 'rem';
    return remValue;
}