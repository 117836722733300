import { useEffect, useRef, useState } from 'react'
import UiDialog from '../common/ui/UiDialog'
import { Button, Theme, makeStyles } from '@material-ui/core'
import { ArrowDownward } from '@material-ui/icons'
import { Form, Formik } from 'formik'
import { ContractorForm } from './ContractorForm'
import { toRem16 } from '../../styles/commonStyles'
import { debounce } from '../../utils/debounce'
import { Contractor } from './models/contractor.model'
import { addContractor, editContractor } from './apiServices/contractor.api'
import { useCurrentStore } from '../common/hooks/useCurrentStore'
import * as Yup from 'yup'
import { formSubmitErrorHandler, showError } from '../../services/formService'
import Loader from '../common/Loader'
import { ERRORS } from './constants/contractor.const'
import { InformationBlock } from './InformationBlock'
import { ThemeColors } from '../../styles/models/Colors.interface'
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext'
import { FormObserver } from '../bookkeeping/reports/utils/Form/FormObserver'
import { useContractorContext } from './ContractorProvider'
import { COLORS } from '../../variables/colors'

const useStyle = makeStyles<Theme, ThemeColors>(() => ({
    scrollBottom: {
        position: 'fixed',
        bottom: '7rem',
        right: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        width: toRem16(56),
        height: toRem16(56),
        cursor: 'pointer',
        borderRadius: '50%',
        backgroundColor: (colorTheme) => `${colorTheme.primary}`,
        color: (colorTheme) => `${colorTheme.primaryWhite}`,
    },
    saveBtn: {
        marginLeft: '1rem',
    },
    actionButtons: {
        height: '2.6rem',
        display: 'flex',
        justifyContent: 'center',
        "& .MuiButton-containedPrimary": {
            backgroundColor: colorTheme => colorTheme.primary,
        },
    },
    modalContent: {
        '& .MuiDialogActions-root': {
            display: 'flex',
            justifyContent: 'center',
        },
    },
}))

const MODAL_CONTENT_DIV_ID = 'modal-content'
const HandleScroll = (props: any) => {
    const handleScrollToElement = debounce((event: any) => {
        const scrollDemo = document.getElementById(MODAL_CONTENT_DIV_ID)!
        const currentPosition = scrollDemo.scrollTop
        if (currentPosition === 0) {
            props.setIsScrollable(true)
        } else {
            props.setIsScrollable(false)
        }
    }, 100)

    useEffect(() => {
        const scrollDemo = document.getElementById(MODAL_CONTENT_DIV_ID)!
        scrollDemo.addEventListener('scroll', handleScrollToElement, {
            passive: true,
        })
        return () => {
            scrollDemo.removeEventListener('scroll', handleScrollToElement)
        }
    }, [handleScrollToElement])

    return <div />
}

const emptyData = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    type: '',
    contractor_w_nine: [{ year: '' }],
    business_name: '',
    tax_identification_type: '',
    tax_identification_number: '',
    street: '',
    city: '',
    state: '',
    zip: '',
}

interface Props {
    isOpen: boolean
    setIsOpen: (param: boolean) => void
    isEdit?: boolean
    initialData?: Contractor
    reloadData?: () => void
    setSelectedContractor?: (data: any) => void
}

export const AddContractorModal = (props: Props) => {
    const { colorTheme } = useThemeContext()
    const {
        isOpen,
        setIsOpen,
        isEdit,
        initialData,
        reloadData,
        setSelectedContractor,
    } = props
    const { loadContractors } = useContractorContext()

    const [isScrollable, setIsScrollable] = useState<boolean>(true)
    const [apiLoading, setApiLoading] = useState(false)
    const [formObserverValue, setFormObserverValue] = useState<any>({})
    const [initialFormValues, setInitialFormValues] =
        useState<Contractor>(emptyData)
    const formRef = useRef(null)
    const { currentBusinessId } = useCurrentStore()
    const classes = useStyle(colorTheme)
    let submitCloser: any = null

    useEffect(() => {
        if (initialData) {
            setInitialFormValues(initialData)
            setFormObserverValue(initialData)
        } else {
            setInitialFormValues(emptyData)
            setFormObserverValue(emptyData)
        }
    }, [initialData])

    const validationSchema = Yup.object({
        first_name: Yup.string().required(ERRORS.FIRST_NAME),
        last_name: Yup.string().required(ERRORS.LAST_NAME),
        email: Yup.string()
            .email(ERRORS.VALID_EMAIL)
            .max(255)
            .required(ERRORS.EMAIL),
        phone: Yup.string().required(ERRORS.PHONE),
        type: Yup.string(),
        business_name: Yup.string().when('type', {
            is: 'business',
            then: Yup.string().required(ERRORS.BUSSINESS),
            otherwise: Yup.string().optional(),
          }),
        contractor_w_nine: Yup.array().of(
            Yup.object().shape({
                year: Yup.string().required(ERRORS.TAX),
            })
        ),
        tax_identification_type: Yup.string(),
        tax_identification_number: Yup.string(),
        street: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        zipcode: Yup.string(),
    })

    const actions = () => (
        <>
            <Button
                variant="outlined"
                color="secondary"
                onClick={() => setIsOpen(false)}
                data-cy="cancel-new-cat-btn"
            >
                Cancel
            </Button>

            <Button
                variant="contained"
                color="primary"
                onClick={(e) => submitCloser?.(e)}
                data-cy="save-new-cat-btn"
                className={classes.saveBtn}
            >
                Save Contractor
            </Button>
        </>
    )

    const checkDuplicateTaxYear = (w9: any[]) => {
        const seen = new Set()
        for (const item of w9) {
            if (seen.has(item.year)) {
                return true
            }
            seen.add(item.year)
        }
        return false
    }

    const submit = (values: Contractor, { setFieldError }: any) => {
        if (checkDuplicateTaxYear(values.contractor_w_nine)) {
            showError('There are dupicate tax years please correct it', 'error')
            return
        }
        if (currentBusinessId) {
            setApiLoading(true)
            const requestFor = isEdit ? editContractor : addContractor
            formSubmitErrorHandler(
                requestFor(currentBusinessId, {
                    ...values,
                    tax_identification_number:
                        `${values.tax_identification_number}`.replaceAll(
                            '-',
                            ''
                        ),
                }).then((res: any) => {
                    loadContractors()
                    setIsOpen(false)
                    setApiLoading(false)
                    reloadData?.()
                    setSelectedContractor?.(res)
                }),
                () => {
                    setApiLoading(false)
                },
                setFieldError
            )
        }
    }

    const onformChangeCallback = (values: any) => {
        setFormObserverValue(values)
    }

    return (
        <UiDialog
            open={isOpen}
            handleClose={() => setIsOpen(false)}
            title={`${isEdit ? 'Edit' : 'New'} Contractor`}
            size="sm"
            actions={
                <div className={classes.actionButtons}>
                    {apiLoading ? <Loader /> : actions()}
                </div>
            }
            customDialogStyles={
                apiLoading ? { paper: classes.modalContent } : {}
            }
        >
            <HandleScroll setIsScrollable={setIsScrollable} />
            <Formik
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={submit}
                validateOnMount
            >
                {({
                    handleSubmit,
                    setFieldValue,
                    values: formValues,
                }) => {
                    submitCloser = handleSubmit
                    return (
                        <Form>
                            <FormObserver onformChange={onformChangeCallback} />
                            <InformationBlock
                                message="If any optional field is left blank, you can send a W-9 to the contractor. This will allow the contractor to fill out the remaining information. You can check the status of a W-9 at any time from the “Manage W-9s” section of the portal."
                                maxWith={512}
                            />
                            <ContractorForm
                                formikRef={formRef}
                                values={formValues}
                                formObserverValue={formObserverValue}
                                isEditContarctor={!!initialFormValues.email}
                                setFieldValue={setFieldValue}
                            />
                            {isScrollable && (
                                <div
                                    className={classes.scrollBottom}
                                    onClick={() => {
                                        const scrollableDiv =
                                            document.getElementById(
                                                MODAL_CONTENT_DIV_ID
                                            )!
                                        scrollableDiv.scrollTo({
                                            top: scrollableDiv.scrollHeight,
                                            behavior: 'smooth',
                                        })
                                    }}
                                >
                                    <ArrowDownward />
                                </div>
                            )}
                        </Form>
                    )
                }}
            </Formik>
        </UiDialog>
    )
}
