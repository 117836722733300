import { BankAccountGroup } from '../../models/bankAccountGroup';
import { BankAccount } from '../../models/bankAccount';

import {
    getResource,
    patchResource,
    postResource,
    deleteResource,
} from '../axiosService';


export function getBankAccountGroupList(
    accountId: number | string,
    businessId: number | string
) {
    const api = `api/account/${accountId}/business/${businessId}/bank_account_group`;
    return getResource<BankAccountGroup[]>(api);
}

export function getBankAccountGroup(
    accountId: number | string,
    businessId: number | string,
    groupId: number | string
) {
    const api = `api/account/${accountId}/business/${businessId}/bank_group/${groupId}`;
    return getResource<BankAccountGroup>(api);
}

export function getFinicityFullModal(businessId: number | string) {
    const api = `/api/finicity/business/${businessId}/modal_full_url`;
    return getResource<any>(api);
}

export function getFinicityFixModal(
    businessId: number | string,
    bankAccountId: number | string
) {
    const api = `/api/finicity/business/${businessId}/bank_account/${bankAccountId}/modal_url`;
    return getResource<any>(api);
}

export function connectFinicityAccounts(businessId: number | string, data: any) {
    const api = `/api/finicity/business/${businessId}/accounts/connect`;
    return postResource(api, data);
}

export function preConnectFinicityAccounts(businessId: number | string) {
    const api = `/api/finicity/business/${businessId}/accounts/preconnect`;
    return getResource<any>(api);
}

export function disconnectBankAccount(
    accountId: number | string,
    businessId: number | string,
    bankAccountId: number | string
) {
    const api = `/api/account/${accountId}/business/${businessId}/bank_account/${bankAccountId}/disconnect`;
    return patchResource<any>(api, {});
}

export function disconnectPlaidBankAccount(
    bankAccountId: number | string
) {
    const api = `/api/plaid/bank_account/${bankAccountId}/disconnect`;
    return patchResource<any>(api, {});
}

export function reconnectBankAccount(
    accountId: number | string,
    businessId: number | string,
    bankAccountId: number | string
) {
    const api = `/api/account/${accountId}/business/${businessId}/bank_account/${bankAccountId}/reconnect`;
    return patchResource<any>(api, {});
}

export function removeBankAccount(
    accountId: number | string,
    businessId: number | string,
    bankAccountId: number | string
) {
    const api = `/api/account/${accountId}/business/${businessId}/bank_account/${bankAccountId}`;
    return deleteResource(api);
}

export function createBankAccount(
    accountId: number | string,
    businessId: number | string,
    data: BankAccount
) {
    const api = `/api/account/${accountId}/business/${businessId}/bank_account`;
    return postResource(api, data);
}

export function getBankAccountDetails(
    accountId: number | string,
    businessId: number | string,
    bankAccountId: number | string
) {
    const api = `/api/account/${accountId}/business/${businessId}/bank_account/${bankAccountId}`;
    return getResource<any>(api);
}

export function updateBankAccount(
    accountId: number | string,
    businessId: number | string,
    bankAccountId: number | string,
    data: BankAccount
) {
    const api = `/api/account/${accountId}/business/${businessId}/bank_account/${bankAccountId}`;
    return patchResource(api, data);
}

export function updateBankAccountSync(
    accountId: number | string,
    businessId: number | string,
    bankAccountId: number | string,
    data: { sync_from_date: number }
) {
    const api = `/api/account/${accountId}/business/${businessId}/bank_account/${bankAccountId}/update`;
    return patchResource(api, data);
}

export function getBankingPageLinkToken(businessId: null | string) {
    const api = `/api/plaid/bank_account/create_link_token`
    return postResource<any>(api, { business_id: businessId })
}

export function getBankingPageExchangeToken(businessId: null | string, metaData: any) {
    const api = `/api/plaid/bank_account/exchange_public_token`
    return postResource<any>(api, {
        business_id: businessId,
        meta_data: metaData,
    })
}

export function getBankingPageUpdateToken(bankAccountId: null | string) {
    const api = `/api/plaid/bank_account/${bankAccountId}/update_link_token`
    return getResource<any>(api)
}

export function getAccountStatusSync(bankAccountId: null | string) {
    const api = `/api/plaid/bank_account/${bankAccountId}/account_sync`
    return getResource<any>(api)
}
