import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api'
import store from '../../store'
import { useRef, useState } from 'react'
import { InputAdornment, TextField } from '@material-ui/core'
import { ADDRESS_COMPONENTS } from './payroll.const'
import SearchIcon from '@material-ui/icons/Search'

export const getStateAbbr = (stateName: string): string | null => {
    const currentStore = store.getState()
    const state = currentStore.config.apiConfig.generic.states.find(
        (item: any) => item.name.toLowerCase() === stateName.toLowerCase()
    )
    return state ? state.abbr : null
}

export const AddressAutocompletion = ({ setFieldValue, multiStreetFields = true }: { setFieldValue: any, multiStreetFields?: boolean }) => {
    const [formattedAddress, setFormattedAddress] = useState('') 
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: (window as any).ApiConfig.google_maps_api_key,
        libraries: ['places'],
    })
    const autoCompleteRef = useRef<any>(null)
   
    return (
        <div>
            
        {isLoaded && (
            <StandaloneSearchBox
                onLoad={(ref: any) => {
                    autoCompleteRef.current = ref
                }}
                onPlacesChanged={() => {
                    const places =
                        autoCompleteRef?.current?.getPlaces()
                    if (places.length) {
                        const address = places[0]
                        setFormattedAddress(
                            address.formatted_address
                        )

                        const addressComponents =
                            address.address_components

                        const addressComponentsMap =
                            addressComponents.reduce(
                                (acc: any, component: any) => {
                                    acc[component.types[0]] =
                                        component.long_name
                                    return acc
                                },
                                {} as Record<string, string>
                            )


                        if (multiStreetFields) {
                            setFieldValue(
                                'street_1',
                                `${
                                    addressComponentsMap[
                                        ADDRESS_COMPONENTS.STREET_NUMBER
                                    ]
                                } ${
                                    addressComponentsMap[
                                        ADDRESS_COMPONENTS.ROUTE
                                    ]
                                }`
                            )
                            setFieldValue(
                                'street_2',
                                `${
                                    addressComponentsMap[
                                        'sublocality_level_1'
                                    ] ?? ''
                                } `
                            )
                        } else {
                            const streetNumber =
                                addressComponentsMap[
                                    ADDRESS_COMPONENTS.STREET_NUMBER
                                ]
                            const steetRoute =
                                addressComponentsMap[ADDRESS_COMPONENTS.ROUTE]
                                setFieldValue(
                                    'street',
                                    `${streetNumber} ${steetRoute}`
                                )
                        }
                        
                        setFieldValue(
                            'city',
                            addressComponentsMap[
                                ADDRESS_COMPONENTS.LOCALITY
                            ]
                        )
                        setFieldValue(
                            'state',
                            getStateAbbr(
                                addressComponentsMap[
                                    ADDRESS_COMPONENTS.STATE
                                ]
                            )
                        )
                        setFieldValue(
                            'zip',
                            addressComponentsMap[
                                ADDRESS_COMPONENTS.ZIP_CODE
                            ]
                        )
                    }
                }}
            >
                <TextField
                    placeholder={'Search Location'}
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    size="small"
                    fullWidth
                    value={formattedAddress}
                    onChange={(e) =>
                        setFormattedAddress(e.target.value)
                    }
                />
            </StandaloneSearchBox>
        )}

</div>
    )
}