import { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AppData, ApplicationStore } from '../../models'
import { getPaymentUrl } from '../../services/apiService'
import Loader from '../common/Loader'
import UiDialog from '../common/ui/UiDialog'
import { loadPostAuthData } from '../../store/actions/appData'
import { showAlert } from '../../store/actions/feedback'
import Axios, { CancelTokenSource } from 'axios'

interface ChargeoverIframeModalProps {
    productIds: string[]
    open: boolean
    handleClose: any
    appData: AppData
    loadPostAuthData: any
    showAlert: any
    utmContent: string
    sfProductIds?: string[]
    otherParams?: any
    autoPaymentProcessing: (precessing: any) => void
}

function ChargeoverIframeModal({
    productIds = [],
    open,
    handleClose,
    appData,
    loadPostAuthData,
    showAlert,
    utmContent,
    sfProductIds,
    otherParams,
    autoPaymentProcessing,
}: ChargeoverIframeModalProps) {
    const [loading, setLoading] = useState(true)
    const [purchaseUrl, setPurchaseUrl] = useState('')
    const [cancelToken, setCancelToken] = useState<CancelTokenSource>()

    const iframe = () => {
        return {
            __html: `<iframe src="${purchaseUrl}" style="border: none;" width="100%" height="100%"></iframe>`,
        }
    }

    const handleEvent = (e: any) => {
        if (!e.data.result || e.origin !== window.origin) {
            return
        }
        window.removeEventListener('message', handleEvent, false)
        if (e.data.result === 'success') {
            loadPostAuthData().then(() => handleClose('success'))
            showAlert({
                alertText: 'Your payment has been successfully processed',
                alertType: 'success',
            })
        } else {
            showAlert({
                alertText: 'Sorry, some error occurred. Please try again.',
                alertType: 'error',
            })
            handleModalClose()
        }
    }

    const handleModalClose = () => {
        cancelToken?.cancel()
        window.removeEventListener('message', handleEvent, false)
        handleClose()
    }

    const postSuccessfulPayment = () => {
        loadPostAuthData().then(() => {
            setLoading(false)
            handleClose('success')
        })
        showAlert({
            alertText: 'Your payment has been successfully processed',
            alertType: 'success',
        })
    }

    useEffect(() => {
        if (open) {
            setLoading(true)
            window.removeEventListener('message', handleEvent, false)
            window.addEventListener('message', handleEvent, false)
            const cancelToken = Axios.CancelToken.source()
            setCancelToken(cancelToken)
            const utmSource = 'Portal'
            const utmTerm = 'Client'
            const utmMedium = 'Client+Portal'
            const utmContentParam = utmContent
            autoPaymentProcessing(true)
            getPaymentUrl(
                appData.current_account_id,
                {
                    product_ids: productIds,
                    sf_product_ids: sfProductIds,
                    utm_content: utmContent,
                    ...(otherParams ? otherParams : {}),
                },
                cancelToken.token
            )
                .then((res: any) => {
                    if (open) {
                        if (res?.payment_successful) {
                            postSuccessfulPayment()
                        }
                        if (!res?.payment_successful && res?.need_to_pay) {
                            const utmParams = `?utm_source=${utmSource}&utm_term=${utmTerm}&utm_medium=${utmMedium}&utm_content=${utmContentParam}`
                            const redirectUrl = res.redirect_url + utmParams
                            setPurchaseUrl(redirectUrl)
                            setLoading(false)
                            autoPaymentProcessing(false)
                        }
                    } else {
                        window.removeEventListener(
                            'message',
                            handleEvent,
                            false
                        )
                    }
                })
                .catch((error) => {
                    if (error?.status === 400) {
                        showAlert({
                            alertText:
                                'Sorry, some error occurred. Please try again.',
                            alertType: 'error',
                        })
                    }
                    window.removeEventListener('message', handleEvent, false)
                    handleModalClose()
                    autoPaymentProcessing(false)
                })
        }
    }, [open]) //eslint-disable-line react-hooks/exhaustive-deps
    return (
        <Fragment>
            <UiDialog
                open={open && Boolean(purchaseUrl)}
                handleClose={handleModalClose}
                title="Provide Payment Details"
                size="md"
                disableBackdropClick={true}
            >
                <div style={{ height: '500px' }}>
                    {loading ? (
                        <Loader />
                    ) : (
                        <div
                            style={{ height: '100%', overflow: 'hidden' }}
                            dangerouslySetInnerHTML={iframe() as any}
                        ></div>
                    )}
                </div>
            </UiDialog>
        </Fragment>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
})
export default connect(mapStateToProps, { loadPostAuthData, showAlert })(
    ChargeoverIframeModal
)
