import { useEffect, useMemo, useRef, useState } from 'react'
import { useCurrentStore } from '../../../common/hooks/useCurrentStore'
import {
    Formik,
    FormikProps,
    FormikValues,
    Field,
    FieldProps,
    FormikErrors,
} from 'formik'
import {
    Button,
    Grid,
    Paper,
    makeStyles,
    TextField,
    Box,
    InputAdornment,
    Theme,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditOutlined from '@material-ui/icons/EditOutlined'
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined'
import VehicleIcon from '@material-ui/icons/DirectionsCarOutlined'
import SearchIcon from '@material-ui/icons/Search'
import UiFormControlSelection from '../../../common/ui/UiFormControlSelection'
import Autocomplete, {
    AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete'
import UiDialog from '../../../common/ui/UiDialog'
import UiText from '../../../common/ui/UiText'
import PortalTabs from '../../../common/PortalTabs'
import TabPanelHolder from '../../../common/TabPanelHolder'
import {
    initialFormValues,
    validationSchema,
    getMileageRate,
    formatRequestData,
    standardizeTimestamp,
    ActivityIdType,
    embeddedLocationTempIds,
} from './TripFormUtils'
import { createTrip, updateTrip } from '../../../../services/apiService/trips'

import { AddEditVehicle } from '../../vehicles/components/AddEditVehicle'
import LocationModal from '../../locations/modal/LocationModal'
import PurposeModal from '../../purposes/modal/PurposeModal'
import { Purpose } from '../../../../models/purposes'
import { initialValues as vehicleInitialValues } from '../../vehicles/Vehicles'
import { Moment } from 'moment'
import { PLAN_LEVEL_NAMES } from '../../../sales/UpgradePortalAccess'

import type { Trip, TripVehicle } from '../../../../models'
import type { Location } from '../../../../models/locations'
import DiscardChangesModal from '../../locations/modal/DiscardChangesModal'
import type { AutoCompleteFieldCreatedValuesType } from '../TripUtil'
import { useTripsContext } from '../Provider/TripsProvider'
import Loader from '../../../common/Loader'
import React from 'react'
import useLocationsHook from '../../locations/locations.hook'
import { toRem16 } from '../../../../styles/commonStyles'
import { Vehicle } from '../../../bookkeeping/reports/MileageReport'
import { useThemeContext } from '../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../styles/models/Colors.interface'
import { capitalizeFirstLetter } from '../../../../utils/appUtil'
import useDeviceSize from '../../../../hooks/useDeviceSize'
import { adjustToMiddayUTC } from '../../../../utils/dateUtil'

type TripOverlayPropsType = {
    isOpen: boolean
    handleClose: () => void
    trip?: Trip
    reloadData?: () => void
    isNew?: boolean
    isReturnTrip?: boolean
    AutoCompleteFieldCreatedValues?: AutoCompleteFieldCreatedValuesType
    submitCallback?: (arg?: Trip) => void
}

const useStyles = makeStyles<Theme, ThemeColors>((theme) => ({
    flex: {
        display: 'flex',
    },
    actionButton: {
        marginTop: '0.5rem',
    },
    defaultMenuItem: {
        color: (colorTheme) => colorTheme.grey400,
    },
    autocompleteOption: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    lightText: {
        color: (colorTheme) => colorTheme.grey400,
        fontWeight: 400,
    },
    tinyLabel: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        letterSpacing: '0.045rem',
        color: (colorTheme) => colorTheme.grey400,
    },
    cross: {
        position: 'relative',
        top: '.5rem',
        left: '1rem',
    },
    equal: {
        position: 'relative',
        top: '.5rem',
        right: '.2rem',
    },
    newLinkBox: {
        borderBottom: '0.063rem solid rgba(18, 23, 36, 0.2)',
    },
    mr0_5: {
        marginRight: '0.5rem',
    },
    ml0_5: {
        marginLeft: '0.5rem',
    },
    mt1: {
        marginTop: '1rem',
    },
    mileageTitle: {
        marginTop: '1rem',
        marginLeft: '1rem',
    },
    autocompleteInput: {
        width: '100%',
    },
    vehicleInput: {
        maxWidth: '24rem',
    },
    w14: {
        width: '14rem',
    },
    locationInput: {
        minWidth: '28rem',
        [theme.breakpoints.down('xs')]:{
            minWidth: 'auto'
        }
    },
    purposeInput: {
        maxWidth: '24rem',
    },
    textField: {
        width: '100%',
    },
    gray: {
        color: '#8C8C8C',
    },
    vehicleSubtitle: {
        fontSize: 12,
        color: (colorTheme) => colorTheme.grey400,
        marginLeft: '2rem',
    },
    subtitle: {
        fontSize: 12,
        color: (colorTheme) => colorTheme.grey400,
    },
    cover: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(200, 200, 200, .5)',
        zIndex: 1,
    },
    p0: {
        padding: 0,
    },
    widthAuto: {
        width: 'auto',
    },
    loaderContainer: {
        width: toRem16(50),
        height: toRem16(50),
        margin: 'auto',
    },
    dialogRoot: {
        [theme.breakpoints.down('xs')]: {
            '& .MuiDialog-paper': {
                margin: '16px',
            },
            '& .MuiDialog-paperFullWidth': {
                width: '100%',
            },
            '& .MuiDialogContent-root': {
                padding: '8px'
            },
        },
    },
}))

const TripOverlayForm = (props: TripOverlayPropsType) => {
    const {
        isOpen,
        handleClose,
        trip,
        reloadData,
        submitCallback,
        isNew,
        isReturnTrip,
    } = props

    const tripId = trip?.id
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const store = useCurrentStore()

    const overlayTitle = isNew ? 'New Trip' : 'Edit Trip'
    const formikRef = useRef<FormikProps<FormikValues>>(null)

    const [loading, setLoading] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false)
    const [openVehicleModal, setOpenVehicleModal] = useState(false)
    const [openStartLocationModal, setOpenStartLocationModal] = useState(false)
    const [openDestinationModal, setOpenDestinationModal] = useState(false)
    const [openPurposeModal, setOpenPurposeModal] = useState(false)
    const [isFreeTrial, setIsFreeTrial] = useState<boolean>(false)
    const [editableLocation, setEditableStartLocation] = useState(null)
    const [editableDestination, setEditableDestination] = useState(null)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showTripConfirmModal, setShowTripConfirmModal] = useState(false)
    const { formikRef: LocationFormRef } = useLocationsHook()
    const { isMobileDevice } = useDeviceSize()

    const {
        allVehicles,
        allLocations,
        allPurposes,
        setAllPurposes,
        setAllVehicles,
        setAllLocations,
        setRecallData,
    } = useTripsContext()

    const currentYear = new Date().getFullYear()
    const minDate = new Date(2014, 0, 1)
    const maxDate = new Date(currentYear, 11, 31)

    const selectedYear = useRef<string>(currentYear.toString())
    const defaultVehicle = allVehicles?.find(
        (vehicle: any) => vehicle.is_default
    )
    const defaultPurpose = allPurposes?.find(
        (purpose: any) => purpose.is_default_mt_purpose
    )
    const { currentAccount } = store
    const currentPlan: string = currentAccount.client_tier_permission

    const activities = store.currentConfig.apiConfig.mileage_tracker.activities
    const mileageYears =
        store.currentConfig.apiConfig.mileage_tracker.supported_years

    let [shouldShowParkingAndTolls, setShouldShowParkingAndTolls] =
        useState(isNew)

    const shouldDisableAddVehicleButton =
        isFreeTrial && allVehicles?.filter((v:Vehicle) => v.id !== '').length >= 1

    const setFormFieldValue = (fieldName: string, value: any) => {
        formikRef?.current?.setFieldValue(fieldName, value)
    }

    const { embedded_location_from_id, embedded_location_to_id } =
        embeddedLocationTempIds

    const getFormValues = useMemo(() => {
        if (!isNew && trip) {
            return {
                ...initialFormValues,
                ...trip,
                activity: trip.activity?.toLowerCase() ?? 'business',
                startLocation:
                    trip.location_from ??
                    (trip.embedded_location_from
                        ? embedded_location_from_id
                        : ''),
                destination:
                    trip.location_to ??
                    (trip.embedded_location_to ? embedded_location_to_id : ''),
                mileageRate: trip.mileage_rate > 0 ? trip.mileage_rate : 0,
                mileageDeductions: trip?.tax_deductions ?? 0,
                parking: trip.parking > 0 ? trip.parking : 0,
                tolls: trip.tolls > 0 ? trip.tolls : 0,
                showOdometer: trip?.show_odometer ?? false,
                odometerStart: trip?.odometer_start ?? 0,
                odometerEnd: trip?.odometer_end ?? 0,
            }
        } else if (isReturnTrip && trip) {
            return {
                ...initialFormValues,
                ...trip,
                activity: trip.activity?.toLowerCase() ?? 'business',
                startLocation:
                    trip?.location_to ??
                    (trip.embedded_location_to ? embedded_location_to_id : ''),
                destination:
                    trip?.location_from ??
                    (trip.embedded_location_from
                        ? embedded_location_from_id
                        : ''),
                mileageRate: trip.mileage_rate > 0 ? trip.mileage_rate : 0,
                mileageDeductions: trip?.tax_deductions ?? 0,
                parking: trip.parking > 0 ? trip.parking : 0,
                tolls: trip.tolls > 0 ? trip.tolls : 0,
                showOdometer: trip?.show_odometer ?? false,
                odometerStart: trip?.odometer_end ?? 0,
                odometerEnd: typeof trip?.odometer_start === 'number' &&  typeof trip?.odometer_end === 'number' ?  (trip.odometer_end + (trip.odometer_end - trip.odometer_start)) : 0,
            }
        } else {
            const activityId = initialFormValues.activity as ActivityIdType
            const timestamp = initialFormValues.date
            const mileageRate = getMileageRate(
                activities,
                activityId,
                timestamp
            )

            return {
                ...initialFormValues,
                vehicle: defaultVehicle?.id || '',
                purpose: defaultPurpose?.id || '',
                mileageRate,
            }
        }
    }, [trip])

    useEffect(() => {
        if (!isOpen) return

        if (isNew && !isReturnTrip) {
            return setAllLocations((prev: Location[]) => 
            prev.filter(({ id, saveChangesToLocations }) => 
                    ![embedded_location_from_id, embedded_location_to_id].includes(id) && saveChangesToLocations !== false
                )
            );        
        }

        if(!trip) return;

        const { embedded_location_from, embedded_location_to } = trip

        const addEmbeddedLocationToDropdown = (
            locationData: any,
            locationId: string,
            type: 'startLocation' | 'destination'
        ) => {
            setAllLocations((prev: any[]) => {
                const embeddedLocationIndex = prev.findIndex(
                    (location: Location) => location.id === locationId
                )

                const updatedLocation = {
                    type,
                    id: locationId,
                    ...locationData,
                }

                if (embeddedLocationIndex === -1) {
                    return [...prev, updatedLocation]
                } else {
                    const updatedLocations = [...prev]
                    updatedLocations[embeddedLocationIndex] = updatedLocation
                    return updatedLocations
                }
            })
        }

        const removeEmbeddedLocationFromDropdown = (locationId: string) => {
            setAllLocations((prev: any[]) =>
                prev.filter((location: Location) => location.id !== locationId)
            )
        };

        if (embedded_location_from) {
            addEmbeddedLocationToDropdown(
                embedded_location_from,
                embedded_location_from_id,
                isReturnTrip ? 'destination' : 'startLocation'
            )
        }else {
            removeEmbeddedLocationFromDropdown(embedded_location_from_id)
        }

        if (embedded_location_to) {
            addEmbeddedLocationToDropdown(
                embedded_location_to,
                embedded_location_to_id,
                isReturnTrip ? 'startLocation' : 'destination'
            )
        }else {
            removeEmbeddedLocationFromDropdown(embedded_location_to_id)
        }
    }, [trip, isOpen])

    // const addDefaultOption = (prevItems: any[], name: string) => {
    //     if (prevItems.findIndex((item) => item.id === '') === -1) {
    //         return [{ id: '', name, disabled: true }, ...prevItems]
    //     }
    //     return prevItems
    // }

    // useEffect(() => {
    //     setAllLocations((prev: Location[]) =>
    //         addDefaultOption(prev, 'Select Location')
    //     )
    // }, [allLocations])

    // useEffect(() => {
    //     setAllPurposes((prev: Purpose[]) =>
    //         addDefaultOption(prev, 'Select Purpose')
    //     )
    // }, [allPurposes])

    // useEffect(() => {
    //     setAllVehicles((prev: Vehicle[]) =>
    //         addDefaultOption(prev, 'Select Vehicle')
    //     )
    // }, [allVehicles])

    const initialValues = getFormValues

    const showDiscardChangesModal = () => {
        if (LocationFormRef.current?.values?.address?.country === null)
            delete LocationFormRef.current?.values?.address?.country
        const isFormChanged =
            JSON.stringify(LocationFormRef.current?.values) !==
            JSON.stringify(LocationFormRef.current?.initialValues)

        if (isFormChanged) {
            setShowConfirmModal(true)
        } else {
            setOpenStartLocationModal(false)
            setOpenDestinationModal(false)
            setEditableStartLocation(null)
            setEditableDestination(null)
        }
    }

    const handleCloseConfirmModal = (result: boolean) => {
        setShowConfirmModal(false)
        if (result === true) {
            setOpenStartLocationModal(false)
            setOpenDestinationModal(false)
            setEditableStartLocation(null)
            setEditableDestination(null)
        }
    }

    const showTripDiscardChangesModal = () => {
        formikRef.current?.dirty ? setShowTripConfirmModal(true) : handleClose()
    }

    const handleTripCloseConfirmModal = (result: boolean) => {
        setShowTripConfirmModal(false)
        if (result === true) {
            handleClose()
        }
    }

    const handleOdometerChange = (
        odometerStart: number,
        odometerEnd: number
    ) => {
        if (
            odometerStart > 0 &&
            odometerEnd > 0 &&
            odometerEnd >= odometerStart
        ) {
            const miles = odometerEnd - odometerStart
            setFormFieldValue('miles', miles)
            updateMileage(miles)
        }
    }

    const handleInputFocus = (event: any) => {
        const { name, value } = event.target
        if (value === '0') {
            setFormFieldValue(name, '')
        }
    }

    const handleMilesChange = (event: any) => {
        const { value } = event.target
        setFormFieldValue('miles', value)
        setFormFieldValue('showOdometer', false);
        setFormFieldValue('odometerStart', 0);
        setFormFieldValue('odometerEnd', 0);

        updateMileage(value)
    }

    const updateMileage = (_miles?: number) => {
        const activityId = formikRef?.current?.values?.activity ?? 'business'
        const timestamp =
            formikRef?.current?.values?.date ?? initialFormValues.date
        const miles = _miles ?? formikRef?.current?.values?.miles
        if (activityId && timestamp) {
            const newMileageRate = getMileageRate(
                activities,
                activityId,
                timestamp
            )
            const standardizedTimestamp = standardizeTimestamp(timestamp)
            selectedYear.current = new Date(standardizedTimestamp)
                .getFullYear()
                .toString()
            setFormFieldValue('mileageRate', newMileageRate)

            const mileageDeductions = miles * newMileageRate
            setFormFieldValue('mileageDeductions', mileageDeductions)
        }
    }

    const handleActivityChange = async (event: {
        target: { value: string }
    }) => {
        const { value } = event.target
        setFormFieldValue('activity', value)
        const timestamp =
            formikRef?.current?.values?.date ?? initialFormValues.date
        const newMileageRate = getMileageRate(
            activities,
            value as ActivityIdType,
            timestamp
        )
        const miles = formikRef?.current?.values?.miles
        const mileageDeductions = miles * newMileageRate
        setFormFieldValue('activity', value)
        setFormFieldValue('mileageRate', newMileageRate)
        setFormFieldValue('mileageDeductions', mileageDeductions)

        if (value === 'business') {
            setShouldShowParkingAndTolls(true)
        } else {
            setShouldShowParkingAndTolls(false)
        }
    }

    const handleDateChange = async (event: Moment) => {
        setFormFieldValue('date', adjustToMiddayUTC(event))
        await Promise.resolve()
        updateMileage()
    }

    const handleTabChange = (value: any) => {
        const shouldShowOdometer = value === 1
        setFormFieldValue('showOdometer', shouldShowOdometer)
    }

    const handleSaveClick = () => {
        formikRef.current?.validateForm().then((errors: FormikErrors<any>) => {
            if (Object.keys(errors).length === 0) {
                formikRef.current?.submitForm()
            } else {
                Object.keys(errors).map((field: string) =>
                    formikRef.current?.setFieldTouched(field, true)
                )
            }
        })
    }

    const handleSubmit = (values: FormikValues) => {
        setSubmitting(true)
        const formattedData = formatRequestData(values)

        if (values?.startLocation_data?.saveChangesToLocations === false) {
            formattedData.$location_from = values.startLocation_data
            formattedData.embedded_location_from = values.startLocation_data
            delete formattedData.location_from
        } else {
            delete formattedData.$location_from
            delete formattedData.embedded_location_from
        }

        if (values?.destination_data?.saveChangesToLocations === false) {
            formattedData.$location_to = values.destination_data
            formattedData.embedded_location_to = values.destination_data
            delete formattedData.location_to
        } else {
            delete formattedData.$location_to
            delete formattedData.embedded_location_to
        }

        if (
            [embedded_location_from_id, embedded_location_to_id].includes(
                formattedData.location_from!
            )
        ) {
            if (!isReturnTrip) {
                formattedData.$location_from = values.embedded_location_from
                formattedData.embedded_location_from =
                    values.embedded_location_from
                delete formattedData.location_from
            } else {
                formattedData.$location_from = values.embedded_location_to
                formattedData.embedded_location_from =
                    values.embedded_location_to
                delete formattedData.location_from
            }
        }

        if (
            [embedded_location_from_id, embedded_location_to_id].includes(
                formattedData.location_to!
            )
        ) {
            if (!isReturnTrip) {
                formattedData.$location_to = values.embedded_location_to
                formattedData.embedded_location_to = values.embedded_location_to
                delete formattedData.location_to
            } else {
                formattedData.$location_to = values.embedded_location_from
                formattedData.embedded_location_to =
                    values.embedded_location_from
                delete formattedData.location_to
            }
        }

        const postSubmit = (isError: boolean, newTrip: Trip) => {
            if (!isError) {
                setRecallData(true)
                submitCallback && submitCallback(newTrip)
                reloadData?.()
                handleClose()
            }
            setSubmitting(false)
        }

        isNew
            ? createTrip(formattedData, postSubmit)
            : tripId && updateTrip(tripId!, formattedData, postSubmit)
    }

    const formatMoney = (value: string | number, fixed = false) => {
        if (typeof value === 'string') {
            return value ? '$ ' + parseFloat(value) : '$ 0.00'
        } else {
            if (fixed) {
                return value > 0 ? '$ ' + value.toFixed(2) : '$ 0.00'
            }
            return value > 0 ? '$ ' + value : '$ 0.00'
        }
    }

    useEffect(() => {
        if (isOpen) {
            trip && setShouldShowParkingAndTolls(trip?.activity === 'business')
            setIsFreeTrial(currentPlan === PLAN_LEVEL_NAMES.FREE)
        }
    }, [currentPlan, isOpen, trip])

    const ActionButtons = () => (
        <React.Fragment>
            {isSubmitting ? (
                <Box className={classes.loaderContainer}>
                    <Loader />
                </Box>
            ) : (
                <Grid
                    justify="flex-end"
                    container
                    className={classes.actionButton}
                >
                    <Button
                        variant="outlined"
                        color="secondary"
                        disabled={isSubmitting}
                        className={classes.mr0_5}
                        onClick={showTripDiscardChangesModal}
                        data-cy="cancel-trip-changes"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleSaveClick}
                    >
                        {isNew ? 'Save Trip' : 'Save Changes'}
                    </Button>
                </Grid>
            )}
        </React.Fragment>
    )

    const AutoCompleteTextField = (
        params: AutocompleteRenderInputParams,
        props: FieldProps,
        errors: any,
        fieldError?: any,
        selectedField?: string,
        inputLabel?: string
    ) => {
        const isTouchedOrSubmitted =
            !!formikRef?.current?.touched[props.field.name] ||
            !!formikRef?.current?.submitCount
        const hasFieldError =
            (!!errors && errors[props.field.name]) ||
            props?.meta?.error ||
            fieldError
        const showError =
            !!(isTouchedOrSubmitted && hasFieldError) && !props?.field.value

        return (
            <TextField
                {...params}
                size="small"
                className={classes.textField}
                fullWidth={false}
                variant="outlined"
                InputProps={params.InputProps}
                label={inputLabel ?? props.field.name}
                InputLabelProps={{
                    style: { textTransform: 'capitalize' },
                }}
                data-cy={`${selectedField}-input`}
                error={showError}
                helperText={showError ? hasFieldError : ''}
            />
        )
    }

    const showEditItem = (itemType: string, values: any) => {
        let location
        let setEditableLocation
        let setOpenLocationModal

        switch (itemType) {
            case 'startLocation':
                location = allLocations.find(
                    (location: Location) =>
                        location?.id === values?.startLocation
                )
                setEditableLocation = setEditableStartLocation
                setOpenLocationModal = setOpenStartLocationModal
                break
            case 'destination':
                location = allLocations.find(
                    (location: Location) => location?.id === values?.destination
                )
                setEditableLocation = setEditableDestination
                setOpenLocationModal = setOpenDestinationModal
                break
            default:
                return
        }

        setEditableLocation(location)
        setOpenLocationModal(true)
    }

    const getInputLabel = (obj: any) => {
        if (!obj.name) {
            const vehicleLabel = `${obj.make} ${obj.model} `
            const licenseNumber = obj.license_plate_number
                ? '(' + obj.license_plate_number + ')'
                : ''
            if (obj.license_plate_number) {
                return vehicleLabel + licenseNumber
            }
            return vehicleLabel
        }
        return obj.title ?? obj.name
    }

    const getInputSubtitle = (obj: any) => {
        if (obj.address) {
            return obj.address.address
        }
        if (typeof obj.is_default_mt_purpose !== 'undefined') {
            return obj.is_default_mt_purpose ? 'Default' : ''
        }
        if (obj.is_default) {
            return 'Default'
        }
        return ''
    }

    const AutoCompleteRenderOption = (option: any) => {
        const shouldShowIcon = !!option.make
        return (
            <div className={classes.autocompleteOption}>
                <div className={classes.flex}>
                    {shouldShowIcon && <VehicleIcon />}
                    <span className={shouldShowIcon ? classes.ml0_5 : ''}>
                        {getInputLabel(option)}
                    </span>
                </div>
                <div
                    className={
                        shouldShowIcon
                            ? classes.vehicleSubtitle
                            : classes.subtitle
                    }
                >
                    {getInputSubtitle(option)}
                </div>
            </div>
        )
    }

    const createNewLink = (
        { children, ...other }: any,
        selectedField: string
    ) => {
        let callback: () => void
        let createNewLabel = ''
        const isVehicleField = selectedField === 'vehicle'
        switch (selectedField) {
            case 'vehicle':
                callback = () => setOpenVehicleModal(true)
                createNewLabel = 'Vehicle'
                break
            case 'startLocation':
                callback = () => setOpenStartLocationModal(true)
                createNewLabel = 'Location'
                break
            case 'destination':
                callback = () => setOpenDestinationModal(true)
                createNewLabel = 'Location'
                break
            case 'purpose':
                callback = () => setOpenPurposeModal(true)
                createNewLabel = 'Purpose'
                break
            default:
                break
        }
        return (
            <Paper {...other}>
                <Box className={classes.newLinkBox}>
                    <Button
                        fullWidth
                        startIcon={<AddIcon />}
                        onMouseDown={(event) => {
                            event.preventDefault()
                        }}
                        onClick={(event) => {
                            event.preventDefault()
                            callback()
                        }}
                        disabled={
                            isVehicleField && shouldDisableAddVehicleButton
                        }
                    >
                        {selectedField === 'vehicle'
                            ? 'Add a New Vehicle'
                            : `New ${createNewLabel}`}
                    </Button>
                </Box>
                {children}
            </Paper>
        )
    }

    const getAutoCompleteValue = (values: any, selectedField: string) => {
        const id = values?.[selectedField] || ''
        const mapping: Record<string, any[]> = {
            vehicle: allVehicles,
            purpose: allPurposes,
            startLocation: allLocations,
            destination: allLocations,
        }

        return mapping[selectedField]?.find((item: any) => item.id === id)
    }

    const locationCallback = (
        type: 'startLocation' | 'destination',
        location: Location
    ) => {
        type === 'startLocation'
            ? setOpenStartLocationModal(false)
            : setOpenDestinationModal(false)

        if (location.saveChangesToLocations === false) {
            location.id = String(Math.random() * 100)
            // @ts-ignore
            location.field = type
            // @ts-ignore
            location.type = type
        }

        if (location) {
            setFormFieldValue(type, location.id)
            setFormFieldValue(`${type}_data`, location)

            const updatedLocations = [...allLocations].filter(loc => !(parseFloat(loc.id) <= 100 && loc.field === type))
            const indexOfLocation = updatedLocations.findIndex(
                (loc: Location) => loc.id === location.id
            )

            if (indexOfLocation === -1) {
                // @ts-ignore
                setAllLocations((prev: Location[]) => [...prev.filter(loc => !(parseFloat(loc.id) <= 100 && loc.field === type)), location])
            } else {
                updatedLocations.splice(indexOfLocation, 1, location)
                setAllLocations(updatedLocations)
            }
        }
    }

    const handleCloseLocationModal = (
        type: 'startLocation' | 'destination'
    ) => {
        const mapping: Record<string, any> = {
            startLocation: editableLocation,
            destination: editableDestination,
        }

        const modalMapping: Record<string, any> = {
            startLocation: setOpenStartLocationModal,
            destination: setOpenDestinationModal,
        }

        if (mapping[type]) {
            showDiscardChangesModal()
        } else {
            modalMapping[type](false)
        }
    }

    const renderAutocompleteInput = (
        options: any,
        selectedField: string,
        values: any,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined
        ) => void,
        errors?: FormikErrors<any>,
        inputLabel?: string
    ) => (
        <Field name={selectedField}>
            {(props: FieldProps<any>) => {
                const isLocationField =
                    selectedField === 'startLocation' ||
                    selectedField === 'destination'
                
                if(isLocationField){
                    options = options.filter((option: any) => (!option.type || option.type === selectedField) ? true : false)
                }

                const value = getAutoCompleteValue(values, selectedField) || null
                return (
                    <Autocomplete
                        value={value}
                        fullWidth={isLocationField}
                        options={options}
                        className={classes.autocompleteInput}
                        getOptionLabel={(option) => getInputLabel(option)}
                        // getOptionDisabled={(option) => option.disabled}
                        // getOptionSelected={(option, value) => {
                        //     return typeof option === 'string'
                        //         ? option === value
                        //         : option?.id === value?.id
                        // }}
                        renderInput={(
                            params: AutocompleteRenderInputParams
                        ) => {
                            const selectedFieldError =
                                formikRef?.current?.errors[selectedField]
                            if (selectedField !== 'vehicle') {
                                params.InputProps = {
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon
                                                className={classes.gray}
                                            />
                                        </InputAdornment>
                                    ),
                                }
                            }
                            return AutoCompleteTextField(
                                params,
                                props,
                                errors,
                                selectedFieldError,
                                selectedField,
                                inputLabel
                            )
                        }}
                        onBlur={() => {
                            props?.form.setTouched({
                                ...props.form.touched,
                                [props.field.name]: true,
                            })
                        }}
                        renderOption={(option: any) =>
                            AutoCompleteRenderOption(option)
                        }
                        onChange={(e, item: any) => {
                            setFormFieldValue(
                                selectedField,
                                item ? item.id : ''
                            )
                        }}
                        PaperComponent={(children) => {
                            return createNewLink(children, selectedField)
                        }}
                    />
                )
            }}
        </Field>
    )

    const Form = (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
            innerRef={formikRef}
            // validateOnChange={true}
            // validateOnBlur={true}
        >
            {({ values, setFieldValue, errors }) => (
                <form>
                    <Box p={2} className={classes.vehicleInput}>
                        {renderAutocompleteInput(
                            allVehicles,
                            'vehicle',
                            values,
                            setFieldValue,
                            errors,
                            'Vehicle (Required)'
                        )}
                    </Box>
                    <Box p={2} className={classes.w14}>
                        <UiFormControlSelection
                            label="Date (Required)"
                            type="date"
                            fieldName="date"
                            dateType="string"
                            showFloatingLabel={true}
                            dateFormat="MMM DD, yyyy"
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={(e: any) => handleDateChange(e)}
                            endIcon={<CalendarIcon />}
                            fullWidth={true}
                        />
                    </Box>
                    <Box p={2} className={classes.w14}>
                        <UiFormControlSelection
                            label="Activity (Required)"
                            showFloatingLabel={true}
                            type="select"
                            fieldName="activity"
                            optionsData={activities}
                            optionValue="id"
                            optionKey="name"
                            onChange={(e: any) => {
                                handleActivityChange(e)
                            }}
                            fullWidth={true}
                            showPlaceholderAsOption={false}
                        />
                    </Box>
                    <Box p={2} display="flex" className={classes.locationInput}>
                        {renderAutocompleteInput(
                            allLocations,
                            'startLocation',
                            values,
                            setFieldValue,
                            errors,
                            'Start Location (Required)'
                        )}

                        {values.startLocation && (
                            <Button
                                onClick={() =>
                                    showEditItem('startLocation', values)
                                }
                            >
                                <EditOutlined className={classes.gray} />
                            </Button>
                        )}
                    </Box>
                    <Box p={2} display="flex" className={classes.locationInput}>
                        {renderAutocompleteInput(
                            allLocations,
                            'destination',
                            values,
                            setFieldValue,
                            errors,
                            'Destination (Required)'
                        )}

                        {values.destination && (
                            <Button
                                onClick={() =>
                                    showEditItem('destination', values)
                                }
                            >
                                <EditOutlined className={classes.gray} />
                            </Button>
                        )}
                    </Box>
                    <Box p={2} display="flex" className={classes.purposeInput}>
                        {renderAutocompleteInput(
                            allPurposes,
                            'purpose',
                            values,
                            setFieldValue,
                            errors,
                            'Purpose (Required)'
                        )}
                    </Box>
                    <UiText
                        weight="medium_500"
                        variant="hatchback_125"
                        className={classes.mileageTitle}
                    >
                        Mileage{' '}
                        <span className={classes.lightText}>(Required)</span>
                    </UiText>
                    <Box pt={2} pb={2}>
                        <PortalTabs
                            selectedValue={values.showOdometer ? 1 : 0}
                            onTabChange={(value: any) => {
                                handleTabChange(value)
                            }}
                        >
                            <TabPanelHolder index={0} title="Miles">
                                <Box mt={4} mb={1}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <UiFormControlSelection
                                                label="Miles (Required)"
                                                type="number"
                                                fieldName="miles"
                                                showFloatingLabel={true}
                                                onChange={(e: any) => {
                                                    handleMilesChange(e)
                                                }}
                                                value={values.miles}
                                                min={0}
                                                size={isMobileDevice ? 'medium' : 'small'}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            className={classes.cross}
                                        >
                                            x
                                        </Grid>
                                        <Grid item xs={3}>
                                            <UiText
                                                className={classes.tinyLabel}
                                            >
                                                Mileage Rate*
                                            </UiText>
                                            <UiText>
                                                {formatMoney(
                                                    values.mileageRate ?? 0
                                                )}
                                            </UiText>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            className={classes.equal}
                                        >
                                            =
                                        </Grid>
                                        <Grid item xs={4}>
                                            <UiText
                                                className={classes.tinyLabel}
                                            >
                                                Mileage Deductions
                                            </UiText>
                                            <UiText>
                                                {formatMoney(
                                                    values.mileageDeductions ??
                                                        0,
                                                    true
                                                )}
                                            </UiText>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <UiText
                                    variant="bike_50"
                                    className={classes.lightText}
                                >
                                    *IRS rate for {capitalizeFirstLetter(formikRef?.current?.values?.activity)} Travel in{' '}
                                    {selectedYear.current}
                                </UiText>
                            </TabPanelHolder>
                            <TabPanelHolder index={1} title="Odometer">
                                <Box mt={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <UiFormControlSelection
                                                label="Odometer Start"
                                                type="number"
                                                fieldName="odometerStart"
                                                value={values.odometerStart}
                                                showFloatingLabel={true}
                                                onFocus={(e: any) => {
                                                    handleInputFocus(e)
                                                }}
                                                onChange={(e: any) => {
                                                    handleOdometerChange(
                                                        e.target.value,
                                                        values.odometerEnd
                                                    )
                                                }}
                                                size={isMobileDevice ? 'medium' : 'small'}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <UiFormControlSelection
                                                label="Odometer End"
                                                type="number"
                                                fieldName="odometerEnd"
                                                value={values.odometerEnd}
                                                showFloatingLabel={true}
                                                onFocus={(e: any) => {
                                                    handleInputFocus(e)
                                                }}
                                                onChange={(e: any) => {
                                                    handleOdometerChange(
                                                        values.odometerStart,
                                                        e.target.value
                                                    )
                                                }}
                                                size={isMobileDevice ? 'medium' : 'small'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TabPanelHolder>
                        </PortalTabs>
                    </Box>
                    {shouldShowParkingAndTolls && (
                        <>
                            <Box pt={2} pb={2}>
                                <UiText
                                    weight="medium_500"
                                    variant="hatchback_125"
                                    className={classes.mt1}
                                >
                                    Parking & Tolls{' '}
                                    <span className={classes.lightText}>
                                        (Optional)
                                    </span>
                                </UiText>
                                <Box mt={2}>
                                    <UiText variant="car_100">
                                        In addition to using the standard
                                        mileage rate, you can deduct any
                                        business-related parking fees and tolls.
                                    </UiText>
                                </Box>
                                <Box mt={2} mb={2}>
                                    <UiText variant="car_100">
                                        Only enter amounts paid with cash or
                                        with accounts that aren’t already
                                        connected to the portal.
                                    </UiText>
                                </Box>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <UiFormControlSelection
                                        label="Parking (Optional)"
                                        type="number"
                                        fieldName="parking"
                                        showFloatingLabel={true}
                                        startIcon="$"
                                        onFocus={(e: any) => {
                                            handleInputFocus(e)
                                        }}
                                        min={0}
                                        size={isMobileDevice ? 'medium' : 'small'}
                                    />
                                </Grid>
                            </Grid>
                            <Box mb={4} mt={1}>
                                <UiText
                                    variant="bike_50"
                                    className={classes.lightText}
                                >
                                    (Parking fees you pay to park your car at
                                    your place of work are nondeductible
                                    commuting expenses.)
                                </UiText>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <UiFormControlSelection
                                        label="Tolls (Optional)"
                                        type="number"
                                        fieldName="tolls"
                                        showFloatingLabel={true}
                                        startIcon="$"
                                        onFocus={(e: any) => {
                                            handleInputFocus(e)
                                        }}
                                        min={0}
                                        size={isMobileDevice ? 'medium' : 'small'}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Box mt={shouldShowParkingAndTolls ? 4 : 0}>
                        <UiFormControlSelection
                            label="Description (Optional)"
                            type="textarea"
                            fieldName="description"
                            showFloatingLabel={true}
                        />
                    </Box>
                </form>
            )}
        </Formik>
    )

    return (
        <>
            <UiDialog
                customRootClass={classes.dialogRoot}
                open={isOpen}
                handleClose={showTripDiscardChangesModal}
                title={overlayTitle}
                size="sm"
                actions={<ActionButtons />}
            >
                {loading && <div className={classes.cover} />}
                {Form}
            </UiDialog>
            {/* new vehicle modal */}
            <AddEditVehicle
                isEditMode={false}
                showModal={openVehicleModal}
                mileageYears={mileageYears}
                onModalClose={() => setOpenVehicleModal(false)}
                refreshVehicles={() => {}}
                initialFormValues={vehicleInitialValues}
                callbackVehicle={(vehicle: TripVehicle) => {
                    setFormFieldValue('vehicle', vehicle.id)
                    setAllVehicles((prev: TripVehicle[]) => [...prev, vehicle])
                }}
            />
            {/* startLocation modal */}
            <LocationModal
                open={openStartLocationModal}
                handleClose={() => handleCloseLocationModal('startLocation')}
                formikRef={LocationFormRef}
                locationCallback={(location: Location) =>
                    locationCallback('startLocation', location)
                }
                location={editableLocation}
                onlyForOneTrip={!!editableLocation}
            />
            {/* destination modal */}
            <LocationModal
                open={openDestinationModal}
                handleClose={() => handleCloseLocationModal('destination')}
                formikRef={LocationFormRef}
                locationCallback={(location: Location) =>
                    locationCallback('destination', location)
                }
                location={editableDestination}
                onlyForOneTrip={!!editableDestination}
            />
            <PurposeModal
                open={openPurposeModal}
                handleClose={() => setOpenPurposeModal(false)}
                purposeCallback={(purpose: Purpose) => {
                    setOpenPurposeModal(false)
                    if (purpose) {
                        setFormFieldValue('purpose', purpose.id)
                        setAllPurposes((prev: Purpose[]) => [...prev, purpose])
                    }
                }}
            />
            <DiscardChangesModal
                open={showConfirmModal}
                handleClose={handleCloseConfirmModal}
            />
            <DiscardChangesModal
                open={showTripConfirmModal}
                handleClose={handleTripCloseConfirmModal}
            />
        </>
    )
}

export default TripOverlayForm
