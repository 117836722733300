import { FieldArray, FormikContextType, useFormikContext } from 'formik'
import UiFormOptions from './UiFormOptions'
import {
    Button,
    Divider,
    IconButton,
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core'
import { ThemeColors } from '../../../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../../../common/whiteLabel/ColorThemeContext'
import AddIcon from '@material-ui/icons/Add'
import UiText from '../../../../../common/ui/UiText'
import { toRem16 } from '../../../../../../styles/commonStyles'
import { getStepModel, getValueByModelName } from '../../../common/VtoUtils'
import { Fragment, useRef, useState } from 'react'
import { useVtoContext } from '../../../providers/VtoProvider'
import { MULTI_STEP } from '../../../models/vto.const'
import { useCurrentStore } from '../../../../../common/hooks/useCurrentStore'
import UiButton from '../../../../../common/ui/UiButton'
import ChargeoverIframeModal from '../../../../../sales/ChargeoverIframeModal'
import Icon from '../../../../../common/Icon'
import LinkButton from '../../../../../common/LinkButton'
import { EXPENSE_TOTAL_TYPE_QUESTION, EXPENSE_TYPE_QUESTION, SET_TYPE_QUESTION } from '../../../common/questions.const'
import OwnersAlertModal from '../modals/OwnersAlertModal'
import {
    CheckFieldArrayDetails,
    getDropdownOptions,
    isSolePrepBusiness,
    setNestedProperty,
} from '../../../common/vtoFieldArrayUtils'
import InfoTooltip from '../../../../../common/InfoTooltip'
import { setTimeout } from 'timers'

const useStyles = makeStyles<Theme, ThemeColors>((theme) =>
    createStyles({
        iconContainerText: {
            marginLeft: '0.5rem',
            textTransform: 'uppercase',
        },
        addActionContainer: {
            display: 'flex',
            color: (colorTheme) => colorTheme.blue200,
            cursor: 'pointer',
            width: 'fit-content',
        },
        deleteActionContainer: {
            display: 'flex',
            color: (colorTheme) => colorTheme.primaryWhite,
            backgroundColor: (colorTheme) => colorTheme.red200,
            cursor: 'pointer',
            width: 'fit-content',
            marginLeft: '1rem',
            '&:hover': {
                color: (colorTheme) => colorTheme.primaryWhite,
                backgroundColor: (colorTheme) => colorTheme.red200,
            },
        },
        iconContainer: {
            display: 'flex',
            alignItems: 'center',
            color: (colorTheme) => colorTheme.red200,
            cursor: 'pointer',
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& .btn-container': {
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
            }
        },
        inputRow: {
            marginBottom: toRem16(16),
        },
        dividerStyle: {
            marginTop: '2.5rem',
            marginBottom: '2rem',
        },
        alertContainer: {
            height: toRem16(44),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .btn-content': {
                display: 'flex',
            },
            [theme.breakpoints.down('xs')]: {
                height: 'auto',
                flexDirection: 'column',
                alignItems: 'flex-start',

                '& > p': {
                    height: toRem16(44),
                    lineHeight: toRem16(44)
                },

                '& > .btn-content': {
                    marginTop: '1rem',
                },
            }
        },
        linkButton: {
            margin: '1rem 0 1rem 0',
        },
    })
)

const DeleteConfirmContainer = ({
    styles,
    currentIndex,
    setDeletableIndex,
    handleDelete,
    colorTheme,
    title,
}: any) => {
    return (
        <div className={styles.alertContainer}>
            <UiText weight="semi_bold_600" variant="hatchback_125">
                Delete {title} {currentIndex + 1}
            </UiText>
            <div className="btn-content">
                <Button
                    variant="text"
                    onClick={() => setDeletableIndex(-1)}
                    className={styles.addActionContainer}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={styles.deleteActionContainer}
                    onClick={handleDelete}
                    startIcon={
                        <Icon
                            icon="deleteTrash"
                            svgColor={colorTheme.primaryWhite}
                        />
                    }
                >
                    Delete
                </Button>
            </div>
        </div>
    )
}

const AddItem = ({ styles, item_title, onAddItem }: any) => {
    return (
        <>
            <LinkButton
                startIcon={<AddIcon />}
                onClick={onAddItem}
                className={styles.linkButton}
            >
                <UiText variant="motorcycle_90" weight="semi_bold_600">
                    Add {item_title}
                </UiText>
            </LinkButton>
        </>
    )
}

const DeleteItem = ({
    setDeletableIndex,
    currentIndex,
    fieldArrInput,
    colorTheme,
    newProps,
}: any) => {
    return (
        <div key={`${fieldArrInput}.${currentIndex}`}>
            <IconButton
                onClick={() => {
                    setDeletableIndex(newProps.index)
                }}
            >
                <Icon icon="deleteTrash" svgColor={colorTheme.grey400} />
            </IconButton>
        </div>
    )
}

const generateEmptyObj = (questions: any) => {
    let data: any = {};

    questions.forEach((question: any) => {
        if (question.type === SET_TYPE_QUESTION) {
            question.questions.forEach((ques: any) => {
                if(ques.type === EXPENSE_TYPE_QUESTION || 
                    ques.type === EXPENSE_TOTAL_TYPE_QUESTION) {
                    // we had to explicitly add this for the nested structure of expense type of question
                    // THE MARKING QUESTION COUNT OF EXPENSE TYPE
                    // for expense type of question either expense or no expense will be selected
                    /// this is a hacky way to do it - OEHA-23988
                    if(data[ques.model] === undefined) {
                        data[ques.model] = {
                            has_others: null,
                            list: {},
                            others: {},
                            has_none: null
                        }
                    }
                    
                } else {
                    if (ques.model) {
                        // This function ensures that the nested structure is properly built
                        setNestedProperty(data, ques, null);
                    }
                }
            });
        }

        if (question.model) {
            setNestedProperty(data, question, null);
        }
    });

    return data;
};

// TOD: Figma styles will be added later
const UiFormFieldArray = (props: any) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const [deletableIndex, setDeletableIndex] = useState(-1)
    const [openChargeOverModal, setOpenChargeOverModal] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const { personalAccount, currentAccount } = useCurrentStore()
    const { values, setFieldValue }: FormikContextType<any> = useFormikContext()
    const {
        parentStep,
        isBusinessVTO,
        configProductDetails,
        selectedYear,
        setCurrentStep,
        setParentStep,
        currentVtoConfig,
    } = useVtoContext()

    const containerRef = useRef<any>(null);
    const isSolePrep = isSolePrepBusiness(props?.model, values)

    const question = props?.questions
    const defaultItem = props?.defaultItem
    const propOptions = props?.options
    const hasPurchaseOptions = props?.options?.purchased
    const productData = configProductDetails[hasPurchaseOptions?.product]
    const account = isBusinessVTO ? currentAccount : personalAccount

    /**
     * for MULTI_STEP type STEP -> Personal VTO > Sole Proprietorship
     * we need to get the exact values from the formik context 
     * currentVtoDetails?.solePropreitorship[0]
     */
    const { stepModel, stepIndex } = getStepModel(parentStep)
    const fieldArrInput =
        parentStep?.type === MULTI_STEP
            ? `${stepModel}.${stepIndex}.${props?.model}`
            : props?.model

    let emptyObj = generateEmptyObj(props?.questions)
    emptyObj = JSON.parse(JSON.stringify(emptyObj))
    const arrayVal = getValueByModelName(fieldArrInput, values)
    const dropdownOptions = getDropdownOptions(
        propOptions,
        currentVtoConfig,
        values
    )

    const handleItemPush = (push: any) => {
        if (isSolePrep) {
            setOpenAlert(true)
            return
        }
        let obj = generateEmptyObj(props?.questions)
        // deep copy
        obj = JSON.parse(JSON.stringify(obj))
        push(obj)
        setTimeout(() => {
            scrollToNextItem()
        }, 500)
    }

    const scrollToNextItem = () => {
        if (containerRef.current) {
            const arrayIndex = arrayVal?.length
            const element = document.getElementById(`${fieldArrInput}-${arrayIndex}`)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
        }
    }

    //  purchased flow of sole proprietorship/ rental income
    // checks are added to add the empty object in the array & allow purchase & add more btn
    const { addMore, allowPurchase, addEmptyItem } = CheckFieldArrayDetails(
        account,
        hasPurchaseOptions,
        arrayVal
    )

    if (addEmptyItem) {
        setFieldValue(fieldArrInput, [...arrayVal, { ...emptyObj}])
    }

    return (
        <Fragment>
            <ChargeoverIframeModal
                open={openChargeOverModal}
                productIds={[productData?.product_id]}
                handleClose={(event: any) => {
                    setOpenChargeOverModal(false)
                }}
                utmContent={
                    isBusinessVTO
                        ? 'Business+VTO+Additional+Services'
                        : 'Personal+VTO+Additional+Services'
                }
                otherParams={{
                    product_key: props?.options?.purchased?.product,
                    vto_year: selectedYear,
                }}
            />
            <OwnersAlertModal
                open={openAlert}
                handleClose={() => setOpenAlert(false)}
                onOk={() => {
                    setOpenAlert(false)
                    setCurrentStep('business_type')
                    setParentStep('business_type')
                }}
            />
            <div ref={containerRef}>
                <FieldArray name={fieldArrInput} >
                    {({ remove, push }) => {
                        return (
                            <>
                                
                                {arrayVal &&
                                    arrayVal.map((singleField: any, index: any) => {
                                        const newProps = {
                                            ...props,
                                            arrayFieldValue: singleField,
                                            type: 'questions-set',
                                            questions: question,
                                            parentModel: fieldArrInput,
                                            index: index,
                                            isFieldArray: true,
                                            item_title: props?.item_title,
                                            selectOptions: dropdownOptions,
                                        }
                                        const nonZeroIndex = index !== 0
                                        return (
                                            <div
                                                key={`${fieldArrInput}.${index}`}
                                                className={styles.inputRow}
                                                id={`${fieldArrInput}-${index}`}
                                            >
                                                <div>
                                                    {nonZeroIndex && (
                                                        <Divider
                                                            variant="fullWidth"
                                                            className={
                                                                styles.dividerStyle
                                                            }
                                                        />
                                                    )}
                                                </div>

                                                <div>
                                                    {deletableIndex !== -1 &&
                                                    deletableIndex === index ? (
                                                        <DeleteConfirmContainer
                                                            styles={styles}
                                                            currentIndex={index}
                                                            setDeletableIndex={
                                                                setDeletableIndex
                                                            }
                                                            handleDelete={() => {
                                                                remove(
                                                                    newProps?.index
                                                                )
                                                                setDeletableIndex(
                                                                    -1
                                                                )
                                                            }}
                                                            colorTheme={colorTheme}
                                                            title={
                                                                props?.item_title
                                                            }
                                                        />
                                                    ) : (
                                                        <div
                                                            className={
                                                                styles.titleContainer
                                                            }
                                                        >
                                                            <UiText
                                                                weight="medium_500"
                                                                variant="hatchback_125"
                                                            >
                                                                {props?.item_title}{' '}
                                                                {index + 1}
                                                            </UiText>
                                                            <DeleteItem
                                                                setDeletableIndex={
                                                                    setDeletableIndex
                                                                }
                                                                currentIndex={index}
                                                                fieldArrInput={
                                                                    fieldArrInput
                                                                }
                                                                colorTheme={
                                                                    colorTheme
                                                                }
                                                                newProps={newProps}
                                                            />
                                                        </div>
                                                    )}
                                                    <div className="col">
                                                        <UiFormOptions
                                                            {...newProps}
                                                            index={index}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                {addMore && (
                                    <AddItem
                                        styles={styles}
                                        item_title={props?.item_title}
                                        onAddItem={() => {
                                            handleItemPush(push)
                                        }}
                                    />
                                )}
                            </>
                        )
                    }}
                    
                </FieldArray>
                
            </div>
           
            {allowPurchase && (
                <div className={styles.titleContainer}>
                    <div className='btn-container'>
                        <UiButton
                            btnType="tertiary"
                            handleClick={() => {
                                setOpenChargeOverModal(true)
                            }}
                            customLabel={
                                <div style={{ display: 'flex' }}>
                                    <UiText>Purchase</UiText>
                                    <UiText>
                                        &nbsp;|{' '}
                                        {
                                            productData?.price_book
                                                ?.first_charge_amount
                                        }
                                    </UiText>
                                </div>
                            }
                        />
                        {hasPurchaseOptions?.hint && (
                            <div className={'classes.iconStyle'}>
                                <InfoTooltip
                                    tooltipText={hasPurchaseOptions?.hint}
                                    placement="top"
                                    customNode={
                                        <Icon
                                            icon="info"
                                            strokeColor={
                                                colorTheme.grey200
                                            }
                                        />
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default UiFormFieldArray
