import { makeStyles, Theme } from '@material-ui/core'
import UiText from '../../../../common/ui/UiText'
import { toRem16 } from '../../../../../styles/commonStyles'
import Icon from '../../../../common/Icon'

const useStyles = makeStyles<Theme>(() => {
    return {
        container: {
            marginTop: '1rem',
        },
        fileHeader: {
            marginTop: '2rem',
            marginBottom: '1rem',
        },
        formTitle: {
            textTransform: 'uppercase',
        },
        fileListing: {
            marginTop: '0.8rem',
        },
        alertContainer: {
            display: 'flex',
            marginLeft: '1.4rem',
            marginTop: '0.3rem',
        },
        textContainer: {
            marginTop: toRem16(3),
            marginLeft: toRem16(2),
        },
    }
})
const FileName = ({ file, index, required }: any) => {
    const styles = useStyles()
    return (
        <UiText className={styles.fileListing} weight="medium_500">
            {index + 1}. &nbsp; {file.title} &nbsp;
            {required && (
                <div className={styles.alertContainer}>
                    <Icon icon="alert" size="15px" />{' '}
                    <UiText
                        className={styles.textContainer}
                        variant="moped_75"
                        textColor="error"
                    >
                        (Required)
                    </UiText>
                </div>
            )}
            <div className={styles.alertContainer}>
                <span className={styles.fileCount}>{file.fileCount}</span>{' '}
                &nbsp;
                {file.form && (
                    <span className={styles.fileCount}>
                        {' '}
                        {file.fileCount > 1 ? 'forms' : 'form'}
                    </span>
                )}
                {!file.form && (
                    <span className={styles.fileCount}>
                        {' '}
                        {file.fileCount > 1 ? 'files' : 'file'}
                    </span>
                )}
            </div>
        </UiText>
    )
}

export default FileName
