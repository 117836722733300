import { useEffect } from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../../../../styles/models/Colors.interface'
import { toRem16 } from '../../../../../../../styles/commonStyles'
import UiText from '../../../../../../common/ui/UiText'
import UiButton from '../../../../../../common/ui/UiButton'
import ExpeditedCard from './ExpeditedCard'
import { expeditePage } from '../../../../models/vto.const'
import { SelectedPackage } from './SelectedPackage'
import store from '../../../../../../../store'
import { ApplicationStore } from '../../../../../../../models'
import { hasExpeditedPermission } from '../../../../common/VtoUtils'
import Loader from '../../../../../../common/Loader'

interface StepFourProps {
    selectedPackage: any
    handleSubmit: () => void
    noPackageSelected: any
    expedite: expeditePage
    setSubmitWithExpedita: (id: string) => void
    currentVtoDetails: any
    loading?: boolean
    selectedYear: any
    isBusinessVTO: boolean
    isAutoPaymentProcessing: boolean
    setNoPackageSelected: (data: any) => void
}

const useStyles = makeStyles<Theme, ThemeColors>((theme) => {
    return {
        container: {
            maxWidth: toRem16(640),
            margin: '1rem auto',
        },
        btn: {
            margin: '0 auto',
            '& span': {
                fontWeight: 600,
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        btnContainer: {
            display: 'flex',
            justifyContent: 'center',
            padding: `${toRem16(24)} 0`,
            marginBottom: '2rem',
        },
        spacingSm: {
            marginTop: '1rem',
        },
        packageContainer: {
            width: toRem16(410),
            margin: '0 auto',
        },
        loader: {
            '& .MuiCircularProgress-colorPrimary': {
                color: (colorTheme) => colorTheme.orange100,
            },
        },
        loaderContainer: {
            margin: toRem16(24),
        },
    }
})

export default function SubmitTaxInfo({
    handleSubmit,
    selectedPackage,
    noPackageSelected,
    expedite,
    setSubmitWithExpedita,
    currentVtoDetails,
    loading,
    selectedYear,
    isBusinessVTO,
    isAutoPaymentProcessing,
    setNoPackageSelected
}: StepFourProps) {
    const theme = useThemeContext()
    const styles = useStyles(theme.colorTheme)
    const { featureConfig } = store.getState() as ApplicationStore

    const isSelectedFreeTaxExtension = currentVtoDetails.extension_upsell_opted
    const isExpeditedSelected = currentVtoDetails.expedite_upsell_opted

    useEffect(() => {
        return () => {
            setNoPackageSelected(null)
        }
    }, [])

    const showExpeditedOption = () => {
        const isAnyExpeditedProductSelected =
            isSelectedFreeTaxExtension || isExpeditedSelected
        if (
            featureConfig?.disabledExpeditedOption[selectedYear] ||
            hasExpeditedPermission(isBusinessVTO)
        ) {
            return false
        } else if (isAnyExpeditedProductSelected) {
            return false
        } else if (expedite === expeditePage.OPTION) {
            return true
        }
    }

    return (
        <div className={styles.container}>
            {isAutoPaymentProcessing ? (
                <div className={styles.loaderContainer}>
                    <Loader customClass={styles.loader} />
                </div>
            ) : (
                <>
                    {showExpeditedOption() && (
                        <ExpeditedCard
                            setSubmitWithExpedita={setSubmitWithExpedita}
                            handleSubmit={handleSubmit}
                        />
                    )}

                    <div className={styles.btnContainer}>
                        <UiButton
                            btnType="tertiary"
                            label={
                                showExpeditedOption()
                                    ? 'Submit with Normal Processing'
                                    : 'Submit Your Tax Info'
                            }
                            customClass={styles.btn}
                            handleClick={handleSubmit}
                            disabled={loading}
                        />
                    </div>
                </>
            )}
            {noPackageSelected ? (
                <div>
                    <UiText
                        variant="motorcycle_90"
                        className={styles.spacingSm}
                        textAlign="center"
                    >
                        You are opting out of all upgrades to your tax
                        submission.
                    </UiText>
                </div>
            ) : (
                <div className={styles.packageContainer}>
                    <UiText
                        variant="hatchback_125"
                        weight="semi_bold_600"
                        textAlign="center"
                    >
                        Your Upgrades
                    </UiText>
                    <UiText className={styles.spacingSm} textAlign="center">
                        We will add these to your subscription and charge the
                        card that is on your account.
                    </UiText>
                    <SelectedPackage selectedPackage={selectedPackage} />
                </div>
            )}
        </div>
    )
}
