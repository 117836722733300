import {
    Theme,
    makeStyles,
    Divider,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core'
import { useVtoContext } from '../../providers/VtoProvider'
import FileUpload from './FileUploads'
import { ThemeColors } from '../../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../../common/whiteLabel/ColorThemeContext'
import { hasConditionMatched } from '../../common/VtoUtils'
import UiText from '../../../../common/ui/UiText'
import { Fragment, useMemo } from 'react'
import { FileGroupName } from '../../models/vto-config.model'
import { FileDetails, FileInfo, GroupDetails } from '../../models/vto.document-model'
import FileName from './FileUploadUtils'
import { getUploadFileCountByName } from '../../common/SectionUtil'
import { useFormikContext } from 'formik'

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        container: {
            marginTop: '1rem',
        },
        fileHeader: {
            marginTop: '2rem',
            marginBottom: '1rem',
        },
        formTitle: {
            textTransform: 'uppercase',
        },
        fileListing: {
            marginTop: '0.8rem',
        }
    }
})

export interface Section {
    name: string
    questions: any[]
    title: string
    type: string
}
//TODO types
const IndividualUpload = ({ uploadSectionData, requiredFilesData }: any) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const { values, setFieldValue }: { values: any , setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void; } = useFormikContext()
    const {
        isPersonalVTO,
    } = useVtoContext()

    const disclaimerData = uploadSectionData?.disclaimer
    const showCondition = disclaimerData?.show_condition

    const showDisclaimer = useMemo(() => {
        return (
            isPersonalVTO &&
            disclaimerData &&
            hasConditionMatched(showCondition, values)
        )
    }, [values, disclaimerData, isPersonalVTO, showCondition])



    const fileUploads = values?.uploads

    const isFileUploadComplete = (fileName: any, fileCount: number, checkKey: string) => {
        if(fileUploads[checkKey]) {
            return true
        } else {
            return fileCount === getUploadFileCountByName(fileName, values) 
        }
    }

    return (
        <div className={styles.container}>
            {showDisclaimer && (
                <div>
                    <UiText variant="hatchback_125">
                        Community Property Disclaimer
                    </UiText>
                    <UiText> {disclaimerData?.message} </UiText>
                </div>
            )}
            {Object.keys(requiredFilesData as FileInfo).map(
                (group: any, index: number) => {
                    const groupDetails = requiredFilesData?.[
                        group as FileGroupName
                    ] as GroupDetails
                    const key = `no_${groupDetails.groupType}_files`


                    return (
                        <Fragment key={`${groupDetails.groupType}-${index}`}>
                            <div className={styles.fileHeader}>
                                <UiText
                                    variant="suv_150"
                                    weight="semi_bold_600"
                                >
                                    {groupDetails?.groupName}
                                </UiText>
                                <Divider className={styles.fileListing} />
                                {groupDetails.files.map(
                                    (file: FileDetails, index: number) => {
                                        const uploadDone = isFileUploadComplete(file.fileName, file.fileCount, key)
                                        return (
                                            <div
                                                key={`${file.fileName}-${index}`}
                                            >
                                                <FileName file={file} index={index} required={!uploadDone}/>
                                                <FileUpload
                                                    fileType={file.fileName}
                                                />
                                            </div>
                                        )
                                    }
                                )}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                setFieldValue(`uploads.${key}`, e.target.checked)
                                            }}
                                            name="select-all"
                                            size="small"
                                            checked={
                                                !!values?.uploads?.[key] as boolean
                                            }
                                        />
                                    }
                                    label={
                                        <UiText>
                                            I don't have other remaining forms
                                            that are requested here
                                        </UiText>
                                    }
                                />
                            </div>
                        </Fragment>
                    )
                }
            )}
        </div>
    )
}

export default IndividualUpload
