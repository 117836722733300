import moment from 'moment';
import { getPermissionStatus } from '../services/apiService/common';
import { setInLocalStorage } from '../services/localStorageService';
import store from '../store';
import { loadPostAuthData } from '../store/actions/appData';
import {
    endOfTimestampEst,
    endOfWithSubtractTimestampEst,
    startOfTimestampEst,
    startOfWithSubtractTimestampEst,
} from './dateUtil';

moment.updateLocale('en', {
    relativeTime: {
        past: 'Now',
        s: 'Now',
        ss: 'Now',
        m: 'Now',
        mm: (number) => {
            return number < 5 ? 'Now' : `${number} Minutes`;
        },
        h: '1 Hour',
        hh: '%d hours',
    },
    calendar: {
        sameDay: function (now) {
            let that: any = this;
            if (Math.abs(that.diff(now) / 1000) < 7200) {
                return '[' + that.fromNow(true) + ']';
            } else {
                return 'h:mm A';
            }
        },
        lastDay: 'D MMM YYYY',
        lastWeek: 'D MMM YYYY',
        sameElse: 'D MMM YYYY',
    },
});

export function isTrialEnded() {
    let { appData, auth } = store.getState();
    return appData.current_account &&
        appData.current_account.account_type === 'personal'
        ? false
        : auth.roles.indexOf('TRIAL_ENDED') !== -1;
}

export const getMessageTime = (time_stamp: number) =>
    moment(time_stamp).calendar();

export const getTimeParams = (period: string, tillToday?: boolean) => {
    switch (period) {
        case 'Today':
            return { end_date: endOfTimestampEst('day'), type: 'today' };
        case 'This Month':
            return {
                start_date: startOfTimestampEst('month'),
                end_date: endOfTimestampEst('month'),
            };
        case 'Three Months':
            return {
                start_date: startOfWithSubtractTimestampEst(
                    'month',
                    2,
                    'month'
                ),
                end_date: endOfTimestampEst('month'),
            };

        case 'Last Quarter':
            return {
                start_date: startOfWithSubtractTimestampEst(
                    'quarter',
                    1,
                    'quarter'
                ),
                end_date: endOfWithSubtractTimestampEst(
                    'quarter',
                    1,
                    'quarter'
                ),
                type: 'last_quarter',
            };
        case 'This Year':
            return {
                start_date: startOfTimestampEst('year'),
                end_date: tillToday
                    ? endOfTimestampEst('day')
                    : endOfTimestampEst('year'),
                type: 'year',
            };
        case 'Last Year':
            return {
                start_date: startOfWithSubtractTimestampEst('year', 1, 'year'),
                end_date: endOfWithSubtractTimestampEst('year', 1, 'year'),
                type: 'last_year',
            };
        default:
            return {};
    }
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

export const numberFormatter = (number : number) : string =>{
    return number.toLocaleString("en-US");
}

export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const initPermissionCheck = (count: any) => {
    const refreshIntervalId = setInterval(() => {
        getPermissionStatus().then((response: any) => {
            if (response.is_permission_set || count === 1) {
                setInLocalStorage('permission_check', null);
                clearInterval(refreshIntervalId);
                store.dispatch(loadPostAuthData());
            } else {
                setInLocalStorage('permission_check', { count: --count });
            }
        });
    }, 30000);
};

export const isEmpty = (data: any): boolean => {
    if (!data) return true;
    if (Array.isArray(data) && data.length === 0) {
        return true;
    }
    if (typeof data === 'object') {
        return JSON.stringify(data) === '{}';
    }
    return false;
};

export const isObject = (value: unknown) => {
    return value && (Array.isArray(value) || typeof value === 'object');
};
export const getNumberfromString = (input: string) : number =>{
    const numberPatternRegex = /\D/g;
    return parseInt(input.replace(numberPatternRegex, ''));
}

export const capitalizeFirstLetter = (string: string|undefined): string => {
    if(string !== undefined){
        return string.charAt(0).toUpperCase() + string.slice(1)
    }else{
        return ''
    }
}

export const deepCopy = (obj: any): any => {
    if (obj === null || typeof obj !== 'object') {
        return obj
    }

    if (Array.isArray(obj)) {
        return obj.map(deepCopy)
    }

    const copiedObj: any = {}
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            copiedObj[key] = deepCopy(obj[key])
        }
    }

    return copiedObj
}