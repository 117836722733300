import {
    Button,
    Checkbox,
    createStyles,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    withStyles,
} from '@material-ui/core'
import { useState } from 'react'
import { Contractor, Contractor_w_nine } from '../models/contractor.model'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { toRem16 } from '../../../styles/commonStyles'
import { Container } from '../Wrapper'
import { CAN_NOT_FILE, statusTab } from './File1099Necs'
import { InfoOutlined } from '@material-ui/icons'
import { COLORS } from '../../../variables/colors'
import UiText from '../../common/ui/UiText'
import { CurrencyInputField, formatCurrency } from '../InlineCurrencyInput'
import Loader from '../../common/Loader'
import Icon from '../../common/Icon'

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        infoIcon: {
            textAlign: 'center',
        },
        uploadBtn: {
            color: (colorTheme) => `${colorTheme.blue200}`,
            textDecoration: 'none',
        },
        filtered: {
            textAlign: 'center',
            margin: `${toRem16(80)} 0`,
        },
        hyperTextColor: {
            color: (colorTheme) => `${colorTheme.blue200}`,
        },
        rowContainer: {
            width: '98%',
        },
        contractorError: {
            width: 'max-content',
            backgroundColor: (colorTheme) => colorTheme.red700,
            padding: toRem16(16),
            borderRadius: toRem16(4),
        },
        supportLink: {
            textDecoration: 'none',
            color: (colorTheme) => colorTheme.blue200,
        },
    }
})

export const RenderTable = (props: any) => {
    const [page, setPage] = useState(0)

    const {
        contractorList,
        handleSelectAllClick,
        handleClick,
        numSelected,
        rowCount,
        selected,
        setSelected,
        handleUpdatePaidAmount,
        taxYear,
        handleFilterPage,
        searchInput,
        status,
        colorTheme,
        handleViewPdf,
        viewPdf,
        error,
        setError,
        pagination,
        setIsAddModalOpen,
        setEditContractor,
    } = props
    const classes = useStyles(colorTheme)
    const labelId = `enhanced-table-checkbox-${1}`
    const findPaidAmount = (w9s: Contractor_w_nine[], year: string) => {
        const index = w9s.findIndex((w9: Contractor_w_nine) => w9.year === year)
        if (index >= 0) {
            return `${w9s[index].paid_amount}`
        }
        return '0'
    }

    const findErrorText = (id: string) => {
        return error?.errors?.find((item: any) => item.contractor === id)
            ?.message
    }

    const StyledTableCell = withStyles((theme: Theme) =>
        createStyles({
            body: {
                borderBottom: 'none',
            },
        })
    )(TableCell)

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage)
        setSelected([])
    }

    const indexOfLastUser = (page + 1) * rowCount
    const indexOfFirstUser = indexOfLastUser - rowCount
    const constractors = pagination
        ? contractorList.slice(indexOfFirstUser, indexOfLastUser)
        : contractorList

    return contractorList.length ? (
        <>
            <Container
                margin="0 0 2rem 0"
                height={toRem16(450)}
                maxheight={toRem16(500)}
                overflow="auto"
            >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {status === statusTab.NOT_FILED && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={
                                            numSelected > 0 &&
                                            numSelected < rowCount
                                        }
                                        checked={
                                            rowCount > 0 &&
                                            numSelected === rowCount
                                        }
                                        onChange={(e) =>
                                            handleSelectAllClick(e, page)
                                        }
                                        inputProps={{
                                            'aria-label': 'select all desserts',
                                            // @ts-ignore
                                            'data-cy': 'select-all',
                                        }}
                                        color="primary"
                                    />
                                </TableCell>
                            )}
                            <TableCell>Contractor Name</TableCell>
                            <TableCell align="right" />
                            <TableCell align="right">{`Amount paid in ${
                                taxYear ?? ''
                            }`}</TableCell>
                            {status === statusTab.FILED && (
                                <TableCell align="right">
                                    Form 1099-NEC
                                </TableCell>
                            )}
                            <TableCell align="right" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contractorList.length > 0 &&
                            constractors.map(
                                (item: Contractor, index: number) => {
                                    const isSelected = selected.findIndex(
                                        (contractor: any) =>
                                            item.id === contractor.id
                                    )
                                    const errorMessage = findErrorText(item.id!)
                                    return (
                                        <>
                                            <TableRow key={`${item.id}_`}>
                                                {status ===
                                                    statusTab.NOT_FILED && (
                                                    <StyledTableCell padding="checkbox">
                                                        {CAN_NOT_FILE.includes(
                                                            item
                                                                .contractor_w_nine[0]
                                                                .status ?? ''
                                                        ) ? (
                                                            <div
                                                                className={
                                                                    classes.infoIcon
                                                                }
                                                            >
                                                                <InfoOutlined
                                                                    style={{
                                                                        color: COLORS.grey400,
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : errorMessage ? (
                                                            <Icon
                                                                size={'20px'}
                                                                icon="alert"
                                                            />
                                                        ) : (
                                                            <Checkbox
                                                                checked={
                                                                    isSelected >=
                                                                    0
                                                                }
                                                                inputProps={{
                                                                    'aria-labelledby':
                                                                        labelId,
                                                                    // @ts-ignore
                                                                    'data-cy':
                                                                        'select',
                                                                }}
                                                                color="primary"
                                                                onClick={() =>
                                                                    handleClick(
                                                                        item
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </StyledTableCell>
                                                )}
                                                <StyledTableCell>
                                                    {item.first_name +
                                                        ' ' +
                                                        item.last_name}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {CAN_NOT_FILE.includes(
                                                        item
                                                            .contractor_w_nine[0]
                                                            .status ?? ''
                                                    ) && (
                                                        <UiText textColor="textSecondary">
                                                            Waiting on W-9
                                                        </UiText>
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {status ===
                                                    statusTab.NOT_FILED ? (
                                                        <CurrencyInputField
                                                            name="currency"
                                                            handleOnBlur={
                                                                handleUpdatePaidAmount
                                                            }
                                                            id={`${item.id}`}
                                                            // onFocus={() =>
                                                            //     setError(
                                                            //         (
                                                            //             prev: any
                                                            //         ) => ({
                                                            //             ...prev,
                                                            //             [item.email]:
                                                            //                 false,
                                                            //         })
                                                            //     )
                                                            // }
                                                            size="small"
                                                            variant="outlined"
                                                            rowData={item}
                                                            initialValue={findPaidAmount(
                                                                item.contractor_w_nine,
                                                                taxYear
                                                            )}
                                                            isError={
                                                                error[
                                                                    item.email
                                                                ]
                                                            }
                                                            helperText="Amount is Required"
                                                        />
                                                    ) : (
                                                        formatCurrency(
                                                            findPaidAmount(
                                                                item.contractor_w_nine,
                                                                taxYear
                                                            )
                                                        )
                                                    )}
                                                </StyledTableCell>
                                                {status === statusTab.FILED && (
                                                    <StyledTableCell align="right">
                                                        <Button
                                                            variant="text"
                                                            className={
                                                                classes.uploadBtn
                                                            }
                                                            onClick={() =>
                                                                handleViewPdf(
                                                                    item.id
                                                                )
                                                            }
                                                        >
                                                            {viewPdf.loading &&
                                                            viewPdf.id ===
                                                                item.id ? (
                                                                <Loader />
                                                            ) : (
                                                                'View'
                                                            )}
                                                        </Button>
                                                    </StyledTableCell>
                                                )}
                                                <StyledTableCell
                                                    width={'20px'}
                                                    align="right"
                                                >
                                                    {status ===
                                                        statusTab.NOT_FILED && (
                                                        <Button
                                                            variant="text"
                                                            onClick={() => {
                                                                setEditContractor(
                                                                    item
                                                                )
                                                                setIsAddModalOpen(
                                                                    true
                                                                )
                                                            }}
                                                            className={
                                                                classes.hyperTextColor
                                                            }
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                </StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={6}>
                                                    {errorMessage && (
                                                        <div
                                                            className={
                                                                classes.rowContainer
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    classes.contractorError
                                                                }
                                                            >
                                                                <UiText>
                                                                    <span>
                                                                        {
                                                                            errorMessage
                                                                        }
                                                                    </span>{' '}
                                                                    <span>
                                                                        <a
                                                                            className={
                                                                                classes.supportLink
                                                                            }
                                                                            target="_blank"
                                                                            href="https://1800accountant.my.site.com/support/s/contactsupport"
                                                                        >
                                                                            Contact
                                                                            Support
                                                                        </a>
                                                                    </span>
                                                                </UiText>
                                                            </div>
                                                        </div>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )
                                }
                            )}
                    </TableBody>
                </Table>
            </Container>
            {pagination && (
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={contractorList.length}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowCount}
                />
            )}
        </>
    ) : searchInput ? (
        <div className={classes.filtered}>
            <UiText>No contractor found</UiText>
        </div>
    ) : (
        handleFilterPage()
    )
}
