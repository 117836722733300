import { Grid, makeStyles, Theme } from '@material-ui/core';
import UiText from '../../common/ui/UiText';
import { ThemeColors } from '../../../styles/models/Colors.interface';
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext';

const useStyles = makeStyles<Theme, ThemeColors>(() => ({
    requiredText: {
        marginLeft: '5px',
        color: colorTheme => colorTheme.secondary,
    },
}));

interface Props {
    formType?: string;
    heading: string;
    hideOnMobile?: boolean;
}

function FormHeaderText(props: Props) {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme);
    const { heading, formType, hideOnMobile = false } = props;
    return (
        <Grid container alignItems='center'>
            {!hideOnMobile && <UiText weight='semi_bold_600' variant='suv_150'>
                {heading}
            </UiText>}
            {formType && (
                <UiText weight='medium_500' className={classes.requiredText}>
                    {formType}
                </UiText>
            )}
        </Grid>
    );
}
export default FormHeaderText;
