import React from 'react';
import { Chip, makeStyles } from '@material-ui/core'
import Icon from '../Icon'
import IconList from '../IconList'

interface commonProps {
    customLabel?: React.ReactNode
    label?: React.ReactNode | string
    onDeleteCallout?: (...args: any) => void
    labelTextStyle?: any
    style?: any
    color?: "default" | "primary" | "secondary" | undefined
    variant?: "default" | "outlined" | undefined
    customRootStyles?: any | undefined
    onClickCallout?: (...args: any) => void
    cypressId?: string
    size?: "small" | "medium" | undefined
}

interface RenderIconProps {
    renderIcon?: false | undefined
}

interface IconProps {
    renderIcon: true
    iconName: keyof typeof IconList
    iconSize?: string | { width: string; height: string }
   
}

type UiChipProps = commonProps & (RenderIconProps | IconProps)

const useStyles = makeStyles(() => {
    return {
        labelStyle: {
            display: 'flex',
            alignItems: 'center',
        },
        labelTextStyle: {
            margin: '0 5px'
        },
        iconCustomClass: {
            marginLeft: '8px'
        }
    }
})
const UiChip = (props: UiChipProps) => {
    const styles = useStyles()
    return (
        <Chip
            classes={{ root: props.customRootStyles }}
            {...(props?.size ? { size: props.size }: {})}
            label={
                props.customLabel ? (
                    props.customLabel
                ) : (
                    <div
                        className={
                            props.style ? props.style : styles.labelStyle
                        }
                    >
                        {props.renderIcon && (
                            <Icon
                                icon={props.iconName as any}
                                size={props.iconSize as keyof IconProps}
                            />
                        )}
                        
                        <div
                            className={
                                props.labelTextStyle
                                    ? props.labelTextStyle
                                    : styles.labelTextStyle
                            }
                        >
                            {props.label}
                        </div>
                    </div>
                )
            }
            data-cy={props.cypressId}
            variant={props.variant}
            onDelete={props.onDeleteCallout}
            onClick={props.onClickCallout}
        />
    )
}

export default UiChip