import {
    Button,
    makeStyles,
} from '@material-ui/core'
import UiDialog from '../../../../../common/ui/UiDialog'
import UiText from '../../../../../common/ui/UiText'
import Icon from '../../../../../common/Icon'

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        height: '12rem',
        width: '100%',
        textAlign: 'center',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1rem'
    },
    heading: {
        paddingBottom: theme.spacing(1.5),
    },
    
    btnContainer: {
        display: 'flex',
        marginTop: '2rem',
        justifyContent: 'center',
        gap: '1rem',
        '& .cancel': {
            marginLeft: '0.5rem'
        }
    },
}))
  
function OwnersAlertModal({open, handleClose, onOk}: {
    open: boolean
    handleClose: () => void
    onOk: () => void
}) {
    const classes = useStyles()
    return (
        <UiDialog
            open={open}
            hideTitleSection={true}
            handleClose={handleClose}
            size="sm"
        >
            <div className={classes.modalContainer}>
                <div>
                    <div className={classes.iconContainer}>
                        <Icon icon='alert'/>
                    </div>
                    <UiText className={classes.heading}>
                        You may not add another owner to a <b>Sole Proprietorship</b>. If your business has more then one owner, you must select a different Business Type.
                        Do you want to go to <b>Business Type</b> step?
                    </UiText>
                </div>
                
                <div className={classes.btnContainer}>
                    <Button
                        onClick={onOk}
                        variant="contained"
                        color="primary"
                    >
                        Move to Business Type
                    </Button>
                    <Button
                        className="cancel"
                        onClick={handleClose}
                        variant="outlined"
                        color="primary"
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </UiDialog>
    )
}

export default OwnersAlertModal
