import {
    Button,
    IconButton,
    InputAdornment,
    TextField,
    Theme,
    makeStyles,
} from '@material-ui/core'
import { debounce } from 'lodash';
import { ThemeColors } from '../../../../../../styles/models/Colors.interface'
import { useThemeContext } from '../../../../../common/whiteLabel/ColorThemeContext'
import { toRem16 } from '../../../../../../styles/commonStyles'
import { FieldArray, getIn, useFormikContext } from 'formik'
import UiText from '../../../../../common/ui/UiText'
import { useVtoContext } from '../../../providers/VtoProvider'
import UiFormOptions, { QuestionTitle } from './UiFormOptions'
import { getValueByModelName } from '../../../common/VtoUtils'
import { VtoHelpers } from '../../../common/VtoHelpers'
import Icon from '../../../../../common/Icon'
import { useCallback, useEffect, useState } from 'react'
import InfoTooltip from '../../../../../common/InfoTooltip'
import { getDropdownOptions } from '../../../common/vtoFieldArrayUtils'

const useStyles = makeStyles<Theme, ThemeColors>((theme) => ({
    container: {
        display: 'flex',
        minHeight: toRem16(115),
        justifyContent: 'space-between',
        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        '& .info-div, & .input-div': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    questionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .addition-select': {
            marginTop: `0.125rem`,
            width: '40%',
        },
    },
    iconStyle: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    otherExapnse: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    noneExp: {
        marginTop: toRem16(36),
    },
    otherInput: {
        display: 'flex',
        gap: toRem16(16),
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        color: (colorTheme) => colorTheme.red200,
        cursor: 'pointer',
    },
    alertContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .btn-content': {
            display: 'flex',
        },
    },
    deleteActionContainer: {
        display: 'flex',
        color: (colorTheme) => colorTheme.primaryWhite,
        backgroundColor: (colorTheme) => colorTheme.red200,
        cursor: 'pointer',
        width: 'fit-content',
        marginLeft: '1rem',
        '&:hover': {
            color: (colorTheme) => colorTheme.primaryWhite,
            backgroundColor: (colorTheme) => colorTheme.red200,
        },
    },
    questionFieldContainer: {
        marginTop: toRem16(16),
    },
    otherExpenseContainer: {
        display: 'flex',
        alignItems: 'center',
        minHeight: toRem16(115),
    },
    borderBottom: {
        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
    },
    otherExpensesItemContainer: {
        paddingLeft: toRem16(33),
        borderBottom: (colorTheme) => `1px solid ${colorTheme.grey200}`,
        '&:nth-last-child(2)': {
            borderBottom: 'none',
        },
    },
    pl33: {
        paddingLeft: toRem16(33),
    },
    otherExpenseItem: {
        border: 'none !important',
        gap: '1rem',
        '& > div': {
            margin: 0
        },
        '& > div > div': {
            marginTop: 0
        },
        '& > div:first-child': {
            flex: 1,
        },
    },
}))


const profitOrLoss = (options: any, values: any, props: any) => {
    const parentIndex = props?.index
    const parentModel = props?.parentModel;
    const expenses = getValueByModelName(options.expenses, values, parentModel, parentIndex)
    const netRevenue = getValueByModelName(options.revenue, values, parentModel, parentIndex)
    const totalNetRevenue = isNaN(Number(netRevenue)) ? VtoHelpers.getExpensesSum(netRevenue) : Number(netRevenue);
    const totalExpense = VtoHelpers.getExpensesSum(expenses)
    return totalNetRevenue - totalExpense;
}

export const ExpensesProfit = (props: any) => {
    const { model, options } = props
    const { values, setFieldValue } = useFormikContext<any>()
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    let total = VtoHelpers.getProfitOrLoss(props, values)
    let title = props.title
    const questionFieldModel = props?.parentModel
        ? `${props?.parentModel}.${props?.index}.${model}`
        : model

    if (options?.profit_loss) {
        total = profitOrLoss(options, values, props)
        total > 0 || total === 0 ? (title = 'Profit') : (title = 'Loss')
    }

    const debouncedSetFieldValue = useCallback(
        debounce((questionFieldModel, total) => setFieldValue(questionFieldModel, total), 0),
        []
      );
      
      useEffect(() => {
        if (!options?.profit_loss) {
          debouncedSetFieldValue(questionFieldModel, total);
        }
      }, [total, debouncedSetFieldValue]);

    return (
        <>
            <QuestionTitle title={title} required={props.options?.required ||props.isRequired } />
            <div className={classes.questionFieldContainer}>
                <TextField
                    variant="outlined"
                    type="number"
                    size="small"
                    name={questionFieldModel}
                    disabled
                    value={(options?.profit_loss ? Math.round(Math.abs(total) * 100) / 100 : Math.round(total * 100) / 100).toFixed(2)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon icon={'dollarUpdated'} />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        </>
    )
}

export const ExpensesTotal = (props: any) => {
    const { model } = props
    const { values } = useFormikContext<any>()
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)

    const questionFieldModel = props?.parentModel
        ? `${props?.parentModel}.${props?.index}.${model}`
        : model
    const expenses = getValueByModelName(questionFieldModel ?? '', values)

    const total = VtoHelpers.getExpensesSum(expenses)

    return (
        <>
            <QuestionTitle title={props.title} required={props.options?.required ||props.isRequired }/>
            <div className={classes.questionFieldContainer}>
                <TextField
                    variant="outlined"
                    type="number"
                    size="small"
                    name={questionFieldModel}
                    disabled
                    value={(Math.round(total * 100) / 100).toFixed(2)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                        <Icon icon={'dollarUpdated'} />
                    </InputAdornment>,
                    }}
                />
            </div>
        </>
    )
}

const emptyData = {
    key: '',
    name: '',
    value: '',
    checked: true,
}

const DeleteConfirmContainer = ({
    handleDelete,
    title,
    currentIndex,
    styles,
    abortDelete,
    colorTheme,
}: any) => {
    return (
        <div className={styles.alertContainer}>
            <UiText weight="semi_bold_600" variant="hatchback_125">
                Delete {title || 'Expense'} {currentIndex + 1}
            </UiText>
            <div className="btn-content">
                <Button
                    variant="text"
                    onClick={abortDelete}
                    className={styles.addActionContainer}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={styles.deleteActionContainer}
                    onClick={handleDelete}
                    startIcon={
                        <Icon
                            icon="deleteTrash"
                            svgColor={colorTheme.primaryWhite}
                        />
                    }
                >
                    Delete
                </Button>
            </div>
        </div>
    )
}
export const UiFormExpenses = (props: any) => {
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const { options, model } = props
    const { currentVtoConfig, currentVtoDetails } = useVtoContext()
    const { values, setFieldValue } = useFormikContext<any>()
    const [deletableIndex, setDeletableIndex] = useState(-1)

    const questionFieldModel = props?.parentModel
        ? `${props?.parentModel}.${props?.index}.${model}`
        : model

    const additionQuestions = options?.addition_questions
    const expenses = getValueByModelName(questionFieldModel ?? '', values)

    const expenseOptions = () => {
        if (options?.configName && currentVtoConfig) {
            return getValueByModelName(options.configName, currentVtoConfig)
        } else if (options?.customList && currentVtoDetails) {
            return VtoHelpers.customList(options?.customList, currentVtoDetails)
        }
        return []
    }

    let selectAdditionOptions: any = []

    if (additionQuestions) {
        selectAdditionOptions = getDropdownOptions(
            additionQuestions?.[0]?.options,
            currentVtoConfig,
            values
        )
    }

    const isNoneSelected = () => {
        return expenses?.has_none
    }

    const makeInputList = () => {
        return expenseOptions().map((item: any) => {
            return {
                ...item,
                type: 'checkbox',
                disabled: isNoneSelected(),
                model: `${questionFieldModel}.list.${item.name}`,
                hint: item?.hint,
            }
        })
    }

    const isNoneDisabled = !VtoHelpers.isHasNoExpensesAllowed(expenses)

    const hasNone = () => {
        return {
            type: 'checkbox',
            key: `expense_list_has_none`,
            disabled: isNoneDisabled,
            model: `${questionFieldModel}.has_none`,
            title: options?.noneTitle ?? 'No Expenses',
            fastField: false,
        }
    }
    const hasOthers = () => {
        return {
            type: 'checkbox',
            key: `exeanse_list_has_others`,
            disabled: isNoneSelected(),
            model: `${questionFieldModel}.has_others`,
            title: options?.othersTitle ?? 'Other',
            fastField: false,
        }
    }

    const isChecked = (modelName: string) => {
        return getValueByModelName(modelName, values)
    }

    const othersArray = getValueByModelName(
        `${questionFieldModel}.others`,
        values
    )

    if (
        isChecked(`${questionFieldModel}.has_others`) &&
        (othersArray?.length === 0 || othersArray === undefined)
    ) {
        if (othersArray?.length) {
            setFieldValue(`${questionFieldModel}.others`, [
                ...othersArray,
                emptyData,
            ])
        } else {
            setFieldValue(`${questionFieldModel}.others`, [emptyData])
        }
    }

    const hasCheckedOthers = isChecked(`${questionFieldModel}.has_others`)
    const isListDetails = getValueByModelName(`${questionFieldModel}.list`, values)

    return (
        <>
            {makeInputList()?.map((item: any) => {
                const itemValue = getIn(values, item.model);
                const checkboxInputProps = {
                    ...item,
                    key: `expense_list_${item.name}`,
                    model: `${item.model}.checked`,
                    fastField: false,
                    onChange: (e: any) => {
                        let fieldValue = e.target.checked
                        if(fieldValue) {
                            if(isListDetails?.length === 0) {
                                setFieldValue(`${questionFieldModel}.list`, {})
                            }
                            setFieldValue(item.model, {
                                name: item.title || itemValue.title || '',
                                checked: true,
                                value: ''
                            })
                        } else {
                            if(itemValue) {
                                setFieldValue(item.model, {
                                    ...itemValue,
                                    name: item.title || itemValue.title || '',
                                    checked: false,
                                })
                            }
                        }
                        
                    }
                }
                const valueInputProps = {
                    ...item,
                    type: 'money',
                    title: '',
                    key: `expense_list_${item.name}_value`,
                    model: `${item.model}.value`,
                    isRequired: true,
                    onChange: (e: any) => {
                        let fieldValue = e.target.value
                        if(fieldValue < 0 || fieldValue === '0' || fieldValue === 0) {
                            fieldValue = 0
                        }
                        setFieldValue(item.model, {
                            ...itemValue,
                            name: item.title || itemValue.title || '',
                            checked: true,
                            value: fieldValue,
                        })
                    }
                }

                return (
                    <div
                        className={additionQuestions ? '' : classes.container}
                        key={item?.name}
                    >
                        {additionQuestions ? (
                            <div>
                                <div className="ui-question">
                                    <UiFormOptions {...checkboxInputProps} />
                                    {item?.hint && (
                                        <div className={classes.iconStyle}>
                                            <InfoTooltip
                                                tooltipText={item?.hint}
                                                placement="top"
                                                customNode={
                                                    <Icon
                                                        icon="info"
                                                        strokeColor={
                                                            colorTheme.grey400
                                                        }
                                                    />
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className={classes.questionContainer}>
                                    {isChecked(`${item.model}.checked`) && (
                                        <div className='addition-select'>
                                            {(() => {
                                                const selectInputProps = {
                                                    ...item,
                                                    type: additionQuestions?.[0]?.type,
                                                    title: '',
                                                    key: `expense_list_${item.name}_value`,
                                                    ...additionQuestions?.[0],
                                                    model: `${item.model}.${additionQuestions?.[0]?.model}`,
                                                    dropDownOptions: selectAdditionOptions,
                                                    options: {...additionQuestions?.[0]?.options, required: true},
                                                    onChange: (e: any) =>
                                                        setFieldValue(
                                                            item.model,
                                                            {
                                                                ...itemValue,
                                                                [additionQuestions?.[0]?.model]: e.target
                                                                    .value,
                                                            }
                                                        ),
                                                }


                                                return (
                                                    <UiFormOptions
                                                        {...selectInputProps}
                                                    />   
                                                )
                                            })()}
                                        </div>
                                    )}
                                    <div>
                                        {isChecked(`${item.model}.checked`) && (
                                            <UiFormOptions
                                                {...valueInputProps}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="info-div">
                                    <UiFormOptions {...checkboxInputProps} />
                                    {item?.hint && (
                                        <div className={classes.iconStyle}>
                                            <InfoTooltip
                                                tooltipText={item?.hint}
                                                placement="top"
                                                customNode={
                                                    <Icon
                                                        icon="info"
                                                        strokeColor={
                                                            colorTheme.grey400
                                                        }
                                                    />
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="input-div">
                                    {isChecked(`${item.model}.checked`) && (
                                        <UiFormOptions {...valueInputProps} />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                )
            })}

            {(options?.has_others || options?.othersTitle) && (
                <div className={`${classes.otherExpenseContainer} ${hasCheckedOthers ? '' : classes.borderBottom}`}>
                    <UiFormOptions {...hasOthers()} />
                </div>
            )}

            {hasCheckedOthers && (
                <div>
                    <FieldArray name={`${questionFieldModel}.others`}>
                        {({ push, remove }) => {
                            return (
                                <>
                                    {othersArray?.map(
                                        (row: any, index: number) => {
                                            const otherNameFiled = {
                                                ...row,
                                                type: 'string',
                                                key: index + 'string',
                                                customPlaceholder: 'Name',
                                                model: `${questionFieldModel}.others.${index}.name`,
                                                isRequired: true,
                                            }
                                            const otherValueFiled = {
                                                ...row,
                                                key: index + 'money',
                                                type: 'money',
                                                customPlaceholder: '0.00',
                                                model: `${questionFieldModel}.others.${index}.value`,
                                                isRequired: true,
                                            }
                                            return (
                                                <div
                                                    key={`${questionFieldModel}_${index}`}
                                                    className={classes.otherExpensesItemContainer}
                                                >
                                                    {deletableIndex !== -1 &&
                                                    deletableIndex === index ? (
                                                        <>
                                                            <DeleteConfirmContainer
                                                                handleDelete={() => {
                                                                    setDeletableIndex(
                                                                        -1
                                                                    )
                                                                    if (
                                                                        othersArray?.length ===
                                                                        1
                                                                    ) {
                                                                        setFieldValue(
                                                                            `${questionFieldModel}.has_others`,
                                                                            false
                                                                        )
                                                                    }
                                                                    remove(
                                                                        index
                                                                    )
                                                                }}
                                                                title={
                                                                    options?.item_title
                                                                }
                                                                currentIndex={
                                                                    index
                                                                }
                                                                styles={classes}
                                                                abortDelete={() => {
                                                                    setDeletableIndex(
                                                                        -1
                                                                    )
                                                                }}
                                                                colorTheme={
                                                                    colorTheme
                                                                }
                                                            />
                                                        </>
                                                    ) : (
                                                        <div
                                                            className={
                                                                classes.otherExapnse
                                                            }
                                                        >
                                                            <UiText weight="medium_500">{`${
                                                                options?.item_title ??
                                                                'Expense'
                                                            } ${
                                                                index + 1
                                                            }`}</UiText>
                                                            <IconButton
                                                                onClick={() =>
                                                                    setDeletableIndex(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <Icon
                                                                    icon="deleteTrash"
                                                                    svgColor={
                                                                        colorTheme.grey400
                                                                    }
                                                                />
                                                            </IconButton>
                                                        </div>
                                                    )}
                                                    <div
                                                        className={
                                                            `${classes.container} ${classes.otherExpenseItem}`

                                                        }
                                                    >
                                                        <UiFormOptions
                                                            {...otherNameFiled}
                                                        />
                                                        <UiFormOptions
                                                            {...otherValueFiled}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )}
                                    <div className={classes.pl33}>
                                    <Button
                                        onClick={() => {
                                            push(emptyData)
                                        }}
                                    >
                                        + Add Another{' '}
                                        {options?.item_title ?? 'Expense'}
                                    </Button>
                                    </div>
                                </>
                            )
                        }}
                    </FieldArray>
                </div>
            )}
            {options?.has_none && (
                <div className={classes.noneExp}>
                    <UiFormOptions {...hasNone()} />
                </div>
            )}
        </>
    )
}
