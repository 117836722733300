import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { connect } from 'react-redux'
import { ApplicationStore } from '../../../../models'
import { getTaxPrepWidgetData } from '../../../../services/apiService'
import { onclickOfNotification } from '../../../../store/actions/count'
import { calculateSum } from '../utils/states'

type Props = {
    children: ReactNode
    appData: any
    featureConfig: any
    config: any
    notificationAction: any
    onclickOfNotification: any
    vtoSelectYear?: number | null | undefined
}

interface StateValue {
    taxPrepData: any
    setTaxPrepData: (data: any) => void
    apiLoaded: boolean
    setApiLoaded: (data: boolean) => void
    taxYear: number
    taxHeader: string
    config: any
    isHideWidget: boolean
    setIsHideWidget: (data: boolean) => void
    vtoExtensionsWidgetHideForStatus: string
    vtoExtensionWidgets: string
    appData: any
    featureConfig: any
    notificationClickAction: any
    onclickOfNotification: (data: any) => any
    stateTax: number
    isError: boolean
    setIsError: (data: boolean) => void
    setApiSuccess: (data: boolean) => void
}

const WidgetContext = createContext<StateValue | null>(null)

export function useWidgetContext(): StateValue {
    const state = useContext(WidgetContext)

    if (!state) {
        throw new Error('useWidgetContext must be used within WidgetContext')
    }

    return state
}

function WidgetProvider({
    children,
    appData,
    featureConfig,
    config,
    notificationAction,
    onclickOfNotification,
    vtoSelectYear = null
}: Props) {
    const [taxPrepData, setTaxPrepData] = useState<any>({})
    const [apiLoaded, setApiLoaded] = useState<boolean>(false)
    const [stateTax, setStateTax] = useState<number>(0)
    const [isError, setIsError] = useState<boolean>(false)
    const [apiSuccess, setApiSuccess] = useState(false);
    const taxYear = vtoSelectYear ? vtoSelectYear : featureConfig?.vtoExtensionsYear 
    const taxHeader = `Taxes ${taxYear}`
    const [isHideWidget, setIsHideWidget] = useState<boolean>(false)
    const vtoExtensionsWidgetHideForStatus =
        featureConfig.vtoExtensionsWidgetHideForStatus
    const vtoExtensionWidgets = featureConfig.vtoExtensionWidgets
    const notificationClickAction = notificationAction?.clickAction ?? {}

    const providerValue: StateValue = {
        taxPrepData,
        setTaxPrepData,
        apiLoaded,
        setApiLoaded,
        taxYear,
        taxHeader,
        config,
        isHideWidget,
        setIsHideWidget,
        vtoExtensionsWidgetHideForStatus,
        vtoExtensionWidgets,
        appData,
        featureConfig,
        notificationClickAction,
        onclickOfNotification,
        stateTax,
        isError,
        setIsError,
        setApiSuccess
    }

    useEffect(() => {
        const stateTax = taxPrepData?.business?.state_taxes
        setStateTax(calculateSum(stateTax) || 0)
    }, [taxPrepData?.business?.state_taxes])

    useEffect(() => {
        if (isError) {
            setTimeout(() => {
                setIsError(false)
            }, 10000)
        }
    }, [isError])

    useEffect(() => {
        const checkIfWidgetNeedsTobeHidden = (response: any) => {
            if (!response) return
            const businessStatus =
                response?.business?.tax_preparation_current_step.title
            const personalStatus =
                response?.personal?.tax_preparation_current_step.title
            if (!businessStatus || !personalStatus) return
            if (
                businessStatus === vtoExtensionsWidgetHideForStatus &&
                personalStatus === vtoExtensionsWidgetHideForStatus
            ) {
                setIsHideWidget(true)
            }
        }

        if (featureConfig.vtoExtensionWidgets) {
            getTaxPrepWidgetData(appData.current_account_id, taxYear).then(
                (response: any) => {
                    setTaxPrepData(response)
                    setApiLoaded(true)
                    checkIfWidgetNeedsTobeHidden(response)
                }
            )
        }
    }, [
        appData.current_account_id,
        taxYear,
        featureConfig.vtoExtensionWidgets,
        vtoExtensionsWidgetHideForStatus,
        apiSuccess
    ])

    return (
        <WidgetContext.Provider value={providerValue}>
            {children}
        </WidgetContext.Provider>
    )
}

const mapStateToProps = (state: ApplicationStore) => ({
    appData: state.appData,
    featureConfig: state.featureConfig,
    config: state.config,
    notificationAction: state.count,
})
export default connect(mapStateToProps, { onclickOfNotification })(
    WidgetProvider
)
