import { useVtoContext } from '../../providers/VtoProvider'

const IntroVideo = ({width = "640" }) => {
    const { isPersonalVTO } = useVtoContext()
    const introVideoURL = isPersonalVTO
        ? `https://www.youtube.com/embed/u9YIqldZgSg?ecver=2&autoplay=1`
        : `https://www.youtube.com/embed/L4Zai5AkQPk?ecver=2&autoplay=1`
    return (
        <iframe
            allowFullScreen
            title="intro-video"
            src={introVideoURL}
            width={width}
            height="360"
        ></iframe>
    )
}

export default IntroVideo
