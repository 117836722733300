import SectionQuestionsRenderer from '../../sections/SectionQuestionsRenderer'

const BusinessEntityType = ({ section }: any) => {

    return (
        <>
            <SectionQuestionsRenderer
                section={section}
            />
        </>
    )
}

export default BusinessEntityType
