import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { ActiveRoutingContext } from '../../routing/Providers/ActiveRoutingProvider'
import {
    Box,
    Button,
    Hidden,
    IconButton,
    Theme,
    debounce,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ArrowDownward } from '@material-ui/icons'
import { createEmployee } from '../../../services/apiService/adp-payroll/employee'
import { requestErrorHandler } from '../../../services/formService'
import UiDialog from '../../common/ui/UiDialog'
import UiText from '../../common/ui/UiText'
import { commonStyles, toRem16 } from '../../../styles/commonStyles'
import CreateNewEmployee from './CreateNewEmployeeForm'
import { Formik } from 'formik'
import { useADPEmployeesContext } from './ADPEmployeesProvider'
import { ThemeColors } from '../../../styles/models/Colors.interface'
import { useThemeContext } from '../../common/whiteLabel/ColorThemeContext'
import { createEmployeeSchemaValidation } from './EmployeeFormData'
import Loader from '../../common/Loader'
import { showInfo } from '../../../store/actions/feedback'
import { useDispatch } from 'react-redux'
import { UnsavedSectionWarning } from '../../payroll/company/UnsavedSectionWarning'
import useDeviceSize from '../../../hooks/useDeviceSize'
import { errorMapping } from '../company/Utils/ErrorHandling'

const useStyles = makeStyles<Theme, ThemeColors>((theme) => ({
    content: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up(1601)]: {
            justifyContent: 'flex-start',
        },
    },
    container: {
        '& .MuiPaper-root': {
            height: 816,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                margin: '0px',
                top: '0px',
                '& .MuiDialogTitle-root': {
                    display: 'none',
                },
            },
            '& .MuiDialogContent-root': {
                [theme.breakpoints.down('sm')]: {
                    padding: 0,
                    '& form': {
                        padding: `${toRem16(20)} ${toRem16(20)}`,
                    },
                },
                '& .top-section': {
                    '& .alert': {
                        height: '36px',
                        width: 'inherit',
                        marginBottom: theme.spacing(2),
                    },
                    '& .delete-section': {
                        display: 'flex',
                    },
                    [theme.breakpoints.down('sm')]: {
                        height: '100%',
                        maxHeight: '100%',
                        padding: '0px',
                    },
                },
            },
        },
    },
    dialogDetails: {
        '& .MuiDialogContent-root': {
            maxHeight: '600px',
            height: '550px',
        },
        '& .MuiPaper-root': {
            '& .MuiDialogTitle-root': {
                padding: '12px 16px ',
            },
        },
    },
    scrollContainer: {
        position: 'relative',
    },
    scrollBottom: {
        position: 'absolute',
        bottom: '6rem',
        right: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        width: toRem16(56),
        height: toRem16(56),
        cursor: 'pointer',
        borderRadius: '50%',
        backgroundColor: (colorTheme) => `${colorTheme.primary}`,
        color: (colorTheme) => `${colorTheme.primaryWhite}`,
    },
    loader: {
        width: toRem16(60),
        height: toRem16(60),
    },
    mobileLoader: {
        width: '113px',
        marginRight: '10px',
        '& .MuiCircularProgress-root': {
            width: `${toRem16(30)} !important`,
            height: `${toRem16(30)} !important`,
        },
    },
    topHeader:{
        borderBottom: (colorTheme) =>  `1px solid ${colorTheme.grey200}`,
        padding: '10px',
    },
    actionButtonContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        gridGap: toRem16(15)  
    }
}))

const MODAL_CONTENT_DIV_ID = 'modal-content'
const HandleScroll = (props: any) => {
    const handleScrollToElement = debounce((event: any) => {
        const scrollDemo = document.getElementById(MODAL_CONTENT_DIV_ID)!
        const currentPosition = scrollDemo.scrollTop
        if (currentPosition === 0) {
            props.setIsScrollable(true)
        } else {
            props.setIsScrollable(false)
        }
    }, 100)

    useEffect(() => {
        const scrollDemo = document.getElementById(MODAL_CONTENT_DIV_ID)!
        scrollDemo.addEventListener('scroll', handleScrollToElement, {
            passive: true,
        })
        return () => {
            scrollDemo.removeEventListener('scroll', handleScrollToElement)
        }
    }, [handleScrollToElement])

    return <div />
}

interface Props {
    openModal: boolean
    setOpenModal: (value: boolean) => void
    refreshData: () => void
}
const CreateNewEmployeeModal = (props: Props) => {
    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const [loading, setLoading] = useState(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [addNewJob, setAddNewJob] = useState(false)
    const [isScrollable, setIsScrollable] = useState<boolean>(true)
    const [showUnsavedWarningModal, setShowUnsavedWarningModal] = useState('')
    const { colorTheme } = useThemeContext()
    const classes = useStyles(colorTheme)
    const common = commonStyles()
    const theme = useTheme()
    const { companyDetails } = useADPEmployeesContext()
    const isXsDown = useMediaQuery(theme.breakpoints.down('xs'))
    const formikRef = useRef<any>(null)
    let submitCloser: any = null
    const dispatch = useDispatch()
    const { refreshData, openModal, setOpenModal } = props
    const { isMobileDevice } = useDeviceSize()

    useEffect(() => {
        setActiveRouteHeading('Employees')
    }, [setActiveRouteHeading])

    const handleUnsavedWanringModalAction = (
        action: 'close' | 'keep' | 'discard' | 'save'
    ) => {
        switch (action) {
            case 'close':
                setShowUnsavedWarningModal('')
                break
            case 'keep':
                setShowUnsavedWarningModal('')
                break
            case 'discard':
                setOpenModal(false)
                setShowUnsavedWarningModal('')
                break
            case 'save':
                setShowUnsavedWarningModal('')
                submitCloser?.()
                break
        }
    }

    const handleModalClose = () => {
        if (formikRef?.current?.dirty) {
            setShowUnsavedWarningModal('open')
        } else {
            setOpenModal(false)
        }
    }

    const actions = () =>
        loading ? (
            <div className={classes.loader}>
                <Loader />
            </div>
        ) : (
            <>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleModalClose}
                    data-cy="cancel-new-cat-btn"
                >
                    Cancel
                </Button>

                <Box my={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => submitCloser?.(e)}
                        disabled={loading}
                    >
                        Save
                    </Button>
                </Box>
            </>
        )

    const dispatchError = (errors: any) => {
        dispatch(
            showInfo({
                infoData: (
                    <Fragment>
                        <ul>
                            {errors?.map((err: any) => (
                                <li>{err.message}</li>
                            ))}
                        </ul>
                    </Fragment>
                ),
            })
        )
    }

    const onSubmit = (data: any, { setFieldError }: any) => {
        setLoading(true)
        const enteredData: any = {
            legalName: {
                firstName: data.first_name,
                middleName: data.middle_initial,
                lastName: data.last_name,
            },
            personalEmailAddress: data.email,
            birthDate: data.dob,
            startDate: data.job_start_date,
            compensationPackage: {
                compensationAmount: data.amount,
            },
            governmentRegistrationCode: 'SSN',
            governmentRegistrationDocumentNumber: data.ssn.replace(
                /^(\d{3})(\d{2})(\d{4})$/,
                '$1-$2-$3'
            ),
            employmentTypeCode: data.frequency,
            homeAddress: {
                address1: data.street_1,
                address2: data.street_2,
                city: data.city,
                stateCode: data.state,
                postalCode: data.zip,
            },
    
        }

        if (data.isOwner === 'yes') {
            enteredData.ownershipDetails = {
                primaryOwnerIndicator: false,
                ownershipPercentage: data.ownership,
            }
        }

        if (!addNewJob) {
            enteredData.workAssignmentLocations = {
                locationID: companyDetails?.companyLocation?.[0]?.locationID,
            }
            enteredData.jobID = data?.job?.id
        } else {
            enteredData.workAssignmentLocations = {
                locationID: data.job_location,
            }
            enteredData.job = {
                jobName: data.job_title,
            }
        }

        requestErrorHandler(
            createEmployee(enteredData)
                .then((data) => {
                    setOpenModal(false)
                    setLoading(false)
                    refreshData()
                })
                .catch((error: any) => {
                    setLoading(false)
                    if (error?.errors?.length) {
                        error?.errors?.length &&
                            error?.errors.forEach((err: any) => {
                                if (err.vocabulary === 'jobName') {
                                    setFieldError(
                                        'job_title',
                                        'This job title already exits.'
                                    )
                                } else {
                                    dispatchError(error?.errors)
                                }
                            })
                    } else {
                        Object.keys(error?.errors).forEach((key: any) => {
                            setFieldError(
                                errorMapping()[key],
                                error?.errors[key][0]
                            )
                        })
                    }
                })
        )
    }
    return (
        <div className={classes.scrollContainer}>
            <UnsavedSectionWarning
                open={showUnsavedWarningModal ? true : false}
                handleModalAction={handleUnsavedWanringModalAction}
                size="xs"
            />
            <UiDialog
                open={openModal}
                handleClose={() => handleModalClose()}
                disableBackdropClick={true}
                titleNode={
                    <>
                        <div className={common.flex}>
                            <UiText variant="hatchback_125" weight="medium_500">
                                {!isEdit ? 'New Employee' : 'Edit Employee'}
                            </UiText>{' '}
                        </div>
                        <IconButton
                            aria-label="close"
                            onClick={() => handleModalClose()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                }
                size="sm"
                customRootClass={
                    isXsDown ? classes.container : classes.dialogDetails
                }
                actions={isMobileDevice ? null : actions()}
            >
                <HandleScroll setIsScrollable={setIsScrollable} />
                <Formik
                    initialValues={{
                        primaryOwnerIndicator: 'no',
                        street_1: '',
                        street_2: '',
                        city: '',
                        state: '',
                        zip: '',
                    }}
                    onSubmit={onSubmit}
                    validationSchema={createEmployeeSchemaValidation(addNewJob)}
                    enableReinitialize
                    innerRef={formikRef}
                >
                    {({ handleSubmit, values, errors, setFieldValue }) => {
                        submitCloser = handleSubmit
                        return (
                            <>
                                <Hidden mdUp>
                                        <div className={classes.topHeader}>
                                            <div
                                                className={`${common.flex} ${common.alignCenter}`}
                                            >
                                                <div>
                                                    <IconButton
                                                        aria-label='close'
                                                        color='inherit'
                                                        onClick={
                                                            handleModalClose
                                                        }
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </div>
                                                <div className='title'>
                                                    {!isEdit ? 'New employee' : 'Edit employee'}
                                                </div>
                                            </div>
                                            <div
                                                className={classes.actionButtonContainer}
                                            >
                                                <Button
                                                    variant='outlined'
                                                    color='secondary'
                                                    fullWidth
                                                    onClick={handleModalClose}
                                                >
                                                    Cancel
                                                </Button>
                                                {loading ? (
                                                    <div
                                                        className={
                                                            classes.mobileLoader
                                                        }
                                                    >
                                                        <Loader />
                                                    </div>
                                                ) : (
                                                    <Button
                                                        variant='contained'
                                                        fullWidth
                                                        color='primary'
                                                        type='submit'
                                                        disabled={loading}
                                                        onClick={(e) => {
                                                            submitCloser?.(e)
                                                        }}
                                                        data-cy="save-changes-button"
                                                    >
                                                        Save
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </Hidden>
                                <CreateNewEmployee
                                    addNewJob={addNewJob}
                                    setAddNewJob={setAddNewJob}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                />
                                {isScrollable && (
                                    <div
                                        className={classes.scrollBottom}
                                        onClick={() => {
                                            const scrollableDiv =
                                                document.getElementById(
                                                    MODAL_CONTENT_DIV_ID
                                                )!
                                            scrollableDiv.scrollTo({
                                                top: scrollableDiv.scrollHeight,
                                                behavior: 'smooth',
                                            })
                                        }}
                                    >
                                        <ArrowDownward />
                                    </div>
                                )}
                            </>
                        )
                    }}
                </Formik>
            </UiDialog>
        </div>
    )
}

export default CreateNewEmployeeModal
