export const VtoYearData: any = [
    {
        label: 'Tax Year 2019',
        value: 2019,
    },
    {
        label: 'Tax Year 2020',
        value: 2020,
    },
    {
        label: 'Tax Year 2021',
        value: 2021,
    },
    {
        label: 'Tax Year 2022',
        value: 2022,
    },
    {
        label: 'Tax Year 2023',
        value: 2023,
    },
    {
        label: 'Tax Year 2024',
        value: 2024,
    },
]

export const yearIndexMapping: any = {
    0: 2019,
    1: 2020,
    2: 2021,
    3: 2022,
    4: 2023,
    5: 2024,
}

export const DefaultVTOYear = 2024