import React, { useEffect, useState } from 'react'
import { useVtoContext } from '../../../../providers/VtoProvider'
import { useCurrentStore } from '../../../../../../common/hooks/useCurrentStore'
import { hasPermission } from '../../../../../../../services/aclService'
import {
    checkParentQuestion,
    getTheDiscountedPrice,
    makeAnnualFrequency,
} from '../../../../common/VtoUtils'
import { loadProducts } from '../../../../../../../store/actions/products'
import { useDispatch } from 'react-redux'

export interface AdditionalProps {
    suggestedPackages?: Package[]
    setExcludedProdut: (data: excluded) => void
    excludedProduct: excluded
}

interface Product {
    product_id: string
    price_book: number
    // Add other relevant fields
}

export interface Package {
    packageTitle: string
    totalPrice: number
    discountedPrice: number
    isSelected: boolean
    products: Product[]
    extraInfo: string
    deal: string
}

const packageNames = [
    {
        deal: 'Your Selections',
        extraInfo:
            'Simplify your business finances with our core accounting services, tailored to your essential needs',
    },
    {
        deal: 'Most Popular',
        extraInfo:
            'Ensure total tax compliance with comprehensive services, from audit defense to quarterly estimates and personalized advice, all tailored to your business needs',
    },
    {
        deal: 'Best Deal',
        extraInfo:
            'Streamline your finances and save with the all-inclusive Enterprise package, offering everything from full-service bookkeeping to personalized tax planning',
    },
]

export enum excluded {
    BTP = 'btp',
    PTP = 'ptp',
}

function WithPackages<P extends object>(
    WrappedComponent: React.ComponentType<P>
) {
    const ComponentWithPackages = (props: P) => {
        const dispatch = useDispatch()
        const [suggestedPackages, setSuggestedPackages] = useState<Package[]>(
            []
        )
        const [excludedProduct, setExcludedProdut] = useState<excluded>()
        const {
            currentVtoDetails,
            currentVtoConfig,
            currentYearVTOProducts,
            selectedYear,
            isBusinessVTO,
            isPersonalVTO,
            currentVtoYearConfig,
        }: any = useVtoContext()
        const { product, currentAccount, personalAccount } = useCurrentStore()
        const isExpeditedSelected = currentVtoDetails.expedite_upsell_opted
        const selectedFreeTaxExtension =
            currentVtoDetails.extension_upsell_opted
        useEffect(() => {
            if (!product.products.length) {
                dispatch(loadProducts())
            }
        }, [dispatch, product.products.length])

        const vtoType = isBusinessVTO ? 'business' : 'personalInfo'

        const expediteProduct = () => {
            return {
                name: '',
                upsell: 'expedite_tax_prep',
                permission: isBusinessVTO
                    ? 'EXPEDITED_BUSINESS'
                    : 'EXPEDITED_PERSONAL',
                account: isBusinessVTO ? currentAccount : personalAccount,
            }
        }

        const freeTaxExtensionProduct = () => {
            return {
                name: '',
                upsell: isBusinessVTO
                    ? 'business_tax_extension'
                    : 'personal_tax_extension',
                permission: isBusinessVTO
                    ? 'TAXEXTENSION_BUSINESS'
                    : 'TAXEXTENSION_PERSONAL',
                account: isBusinessVTO ? currentAccount : personalAccount,
            }
        }

        const shouldPushExpeditProduct = (productId: string) => {
            const vtoProductId = `${vtoType}.upsell.expedite_tax_prep`
            return (
                isExpeditedSelected &&
                currentYearVTOProducts[vtoProductId] === productId
            )
        }

        const shouldPushFreeTaxExtensionProduct = (productId: string) => {
            const vtoProductId = `${vtoType}.upsell.${
                isBusinessVTO
                    ? 'business_tax_extension'
                    : 'personal_tax_extension'
            }`
            return (
                selectedFreeTaxExtension &&
                currentYearVTOProducts[vtoProductId] === productId
            )
        }

        const createPackage = (title: string, otherProps?: any): Package => {
            const packageData: Package = {
                packageTitle: title,
                totalPrice: 0.0,
                discountedPrice: 0.0,
                isSelected: false,
                products: [],
                ...otherProps,
            }
            return packageData
        }

        const buildPackages = () => {
            setSuggestedPackages([
                createEssentialsPackage(product.products),
                createDeluxePackage(product.products),
                createPremierPackage(product.products),
            ])
        }

        useEffect(() => {
            if (product.products.length) {
                buildPackages()
            }
        }, [product, selectedYear, excludedProduct])

        const isNoneUpsellSelected = () => {
            const taxType = isBusinessVTO ? 'business' : 'personalInfo'
            const businessUpsellQues = currentVtoDetails[taxType].upsell
            for (const ques in businessUpsellQues) {
                if (
                    businessUpsellQues[ques] &&
                    checkParentQuestion(
                        ques,
                        currentVtoDetails,
                        currentVtoConfig,
                        isBusinessVTO
                    )
                ) {
                    return false
                }
            }
            return true
        }

        const handleProductPush = (
            product: any,
            packageData: Package,
            permission: string,
            account: any,
            productName: string,
            superScript?: string
        ) => {
            if (hasPermission(permission, account)) return // Early return for permission check

            const productAnualPrice = makeAnnualFrequency(product.price_book)

            packageData.products.push({ ...product, superScript })
            packageData.totalPrice += productAnualPrice
            packageData.discountedPrice += getTheDiscountedPrice(
                productAnualPrice,
                productName,
                currentVtoYearConfig
            )
        }

        const doesProductMatch = (
            productId: string,
            productName: string,
            upsellProductName?: string
        ): boolean => {
            const vtoProductName =
                productName || `${vtoType}.upsell.${upsellProductName}`

            return productId === currentYearVTOProducts[vtoProductName]
        }

        const createEssentialsPackage = (allProducts: any[]): Package => {
            const firstPackage: Package = createPackage('Essentials', {
                ...packageNames[0],
            })

            const upsellMappings = [
                {
                    name: '',
                    upsell: 'audit_defense',
                    permission: isBusinessVTO
                        ? 'AUDIT_BUSINESS'
                        : 'AUDIT_PERSONAL',
                    account: isBusinessVTO ? currentAccount : personalAccount,
                    requiresCheck: true
                },
                {
                    name: '',
                    upsell: 'advisory',
                    permission: 'TAX_ADVISORY',
                    account: personalAccount,
                    superScript: '4',
                },
                {
                    name: '',
                    upsell: 'dissolution',
                    permission: 'ARTICLES_DISSOLUTION',
                    account: currentAccount,
                    requiresCheck: true,
                },
                {
                    name: '',
                    upsell: 'sales_tax',
                    permission: 'SALES_AND_USE_TAX',
                    account: currentAccount,
                    requiresCheck: true,
                },
                {
                    name: '',
                    upsell: 'quarterly_estimated',
                    permission: 'ESTIMATED_TAXES',
                    account: personalAccount,
                    requiresCheck: true,
                },
                // {
                //     name: '',
                //     upsell: 'personal_finance',
                //     permission: 'FINANCIAL_PLANNING',
                //     account: personalAccount,
                //     requiresCheck: true,
                // },
                {
                    name: '',
                    upsell: 'operating_agreement',
                    permission: 'OPERATING_AGREEMENT',
                    account: personalAccount,
                    requiresCheck: true,
                },
                {
                    name: '',
                    upsell: 'annual_report',
                    permission: 'ANNUAL_REPORT',
                    account: currentAccount,
                    requiresCheck: true,
                },
                {
                    name: '',
                    upsell: 'dedicated_bookkeeping',
                    permission: 'BASIC_BOOKKEEPING',
                    account: currentAccount,
                    superScript: '4',
                    requiresCheck: true,
                },
                {
                    name: '',
                    upsell: 'payroll',
                    permission: 'PAYROLL',
                    account: currentAccount,
                    superScript: '4',
                    requiresCheck: true,
                },
            ]

            const handleUpsellQuestions = (product: any, product_id: any) => {
                const taxType = isBusinessVTO ? 'business' : 'personalInfo'
                const upsellQues: any = currentVtoDetails[taxType].upsell
                Object.keys(upsellQues).forEach((ques) => {
                    const productName = `${taxType}.upsell.${ques}`

                    if (
                        !upsellQues[ques] ||
                        !doesProductMatch(product_id, productName)
                    )
                        return

                    const mapping = upsellMappings.find(
                        (item) => item.upsell === ques
                    )
                    if (mapping) {
                        if (
                            mapping.requiresCheck &&
                            checkParentQuestion(
                                ques,
                                currentVtoDetails,
                                currentVtoConfig,
                                isBusinessVTO
                            )
                        ) {
                            handleProductPush(
                                product,
                                firstPackage,
                                mapping.permission,
                                mapping.account,
                                productName,
                                mapping.superScript
                            )
                        } else if (!mapping.requiresCheck) {
                            handleProductPush(
                                product,
                                firstPackage,
                                mapping.permission,
                                mapping.account,
                                productName,
                                mapping.superScript
                            )
                        }
                    }
                })
            }

            allProducts.forEach((product: any) => {
                const { product_id } = product
                const businessCheck =
                    isBusinessVTO && doesProductMatch(product_id, 'business')
                const personalCheck =
                    isPersonalVTO && doesProductMatch(product_id, 'personal')

                if (businessCheck && excludedProduct !== excluded.BTP) {
                    handleProductPush(
                        product,
                        firstPackage,
                        `VTO${selectedYear}_BUSINESS`,
                        currentAccount,
                        'personal'
                    )
                } else if (personalCheck && excludedProduct !== excluded.PTP) {
                    handleProductPush(
                        product,
                        firstPackage,
                        `VTO${selectedYear}_PERSONAL`,
                        personalAccount,
                        'business'
                    )
                } else if (shouldPushExpeditProduct(product_id)) {
                    handleProductPush(
                        product,
                        firstPackage,
                        isBusinessVTO
                            ? 'EXPEDITED_BUSINESS'
                            : 'EXPEDITED_PERSONAL',
                        isBusinessVTO ? currentAccount : personalAccount,
                        `${vtoType}.upsell.expedite_tax_prep`
                    )
                } else if (shouldPushFreeTaxExtensionProduct(product_id)) {
                    handleProductPush(
                        product,
                        firstPackage,
                        isBusinessVTO
                            ? 'TAXEXTENSION_BUSINESS'
                            : 'TAXEXTENSION_PERSONAL',
                        isBusinessVTO ? currentAccount : personalAccount,
                        `${vtoType}.upsell.${
                            isBusinessVTO
                                ? 'business_tax_extension'
                                : 'personal_tax_extension'
                        }`
                    )
                }
                if (isNoneUpsellSelected()) {
                    if (doesProductMatch(product_id, '', 'audit_defense')) {
                        handleProductPush(
                            product,
                            firstPackage,
                            isBusinessVTO ? 'AUDIT_BUSINESS' : 'AUDIT_PERSONAL',
                            isBusinessVTO ? currentAccount : personalAccount,
                            `${vtoType}.upsell.audit_defense`
                        )
                    }
                } else {
                    handleUpsellQuestions(product, product_id)
                }
            })

            return firstPackage
        }

        function createDeluxePackage(allProducts: any[]): Package {
            const secondPackage: Package = createPackage('Deluxe', {
                bestDeal: true,
                ...packageNames[1],
            })

            const productMappings: any = [
                {
                    name: '',
                    upsell: 'advisory',
                    permission: 'TAX_ADVISORY',
                    account: personalAccount,
                    superScript: '4',
                },
                {
                    name: '',
                    upsell: 'annual_report',
                    permission: 'ANNUAL_REPORT',
                    account: currentAccount,
                    requiresCheck: true,
                },
                {
                    name: '',
                    upsell: 'sales_tax',
                    permission: 'SALES_AND_USE_TAX',
                    account: currentAccount,
                    requiresCheck: true,
                },
                {
                    name: '',
                    upsell: 'operating_agreement',
                    permission: 'OPERATING_AGREEMENT',
                    account: personalAccount,
                    requiresCheck: true,
                },
                {
                    name: '',
                    upsell: 'audit_defense',
                    permission: isBusinessVTO
                        ? 'AUDIT_BUSINESS'
                        : 'AUDIT_PERSONAL',
                    account: isBusinessVTO ? currentAccount : personalAccount,
                },
                {
                    name: '',
                    upsell: 'quarterly_estimated',
                    permission: 'ESTIMATED_TAXES',
                    account: personalAccount,
                },
                {
                    name: '',
                    upsell: 'personal_finance',
                    permission: 'FINANCIAL_PLANNING',
                    account: personalAccount,
                    requiresCheck: false,
                },
            ]
            if (excludedProduct !== excluded.BTP) {
                productMappings.push({
                    name: 'business',
                    permission: `VTO${selectedYear}_BUSINESS`,
                    account: currentAccount,
                })
            }
            if (excludedProduct !== excluded.PTP) {
                productMappings.push({
                    name: 'personal',
                    permission: `VTO${selectedYear}_PERSONAL`,
                    account: personalAccount,
                })
            }

            if (isExpeditedSelected) {
                productMappings.push(expediteProduct())
            }
            if (selectedFreeTaxExtension) {
                productMappings.push(freeTaxExtensionProduct())
            }

            allProducts.forEach((product) => {
                const { product_id } = product

                for (const mapping of productMappings) {
                    const {
                        name,
                        upsell,
                        permission,
                        account,
                        requiresCheck,
                        superScript,
                    } = mapping

                    if (doesProductMatch(product_id, name, upsell)) {
                        // Handle products that require additional checks
                        if (
                            requiresCheck &&
                            checkParentQuestion(
                                upsell || name,
                                currentVtoDetails,
                                currentVtoConfig
                            )
                        ) {
                            handleProductPush(
                                product,
                                secondPackage,
                                permission,
                                account,
                                name ? name : `${vtoType}.upsell.${upsell}`,
                                superScript
                            )
                        } else if (!requiresCheck) {
                            handleProductPush(
                                product,
                                secondPackage,
                                permission,
                                account,
                                name ? name : `${vtoType}.upsell.${upsell}`,
                                superScript
                            )
                        }
                        break // Exit loop once the match is found and handled
                    }
                }
            })

            return secondPackage
        }

        function createPremierPackage(allProducts: any[]): Package {
            const thirdPackage: Package = createPackage('Premier', {
                ...packageNames[2],
            })

            const productMappings: any = [
                {
                    name: '',
                    upsell: 'advisory',
                    permission: 'TAX_ADVISORY',
                    account: personalAccount,
                    superScript: '4',
                },
                {
                    name: '',
                    upsell: 'annual_report',
                    permission: 'ANNUAL_REPORT',
                    account: currentAccount,
                    requiresCheck: true,
                },
                {
                    name: '',
                    upsell: 'sales_tax',
                    permission: 'SALES_AND_USE_TAX',
                    account: currentAccount,
                    requiresCheck: true,
                },
                {
                    name: '',
                    upsell: 'operating_agreement',
                    permission: 'OPERATING_AGREEMENT',
                    account: personalAccount,
                    requiresCheck: false,
                },
                {
                    name: '',
                    upsell: 'audit_defense',
                    permission: isBusinessVTO
                        ? 'AUDIT_BUSINESS'
                        : 'AUDIT_PERSONAL',
                    account: isBusinessVTO ? currentAccount : personalAccount,
                },
                {
                    name: '',
                    upsell: 'quarterly_estimated',
                    permission: 'ESTIMATED_TAXES',
                    account: personalAccount,
                },
                {
                    name: '',
                    upsell: 'dedicated_bookkeeping',
                    permission: 'BASIC_BOOKKEEPING',
                    account: currentAccount,
                    superScript: '4',
                },
                {
                    name: '',
                    upsell: 'payroll',
                    permission: 'PAYROLL',
                    account: currentAccount,
                    superScript: '4',
                },
                {
                    name: '',
                    upsell: 'personal_finance',
                    permission: 'FINANCIAL_PLANNING',
                    account: personalAccount,
                    requiresCheck: false,
                },
            ]

            if (excludedProduct !== excluded.BTP) {
                productMappings.push({
                    name: 'business',
                    permission: `VTO${selectedYear}_BUSINESS`,
                    account: currentAccount,
                })
            }
            if (excludedProduct !== excluded.PTP) {
                productMappings.push({
                    name: 'personal',
                    permission: `VTO${selectedYear}_PERSONAL`,
                    account: personalAccount,
                })
            }

            if (isExpeditedSelected) {
                productMappings.push(expediteProduct())
            }
            if (selectedFreeTaxExtension) {
                productMappings.push(freeTaxExtensionProduct())
            }

            allProducts.forEach((product) => {
                const { product_id } = product

                for (const mapping of productMappings) {
                    const {
                        name,
                        upsell,
                        permission,
                        account,
                        requiresCheck,
                        superScript,
                    } = mapping

                    if (doesProductMatch(product_id, name, upsell)) {
                        // Handle products that require additional checks
                        if (
                            requiresCheck &&
                            checkParentQuestion(
                                upsell || name,
                                currentVtoDetails,
                                currentVtoConfig
                            )
                        ) {
                            handleProductPush(
                                product,
                                thirdPackage,
                                permission,
                                account,
                                name ? name : `${vtoType}.upsell.${upsell}`,
                                superScript
                            )
                        } else if (!requiresCheck) {
                            handleProductPush(
                                product,
                                thirdPackage,
                                permission,
                                account,
                                name ? name : `${vtoType}.upsell.${upsell}`,
                                superScript
                            )
                        }
                        break // Exit loop once the match is found and handled
                    }
                }
            })

            return thirdPackage
        }
        return (
            <WrappedComponent
                {...props}
                suggestedPackages={suggestedPackages}
                setExcludedProdut={setExcludedProdut}
                excludedProduct={excludedProduct}
            />
        )
    }

    return ComponentWithPackages
}

export default WithPackages
