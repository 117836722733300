import UiDialog from '../../../../common/ui/UiDialog'
import IntroVideo from './IntroVideo'

const WatchIntroVideo = ({
    open,
    handleClose,
    videoWidth
}: {
    open: boolean
    handleClose: () => void
    videoWidth?: string
}) => {
    return (
        <UiDialog
            open={open}
            title={'Introduction video'}
            handleClose={handleClose}
            size="sm"
            contentStyles={{ padding: '0 !important' }}
            fullScreen={false}
            hideTitleSection={true}
        >
            <IntroVideo width={videoWidth} />
        </UiDialog>
    )
}

export default WatchIntroVideo
