import axios, { CancelToken, ResponseType } from 'axios';
import { logout } from './authService';
import store from '../store';
import { showError } from '../store/actions/feedback';
import Qs from 'qs';

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_DEV_SERVER_URL}cbapi/app.php/`,
});

const asyncInstance = axios.create({
    baseURL: `${process.env.REACT_APP_DEV_SERVER_URL}`,
});

export const setAuthHeader = (token?: any) => {
    instance.defaults.headers.common['Authorization'] = `Bearer ${
        token || store.getState().auth.access_token
    }`;

    asyncInstance.defaults.headers.common['Authorization'] = `Bearer ${
        token || store.getState().auth.access_token
    }`;
};

export const getResource = <T>(
    subUrl: any,
    cancelToken?: CancelToken,
    params?: any,
    rawResponse = false,
    responseType: ResponseType = 'json'
) => {
    return requestHandler<T>(
        'get',
        subUrl,
        undefined,
        cancelToken,
        params,
        undefined,
        rawResponse,
        responseType
    );
};

export const getApiUrl = (subUrl: string) => {
    return `${process.env.REACT_APP_DEV_SERVER_URL}cbapi/app.php/${subUrl}`;
};

export const postResource = <T>(
    subUrl: any,
    body: any,
    cancelToken?: CancelToken
) => {
    return requestHandler<T>('post', subUrl, body, cancelToken);
};

export const patchResource = <T>(
    subUrl: any,
    body: any,
    cancelToken?: CancelToken
) => {
    return requestHandler<T>('patch', subUrl, body, cancelToken);
};

export const putResource = (
    subUrl: any,
    body: any,
    cancelToken?: CancelToken
) => {
    return requestHandler('put', subUrl, body, cancelToken);
};

export const deleteResource = (subUrl: any, cancelToken?: CancelToken) => {
    return requestHandler('delete', subUrl, undefined, cancelToken);
};
export const getRequestCancelTokenSrc = () => {
    return axios.CancelToken.source();
};
export const postMultipartResource = <T>(
    subUrl: any,
    formData: FormData,
    cancelToken?: CancelToken,
    rawResponse = false,
    responseType: ResponseType = 'json'
) => {
    return requestHandler<T>(
        'post',
        subUrl,
        formData,
        cancelToken,
        null,
        {
            'content-type': 'multipart/form-data',
        },
        rawResponse,
        responseType
    );
};

export const poll = function (api: string, method: string, data: any, cancelToken:CancelToken) {
    const url = `${api}/async`;
    return new Promise((resolve, reject) => {
        return requestHandler(method, url, data).then((response: any) => {
            return polling(
                response.location,
                (response: any) => {
                    return resolve(response.data);
                },
                (response: any) => {
                    reject(response?.data); 
                },
                cancelToken
            );
        });
    });
};

const polling = function (
    location: string,
    callback: Function,
    errorCallback: any,
    cancelToken?:CancelToken
) {
    return asyncInstance
        .get(location, {cancelToken})
        .then((response: any) => {
            if (response.status === 304) {
                polling(location, callback, errorCallback, cancelToken);
            } else {
                callback(response);
            }
        })
        .catch((err: any) => {
            errorCallback(err?.response || null);
        });
};

export const requestHandler = <T>(
    method: any,
    subUrl: any,
    body: any,
    cancelToken?: CancelToken,
    params?: any,
    headers?: any,
    rawResponse = false,
    responseType: ResponseType = 'json'
): Promise<T> => {
    return new Promise((resolve, reject) => {
        instance
            .request({
                method: method,
                url: subUrl,
                data: body,
                cancelToken,
                params,
                responseType,
                headers,
                paramsSerializer: (params) =>
                    Qs.stringify(params, { indices: false }),
            })
            .then((response: any) => {
                if (rawResponse) {
                    resolve(response);
                } else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                const errorCode = error?.response?.status || null;
                const errorStatusCode = error?.response?.data?.error_code || null;
                if (error.response && error.response.status === 401) {
                    logout(); 
                } else if (error.response) {
                    if (
                        error.response?.data?.error_message ||
                        error.response?.data?.display_message
                    ) {
                        reject({
                            status: error.response.status,
                            statusText:
                                error.response?.data?.display_message ||
                                error.response?.data?.error_message,
                            errors: error.response.data?.errors || null,
                            errorStatusCode: errorStatusCode
                        })
                        return
                    }
                    reject({
                        status: error.response.status,
                        statusText:
                            error.response?.data?.message ||
                            error.response?.data?.error,
                        errors: error.response.data?.errors || null,
                    });
                    const { feedback } = store.getState();
                    if (
                        errorCode === 404 &&
                        window.location.hash !== '#/error404'
                    ) {
                        window.location.hash = '#/error404';
                    } else {
                        if (errorCode >= 500 && !feedback?.error?.showError) {
                            store.dispatch(
                                showError({
                                    errorCode: errorCode,
                                    showError: true,
                                })
                            );
                        }
                    }
                } else {
                    reject();
                }
            });
    });
};
