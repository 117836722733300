import { useState } from 'react';
import {
    InputAdornment,
} from '@material-ui/core'
import MomentUtils from '@date-io/moment';
import { CalendarTodayOutlined } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';


const ConnectedAccountsDatePicker = ({ bank, businessId, updateDate }: any) => {
    const currentDate = moment();
    const backDateTwoYears = moment(currentDate).subtract(2, 'years');
    const [importDate, setImportDate] = useState(
        moment(currentDate).subtract(1, 'month')
    );
    
    const update = (date: any) => {
        setImportDate(date)
        updateDate(date.format())
    };


    return (
        <>
            <MuiPickersUtilsProvider
                utils={MomentUtils}
            >
                <DatePicker
                    variant={'inline'}
                    inputVariant='outlined'
                    format={'DD MMM YYYY'}
                    margin='none'
                    disableFuture={true}
                    disablePast={false}
                    minDate={moment(
                        backDateTwoYears
                    ).add(1, 'day')}
                    size='small'
                    style={{ float: 'right', width: '160px', }}
                    value={importDate}
                    autoOk={true}
                    onChange={(value: any) =>
                        update(value)
                    }
                    label={''}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <CalendarTodayOutlined fontSize='small' />
                            </InputAdornment>
                        ),
                    }}
                />
            </MuiPickersUtilsProvider>
        </>
    )
}

export default ConnectedAccountsDatePicker;