import { useState } from 'react'
import * as Yup from 'yup'
import { Theme, makeStyles } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../../../../styles/models/Colors.interface'
import { toRem16 } from '../../../../../../../styles/commonStyles'
import UiText from '../../../../../../common/ui/UiText'
import UiButton from '../../../../../../common/ui/UiButton'
import rightArrow from '../../../../../../../assets/icons-svg/right-arrow-white.svg'
import { useVtoContext } from '../../../../providers/VtoProvider'
import {
    CONSENT_TEXT,
    RETIREMENT_APPOINTMENT_MIN_PROFIT_REQUIREMENT,
} from '../../../../models/vto.const'
import UiFormControlSelection from '../../../../../../common/ui/UiFormControlSelection'
import Loader from '../../../../../../common/Loader'
import { VtoHelpers } from '../../../../common/VtoHelpers'

interface StepOneProps {
    handleNext: (data: any) => void
}

const useStyles = makeStyles<Theme, ThemeColors>(() => {
    return {
        formFooter: {
            marginTop: '2rem',
            maxWidth: toRem16(400),
            margin: '0 auto',
        },
        consent: {
            marginTop: '1rem',
            border: (colorTheme) => `1px solid ${colorTheme.grey200}`,
            padding: '1rem',
            maxHeight: toRem16(180),
            overflow: 'auto',
        },
        nextBtn: {
            '& span': {
                fontWeight: 600,
            },
        },
        spacingSm: {
            marginTop: '1rem',
        },
        spacingMd: {
            marginTop: `${toRem16(24)}`,
        },
        spacingLg: {
            marginTop: `${toRem16(56)}`,
        },
        contentSpacing: {
            marginTop: '1rem',
            marginBottom: '1rem',
        },
    }
})

export default function Disclosures({ handleNext }: StepOneProps) {
    const theme = useThemeContext()
    const styles = useStyles(theme.colorTheme)
    const [disabledCheckbox, setDisabledCheckbox] = useState(true)
    const { currentVtoDetails, loading, isBusinessVTO } = useVtoContext()

    let initialValues = {
        setup_retirement_plan_small_business:
            currentVtoDetails.setup_retirement_plan_small_business,
        submitDetails: {
            submit_confirm: currentVtoDetails.submitDetails?.submit_confirm,
            is_consent_disclosed:
                currentVtoDetails.submitDetails?.is_consent_disclosed || false,
            consent_disclosed_by:
                currentVtoDetails.submitDetails?.consent_disclosed_by,
            consent_disclosed_date: new Date().getTime() / 1000,
        },
    }

    const validationSchema = Yup.object({
        setup_retirement_plan_small_business: Yup.string().nullable(),
        submitDetails: Yup.object().shape({
            submit_confirm: Yup.boolean()
                .nullable()
                .oneOf([true], 'Please agree the terms and agreement'),
            
            consent_disclosed_by: Yup.string()
                .nullable()
                .required('Name is required'),
            consent_disclosed_date: Yup.string().required('Date is required'),
        }),
    })

    function handleScroll() {
        const scrollableDiv = document.getElementById(
            'consent-form'
        ) as HTMLDivElement

        if (
            Math.abs(
                scrollableDiv.scrollTop +
                    scrollableDiv.clientHeight -
                    scrollableDiv.scrollHeight
            ) < 10
        ) {
            setDisabledCheckbox(false)
        }
    }

    function showRetirementPlanningSection() {
        const { business, forms_count } = currentVtoDetails
        const expenses = VtoHelpers.getExpensesSum(business?.balance_expenses)
        const netRevenue =
            parseFloat(business.balance_revenue || 0) -
            parseFloat(business.balance_returns || 0)
        const profit = netRevenue - expenses
        return (
            isBusinessVTO &&
            profit > RETIREMENT_APPOINTMENT_MIN_PROFIT_REQUIREMENT &&
            forms_count?.balance_profit !== 1 &&
            business.has_bookkeeping !== true
        )
    }

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleNext}
                enableReinitialize={false}
            >
                {({ submitForm, values, handleSubmit, setFieldValue }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            
                            {showRetirementPlanningSection() && (
                                <>
                                    <UiText
                                        weight="semi_bold_600"
                                        className={styles.spacingSm}
                                    >
                                        Small Business Retirement Planning
                                    </UiText>
                                    <UiText className={styles.spacingSm}>
                                        You can decrease your tax liability by
                                        setting up a retirement plan for your
                                        small business. Do you want to speak
                                        with an expert about this?
                                    </UiText>
                                    <div className={styles.spacingSm}>
                                        <UiFormControlSelection
                                            inlineLable="I want to speak to someone about setting up a retirement plan"
                                            type="checkbox"
                                            fieldName="setup_retirement_plan_small_business"
                                        />
                                    </div>
                                </>
                            )}

                            <UiText
                                weight="semi_bold_600"
                                className={styles.spacingLg}
                            >
                                Confirmation of Accurate Information
                            </UiText>
                            <UiText className={styles.spacingSm}>
                                To the best of my knowledge the information
                                enclosed in this client tax information is
                                correct and includes all income, deductions, and
                                other information necessary for the preparation
                                of this year’s income tax returns for which I
                                have adequate records.
                            </UiText>
                            <div className={styles.spacingSm}>
                                <UiFormControlSelection
                                    inlineLable="Yes I agree"
                                    type="checkbox"
                                    fieldName="submitDetails.submit_confirm"
                                    required
                                    // errorMessage={errors.submit_confirm}
                                />
                            </div>

                            <UiText
                                className={styles.spacingLg}
                                weight="semi_bold_600"
                            >
                                Consent for Disclosure of Tax Information
                            </UiText>
                            <UiText className={styles.spacingSm}>
                                Read through the information to provide consent.
                                (must scroll to bottom)
                            </UiText>
                            <div
                                id="consent-form"
                                onScroll={handleScroll}
                                className={styles.consent}
                            >
                                <UiText>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: CONSENT_TEXT,
                                        }}
                                    ></span>
                                </UiText>
                            </div>
                            <div className={styles.spacingMd}>
                                <UiFormControlSelection
                                    key={`consent-enable-${disabledCheckbox}`}
                                    disabled={disabledCheckbox}
                                    inlineLable="Yes I agree"
                                    type="checkbox"
                                    fieldName="submitDetails.is_consent_disclosed"
                                />
                            </div>

                            <div className={styles.formFooter}>
                                <div className={styles.contentSpacing}>
                                    <UiFormControlSelection
                                        key={`firstName-${disabledCheckbox}`}
                                        label="Your full name"
                                        disabled={disabledCheckbox}
                                        type="text"
                                        fieldName="submitDetails.consent_disclosed_by"
                                        showFloatingLabel={true}
                                        required
                                    />
                                </div>
                                <div className={styles.contentSpacing}>
                                    <UiFormControlSelection
                                        label="Date"
                                        type="date"
                                        fieldName="submitDetails.consent_disclosed_date"
                                        dateType="string"
                                        placeholder="DD/MM/YYYY"
                                        required
                                        disabled
                                    />
                                </div>

                                <div className={styles.contentSpacing}>
                                    <UiButton
                                        type="submit"
                                        btnType="tertiary"
                                        label="Next"
                                        fullWidth
                                        disabled={
                                            !values.submitDetails
                                                .submit_confirm ||
                                            !values.submitDetails
                                                ?.consent_disclosed_by
                                            || disabledCheckbox
                                        }
                                        endIconSvg={rightArrow}
                                        customClass={styles.nextBtn}
                                    />
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}
