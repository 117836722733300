import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
import {
    useLocation,
    useRouteMatch,
    Switch,
    Route,
    useHistory
} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { ActiveRoutingContext } from '../routing/Providers/ActiveRoutingProvider';
import {
    Box,
    Grid,
    makeStyles,
    Divider,
    Button,
    Theme,
    Chip,
    Hidden,
    IconButton,
    CircularProgress,
    TablePagination,
    Typography
} from '@material-ui/core';
import {
    FilterList,
    ArrowBack,
    Add,
    InfoOutlined,
} from '@material-ui/icons';
import moment from 'moment';

import Dropzone from "react-dropzone";
import UiFormControlSelection from '../common/ui/UiFormControlSelection';
import DocumentsFilter from './DocumentsFilter';
import DocumentsTree from './DocumentsTree';
import FoldersAndListView from './FolderAndListView';
import DocumentList from './DocumentList';
import DocumentView from './DocumentView';
import SearchBox from '../../components/common/SearchBox'
import FoldersTree from '../entity-management/documents-tab/FoldersTree'

import { showError, showInfo } from '../../store/actions/feedback';
import { jsonToRql, rqlToJson } from '../../utils/rql';
import {
    convertBytesToSize,
    convertToBytes,
    isValidDocumentMime,
} from '../../utils/documentUtil';

import {
    DocumentQueryObject,
    Folder,
    UploadedFile,
    ApplicationStore,
    SearchDocumentApiParams,
    FolderTree,
    paginatedDocumentResponse
} from '../../models';
import {
    deleteFile,
    getFolders,
    searchDocuments,
    uploadFilesToFolder,
} from '../../services/apiService';
import { removeFromLocalStorage } from '../../services/localStorageService';
import { buildTree } from '../../services/folderService';
import { useThemeContext } from '../common/whiteLabel/ColorThemeContext';


const filterDefaultValues: DocumentQueryObject = {
    minDate: moment().startOf('day').subtract(4, 'days').unix(),
    maxDate: moment().endOf('day').unix(),
    period: 'all',
    size: {
        operator: 'gt',
        value: 0,
    },
    fileSizeType: 'KB',
}

function Documents({
    accountId,
    documentFiltersConfig,
    allowedMimeTypes,
    allowedDocumentTypes,
    personalAccountId,
    maxFileSize,
}: {
    accountId: string
    documentFiltersConfig: {
        periods: any
        size: {
            operators: { gt: 'Greater Than'; lt: 'Less Than' };
        };
    }
    allowedMimeTypes: string[];
    allowedDocumentTypes: string[];
    personalAccountId: string;
    maxFileSize: string | number;
}) {
    const { colorTheme } = useThemeContext()
    const styles = makeStyles((theme: Theme) => ({
        gridContainer: {
            height: '100%',
        },
        filterButton: {
            marginLeft: 15,
            height: 40,
        },
        gridItem1: {
            overflowWrap: 'break-word',
            [theme.breakpoints.up('md')]: {
                paddingRight: 50,
            },
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            },
        },
        gridItem2: {
            paddingLeft: 50,
        },
        searchInput: {
            height: '40px',
            width: '100%',
        },
        marginContent: {
            marginLeft: theme.spacing(2),
        },
        contentSpacing: {
            marginLeft: theme.spacing(2),
        },
        gridContainerHeight: {
            [theme.breakpoints.up('md')]: {
                height: 'calc(80vh - 75px)'
            }
        },
        dropzone: {
            border: '2px dashed ' + colorTheme.grey200,
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
        }
    }))()

    const { setActiveRouteHeading } = useContext(ActiveRoutingContext)
    const [documentFilterPopupState, setDocumentFilterPopupState] =
        useState(false)
    const [term, setTerm] = useState('')
    const [chipsToDisplay, setChipsToDisplay] = useState<
        { label: string; searchKey: 'period' | 'size' }[]
    >([]);
    const [files, setFiles] = useState<paginatedDocumentResponse<UploadedFile>>();
    const [filePagination, setFilePagination] = useState({
        page: 1,
        perPage: 10,

    })
    const [queryObject, setQueryObject] =
        useState<DocumentQueryObject>(filterDefaultValues);
    const [documentModalState, setDocumentModalState] = useState(false);
    const [hideEmptyFolders, setHideEmptyFolders] = useState(false);
    const [currentFile, setCurrentFile] = useState<UploadedFile>();
    const [folders, setFolders] = useState<Folder[]>([]);
    const [businessFolderTree, setBusinessFolderTree] = useState<FolderTree>();
    const [personalFolderTree, setPersonalFolderTree] = useState<FolderTree>();
    const [uploadableFolderTree, setUploadableFolderTree] = useState<FolderTree>();
    const [uploadingFiles, setUploadingFiles] = useState(false);
    const query = new URLSearchParams(useLocation().search);
    const searchQuery = query.get('search');
    const folderQuery = query.get('folder');
    const folderAccountId = query.get('accountId') as string;
    const history = useHistory();
    const { path } = useRouteMatch();
    const isEntityManagementTab = path.includes('/entity-management')
    const dispatch = useDispatch();
    const fileSizeInByte = convertToBytes(
        queryObject.size.value,
        queryObject.fileSizeType
    );
    const isFileTypeUnsupported = (files: File[]): boolean =>
        files.every((file) =>
            isValidDocumentMime(
                { allowedMimeTypes, allowedDocumentTypes },
                file
            )
        )
    /**
     * active Header is not shown in the EM -> Doc Center we show only a header
     */
    const mainHeader = () => {
        return (
            <Box my={2} mx={2}>
                <Typography variant="h6" color="secondary">
                    Business / Personal Files
                </Typography>
            </Box>
        )
    }
    const [folderHeading, setFolderHeading] = useState<JSX.Element>(
        mainHeader()
    )
    const handleApplyDocumentsFilter = (
        result: DocumentQueryObject | boolean,
        searchTerm = term
    ) => {
        // second paramters is to pass a custom search from anywhere, this is being used to reset the search
        if (result && typeof result === 'object') {
            const convertedSize: { size: number; sizeType: 'MB' | 'KB' } =
                convertBytesToSize(result?.size?.value)
            setQueryObject({
                ...result,
                size: { ...result.size, value: convertedSize.size },
                fileSizeType: convertedSize.sizeType,
            })
            const { minDate, maxDate, size, period } = result
            const rql = jsonToRql({
                ...(period === 'all'
                    ? null
                    : { minDate: minDate, maxDate: maxDate }),
                term: searchTerm,
                period: period === 'all' ? null : period,
                size: size.value ? size : null,
            })
            if (rql) {
                const goToURL = isEntityManagementTab
                    ? `?search=${encodeURIComponent(rql)}&tab="documents"` : `/documents/search?search=${encodeURIComponent(rql)}`
                history.push(goToURL)
            } else {
                history.push(
                    `${
                        isEntityManagementTab
                            ? `/entity-management`
                            : `/documents`
                    }`
                )
            }
        }
        setDocumentFilterPopupState(false)
    }

    const handleDeleteFilter = (
        searchKey: 'period' | 'size',
        index: number
    ) => {
        chipsToDisplay.splice(index, 1)
        setChipsToDisplay(chipsToDisplay)
        const { minDate, maxDate, size, period, fileSizeType } = queryObject
        let jsonToConvert
        if (searchKey === 'period') {
            jsonToConvert = {
                term,
                size: size.value
                    ? {
                          value: convertToBytes(size.value, fileSizeType),
                          operator: size.operator,
                      }
                    : null,
            }
            setQueryObject({
                ...queryObject,
                minDate: filterDefaultValues.minDate,
                maxDate: filterDefaultValues.maxDate,
                period: 'all',
            })
        } else if (searchKey === 'size') {
            jsonToConvert = {
                ...(period === 'all'
                    ? null
                    : { minDate: minDate, maxDate: maxDate }),
                term,
                period: period === 'all' ? null : period,
            }
            setQueryObject({
                ...queryObject,
                size: {
                    operator: 'gt',
                    value: 0,
                },
            })
        }
        const rql = jsonToRql(jsonToConvert)
        if (rql) {
            history.push(`/documents/search?search=${encodeURIComponent(rql)}`)
        } else {
            let url = isEntityManagementTab ? `/entity-management` : `/documents`
            history.push(url)
            
        }
    }

    const sizeLimitMessage = (file: File)=> {
        dispatch(
            showInfo({
                infoData: (
                    <Fragment>
                        <Box textAlign='center' width='100%'>
                            <InfoOutlined fontSize='large' />
                        </Box>
                        <div>File {file.name} is too large.</div>
                        <div>
                            Please downsize the file. If you use a scanner please rescan the image at a lesser dpi.
                        </div>
                    </Fragment>
                ),
            })
        )
    }

    const limitCheck = (files: any) => {
        for(var file of files){
            if(file.size > maxFileSize){
                sizeLimitMessage(file)
                return true
            }
        }
        return false
    }

    const handleUploadFile = (files: File[]) => {
        if (!files.length) {
            return
        }
        const filesInput: any = document.getElementById('upload-button-file')
        if (isFileTypeUnsupported(files)) {
            dispatch(
                showInfo({
                    infoData: (
                        <Fragment>
                            <Box textAlign='center' width='100%'>
                                <InfoOutlined fontSize='large' />
                            </Box>
                            <div>File(s) has unsupported format.</div>
                            <div>
                                Please upload only{' '}
                                <b>{allowedDocumentTypes.join(', ')}</b>
                            </div>
                        </Fragment>
                    ),
                })
            )
            return
        }
        if(limitCheck(files)){
            return
        }

        setUploadingFiles(true)
        const type = accountId === folderAccountId ? 'business' : 'personal'
        uploadFilesToFolder(accountId, folderQuery as string, type, files)
            .then((res) => {
                setFiles(undefined)
                getDocuments({
                    path: folderQuery as string,
                    type,
                });
                loadFolders();
                setUploadingFiles(false);
                filesInput.value = '';
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch(
                        showInfo({
                            infoData: (
                                <Fragment>
                                    <Box textAlign='center' width='100%'>
                                        <InfoOutlined fontSize='large' />
                                    </Box>
                                    <div>{err.statusText}</div>
                                </Fragment>
                            ),
                        })
                    );
                } else {
                    dispatch(
                        showError({
                            errorCode: err.status,
                            showError: true,
                        })
                    );
                }
                setUploadingFiles(false);
            });
    };

    const handleChipsData = useCallback(
        (
            searchJson: DocumentQueryObject & {
                search: string
                term: string
            }
        ) => {
            // Creating an array of chips to display applied filters
            setChipsToDisplay(
                Object.keys(searchJson).reduce(
                    (
                        chips: {
                            label: string
                            searchKey: 'period' | 'size'
                        }[],
                        key
                    ) => {
                        switch (key) {
                            case 'period':
                                // If custom period is selected the date range else period
                                if (searchJson.period === 'custom') {
                                    chips.push({
                                        label: `${moment(
                                            searchJson.minDate * 1000
                                        ).format('DD MMM, YYYY')} - ${moment(
                                            searchJson.maxDate * 1000
                                        ).format('DD MMM, YYYY')}`,
                                        searchKey: 'period',
                                    })
                                } else {
                                    searchJson.period !== 'all' &&
                                        chips.push({
                                            label: documentFiltersConfig
                                                .periods[searchJson.period],
                                            searchKey: 'period',
                                        })
                                }
                                return chips
                            case 'size':
                                const convertedSize = convertBytesToSize(
                                    searchJson.size.value
                                )
                                convertedSize.size &&
                                    chips.push({
                                        label: `${
                                            searchJson.size.operator === 'gt'
                                                ? '>'
                                                : '<'
                                        } ${convertedSize.size} ${
                                            convertedSize.sizeType
                                        }`,
                                        searchKey: 'size',
                                    })
                                return chips
                        }
                        return chips
                    },
                    []
                )
            )
        },
        [documentFiltersConfig.periods]
    )

    const handleDocumentDelete = () => {
        setFiles(undefined)
        deleteFile(accountId, (currentFile as UploadedFile).id)
            .then((res) => {
                setFiles(undefined)
                handleReloadCurrentState();
                loadFolders();
            })
            .catch((err) => {
                showError({ errorCode: err.status, showError: true });
            });
        setCurrentFile(undefined);
    };
    
    // arrange tax folder in a descending order
    const arrangeTaxFolders = useCallback((tree) => {
        let taxFolders: any = {};
        if (!tree?.folders['/taxes']) {
            return taxFolders;
        }
        Object.keys(tree?.folders['/taxes']?.folders as any)
            .sort(
                (a, b) =>
                    tree.folders['/taxes'].folders[a].order -
                    tree.folders['/taxes'].folders[b].order
            )
            .forEach((key: string) => {
                taxFolders[key] = tree?.folders['/taxes'].folders[key];
            });

        tree.folders['/taxes'].folders = {
            ...taxFolders,
        };
    }, []);

    // arrange Permanent folder in a Ascending order
    const arrangePermanentFolders = useCallback((tree) => {
        let permanentFolders: any = {};
        if (!tree?.folders['/permanent_files']) {
            return permanentFolders;
        }
        Object.keys(tree?.folders['/permanent_files']?.folders as any)
            .sort(
                (a, b) =>
                    tree.folders['/permanent_files'].folders[a].order -
                    tree.folders['/permanent_files'].folders[b].order
            )
            .forEach((key: string) => {
                permanentFolders[key] = tree?.folders['/permanent_files'].folders[key];
            });

        tree.folders['/permanent_files'].folders = {
            ...permanentFolders,
        };
    }, []);

    const loadFolders = useCallback(() => {
        setFolders([])
        getFolders(accountId)
            .then((res) => {
                const businessTree = buildTree(res, accountId, 'business');
                const personalTree = buildTree(
                    res,
                    personalAccountId,
                    'personal'
                );
                arrangeTaxFolders(businessTree)    
                arrangeTaxFolders(personalTree)
                arrangePermanentFolders(businessTree)    
                arrangePermanentFolders(personalTree)
                setBusinessFolderTree(() => businessTree);
                setPersonalFolderTree(() => personalTree);
                setFolders(res);
            })
            .catch((err) => {
                dispatch(showError({ errorCode: err.status, showError: true }));
            });
    }, [accountId, personalAccountId, arrangeTaxFolders, arrangePermanentFolders, dispatch]);

    const getDocuments = useCallback(
        (params: SearchDocumentApiParams) => {
            searchDocuments(accountId, params)
                .then((res) => setFiles((prev) => res))
                .catch((err) => {
                    dispatch(
                        showError({ errorCode: err.status, showError: true })
                    )
                    history.push('/documents')
                })
        },
        [accountId, dispatch, history]
    )

    const handleReloadCurrentState = useCallback(() => {
        if (searchQuery) {
            const { period, minDate, maxDate, size } = queryObject
            getDocuments({
                ...(period === 'all'
                    ? null
                    : { minDate: minDate, maxDate: maxDate }),
                search: searchQuery as string,
                term,
                period,
                ...(size.operator === 'gt'
                    ? { minSize: fileSizeInByte }
                    : { maxSize: fileSizeInByte }),
            });
        } else if (folderQuery) {
            getDocuments({
                path: folderQuery,
                type: accountId === folderAccountId ? 'business' : 'personal',
            })
        }
        loadFolders();
    },[accountId, 
    fileSizeInByte, 
    folderAccountId, 
    folderQuery, 
    getDocuments, 
    loadFolders, 
    queryObject, 
    searchQuery, 
    term]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setFilePagination((prev) => ({...prev, page: newPage + 1}))
    };
    
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setFilePagination((prev) => ({...prev, perPage: parseInt(event.target.value, 10)}))
    };

    useEffect(() => {
        if (searchQuery) {
            setActiveRouteHeading('Documents')
            /**
             * Converted search query into JSON to set local data for documents filter
             */
            const searchJson = rqlToJson<
                DocumentQueryObject & { search: string; term: string }
            >(searchQuery, {
                search: searchQuery,
                term: '',
                minDate: 0,
                maxDate: 0,
                size: { value: 0, operator: 'gt' },
                period: 'all',
                fileSizeType: 'KB',
            })
            handleChipsData(searchJson)
            const { term, minDate, maxDate, size, period } = searchJson
            // Converted the size to MB or KB
            const convertedSize = convertBytesToSize(size.value)

            /**
             * Setting local state for Query Object which will be passed to Documents filter popup
             * Setting it here because state will be empty on component refresh
             * and have to calculate this object using search query
             */
            setQueryObject((prev) => ({
                minDate: minDate ? minDate : prev.minDate,
                maxDate: maxDate ? maxDate : prev.maxDate,
                period,
                size: {
                    value: convertedSize.size,
                    operator: size.operator,
                },
                fileSizeType: convertedSize.sizeType,
            }))

            // Setting local search value to retain user search on refresh
            setTerm((prev) => term)
            setFiles(() => undefined)
            getDocuments({
                ...(period === 'all'
                    ? (null as any)
                    : { minDate: minDate, maxDate: maxDate }),
                term,
                period,
                ...(size.operator === 'gt'
                    ? { minSize: fileSizeInByte }
                    : { maxSize: fileSizeInByte }),
                perPage: filePagination.perPage,
                page: filePagination.page     
            });
        } else if (folderQuery) {
            setTerm(() => '');
            setChipsToDisplay([]);
            setQueryObject(filterDefaultValues);
            let folder = accountId === folderAccountId ? businessFolderTree?.[folderQuery] : personalFolderTree?.[folderQuery]
            setUploadableFolderTree(folder)
            if(!isEntityManagementTab) {
                setActiveRouteHeading(
                    <Box display='flex' alignItems='center' component='span'>
                        <IconButton onClick={() => history.goBack()}>
                            <ArrowBack />
                        </IconButton>
                        {folder?.pathToDisplay}
                    </Box>
                );
            } else {
                setFolderHeading(
                    <Box my={2} display='flex' alignItems='center' component='span'>
                        <IconButton onClick={() => history.goBack()}>
                            <ArrowBack />
                        </IconButton>
                        {folder?.pathToDisplay}
                    </Box>
                )
            }
            setFiles(() => undefined);
            getDocuments({
                path: folderQuery,
                type: accountId === folderAccountId ? 'business' : 'personal',
                perPage: filePagination.perPage,
                page: filePagination.page 
            });
        } else {
            setTerm(() => '')
            setChipsToDisplay([])
            setQueryObject(filterDefaultValues)
            setCurrentFile(undefined)
            setFiles(() => undefined)
            setFolderHeading(mainHeader)
            removeFromLocalStorage('selectedFolder')
            !isEntityManagementTab && setActiveRouteHeading('Documents')
            setActiveRouteHeading('Documents');
            removeFromLocalStorage('selectedFolder');
        }
    }, [setActiveRouteHeading, 
        searchQuery, 
        folderQuery, 
        accountId, 
        dispatch, 
        handleChipsData, 
        history, 
        folderAccountId, 
        getDocuments,
        setUploadableFolderTree,
        businessFolderTree,
        personalFolderTree,
        filePagination,
        isEntityManagementTab, 
        fileSizeInByte]);

    useEffect(() => {
        loadFolders()
    }, [loadFolders])
    return (
        <Grid
            container
            className={ isEntityManagementTab ? styles.gridContainerHeight : styles.gridContainer}
            {...(isEntityManagementTab ? { mx: 2 } : { justify: 'center' })}
        >
            <Grid item xs={12} md={5} className={styles.gridItem1}>
                {isEntityManagementTab && <Box>{folderHeading}</Box>}
                {folderQuery && (
                    <Box mb={3}>
                        {uploadingFiles ? (
                            <div className={isEntityManagementTab ? styles.contentSpacing :""}>
                                <CircularProgress size={29} />
                            </div>
                        ) : (
                            <div
                                className={
                                    isEntityManagementTab
                                        ? styles.contentSpacing
                                        : ''
                                }
                            >
                               <Box>

                               <Dropzone
                                    onDrop={handleUploadFile}>
                                    {({
                                        getRootProps,
                                        getInputProps
                                    }) => {
                                        return (
                                            <Box className={styles.dropzone}
                                                {...getRootProps({})}
                                            >
                                                 <Box my={3}>
                                                    <Typography align="center">Drop files here to upload</Typography>
                                                </Box>
                                                <Box my={3}>
                                                    <input id="upload-button-file" {...getInputProps()} />
                                                </Box>
                                                <Box my={3}  display="flex" flexDirection="column" alignItems="center">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        data-cy="upload-files-button"
                                                        >
                                                        Select Files </Button>
                                                </Box>
                                                <Box my={3}>
                                                    <Typography color='textSecondary' variant='body2' align="center">
                                                        Supported file types: <br/> {allowedDocumentTypes.join(', ')}<br />
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                    </Dropzone>
                               </Box>
                            </div>
                        )}
                    </Box>
                )}
                {isEntityManagementTab || folderQuery || searchQuery ? null : (
                    <UiFormControlSelection
                        type="checkbox"
                        inlineLable="Hide empty folders"
                        value={hideEmptyFolders}
                        onChange={(e: any) => {
                            setHideEmptyFolders(e.target.checked)
                        }}
                    />
                )}
                <Box display="flex" mx={2}>
                    <SearchBox
                        searchTerm={term}
                        setInput={setTerm}
                        placeHolder={'Search'}
                        notched={false}
                        fullWidth={true}
                        clearSearch={() => {
                            setTerm('')
                            handleApplyDocumentsFilter(
                                {
                                    ...queryObject,
                                    size: {
                                        value: convertToBytes(
                                            queryObject.size.value,
                                            queryObject.fileSizeType
                                        ),
                                        operator: queryObject.size.operator,
                                    },
                                },
                                ''
                            )
                        }}
                        onKeyPressEvent={(event) => {
                            if (event.key === 'Enter') {
                                handleApplyDocumentsFilter(
                                    {
                                        ...queryObject,
                                        size: {
                                            value: convertToBytes(
                                                queryObject.size.value,
                                                queryObject.fileSizeType
                                            ),
                                            operator: queryObject.size.operator,
                                        },
                                    },
                                    term
                                )
                            }
                        }}
                    />
                    <Hidden smDown>
                        <Button
                            startIcon={<FilterList />}
                            variant="outlined"
                            color="secondary"
                            className={styles.filterButton}
                            onClick={() => setDocumentFilterPopupState(true)}
                        >
                            Filters
                        </Button>
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton
                            color="secondary"
                            className={styles.filterButton}
                            onClick={() => setDocumentFilterPopupState(true)}
                        >
                            <FilterList />
                        </IconButton>
                    </Hidden>
                    <DocumentsFilter
                        open={documentFilterPopupState}
                        handleClose={handleApplyDocumentsFilter}
                        queryObject={queryObject}
                    />
                </Box>
                {chipsToDisplay?.length ? (
                    <Box mt={2}>
                        {chipsToDisplay.map((chip, index) => (
                            <Chip
                                key={index}
                                label={chip.label}
                                data-cy='document-selected-filter'
                                onDelete={() =>
                                    handleDeleteFilter(chip.searchKey, index)
                                }
                            />
                        ))}
                    </Box>
                ) : null}
                <Box my={4}>
                    <Divider />
                </Box>
                <Box my={2} display='flex'>
                    {(searchQuery || folderQuery) && files?.items?.length as number > 0  && <TablePagination
                        component='div'
                        labelRowsPerPage='Show:'
                        count={files?.itemsCount as number}
                        page={files?.page as number - 1}
                        onChangePage={handleChangePage}
                        rowsPerPage={files?.perPage as number}
                        onChangeRowsPerPage={
                            handleChangeRowsPerPage
                        }
                    />}
                </Box>
                {isEntityManagementTab ? (
                    <div className={styles.marginContent}>
                        {(files || folderQuery || searchQuery) ? (
                            <FoldersAndListView
                                files={files}
                                handleSelectFile={(file) => {
                                    setCurrentFile(file);
                                    setDocumentModalState(true);
                                }}
                                folderView={folderQuery ? true : false}
                                accountId={accountId}
                                businessFolderTree={businessFolderTree}
                                personalFolderTree={personalFolderTree}
                                folder={uploadableFolderTree}
                                hideEmptyFolders={false}
                                setUploadableFolderTree={setUploadableFolderTree}
                                isEntityManagementTab={isEntityManagementTab}
                                folderQuery={folderQuery}
                            />
                        ) : (
                            <FoldersTree
                                folders={folders}
                                hideEmptyFolders={false}
                            />
                        )}
                    </div>
                ) : (
                    <Switch>
                    <Route path={`${path}/search`}>
                        <DocumentList
                            files={files}
                            handleSelectFile={(file) => {
                                setCurrentFile(file);
                                setDocumentModalState(true);
                            }}
                            folderView={false}
                            accountId={accountId}
                            businessFolderTree={businessFolderTree}
                            personalFolderTree={personalFolderTree}
                        />
                    </Route>
                    <Route path={`${path}/folder`}>
                        <FoldersAndListView
                            files={files}
                            handleSelectFile={(file) => {
                                setCurrentFile(file);
                                setDocumentModalState(true);
                            }}
                            folderView={true}
                            accountId={accountId}
                            businessFolderTree={businessFolderTree}
                            personalFolderTree={personalFolderTree}
                            folder={uploadableFolderTree}
                            hideEmptyFolders={false}
                            setUploadableFolderTree={setUploadableFolderTree}
                        />
                    </Route>
                    <Route exact path={path}>
                        <DocumentsTree
                            folders={folders}
                            showHeaders={true}
                            hideEmptyFolders={hideEmptyFolders}
                            businessFolderTree={businessFolderTree}
                            personalFolderTree={personalFolderTree}
                            setUploadableFolderTree={setUploadableFolderTree}
                        />
                    </Route>
                    </Switch>
                )}
            </Grid>
            <Hidden mdDown>
                <Divider orientation="vertical" flexItem={true} />
            </Hidden>
            <Grid item xs={12} md={6} className={styles.gridItem2}>
                <DocumentView
                    modalOpen={documentModalState}
                    currentFile={currentFile}
                    handleClearFile={() => setCurrentFile(undefined)}
                    folders={folders}
                    setCurrentFile={setCurrentFile}
                    handleConfirmDelete={handleDocumentDelete}
                    reloadCurrentState={handleReloadCurrentState}
                    resetFiles={()=> setFiles(undefined)}
                />
            </Grid>
        </Grid>
    )
}

const mapStateToProps = ({ appData, config }: ApplicationStore) => ({
    accountId: appData.current_account_id,
    documentFiltersConfig: config.local.filters,
    allowedMimeTypes: config.apiConfig.file_center.allowed_mime_types,
    allowedDocumentTypes: config.apiConfig.file_center.allowed_extensions,
    maxFileSize: config.apiConfig.file_center.max_file_size,
    personalAccountId: appData.personal_account.id,
});
export default connect(mapStateToProps)(Documents);