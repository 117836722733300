import { memo, useState } from 'react'
import { Step, Stepper } from '@material-ui/core'
import { NavMenuItems } from '../../../../models/VtoDetails.model'
import { useThemeContext } from '../../../../../../common/whiteLabel/ColorThemeContext'
import { useVtoContext } from '../../../../providers/VtoProvider'
import { useMenuStyles } from './MenuStyles'
import {
    RenderSection,
    RenderStep,
} from './StepUtils'
import { getSectionInfo } from '../../../../common/SectionUtil'
import { MULTI_STEP } from '../../../../models/vto.const'
import { getStepModel } from '../../../../common/VtoUtils'
import { useFormikContext } from 'formik'
interface SectionsProps {
    menuItems: NavMenuItems
    onMenuItemClick: (parentStep: any, key: string) => void
    currentStep: string
    showContentPage: () => void
    missingUploadCount: any
}

export const StepMenu = memo((props: SectionsProps) => {
    const { menuItems, currentStep, onMenuItemClick } = props
    const { colorTheme } = useThemeContext()
    const [collapse, setCollapse] = useState<{[key: string]: boolean} | null>(null);
    const {
        currentYearMetaData,
        currentVtoDetails,
        setParentStep,
        parentStep,
        highlightIncompleteSections,
        setFirstIncompleteStep,
    } = useVtoContext()
    const { values} = useFormikContext()
    const styles = useMenuStyles(colorTheme)


    const handleCollapse = (key: string) => {
        setCollapse(prev => ({
            ...prev,
            [key]: !collapse?.[key]
        }))
    }

    const availableSections = currentYearMetaData?.formattedSections

    if (!menuItems.length) return null


    return (
        <div className={styles.scrollContainer}>
            {menuItems.length > 0 &&
                menuItems?.map((item: any, index: number) => {
                    let sectionInfo = {
                        isDone: true,
                        count: 0,
                    }
                    const isActiveStep = item.name === currentStep
                    const sectionsData = item.sections
                    // for single step (single section is considered as step)
                    if (sectionsData.length === 1) {
                        // this is a workaround to get the form values for business type section
                        // since this section has two questions and we need to do the comparison based on the answers
                        // OEHA-24219
                        const formValues = item.sections[0] === 'business_type' ? values : currentVtoDetails
                        sectionInfo = getSectionInfo(
                            item.sections[0],
                            currentYearMetaData,
                            formValues
                        )
                    }
                    const isLastStep = menuItems?.length - 1 === index
                    const isCollapsed = !!collapse?.[item.name];
                    if(sectionInfo.count !== 0 ) {
                        setParentStep((prev: any) => (!prev?.name ? item : prev))
                        setFirstIncompleteStep((prev: string) => (prev === '' ? item?.name : prev))
                    }
                    return (
                        <Stepper
                            className={`${styles.stepper} vto-steps`}
                            orientation="vertical"
                            key={item?.key}
                        >
                            <RenderStep
                                step={item}
                                styles={styles}
                                isActiveStep={isActiveStep}
                                isLastStep={isLastStep}
                                onMenuItemClick={onMenuItemClick}
                                sectionInfo={sectionInfo}
                                setParentStep={setParentStep}
                                highlightInfo={highlightIncompleteSections}
                                handleCollapse={handleCollapse}
                                isCollapsed={isCollapsed}
                                hasVisibleSections={sectionsData?.length > 1}
                            />
                            {sectionsData?.length > 1 && !isCollapsed &&
                                sectionsData.map((section: any, i: number) => {
                                    const { stepModel, stepIndex } =
                                        getStepModel(item)

                                    let sectionInfo = getSectionInfo(
                                        section,
                                        currentYearMetaData,
                                        item?.type === MULTI_STEP
                                            ? currentVtoDetails?.[stepModel][
                                                  stepIndex
                                              ]
                                            : currentVtoDetails
                                    )
                                    let activeSection =
                                        parentStep?.name === item?.name &&
                                        section === currentStep
                                    if(sectionInfo.count !== 0 ) {
                                        setFirstIncompleteStep((prev: string) => (prev === '' ? section : prev))
                                        setParentStep((prev: any) => (!prev?.name ? item : prev))
                                    }
                                    return (
                                        <Step
                                            key={section}
                                            className={`${styles.vtoStep} ${
                                                activeSection ? styles.activeItem : ''
                                            }`}
                                            onClick={() => {
                                                onMenuItemClick(item, section)
                                            }}
                                        >
                                            <RenderSection
                                                item={item}
                                                section={section}
                                                styles={styles}
                                                sectionInfo={sectionInfo}
                                                activeSection={activeSection}
                                                availableSections={availableSections}
                                                highlightInfo={highlightIncompleteSections}
                                            />
                                        </Step>
                                    )
                                })}
                        </Stepper>
                    )
                })}
        </div>
    )
})
