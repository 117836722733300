import { useCallback, useEffect, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Theme,
    makeStyles,
} from '@material-ui/core'
import Icon from '../../../../../common/Icon'
import UiText from '../../../../../common/ui/UiText'
import IconList from '../../../../../common/IconList'
import {
    CurrentStepType,
    TaxPrepStatus,
    TaxPreparationSteps,
} from '../../../../../../models/vto/VtoInfo'
import { useVtoContext } from '../../../providers/VtoProvider'
import { commonStyles, toRem16 } from '../../../../../../styles/commonStyles'
import { useThemeContext } from '../../../../../common/whiteLabel/ColorThemeContext'
import { ThemeColors } from '../../../../../../styles/models/Colors.interface'
import TaxPrepStepButton from './TaxPrepStepButton'
import {
    StatusTitleMapping,
    ApStatusMapping,
    AdditionalStatusMapping,
} from './constant'
import TaxPrice from '../../../../../dashboard/tax-prep/tax-prep-statuses/TaxPrice'
import {
    FEDERAL_TAX_TYPE,
    STATUS_CLIENT_REVIEW,
    STATE_TAX_TYPE,
} from '../../../../../dashboard/tax-prep/constants/tax-prep.const'
import UiButton from '../../../../../common/ui/UiButton'
import LinearProgressBar from '../../../../../common/LinearProgressBar'
import { vtoCompletionPercent } from '../../../common/SectionUtil'
import StatesModal from '../../../../../dashboard/tax-prep/modals/StatesModal'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles<Theme, ThemeColors>((theme: Theme) => {
    return {
        container: {
            marginTop: '1rem',
            padding: '1.3rem 0.5rem 0',
        },
        stepList: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            position: 'relative',
            '& .status-steps': {
                marginTop: toRem16(9),
                position: ' relative',
                '& .current-step': {
                    marginTop: '1.5rem',
                    background: (colorTheme) => colorTheme.primaryWhite,
                },
                '& .icon-style': {
                    marginTop: '1.5rem',
                    background: (colorTheme) => colorTheme.primaryWhite,
                },
            },
        },
        stepLine: {
            position: 'absolute',
            borderLeft: (colorTheme) => `3px solid ${colorTheme.grey200}`,
            height: '100%',
            left: '10px',
        },
        stepTitle: {
            marginLeft: '1rem',
            color: (colorTheme) => colorTheme.black100,
        },
        stepHint: {
            marginLeft: '1rem',
            marginTop: '0.2rem',
            color: (colorTheme) => colorTheme.black100,
            paddingBottom: '0rem',
        },
        contentStyles: {
            marginTop: toRem16(30),
            position: 'relative',
        },
        currentStepChip: {
            margin: `0 0 ${toRem16(6)} 1rem`,
            maxWidth: toRem16(92),
            padding: `4px 8px`,
            borderRadius: toRem16(4),
            paddingLeft: '0.5rem',
            background: (colorTheme) => colorTheme.grey1500,
            position: 'absolute',
            top: '-1.9rem',
        },
        taxRefundContainer: {
            margin: '1rem 0',
        },
        taxRefundDetails: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        seeAllStateBtn: {
            padding: 0,
            '&:hover': {
                background: 'none',
            },
        },
        accordion: {
            '&.MuiPaper-elevation1': {
                boxShadow: 'none',
            },
            '& .MuiAccordionSummary-root': {
                padding: '0',
            },
            '& .MuiAccordionSummary-content': {
                left: '0',
                '& > div:first-of-type': {
                    '& > div:first-of-type': {
                        display: 'flex',
                        alignItems: 'center',
                        '& > p': {
                            marginLeft: '1rem',
                        },
                    },
                },
            },
            '& .MuiAccordionDetails-root': {
                display: 'flex',
                flexDirection: 'column',
                padding: '0',
            },
        },
        accordionStep: {
            '& > div:first-of-type': {
                height: '100%',
            },
        },
        linearProgressBar: {
            marginTop: '1rem',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                width: '95%',
            },
        },
    }
})

const StartedInfo = ({ percentage, selectedYear, vtoType }: any) => {
    return (
        <span>
            Before we can prepare your taxes, you need to confirm your tax
            information. We’ve already filled out {percentage}% of your{' '}
            {selectedYear}
            {vtoType ? ' Business' : ' Personal'} Taxes using the latest
            information from your account.
        </span>
    )
}

const TaxPreparationStatuses = ({ stateTax }: any) => {
    const { colorTheme } = useThemeContext()
    const styles = useStyles(colorTheme)
    const commonClasses = commonStyles()
    const [openStates, setOpenStates] = useState(false)
    const [updatedTaxPrepStatuses, setUpdatedTaxPrepStatuses] = useState<any[]>(
        []
    )
    const [expanded, setExpanded] = useState(false)

    const {
        vtoConfig,
        currentBusinessVto,
        currentPersonalVto,
        isBusinessVTO,
        currentVtoDetails,
        vtoAllowedSteps,
        currentYearMetaData,
        selectedYear,
    } = useVtoContext()

    const currentVto = isBusinessVTO ? currentBusinessVto : currentPersonalVto
    const currentStep =
        currentVto?.tax_preparation_current_step as CurrentStepType
    const completedSteps =
        currentVto?.tax_preparation_completed_steps as TaxPreparationSteps[]
    const taxPrepStatuses = vtoConfig.progress_tax_preparation_statuses
    const currentTaxPrepStatus = currentVto?.tax_preparation_status
    const currentTaxApStatus = currentVto?.tax_ap_status
    const isCurrentStep = (step: TaxPrepStatus) => {
        // if (currentStep.title === 'Started' && step.code === 'submitted')
        //     return true
        return currentStep.title === step.title
    }

    const completionPercentage = () => {
        if (!currentYearMetaData?.Steps) return {percentage: 0, isAllStepDone: false}
        return vtoCompletionPercent(
            vtoAllowedSteps,
            currentYearMetaData,
            currentVtoDetails
        )
    }

    const stateTaxes = currentVto?.state_taxes
    const federalTax = currentVto?.federal_tax
    const { percentage } = completionPercentage()

    const hasCompletedStep = useCallback(
        (step: TaxPrepStatus, stepIndex: number) => {
            const isCompleted = completedSteps?.includes(step.code)
            const currentStepIndex = taxPrepStatuses?.findIndex(
                (status) => status.code === currentTaxPrepStatus
            )
            if (step.code === 'started')
                return completedSteps?.includes('submitted')
            if (currentTaxPrepStatus === 'completed') return true
            if (currentStepIndex !== -1) {
                return currentStepIndex > stepIndex ? true : false
            }
            return isCompleted
        },
        [completedSteps, currentTaxPrepStatus, taxPrepStatuses]
    )

    const handleChange = (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded)
    }

    const lastStepIndex = taxPrepStatuses.length - 1

    const statusIcon = (step: TaxPrepStatus, stepIndex: number) => {
        return hasCompletedStep(step, stepIndex) ? (
            <Icon
                icon={'CompleteIconPrimary' as keyof typeof IconList}
                size={'24px'}
                svgColor={colorTheme.tertiary}
            />
        ) : (
            <Icon icon="EmptyCircle" strokeColor={colorTheme.secondary} />
        )
    }

    const getFedralTaxType = () => {
        const taxType = federalTax?.type
        return taxType === 'Owes'
            ? FEDERAL_TAX_TYPE.FEDERAL_TAX_DUE
            : taxType === 'NA'
            ? FEDERAL_TAX_TYPE.FEDERAL_TAX_RETURN
            : FEDERAL_TAX_TYPE.FEDERAL_TAX_REFUND
    }

    const getStateTaxType = () => {
        const taxType = stateTaxes[0]?.type
        return taxType === 'NA'
            ? STATE_TAX_TYPE.STATE_TAX_RETURN
            : stateTax < 0
            ? STATE_TAX_TYPE.STATE_TAX_DUE
            : STATE_TAX_TYPE.STATE_TAX_REFUND
    }

    const renderTaxRefundInfo = () => (
        <div className={styles.taxRefundContainer}>
            {federalTax && (
                <>
                    <div className={styles.taxRefundDetails}>
                        <UiText>{getFedralTaxType()}</UiText>
                        <TaxPrice
                            type={federalTax?.type}
                            amount={federalTax?.amount}
                        />
                    </div>
                </>
            )}

            {stateTaxes && (
                <>
                    <div
                        className={`${styles.taxRefundDetails} ${commonClasses.mt12}`}
                    >
                        <UiText>{getStateTaxType()}</UiText>
                        {currentVto?.state_taxes?.length > 1 ? (
                            <TaxPrice amount={stateTax} />
                        ) : (
                            <TaxPrice
                                type={stateTaxes[0]?.type}
                                amount={stateTaxes[0]?.amount}
                            />
                        )}
                    </div>
                    {currentVto?.state_taxes?.length > 1 && (
                        <UiButton
                            customClass={styles.seeAllStateBtn}
                            btnType="hyperlink"
                            customLabel={
                                <UiText
                                    variant="motorcycle_90"
                                    weight="semi_bold_600"
                                >
                                    See All States
                                </UiText>
                            }
                            handleClick={() => setOpenStates(true)}
                        />
                    )}
                </>
            )}
        </div>
    )

    useEffect(() => {
        const updatedTaxPrepStatuses = taxPrepStatuses.map(
            (step: any, index: number) => {
                return {
                    ...step,
                    completed: hasCompletedStep(step, index),
                }
            }
        )

        setUpdatedTaxPrepStatuses(updatedTaxPrepStatuses)
    }, [currentStep, hasCompletedStep, taxPrepStatuses])

    const renderStep = (step: TaxPrepStatus, index: number) => (
        <div className={styles.stepList} key={`${step}`}>
            <div className="status-steps">
                <div
                    className={
                        isCurrentStep(step) ? 'current-step' : 'icon-style'
                    }
                >
                    {statusIcon(step as TaxPrepStatus, index)}
                </div>
                <div
                    className={lastStepIndex === index ? '' : styles.stepLine}
                ></div>
            </div>
            <div>
                <div className={styles.contentStyles}>
                    {step.code !== 'completed' && isCurrentStep(step) && (
                        <UiText
                            variant="moped_75"
                            weight="semi_bold_600"
                            className={styles.currentStepChip}
                        >
                            Current Step
                        </UiText>
                    )}
                    <UiText
                        className={styles.stepTitle}
                        variant="hatchback_125"
                        weight="semi_bold_600"
                    >
                        {(currentTaxApStatus ===
                        ApStatusMapping.ADDITIONAL_INFO_REQUEST_SENT && isCurrentStep(step)) 
                            ? AdditionalStatusMapping.title
                            : StatusTitleMapping[step.code].title}
                    </UiText>
                </div>
                <div className={styles.stepHint}>
                    {isCurrentStep(step) &&
                        (currentTaxApStatus ===
                        ApStatusMapping.ADDITIONAL_INFO_REQUEST_SENT ? (
                            <>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: AdditionalStatusMapping.hint,
                                    }}
                                ></span>
                                <TaxPrepStepButton step={step} />
                            </>
                        ) : (
                            <>
                                <UiText variant="motorcycle_90">
                                    {step.code === 'started' ? (
                                        <StartedInfo
                                            percentage={percentage}
                                            selectedYear={selectedYear}
                                            vtoType={isBusinessVTO}
                                        />
                                    ) : (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    StatusTitleMapping[
                                                        step.code
                                                    ].hint ||
                                                    (currentStep?.hint as string),
                                            }}
                                        ></span>
                                    )}
                                </UiText>
                                {currentStep.title === STATUS_CLIENT_REVIEW &&
                                    renderTaxRefundInfo()}
                                <div className={styles.linearProgressBar}>
                                    {/* TODO: Implement the feature */}
                                    {currentStep.title === 'Started' && (
                                        <LinearProgressBar
                                            percentage={percentage}
                                        />
                                    )}
                                </div>
                                <TaxPrepStepButton step={step} />
                            </>
                        ))}
                </div>
            </div>
        </div>
    )

    let inCompleteStepsToRender: any[] = []
    let completedStepsToRender: any[] = []


    

    updatedTaxPrepStatuses.forEach((step: any, index: number) => {
        // exclude the last step from the accordion
        if (step.completed && updatedTaxPrepStatuses.length - 1 !== index) {
            completedStepsToRender.push(renderStep(step, index))
        } else {
            inCompleteStepsToRender.push(renderStep(step, index))
        }
    })

    return (
        <>
            <div className={styles.container}>
                {completedStepsToRender.length > 1 && <Accordion
                    className={styles.accordion}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-content"
                        id="panel-header"
                    >
                        <div className={styles.accordionExpanded}>
                            <div>
                                <Icon
                                    icon={
                                        'CompleteIconPrimary' as keyof typeof IconList
                                    }
                                    size={'24px'}
                                    svgColor={colorTheme.tertiary}
                                />
                                <UiText
                                    variant="motorcycle_90"
                                    weight="medium_500"
                                >
                                    Show {completedStepsToRender.length} Completed Steps
                                </UiText>
                            </div>
                            <div className={styles.accordionStep}>
                                <div className={styles.stepLine}></div>
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {completedStepsToRender}
                    </AccordionDetails>
                </Accordion>}
                {inCompleteStepsToRender}
            </div>
            <StatesModal
                open={openStates}
                handleClose={() => setOpenStates(false)}
            />
        </>
    )
}

export default TaxPreparationStatuses
